import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Title, LayoutPaper } from '@riseart/dashboard';
import { useScrollToEl } from '../../../data/hooks/useScrollToEl';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { DefaultLayout } from '../../layout/Default';
import { SellerProfileForm } from './ProfileForm';

/**
 * SellerProfilePage
 *
 * @returns {JSX.Element}
 */
export const SellerProfilePage = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [sellerName, setSellerName] = useState(null);
  const { formatMessage } = useIntl();
  const { setScrollRef, scrollToRef } = useScrollToEl();

  /**
   * handleRead
   *
   * @param {Record<string, any> | null} seller
   */
  function handleRead(seller: Record<string, any> | null) {
    if (seller && seller.name && sellerName !== seller.name) {
      setSellerName(seller.name);
    }
  }

  return (
    <DefaultLayout
      loading={loading}
      title={formatMessage({ id: 'components.mainMenu.seller' })}
      breadcrumbs={[
        <Link
          key="home"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link
          key="sellerProfile"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('sellerProfile')}
          title={formatMessage({ id: 'components.mainMenu.seller' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'components.mainMenu.seller' })}
        </Link>,

        ...(sellerName
          ? [
              <Typography key="seller" variant="inherit">
                {sellerName}
              </Typography>,
            ]
          : []),
      ]}
      boxRefCallback={setScrollRef}
    >
      <SellerProfileForm
        title={
          <Title variant="h5">{formatMessage({ id: 'components.mainMenu.sellerProfile' })}</Title>
        }
        submitText={formatMessage({ id: 'common.saveChanges' })}
        scrollToRef={scrollToRef}
        onLoading={setLoading}
        onRead={handleRead}
        wrapperComponent={LayoutPaper}
      />
    </DefaultLayout>
  );
};
