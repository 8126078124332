import { useEffect, useState, SyntheticEvent } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {
  CircularLoader,
  Paragraph,
  Title,
  FileList,
  FileUploadDialog,
  FilePreviewList,
  FileListItem,
  useUploadedFiles,
  FormBuilder,
  FormHeader,
  FormSideItem,
  Link,
} from '@riseart/dashboard';
import { FileUploader } from '@riseart/files';
import { art as ART_CONFIG } from '../../config/config.js';
import {
  product as PRODUCT_ENUM,
  units as UNITS_ENUM,
  file as FILE_ENUM,
  art as ART_ENUM,
  errors as ERRORS_ENUM,
} from '../../config/enumeration.js';
import { useNotification } from '../../data/hooks/useNotification';
import { MediaAddFormModel } from '../forms/models/media';
import { fileListConfirmationProps } from '../common/file/settings';
import { AddArtFormModel, AddArtistArtFormModel } from '../forms/models/art';
import { FileDropzone } from '../common/file/Dropzone';
import { fileListClassName } from '../common/file/ListItem';
import { FileRestrictionsHint } from '../common/file/RestrictionsHint';
import { AddMediaList } from '../common/media/AddList';
import { selectPartner } from '../../services/redux/selectors/partner';
import { selectSeller } from '../../services/redux/selectors/seller';
import { mapApiValidationErrors, mapApiErrors } from '../../services/riseart/utils/Form';
import { mapFormToArtData } from '../../data/normalizers/art';
import { Artist as ArtistModel } from '../../data/models/Artist';
import { createFileUpload, updateFileUpload } from '../../data/gql/handlers';
import { CREATE_ART } from '../../data/gql/queries/art/create.graphql';
import { ADD_ART_MEDIA } from '../../data/gql/queries/art/addMedia.graphql';
import { LIST_SELLER_SHIPPING_TABLES } from '../../data/gql/queries/seller/listShippingTables.graphql';

type Props = {
  artist?: Record<string, any>;
  sellerId: number;
  canRentArt: boolean;
  currencyCode: string;
  storeCode: string;
  artListUrl: string;
  detailArtAssembler: (id: number) => string;
  scrollToRef: () => void;
  onLoading?: (loading: boolean) => void;
};

/**
 * ArtAdd
 *
 * @returns {JSX.Element}
 */
export const ArtAdd = ({
  artist,
  sellerId,
  canRentArt,
  currencyCode,
  storeCode,
  artListUrl,
  detailArtAssembler,
  onLoading,
  scrollToRef,
}: Props): JSX.Element => {
  // Load data from gql and libraries
  const { formatMessage } = useIntl();
  const history = useHistory();
  const partner = useSelector(selectPartner);
  const { canUseShippingTables } = useSelector(selectSeller) || {};
  const { data: shippingTablesData, loading: shippingTablesLoading } = useQuery(
    LIST_SELLER_SHIPPING_TABLES,
    { variables: { sellerId, items: 100 } },
  );
  const [createArt, { data: artData }] = useMutation(CREATE_ART);
  const [addArtMedia] = useMutation(ADD_ART_MEDIA);

  // Component state
  const [mediaItems, setMediaItems] = useState<Record<string, any>[]>([]);
  const [progress, setProgress] = useState<{
    loading: boolean;
    opened: boolean;
    text: string | JSX.Element | null;
    step: 'artwork' | 'media' | 'images' | null;
    additional: Record<string, any> | null;
  }>({
    loading: false,
    opened: false,
    text: null,
    step: null,
    additional: null,
  });
  const [productType, setProductType] = useState<string>('');
  const { dispatchNotification } = useNotification();
  const {
    uploadedFiles,
    handleFileDrop,
    handleFileRemove,
    handleFileValidation,
    hasFilesWithErrors,
  } = useUploadedFiles({
    filesByType: { main: [], detail: [] },
    maxFileSize: ART_CONFIG.maxFilesize,
    sizeValidationMessage: formatMessage(
      { id: 'forms.common.largerFilesize' },
      { maxFilesize: ART_CONFIG.maxFilesize },
    ),
  });
  const FormModel = artist && artist.id ? AddArtFormModel : AddArtistArtFormModel;
  const FormModelFields = FormModel.fields.filter(
    ({ name }) => name !== 'rent' || (canRentArt && name === 'rent'),
  );

  useEffect(() => {
    if (typeof onLoading === 'function') {
      onLoading(shippingTablesLoading);
    }
  }, [shippingTablesLoading]);

  return (
    <>
      <FileUploadDialog
        loadingComponent={CircularLoader}
        opened={progress.opened}
        loading={progress.loading}
        onClose={function handleDialogClose() {
          if (artData && artData.createArt) {
            history.push(detailArtAssembler(artData.createArt.id));
          }
        }}
        hasErrors={
          progress.opened && hasFilesWithErrors(progress.additional && progress.additional.files)
        }
      >
        <Paragraph align="center">{progress.text}</Paragraph>
        {progress.step === 'images' ? (
          <FileList>
            {progress.additional
              ? progress.additional.files.map(
                  ({ name, progress, size, errorMessage }: Record<string, any>, idx: number) => (
                    <FileListItem
                      key={`${name}${idx}`}
                      className={fileListClassName}
                      name={name}
                      size={size}
                      errors={errorMessage}
                      progress={progress || 0}
                      status="loading"
                    />
                  ),
                )
              : null}
          </FileList>
        ) : null}
      </FileUploadDialog>
      <FormHeader
        title={<Title variant="h5">{formatMessage({ id: 'components.art.addArt' })}</Title>}
      />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={5}>
          <>
            <FormSideItem>
              <FileDropzone
                label={formatMessage({ id: 'forms.art.label.mainImage' })}
                text={formatMessage({ id: 'forms.art.hints.mainImage' })}
                textMiddle={formatMessage({ id: 'common.or' })}
                textButton={formatMessage({ id: 'common.browseFiles' })}
                onDrop={handleFileDrop(ART_ENUM.image.category.MAIN)}
                validator={handleFileValidation}
                accept={ART_CONFIG.acceptedFiles}
                multiple={false}
                hint={
                  <FileRestrictionsHint translationKey="forms.art.hints.mainImageUploadRestrictions" />
                }
              />
              {/* @ts-ignore */}
              <FilePreviewList
                listItemProps={{ className: fileListClassName }}
                canDelete
                inputFiles={uploadedFiles.main}
                onDelete={handleFileRemove(ART_ENUM.image.category.MAIN)}
                confirmationDialogProps={fileListConfirmationProps}
              />
            </FormSideItem>
            <FormSideItem>
              <FileDropzone
                label={formatMessage({ id: 'forms.art.label.detailImages' })}
                text={formatMessage({ id: 'forms.art.hints.detailImages' })}
                textMiddle={formatMessage({ id: 'common.or' })}
                textButton={formatMessage({ id: 'common.browseFiles' })}
                onDrop={handleFileDrop(ART_ENUM.image.category.DETAIL, true)}
                validator={handleFileValidation}
                accept={ART_CONFIG.acceptedFiles}
                multiple
                hint={
                  <FileRestrictionsHint translationKey="forms.art.hints.imageUploadRestrictions" />
                }
              />
              <FilePreviewList
                canDelete
                listItemProps={{ className: fileListClassName }}
                inputFiles={uploadedFiles.detail}
                onDelete={handleFileRemove(ART_ENUM.image.category.DETAIL)}
                confirmationDialogProps={fileListConfirmationProps}
              />
            </FormSideItem>
            <FormSideItem>
              <AddMediaList
                formModel={MediaAddFormModel(ART_ENUM.media.platform, ART_ENUM.media.type)}
                typeList={ART_ENUM.media.type}
                initialType={ART_ENUM.media.type.VIDEO}
                onUpdate={setMediaItems}
              />
            </FormSideItem>
          </>
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextField
            type="select"
            label="Type"
            variant="standard"
            margin="normal"
            select
            SelectProps={{ native: false }}
            value={productType}
            fullWidth
            helperText={formatMessage({ id: 'forms.art.hints.type' })}
            required
            onChange={(e: SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              setProductType(target.value);
            }}
          >
            {Object.keys(PRODUCT_ENUM.type).map((key: string) => {
              // @ts-ignore
              const productType = PRODUCT_ENUM.type[key];
              return (
                <MenuItem key={key} value={productType}>
                  {formatMessage({ id: `components.art.product.type.${productType}` })}
                </MenuItem>
              );
            })}
          </TextField>
          {productType ? (
            <FormBuilder
              settings={FormModel.settings}
              fields={FormModelFields}
              initialValues={{ rent: canRentArt }}
              submitText={formatMessage({ id: 'components.art.addArt' })}
              customData={{
                artistPickerItemMapper: (item: Record<string, any>) =>
                  new ArtistModel().hydrateFromApiData(item.artist),
                partnerId: partner && partner.id,
                productType,
                currencyCode,
                storeCode,
                unitSystem: UNITS_ENUM.system.metric,
                canUseShippingTables,
                shippingTables:
                  shippingTablesData &&
                  shippingTablesData.listSellerShippingTables &&
                  shippingTablesData.listSellerShippingTables.items,
              }}
              disableSubmit={
                !(
                  productType &&
                  uploadedFiles.main.length &&
                  uploadedFiles.main.some(
                    (file: Record<string, any>) => !file.errors || !file.errors.length,
                  )
                )
              }
              cancel={
                <Button
                  component={Link}
                  to={artListUrl}
                  type="button"
                  variant="outlined"
                  size="large"
                  fullWidth
                >
                  {formatMessage({ id: 'common.cancel' })}
                </Button>
              }
              onSubmit={async (formState, addApiErrors) => {
                try {
                  // @ts-ignore
                  setProgress({
                    loading: true,
                    opened: true,
                    step: 'artwork',
                    text: formatMessage({ id: `components.dialog.art.steps.addArtwork` }),
                  });
                  const INCLUDE_NULL_VALUES = false;

                  const response = await createArt({
                    variables: {
                      store: storeCode,
                      inputArt: {
                        artistId: (artist && artist.id) || formState.data.artistId,
                        units: UNITS_ENUM.centimeter,
                        productType,
                        ...mapFormToArtData(
                          formState.data,
                          FormModelFields,
                          undefined,
                          INCLUDE_NULL_VALUES,
                        ),
                        ...(canRentArt === false ? { rent: false } : {}),
                      },
                    },
                  });

                  const hasMedia = mediaItems && mediaItems.length;

                  setProgress((prevState) => ({
                    ...prevState,
                    ...(hasMedia
                      ? {
                          step: 'media',
                          text: formatMessage({ id: `components.dialog.art.steps.addMedia` }),
                        }
                      : {
                          step: 'images',
                          text: formatMessage({
                            id: `components.dialog.art.steps.uploadImages`,
                          }),
                        }),
                  }));

                  setTimeout(async () => {
                    if (response.data.createArt.id) {
                      const { id } = response.data.createArt;

                      // Add media items
                      if (hasMedia) {
                        await Promise.allSettled(
                          mediaItems.map((inputArtMedia) =>
                            addArtMedia({ variables: { artId: id, inputArtMedia } }),
                          ),
                        );
                      }

                      // Upload files after artwork is created
                      const FileUploadClient = new FileUploader(
                        FileUploader.flattenFiles(uploadedFiles),
                        response.data.createArt.id,
                        FILE_ENUM.object.type.ART,
                        createFileUpload,
                        updateFileUpload,
                      );
                      FileUploadClient.subscribe(
                        FileUploader.eventsTypes.UPDATE,
                        (fileUploadState) => {
                          setProgress((prevState) => ({
                            ...prevState,
                            additional: fileUploadState,
                          }));
                        },
                      );
                      FileUploadClient.subscribe(
                        FileUploader.eventsTypes.COMPLETE,
                        (fileUploadState) => {
                          const hasErrors = hasFilesWithErrors(fileUploadState.files);
                          setProgress((prevState) => ({
                            ...prevState,
                            loading: false,
                            additional: fileUploadState,
                            text: formatMessage({
                              id: `components.dialog.art.file.${hasErrors ? 'error' : 'success'}`,
                            }),
                          }));

                          if (!hasErrors) {
                            setTimeout(() => history.push(detailArtAssembler(id)), 5000);
                          }
                        },
                      );
                      FileUploadClient.process();
                    }
                  }, 1000);
                } catch (error: any) {
                  // Map validation errors from response
                  const validationError = mapApiValidationErrors(error);

                  if (validationError && validationError.length && addApiErrors) {
                    addApiErrors(validationError);
                  } else {
                    // Map any other type of errors from response (validation without provided fields, or non-validation errors)
                    mapApiErrors(error).forEach((err) => {
                      dispatchNotification({
                        detail: err,
                        level: ERRORS_ENUM.levels.ERROR,
                        placeholder: ERRORS_ENUM.placeholders.PAGE,
                      });
                    });
                  }

                  // Reset loader state
                  setProgress({
                    loading: false,
                    text: null,
                    opened: false,
                    step: null,
                    additional: null,
                  });

                  // Scroll to error
                  scrollToRef();
                }
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};
