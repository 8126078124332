import { ERRORS_ACTIONS } from '../../actions/container';

const DEFAULT_ERROR_STATE = {
  data: [],
};

/**
 * errorsReducer
 *
 * @param {Record<string, any>} state
 * @param {Record<string, any>} action
 * @returns {Record<string, any>}
 */
export function errorsReducer(
  state = DEFAULT_ERROR_STATE,
  action: Record<string, any>,
): Record<string, any> {
  switch (action.type) {
    case ERRORS_ACTIONS.ERROR_ADD:
      return {
        data: [...state.data, { ...action.payload }],
      };
    default:
      return { ...state };
  }
}
