import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { SplitButton } from '@riseart/dashboard';
import { delay } from '../../services/riseart/utils/Utils';
import { UrlAssembler } from '../../services/riseart/utils/UrlAssembler';
import { splitButtonConfirmationProps } from '../common/buttons/settings';
import { DataArtActions } from '../data/art/Actions';

type Props = {
  data: Record<string, any>;
  refetchQuery: () => Promise<any>;
};

/**
 * ArtListActionButton
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const ArtListActionButton = ({ data, refetchQuery }: Props): JSX.Element => {
  const history = useHistory();
  const {
    id,
    productCanUnlist,
    productCanSubmit,
    productCanArchive,
    productCanSellOut,
    productStore,
    canCopy,
  } = data;

  return (
    <DataArtActions
      {...{
        id,
        store: productStore && productStore.storeCode,
        productCanUnlist,
        productCanSubmit,
        productCanArchive,
        productCanSellOut,
        canCopy,
      }}
    >
      {(availableActions, { loading }) => (
        <SplitButton
          disabled={loading}
          mainButtonProps={{
            onClick: () => history.push(UrlAssembler.artDetail(data.id)),
            title: data.title,
            children: <FormattedMessage id="common.details" />,
          }}
          confirmationDialogProps={splitButtonConfirmationProps}
          translationPrefix="components.dialog.art.action"
          options={availableActions.map(({ actionKey, text, actionHandler }) => ({
            actionKey,
            requireConfirmation: true,
            text,
            onClick: () => {
              actionHandler()
                .then((response) => {
                  if (
                    actionKey === 'canCopy' &&
                    response &&
                    response.data &&
                    response.data.actionArt &&
                    response.data.actionArt.artId
                  ) {
                    delay(() =>
                      history.push(UrlAssembler.artDetail(response.data.actionArt.artId)),
                    );
                  } else {
                    refetchQuery && refetchQuery();
                  }
                })
                .catch(() => null);
            },
          }))}
        />
      )}
    </DataArtActions>
  );
};
