import get from 'lodash/get';
import { ReactChild } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CircularLoader } from '@riseart/dashboard';
import { components as CONFIG_COMPONENTS } from '../../config/config.js';
import { legal as ENUM_LEGAL } from '../../config/enumeration.js';
import { LocationManager } from '../../services/riseart/Location';
import { UrlAssembler } from '../../services/riseart/utils/UrlAssembler';
import { selectSeller } from '../../services/redux/selectors/seller';
import { LIST_SELLER_LEGALS } from '../gql/queries/seller/listLegals.graphql';
import { READ_LEGAL } from '../../data/gql/queries/legal/read.graphql';

type Props = {
  routeConfig: Record<string, any> | null | undefined;
  children?: ReactChild | ReactChild[] | null;
};

/**
 * TermsProvider
 *
 * @param {props} props
 * @returns {JSX.Element}
 */
export const TermsProvider = ({ routeConfig, children }: Props): any => {
  const { id: sellerId } = useSelector(selectSeller) || {};
  const { loading: readLegalLoading, data: readLegalData } = useQuery(READ_LEGAL, {
    skip: !sellerId,
    variables: { type: ENUM_LEGAL.type.SELLER_TERMS },
  });
  const legalId = get(readLegalData, 'readLegal.id');
  const {
    loading: listLegalsLoading,
    data: listLegalsData,
    error: listLegalsError,
  } = useQuery(LIST_SELLER_LEGALS, {
    skip: !sellerId || !legalId,
    variables: { sellerId, filters: { legal: legalId } },
  });
  const isAlreadyAccepted = get(listLegalsData, 'listSellerLegals.items', []).length > 0;

  // Loading state
  if (listLegalsLoading || readLegalLoading) {
    return <CircularLoader active fullHeight />;
  }

  // If not seller terms found
  if (!legalId) {
    return children;
  }

  // Terms are already accepted by seller
  if (isAlreadyAccepted) {
    return get(routeConfig, 'key') === 'terms' ? (
      <Redirect to={UrlAssembler.byRouteKey('home')} />
    ) : (
      children
    );
  }

  // Terms are not accepted by seller
  if (
    CONFIG_COMPONENTS.terms.ignoreRouteKeys.indexOf(get(routeConfig, 'key')) === -1 &&
    (!isAlreadyAccepted ||
      (listLegalsError &&
        listLegalsError.graphQLErrors &&
        listLegalsError.graphQLErrors.some(
          ({ errorInfo }: Record<string, any>) => errorInfo && errorInfo.status === 404,
        )))
  ) {
    return (
      <Redirect
        to={{
          pathname: UrlAssembler.byRouteKey('terms'),
          search: `?forward=${encodeURIComponent(LocationManager.get('uri'))}`,
        }}
      />
    );
  }

  return children;
};
