import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { menuItemsFinder, PageSubmenu as DashboardPageSubmenu } from '@riseart/dashboard';
import { ROUTER_CONFIG } from '../../config/router.js';
import { selectAuth } from '../../services/redux/selectors/auth';
import { UrlAssembler } from '../../services/riseart/utils/UrlAssembler';
import { isRoleAllowedForACL } from '../../services/riseart/utils/Auth';

type Props = {
  menu?: string;
  selected: string;
  urlParams?: Record<string, any> | null;
};

/**
 * PageSubmenu
 *
 * @returns {JSX.Element}
 */
export function PageSubmenu({ menu, selected, urlParams = null }: Props): JSX.Element {
  const { formatMessage } = useIntl();
  const userRole = get(useSelector(selectAuth), 'payload.acl_role');
  const items =
    (menu &&
      menuItemsFinder(ROUTER_CONFIG, menu, userRole, {
        urlParams,
        isRoleAllowedForACL,
        UrlAssembler,
        formatMessage: ({ id }) => formatMessage({ id: `components.${menu}.tab.${id}` }),
      })) ||
    [];

  return <DashboardPageSubmenu selected={selected} items={items} />;
}
