// @ts-nocheck

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  CircularLoader,
  Paragraph,
  Title,
  FileList,
  FileListItem,
  FileUploadDialog,
  FilePreviewList,
  useUploadedFiles,
  FormBuilder,
  FormWrapper,
  FormHeader,
  LayoutPaper,
} from '@riseart/dashboard';
import { FileUploader } from '@riseart/files';
import { event as EVENT_CONFIG } from '../../../config/config.js';
import {
  file as FILE_ENUM,
  errors as ERRORS_ENUM,
  event as EVENT_ENUM,
  currency as CURRENCY_ENUM,
} from '../../../config/enumeration.js';
import { useScrollToEl } from '../../../data/hooks/useScrollToEl';
import { useNotification } from '../../../data/hooks/useNotification';
import { fileListConfirmationProps } from '../../common/file/settings';
import { DefaultLayout } from '../../layout/Default';
import { selectUser } from '../../../services/redux/selectors/user';
import { selectSeller } from '../../../services/redux/selectors/seller';
import { selectPartner } from '../../../services/redux/selectors/partner';
import { FileDropzone } from '../../common/file/Dropzone';
import { fileListClassName } from '../../common/file/ListItem';
import { FileRestrictionsHint } from '../../common/file/RestrictionsHint';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { EventFormModel } from '../../forms/models/event';
import { Event as EventModel } from '../../../data/models/Event';
import { mapApiValidationErrors, mapApiErrors } from '../../../services/riseart/utils/Form';
import { createFileUpload, updateFileUpload } from '../../../data/gql/handlers';
import { CREATE_EVENT } from '../../../data/gql/queries/event/create.graphql';

/**
 * EventAddPage
 *
 * @returns {JSX.Element}
 */
export const EventAddPage = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { setScrollRef, scrollToRef } = useScrollToEl();
  const { dispatchNotification } = useNotification();
  const user = useSelector(selectUser);
  const partner = useSelector(selectPartner);
  const { storeCode } = useSelector(selectSeller) || {};
  const [createEvent, { data: eventData }] = useMutation(CREATE_EVENT);
  const {
    uploadedFiles,
    handleFileDrop,
    handleFileRemove,
    handleFileValidation,
    hasFilesWithErrors,
  } = useUploadedFiles({
    filesByType: { main: [] },
    maxFileSize: EVENT_CONFIG.maxFilesize,
    sizeValidationMessage: formatMessage(
      { id: 'forms.common.largerFilesize' },
      { maxFilesize: EVENT_CONFIG.maxFilesize },
    ),
  });
  const [progress, setProgress] = useState<{
    loading: boolean;
    opened: boolean;
    text: string | JSX.Element | null;
    step: 'event' | 'images' | null;
    additional: Record<string, any> | null;
  }>({
    loading: false,
    opened: false,
    text: null,
    step: null,
    additional: null,
  });

  return (
    <DefaultLayout
      title={formatMessage({ id: 'components.mainMenu.eventList' })}
      breadcrumbs={[
        <Link
          key="home"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link
          key="eventList"
          component={RouterLink}
          to={UrlAssembler.eventList()}
          title={formatMessage({ id: 'components.mainMenu.eventList' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'components.mainMenu.eventList' })}
        </Link>,
        <Typography key="addEvent" variant="inherit">
          {formatMessage({ id: 'components.event.add' })}
        </Typography>,
      ]}
      boxRefCallback={setScrollRef}
    >
      <LayoutPaper>
        <FileUploadDialog
          loadingComponent={CircularLoader}
          opened={progress.opened}
          loading={progress.loading}
          onClose={function handleDialogClose() {
            if (eventData && eventData.createEvent) {
              history.push(UrlAssembler.eventEdit(eventData.createEvent.id));
            }
          }}
          hasErrors={
            progress.opened && hasFilesWithErrors(progress.additional && progress.additional.files)
          }
        >
          <Paragraph align="center">{progress.text}</Paragraph>
          {progress.step === 'images' ? (
            <FileList>
              {progress.additional
                ? progress.additional.files.map(
                    ({ name, progress, size, errorMessage }: Record<string, any>, idx: number) => (
                      <FileListItem
                        key={`${name}${idx}`}
                        className={fileListClassName}
                        name={name}
                        size={size}
                        errors={errorMessage}
                        progress={progress || 0}
                        status="loading"
                      />
                    ),
                  )
                : null}
            </FileList>
          ) : null}
        </FileUploadDialog>
        <FormHeader
          title={<Title variant="h5">{formatMessage({ id: 'components.event.add' })}</Title>}
        />
        <FormWrapper size="medium">
          <FileDropzone
            label={formatMessage({ id: 'forms.event.label.image' })}
            text={formatMessage({ id: 'forms.event.hints.image' })}
            textMiddle={formatMessage({ id: 'common.or' })}
            textButton={formatMessage({ id: 'common.browseFiles' })}
            // @ts-ignore
            onDrop={handleFileDrop(EVENT_ENUM.image.category.MAIN)}
            validator={handleFileValidation}
            accept={EVENT_CONFIG.acceptedFiles}
            multiple={false}
            hint={
              <FileRestrictionsHint translationKey="forms.event.hints.imageUploadRestrictions" />
            }
          />
          {/* @ts-ignore */}
          <FilePreviewList
            canDelete
            listItemProps={{ className: fileListClassName }}
            inputFiles={uploadedFiles.main}
            confirmationDialogProps={fileListConfirmationProps}
            // @ts-ignore
            onDelete={handleFileRemove(EVENT_ENUM.image.category.MAIN)}
          />
          <FormBuilder
            settings={EventFormModel.settings}
            fields={EventFormModel.fields}
            submitText={formatMessage({ id: 'components.event.add' })}
            customData={{ storeCode }}
            initialValues={{
              visibility: EVENT_ENUM.visibility.PUBLIC,
              rsvpShow: true,
              rsvpType: [],
              ticketCurrency: CURRENCY_ENUM.codes[storeCode] || CURRENCY_ENUM.codes.uk,
            }}
            disableSubmit={
              !(
                uploadedFiles.main.length &&
                uploadedFiles.main.some(
                  (file: Record<string, any>) => !file.errors || !file.errors.length,
                )
              )
            }
            cancel={
              <Button
                component={RouterLink}
                to={UrlAssembler.eventList()}
                type="button"
                variant="outlined"
                size="large"
                fullWidth
              >
                {formatMessage({ id: 'common.cancel' })}
              </Button>
            }
            onSubmit={async (formState, addApiErrors) => {
              try {
                setProgress({
                  loading: true,
                  opened: true,
                  step: 'event',
                  text: formatMessage({ id: `components.dialog.event.steps.addEvent` }),
                  additional: null,
                });
                const response = await createEvent({
                  variables: {
                    inputEvent: {
                      userId: user.id,
                      ...(partner && partner.id ? { partnerId: partner.id } : null),
                      ...(!partner && user && user.id ? { userId: user.id } : null),
                      ...EventModel.mapFormToApiData(formState.data, EventFormModel.fields),
                    },
                  },
                });

                setProgress((prevState) => ({
                  ...prevState,
                  step: 'images',
                  text: formatMessage({ id: `components.dialog.event.steps.uploadingImage` }),
                }));

                setTimeout(() => {
                  // Upload files after event is created
                  if (response.data.createEvent.id) {
                    const { id } = response.data.createEvent;
                    const FileUploadClient = new FileUploader(
                      FileUploader.flattenFiles(uploadedFiles),
                      id,
                      FILE_ENUM.object.type.EVENT,
                      createFileUpload,
                      updateFileUpload,
                    );
                    FileUploadClient.subscribe(
                      FileUploader.eventsTypes.UPDATE,
                      (fileUploadState) => {
                        setProgress((prevState) => ({
                          ...prevState,
                          additional: fileUploadState,
                        }));
                      },
                    );
                    FileUploadClient.subscribe(
                      FileUploader.eventsTypes.COMPLETE,
                      (fileUploadState) => {
                        const hasErrors = hasFilesWithErrors(fileUploadState.files);
                        setProgress((prevState) => ({
                          ...prevState,
                          loading: false,
                          additional: fileUploadState,
                          text: formatMessage({
                            id: `components.dialog.event.file.${hasErrors ? 'error' : 'success'}`,
                          }),
                        }));

                        if (!hasErrors) {
                          setTimeout(() => history.push(UrlAssembler.eventEdit(id)), 5000);
                        }
                      },
                    );

                    FileUploadClient.process();
                  }
                }, 2000);
              } catch (error: any) {
                // Map validation errors from response
                const validationError = mapApiValidationErrors(error);

                if (validationError && validationError.length && addApiErrors) {
                  addApiErrors(validationError);
                } else {
                  // Map any other type of errors from response (validation without provided fields, or non-validation errors)
                  mapApiErrors(error).forEach((err) => {
                    dispatchNotification({
                      detail: err,
                      level: ERRORS_ENUM.levels.ERROR,
                      placeholder: ERRORS_ENUM.placeholders.PAGE,
                    });
                  });
                }

                // Reset loader state
                setProgress({
                  loading: false,
                  text: null,
                  opened: false,
                  step: null,
                  additional: null,
                });

                // Scroll to error
                scrollToRef();
              }
            }}
          />
        </FormWrapper>
      </LayoutPaper>
    </DefaultLayout>
  );
};
