import { gql } from '@apollo/client';
import { ARTIST_FRAGMENT } from '../../fragments/artist/artist.graphql';

export const READ_ARTIST = gql`
  ${ARTIST_FRAGMENT}
  query readArtist($id: ID!) {
    readArtist(id: $id) {
      ...ArtistFragment
    }
  }
`;
