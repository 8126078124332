import React, { useEffect, useState } from 'react';
import { libs as LIBS_CONFIG } from '../../../config/config.js';

import styles from './Button.module.css';

type Props = {
  setToken: (token: Record<string, any>) => void;
  text?: string;
  locale?: string;
};

/**
 * GoogleButton
 *
 * @param {Props} props
 * @returns {React.FC<Props>}
 *
 */
export const GoogleButton: React.FC<Props> = ({ setToken, text, locale }: Props) => {
  const BTN_ID = 'googleLoginBtn';
  const [libLoaded, setLibLoaded] = useState(0);

  useEffect(() => {
    const isGoogleAuthInit =
      typeof window !== 'undefined' &&
      // @ts-ignore
      window.google &&
      // @ts-ignore
      window.google.accounts &&
      // @ts-ignore
      window.google.accounts.id &&
      // @ts-ignore
      window.googleAccountsIdInitialized;
    if (isGoogleAuthInit && libLoaded <= 0) {
      setLibLoaded(1);
      // @ts-ignore
      window.google.accounts.id.renderButton(document.getElementById(BTN_ID), {
        theme: 'filled_black',
        text,
        logo_alignment: 'left',
        ...(locale ? { locale } : null),
      });

      // @ts-ignore
      window[LIBS_CONFIG.google.callbackName] = setToken;
    } else if (libLoaded <= 0) {
      setTimeout(() => {
        setLibLoaded(libLoaded - 1);
      }, 200);
    }
  }, [text, libLoaded, setToken]);

  /**
   * @returns {Node}
   */
  return <div id={BTN_ID} className={styles.googleBtnContainer} />;
};
