import { gql } from '@apollo/client';

export const SORT_ARTIST_ART = gql`
  mutation sortArtistArt($artistId: Int!, $inputArtSort: [InputArtSort!]) {
    sortArtistArt(artistId: $artistId, inputArtSort: $inputArtSort) {
      id
      sort
    }
  }
`;
