import { gql } from '@apollo/client';
import { EVENT_BASE_FRAGMENT } from './base.graphql';

export const EVENT_FRAGMENT = gql`
  ${EVENT_BASE_FRAGMENT}
  fragment EventFragment on Event {
    ...EventBaseFragment
    venueName
    venueAddress
    venueCity
    venuePostCode
    venueCountryCode
    venuePhone
    rsvpType
    rsvpSend
    rsvpShow
    isFinished
    canUpdate
    canActivate
    canCancel
    canRsvp
    visibility
    ticketAmount
    ticketCurrency
    ticketMaxNumber
    creatorType
    creatorId
    creatorRole
    creatorName
    artistId
    artistAlias
    artistName
    images {
      id
      status
      public
      mimeType
      key
      extension
      size
      width
      height
      type
      active
    }
  }
`;
