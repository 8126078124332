import { FormattedMessage } from 'react-intl';
import MenuItem from '@mui/material/MenuItem';
import { find } from '@riseart/fe-utils';
import { payment as PAYMENT } from '../../../config/directory.js';

/**
 * paymentTypeOptions
 *
 * @returns {JSX.Element[]}
 */
export function paymentTypeOptions(): JSX.Element[] {
  return PAYMENT.types.map(({ code }: { code: string; name: string }) => (
    <MenuItem key={code} value={code}>
      <FormattedMessage id={`components.sellerPayment.method.${code}`} />
    </MenuItem>
  ));
}

export function getPaymentAttributes(paymentTypeCode: string): Record<string, any> | undefined {
  return find(PAYMENT.types, ({ code }: { code: string }) => code === paymentTypeCode);
}

/**
 * SellerPaymentsFormModel
 */
export const SellerPaymentsFormModel = {
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      tag: 'input',
      type: 'text',
      name: 'sort_code',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 6 },
          message: 'forms.validation.maxLength6',
        },
        {
          type: 'regex',
          pattern: '^[0-9]{6}$',
          message: 'forms.sellerPayment.validation.sortCode',
        },
      ],
      placeholder: 'forms.sellerPayment.label.sortCode',
      hint: 'forms.sellerPayment.hints.sortCode',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'account_number',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 8 },
          message: 'forms.validation.maxLength8',
        },
        {
          type: 'regex',
          pattern: '^[0-9]{8}$',
          message: 'forms.sellerPayment.validation.accountNumberUK',
        },
      ],
      placeholder: 'forms.sellerPayment.label.accountNumber',
      hint: 'forms.sellerPayment.hints.accountNumberUK',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'swift',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 11 },
          message: 'forms.validation.maxLength11',
        },
      ],
      placeholder: 'forms.sellerPayment.label.swift',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'routing_number',
      validateTrigger: 'onBlur',
      placeholder: 'forms.sellerPayment.label.routingNumber',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'iban',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      placeholder: 'forms.sellerPayment.label.iban',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'bank_name',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      placeholder: 'forms.sellerPayment.label.bankName',
    },
    {
      tag: 'input',
      type: 'text',
      multiline: true,
      minRows: 4,
      name: 'bank_address',
      validateTrigger: 'onBlur',
      placeholder: 'forms.sellerPayment.label.bankAddress',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'holder_name',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      placeholder: 'forms.sellerPayment.label.holderName',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'holder_address',
      multiline: true,
      minRows: 4,
      validateTrigger: 'onBlur',
      placeholder: 'forms.sellerPayment.label.holderAddress',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'account_name',
      validateTrigger: 'onBlur',
      placeholder: 'forms.sellerPayment.label.accountName',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'email_address',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'email',
          message: 'forms.auth.validation.invalidEmail',
        },
      ],
      placeholder: 'forms.sellerPayment.label.emailAddress',
    },
  ],
};

/**
 * SellerPaymentsFormModelByType
 *
 * Use the fields and settings from SellerPaymentsFormModel but overwrites fileds for payment types, if required
 */
export const SellerPaymentsFormModelByType: Record<
  string,
  {
    settings: Record<string, any>;
    fields: Record<string, any>[];
  }
> = {
  international_bank: {
    settings: { ...SellerPaymentsFormModel.settings },
    fields: [
      ...SellerPaymentsFormModel.fields.map((field: Record<string, any>) => {
        if (field.name === 'bank_address') {
          return {
            tag: 'input',
            type: 'text',
            multiline: true,
            minRows: 4,
            name: 'bank_address',
            validateTrigger: 'onBlur',
            rules: [
              {
                required: true,
                message: 'forms.validation.required',
              },
            ],
            placeholder: 'forms.sellerPayment.label.bankAddress',
          };
        } else if (field.name === 'account_number') {
          return {
            tag: 'input',
            type: 'text',
            name: 'account_number',
            validateTrigger: 'onBlur',
            rules: [
              {
                required: true,
                message: 'forms.validation.required',
              },
              {
                type: 'regex',
                pattern: '^[0-9a-zA-Z-/]+$',
                message: 'forms.sellerPayment.validation.accountNumber',
              },
            ],
            placeholder: 'forms.sellerPayment.label.accountNumber',
            hint: 'forms.sellerPayment.hints.accountNumber',
          };
        }

        return field;
      }),
    ],
  },
};
