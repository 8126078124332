import MenuItem from '@mui/material/MenuItem';
import { FormSelectNoneOption } from '@riseart/dashboard';
import { DEFAULT_CHECKBOX_PROPS } from '../input/Checkbox';

/**
 * excludedRelatedTables
 *
 * @param {Record<string, any>} table
 * @param {Record<string, any>[]} tablesList
 * @param {string | number} findId
 * @param {number[]} initial
 * @returns {number[]}
 */
function excludedRelatedTables(
  table: Record<string, any>,
  tablesList: Record<string, any>[],
  findId: number,
  initial: number[] = [],
): number[] {
  return tablesList.reduce((accumulator: number[], item: Record<string, any>) => {
    if (!table.parentId) {
      return accumulator;
    }

    if (parseInt(table.id, 10) === parseInt(item.parentId, 10)) {
      // Found item that is related to the table id
      if (accumulator.indexOf(parseInt(item.id, 10)) === -1) {
        accumulator.push(parseInt(item.id, 10));
      }

      return accumulator;
    }

    if (parseInt(table.parentId, 10) === parseInt(item.id, 10)) {
      if (findId === parseInt(item.parentId, 10)) {
        // Found item that is related to the table id
        if (accumulator.indexOf(parseInt(item.id, 10)) === -1) {
          accumulator.push(parseInt(item.id, 10));
        }
        return accumulator;
      } else {
        // Check recursively for parents
        return excludedRelatedTables(item, tablesList, findId, accumulator);
      }
    }

    return accumulator;
  }, initial);
}

/**
 * parentTablesOptions
 *
 * @param {{ initialOption: any; customData: any; }} options
 * @returns {Record<string, any>[]}
 */
function parentTablesOptions({
  customData: { shippingTables, tableData },
  initialOption,
}: {
  initialOption: any;
  customData: any;
}): Record<string, any>[] {
  // Tables which have their parents or parents' parents set as the current selected table
  // This does not apply for adding new table
  const invalidTables = tableData
    ? excludedRelatedTables(tableData, shippingTables, parseInt(tableData.id, 10))
    : [];

  return [
    initialOption,
    ...(shippingTables
      ? shippingTables
          .filter(({ id }: { id: string }) => invalidTables.indexOf(parseInt(id, 10)) === -1)
          .map(({ id, name }: { id: string; name: string }) => {
            return (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            );
          })
      : []),
  ];
}

/**
 * AddSellerTableFormModel
 */
export const AddSellerTableFormModel = {
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      componentProps: DEFAULT_CHECKBOX_PROPS,
      tag: 'checkbox',
      type: 'text',
      name: 'default',
      validateTrigger: 'onChange',
      placeholder: 'forms.sellerTable.label.isDefault',
      hint: 'forms.sellerTable.hints.isDefault',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'name',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      placeholder: 'forms.sellerTable.label.name',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'parentId',
      initialOption: FormSelectNoneOption(),
      selectOptions: parentTablesOptions,
      validateTrigger: 'onBlur',
      placeholder: 'forms.sellerTable.label.parentTable',
      hint: 'forms.sellerTable.hints.parentTable',
    },
  ],
};

/**
 * EditSellerTableFormModel
 */
export const EditSellerTableFormModel = {
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      tag: 'input',
      type: 'text',
      name: 'name',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      placeholder: 'forms.sellerTable.label.name',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'parentId',
      initialOption: FormSelectNoneOption(),
      selectOptions: parentTablesOptions,
      validateTrigger: 'onBlur',
      placeholder: 'forms.sellerTable.label.parentTable',
      hint: 'forms.sellerTable.hints.parentTable',
    },
  ],
};
