import { Redirect } from 'react-router-dom';
import { ErrorBoundary } from '../../data/errors/ErrorBoundary';
import { ErrorPageRenderer } from '../../data/errors/ErrorPageRenderer';
import { ErrorBoundaryPage } from '../pages/error/Boundary';
import { RouterLocale } from './Locale';
import { I18nProvider } from '../i18n/I18nProvider';
import { LocationManager } from '../../services/riseart/Location';
import { ACLContext } from '../../data/acl/ACLContext';
import { ACLProvider } from '../../data/acl/ACLProvider';
import { RouteConfigContext } from '../../data/route/ConfigContext';
import { UrlAssembler } from '../../services/riseart/utils/UrlAssembler';

/**
 * RouterPage
 *
 * @param {Record<string, any>} props
 * @returns {JSX.Element}
 */
export const RouterPage = ({
  isSSR,
  pageRouteConfig,
  location,
  match,
  children,
  tokenPayload,
}: Record<string, any>): JSX.Element => (
  <RouterLocale isSSR={isSSR} urlLanguage={match.params.lang} location={location}>
    {({
      userLocale,
      routeLocale,
      guiLocale: currentLocale,
    }: {
      userLocale: Record<string, any> | null;
      routeLocale: Record<string, any>;
      guiLocale: Record<string, any>;
    }): JSX.Element => (
      <I18nProvider locale={currentLocale} routeLocale={routeLocale}>
        <ErrorBoundary errorRender={(error: Error) => <ErrorBoundaryPage error={error} />}>
          <ErrorPageRenderer>
            <RouteConfigContext.Provider
              value={{
                ...pageRouteConfig,
                location,
                currentParams: match.params,
                currentLocale,
                userLocale,
                routeLocale,
              }}
            >
              <ACLContext.Provider
                value={pageRouteConfig && pageRouteConfig.acl && pageRouteConfig.acl.roles}
              >
                <ACLProvider
                  profileRole={(tokenPayload && tokenPayload.acl_role) || '*'}
                  render={(isAllowed: boolean) =>
                    isAllowed ? (
                      children({ currentLocale })
                    ) : (
                      <Redirect
                        to={{
                          pathname: UrlAssembler.byRouteKey(
                            (pageRouteConfig &&
                              pageRouteConfig.acl &&
                              pageRouteConfig.acl.authPage &&
                              pageRouteConfig.acl.authPage.key) ||
                              'login',
                            {
                              ...((pageRouteConfig &&
                                pageRouteConfig.acl &&
                                pageRouteConfig.acl.authPage &&
                                pageRouteConfig.acl.authPage.options) ||
                                {}),
                            },
                          ),
                          search: `?forward=${encodeURIComponent(LocationManager.get('uri'))}`,
                        }}
                      />
                    )
                  }
                />
              </ACLContext.Provider>
            </RouteConfigContext.Provider>
          </ErrorPageRenderer>
        </ErrorBoundary>
      </I18nProvider>
    )}
  </RouterLocale>
);
