import { ErrorService } from '../../../riseart/errors/ErrorService';

export const ERRORS_ACTIONS = {
  ERROR_ADD: 'ERROR_ADD',
};

//Action Creators
export function errorAdd(error: Record<string, any>): {
  type: string;
  payload: Record<string, any>;
} {
  const payload = ErrorService.buildPayload(error);

  return {
    type: ERRORS_ACTIONS.ERROR_ADD,
    payload,
  };
}
