import { gql } from '@apollo/client';
import { ARTIST_DEGREE_FRAGMENT } from '../../fragments/artist/degree.graphql';

export const LIST_ARTIST_DEGREES = gql`
  ${ARTIST_DEGREE_FRAGMENT}
  query listArtistDegrees($artistId: Int!) {
    listArtistDegrees(artistId: $artistId) {
      items {
        ...ArtistDegreeFragment
      }
    }
  }
`;
