import { gql } from '@apollo/client';
import { ARTIST_EXHIBITION_FRAGMENT } from '../../fragments/artist/exhibition.graphql';

export const CREATE_ARTIST_EXHIBITION = gql`
  ${ARTIST_EXHIBITION_FRAGMENT}
  mutation createArtistExhibition($artistId: Int!, $inputArtistExhibition: InputArtistExhibition!) {
    createArtistExhibition(artistId: $artistId, inputArtistExhibition: $inputArtistExhibition) {
      ...ArtistExhibitionFragment
    }
  }
`;
