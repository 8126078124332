import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import { DataTable, Link, LAYOUT_PAPER_PADDING } from '@riseart/dashboard';
import { artist as ARTIST_ENUM } from '../../../config/enumeration.js';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { DefaultLayout } from '../../layout/Default';
import { DataListFeed } from '../../data/ListFeed';
import { StatusSelectButton } from '../../common/buttons/StatusSelect';
import { LIST_PARTNER_ARTISTS } from '../../../data/gql/queries/partner/listArtists.graphql';
import { PartnerArtistPaginationResult as PartnerArtistPaginationResultModel } from '../../../data/models/Partner';
import { selectPartner } from '../../../services/redux/selectors/partner';
import { PARTNER_ARTIST_LIST_TABLE_SCHEMA_ADVANCED } from '../../data/tables/schemas/artist';

/**
 * ArtistListPage
 *
 * @returns {JSX.Element}
 */
export const ArtistListPage = (): JSX.Element => {
  const ITEMS_PER_PAGE = 25;
  const partner = useSelector(selectPartner);
  const { formatMessage } = useIntl();
  const addLabel = formatMessage({ id: 'components.artist.add' });
  const pageTitle = formatMessage({ id: 'components.mainMenu.artistsList' });
  const DEFAULT_FILTER_VALUE = [ARTIST_ENUM.status.PENDING, ARTIST_ENUM.status.ACTIVE].join(',');

  return (
    <DefaultLayout
      title={pageTitle}
      includesFab
      breadcrumbs={[
        <Link
          key="home"
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Typography key="artists" variant="inherit">
          {pageTitle}
        </Typography>,
      ]}
    >
      <Paper sx={{ position: 'relative' }}>
        {partner ? (
          <DataListFeed
            query={LIST_PARTNER_ARTISTS}
            fetchPolicy="cache-and-network"
            itemsPerPage={ITEMS_PER_PAGE}
            buildUrl={({
              page,
              show = DEFAULT_FILTER_VALUE,
              itemsPerPage = ITEMS_PER_PAGE,
              qsParams,
            }: Record<string, any>) => {
              const search = queryString.stringify({
                ...qsParams,
                ...(page && page > 1 ? { page } : null),
                ...(show !== DEFAULT_FILTER_VALUE ? { show } : null),
                ...(itemsPerPage && itemsPerPage !== ITEMS_PER_PAGE ? { itemsPerPage } : null),
              });
              return UrlAssembler.artistList({ search: search ? `?${search}` : '' });
            }}
            variablesMapper={({
              page = 1,
              itemsPerPage,
              show,
            }: Record<string, any>): Record<string, any> => ({
              partnerId: partner.id,
              page,
              items: itemsPerPage || ITEMS_PER_PAGE,
              sort: 'artistName',
              order: 'ASC',
              filters: {
                artistStatus: [null, undefined].indexOf(show) === -1 ? show : DEFAULT_FILTER_VALUE,
              },
            })}
          >
            {({
              loading,
              refetchListQuery,
              items,
              pagination,
              initialValues,
              handleParamsChange,
            }: Record<string, any>) => {
              const PartnerArtistPaginationResult =
                new PartnerArtistPaginationResultModel().hydrateFromApiData({ items, pagination });

              return (
                <DataTable
                  columns={[
                    {
                      title: formatMessage({ id: 'components.tables.columns.common.id' }),
                      sx: { textAlign: 'right', pl: LAYOUT_PAPER_PADDING.pl, whiteSpace: 'nowrap' },
                      width: '10%',
                    },
                    {
                      title: formatMessage({ id: 'components.tables.columns.common.name' }),
                      sx: { display: { xs: 'none', sm: 'table-cell' }, whiteSpace: 'nowrap' },
                      width: '30%',
                    },
                    {
                      title: formatMessage({ id: 'components.tables.columns.common.country' }),
                      sx: { display: { xs: 'none', sm: 'table-cell' }, whiteSpace: 'nowrap' },
                      width: '25%',
                    },
                    {
                      title: formatMessage({ id: 'components.tables.columns.common.status' }),
                      width: '15%',
                    },
                    {
                      title: formatMessage({ id: 'components.tables.columns.artist.gender' }),
                      width: '10%',
                    },
                    {
                      title: formatMessage({ id: 'components.tables.columns.artist.birthYear' }),
                      sx: { textAlign: 'right', whiteSpace: 'nowrap' },
                      width: '10%',
                    },
                  ]}
                  schema={PARTNER_ARTIST_LIST_TABLE_SCHEMA_ADVANCED}
                  layoutPaperPadding={LAYOUT_PAPER_PADDING}
                  customData={{ history, refetchListQuery }}
                  data={PartnerArtistPaginationResult.items}
                  refetchListQuery={refetchListQuery}
                  pagination={pagination}
                  noData={formatMessage({ id: 'common.noItems' })}
                  loading={loading}
                  mainAction={
                    <Box
                      sx={{
                        display: { xs: 'none', sm: 'block' },
                        flexGrow: 1,
                        pr: 1,
                        textAlign: 'right',
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        component={Link}
                        to={UrlAssembler.addArtist()}
                        title={addLabel}
                      >
                        {addLabel}
                      </Button>
                    </Box>
                  }
                  secondaryAction={
                    <StatusSelectButton
                      initialValue={
                        [null, undefined].indexOf(initialValues.show) === -1
                          ? initialValues.show
                          : DEFAULT_FILTER_VALUE
                      }
                      optionsList={{
                        ALL: DEFAULT_FILTER_VALUE,
                        ACTIVE: ARTIST_ENUM.status.ACTIVE,
                        PENDING: ARTIST_ENUM.status.PENDING,
                      }}
                      optionsTranslationPrefix="components.artist.status"
                      dialogTranslationPrefix="components.dialog.artist.action"
                      onChange={(show: string | number) => handleParamsChange({ show })}
                    />
                  }
                  title={pageTitle}
                />
              );
            }}
          </DataListFeed>
        ) : null}
        <Fab
          color="primary"
          component={Link}
          to={UrlAssembler.addArtist()}
          title={addLabel}
          sx={{
            position: { xs: 'fixed', lg: 'absolute' },
            top: { xs: 'auto', lg: '135px' },
            left: { xs: 'auto', lg: '-28px' },
            bottom: { xs: '25px', lg: 'auto' },
            right: { xs: '25px', lg: 'auto' },
            fontSize: '60px',
          }}
          size="large"
        >
          <AddIcon />
        </Fab>
      </Paper>
    </DefaultLayout>
  );
};
