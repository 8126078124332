import { gql } from '@apollo/client';

export const READ_FILE_UPLOAD = gql`
  query readFileUpload($transactionId: String!) {
    readFileUpload(transactionId: $transactionId) {
      transactionId
      transactionStage
      transactionStatus
      transactionProgress
      transactionErrorCode
      transactionErrorMessage
      uploadUrl
    }
  }
`;
