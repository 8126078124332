import { combineReducers } from 'redux';
import { store as STORE_CONFIG } from '../../../config/config.js';

// Reducers
import { authReducer } from './auth/auth';
import { meReducer } from './me/me';
import { errorsReducer } from './errors/errors';
import { notificationsReducer } from './application/notification';
import { applicatonStoreReducer } from './application/store';
import { guiReducer } from './application/gui';

const { keys: STORE_KEYS_CONFIG } = STORE_CONFIG;
const reducers = {
  [STORE_KEYS_CONFIG.auth]: authReducer,
  [STORE_KEYS_CONFIG.me]: meReducer,
  [STORE_KEYS_CONFIG.gui]: guiReducer,
  [STORE_KEYS_CONFIG.errors]: errorsReducer,
  [STORE_KEYS_CONFIG.notifications]: notificationsReducer,
  [STORE_KEYS_CONFIG.application]: applicatonStoreReducer,
};

/**
 * Merge reducers
 *
 * @type {Reducer<S>}
 */
export const rootReducer = combineReducers(reducers);
