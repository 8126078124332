import { Client as GqlClient } from '../../services/riseart/apollo/client';
import { CREATE_FILE_UPLOAD } from './queries/file/createUpload.graphql';
import { READ_FILE_UPLOAD } from './queries/file/readUpload.graphql';

/**
 * createFileUpload
 *
 * @param {Record<string, any>} data
 * @returns {Promise<any>}
 */
export async function createFileUpload(data: Record<string, any>): Promise<any> {
  const response = await GqlClient.mutate({
    mutation: CREATE_FILE_UPLOAD,
    variables: data,
  });

  return response && response.data && response.data.createFileUpload;
}

/**
 * updateFileUpload
 *
 * @param {string | number} transactionId
 * @returns {Promise<any>}
 */
export async function updateFileUpload(transactionId: string | number): Promise<any> {
  const response = await GqlClient.query({
    query: READ_FILE_UPLOAD,
    variables: { transactionId },
    fetchPolicy: 'network-only',
  });

  return response && response.data && response.data.readFileUpload;
}
