import { gql } from '@apollo/client';
import { OFFER_FRAGMENT } from '../../fragments/offer/offer.graphql';
import { PAGINATION_FRAGMENT } from '../../fragments/common/pagination.graphql';

export const LIST_SELLER_OFFERS = gql`
  ${OFFER_FRAGMENT}
  ${PAGINATION_FRAGMENT}
  query listSellerOffers(
    $sellerId: Int!
    $page: Int = 1
    $items: Int = 10
    $sort: String = "id"
    $order: String = "ASC"
    $filters: InputOfferListFilters
  ) {
    listSellerOffers(
      sellerId: $sellerId
      page: $page
      items: $items
      sort: $sort
      order: $order
      filters: $filters
    ) {
      items {
        ...OfferFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
`;
