import { gql } from '@apollo/client';
import { ART_FRAGMENT } from '../../fragments/art/art.graphql';

export const CREATE_ART = gql`
  ${ART_FRAGMENT}
  mutation createArt($store: String!, $inputArt: InputArt!) {
    createArt(store: $store, inputArt: $inputArt) {
      ...ArtFragment
    }
  }
`;
