import { gql } from '@apollo/client';
import { ARTIST_COLLECTION_FRAGMENT } from '../../fragments/artist/collection.graphql';

export const LIST_ARTIST_COLLECTIONS = gql`
  ${ARTIST_COLLECTION_FRAGMENT}
  query listArtistCollections($artistId: Int!) {
    listArtistCollections(artistId: $artistId) {
      items {
        ...ArtistCollectionFragment
      }
    }
  }
`;
