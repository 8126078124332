import { gql } from '@apollo/client';
import { SELLER_SHIPPING_TABLE_FRAGMENT } from '../../fragments/seller/shippingTable.graphql';

export const LIST_SELLER_SHIPPING_TABLES = gql`
  ${SELLER_SHIPPING_TABLE_FRAGMENT}
  query listSellerShippingTables($sellerId: Int!, $items: Int = 100, $offset: Int = 0) {
    listSellerShippingTables(sellerId: $sellerId, items: $items, offset: $offset) {
      items {
        ...SellerShippingTableFragment
      }
    }
  }
`;
