import { Switch, Route } from 'react-router-dom';
import { ROUTER_CONFIG } from '../../config/router.js';
import { RouterPage } from './Page';
import { HomePage } from '../pages/Home';
import { ArtListPage } from '../pages/art/List';
import { ArtAddPage } from '../pages/art/Add';
import { ArtEditPage } from '../pages/art/Edit';
import { ArtistAddPage } from '../pages/artist/Add';
import { ArtistDetailsPage } from '../pages/artist/Details';
import { ArtistListPage } from '../pages/artist/List';
import { ArtistProfilePage } from '../pages/artist/Profile';
import { ArtistCvPage } from '../pages/artist/Cv';
import { ArtistDetailsCvPage } from '../pages/artist/DetailsCv';
import { ArtistFeedListPage } from '../pages/artist/feed/List';
import { ArtistFeedAddPage } from '../pages/artist/feed/Add';
import { ArtistFeedEditPage } from '../pages/artist/feed/Edit';
import { PartnerArtistFeedList } from '../pages/artist/feed/PartnerList';
import { PartnerArtistFeedAddPage } from '../pages/artist/feed/PartnerAdd';
import { PartnerArtistFeedEditPage } from '../pages/artist/feed/PartnerEdit';
import { ArtistArtListPage } from '../pages/artist/art/List';
import { ArtistArtAddPage } from '../pages/artist/art/Add';
import { ArtistArtEditPage } from '../pages/artist/art/Edit';
import { SellerProfilePage } from '../pages/seller/Profile';
import { SellerShippingListPage } from '../pages/seller/shipping/List';
import { SellerShippingAddPage } from '../pages/seller/shipping/Add';
import { SellerShippingEditPage } from '../pages/seller/shipping/Edit';
import { SellerPaymentPage } from '../pages/seller/Payment';
import { SellerAwayModePage } from '../pages/seller/AwayMode';
import { TermsPage } from '../pages/Terms';
import { LoginPage } from '../pages/Login';
import { RegisterPage } from '../pages/Register';
import { SignoutPage } from '../pages/Signout';
import { NotFoundPage } from '../pages/error/NotFound';
import { TermsProvider } from '../../data/providers/Terms';
import { OnboardingProvider } from '../../data/providers/Onboarding';
import { EventListPage } from '../pages/event/List';
import { EventAddPage } from '../pages/event/Add';
import { EventEditPage } from '../pages/event/Edit';
import { OnboardingPage } from '../pages/Onboarding';
import { OfferListPage } from '../pages/offer/List';
import { OfferDetailsPage } from '../pages/offer/Details';
import { SettingsDashboardPage } from '../pages/settings/Dashboard';

// List of view components
const VIEWS_COMPONENTS: any = {
  Onboarding: OnboardingPage,
  Home: HomePage,
  ArtList: ArtListPage,
  ArtAdd: ArtAddPage,
  ArtEdit: ArtEditPage,
  ArtistAdd: ArtistAddPage,
  ArtistDetails: ArtistDetailsPage,
  ArtistList: ArtistListPage,
  ArtistProfile: ArtistProfilePage,
  ArtistCv: ArtistCvPage,
  ArtistDetailsCvPage: ArtistDetailsCvPage,
  ArtistFeedList: ArtistFeedListPage,
  ArtistFeedAdd: ArtistFeedAddPage,
  ArtistFeedEdit: ArtistFeedEditPage,
  PartnerArtistFeedList: PartnerArtistFeedList,
  PartnerArtistFeedAdd: PartnerArtistFeedAddPage,
  PartnerArtistFeedEdit: PartnerArtistFeedEditPage,
  ArtistArtList: ArtistArtListPage,
  ArtistArtAdd: ArtistArtAddPage,
  ArtistArtEdit: ArtistArtEditPage,
  SellerProfile: SellerProfilePage,
  SellerShippingList: SellerShippingListPage,
  SellerShippingAdd: SellerShippingAddPage,
  SellerShippingEdit: SellerShippingEditPage,
  SellerPayment: SellerPaymentPage,
  SellerAwayMode: SellerAwayModePage,
  EventList: EventListPage,
  EventAdd: EventAddPage,
  EventEdit: EventEditPage,
  OfferList: OfferListPage,
  OfferDetails: OfferDetailsPage,
  Settings: SettingsDashboardPage,
  Terms: TermsPage,
  Login: LoginPage,
  Register: RegisterPage,
  Signout: SignoutPage,
  NotFound: NotFoundPage,
};

/**
 * RouterRoutes
 *
 * @param {Record<string, any>} appProps
 * @returns {JSX.Element}
 */
export const RouterRoutes = (appProps: Record<string, any>): JSX.Element => (
  <Switch>
    {ROUTER_CONFIG.map((route: Record<string, any>) => (
      <Route
        exact={route.exact}
        path={route.path}
        key={route.key}
        render={(props: Record<string, any>) => {
          const ViewComponent = VIEWS_COMPONENTS[route.component];

          if (!ViewComponent) {
            throw new Error(`View Component ${route.component} is not implemented yet.`);
          }

          return (
            <RouterPage
              {...appProps}
              location={props.location}
              match={props.match}
              pageRouteConfig={route}
            >
              {({ currentLocale }: { currentLocale: Record<string, any> | null | undefined }) => (
                <TermsProvider routeConfig={route}>
                  <OnboardingProvider routeConfig={route}>
                    <ViewComponent
                      {...props}
                      routeConfig={route}
                      currentLocale={currentLocale}
                      {...(route.fallbackComponent && VIEWS_COMPONENTS[route.fallbackComponent]
                        ? { fallbackComponent: VIEWS_COMPONENTS[route.fallbackComponent] }
                        : {})}
                    />
                  </OnboardingProvider>
                </TermsProvider>
              )}
            </RouterPage>
          );
        }}
      />
    ))}
  </Switch>
);
