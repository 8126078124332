import { gql } from '@apollo/client';
import { SELLER_SHIPPING_TABLE_FRAGMENT } from '../../fragments/seller/shippingTable.graphql';

export const READ_SELLER_SHIPPING_TABLE = gql`
  ${SELLER_SHIPPING_TABLE_FRAGMENT}
  query readSellerShippingTable($id: Int!, $sellerId: Int!) {
    readSellerShippingTable(id: $id, sellerId: $sellerId) {
      ...SellerShippingTableFragment
    }
  }
`;
