import { useDispatch } from 'react-redux';
import { errorAdd } from '../../services/redux/actions/errors/errors';
import { ErrorService } from '../../services/riseart/errors/ErrorService';
import { errors as ERRORS_ENUM } from '../../config/enumeration.js';

type MessageOptionsType = {
  detail: string;
  level?: number;
  placeholder?: string;
  handler?: string;
  expire?: number;
};

type NotificationHookReturn = {
  dispatchNotification: (options?: MessageOptionsType) => void;
};

/**
 * useNotification
 *
 * @returns {NotificationHookReturn}
 */
export function useNotification(defaultOptions?: MessageOptionsType): NotificationHookReturn {
  const dispatch = useDispatch();
  const DEFAULT_OPTIONS = {
    detail: '',
    level: ERRORS_ENUM.levels.SUCCESS,
    placeholder: ERRORS_ENUM.placeholders.SNACKBAR,
    expire: 5,
    ...(defaultOptions || {}),
  };

  /**
   * dispatchNotification
   *
   * @param {MessageOptionsType}
   * @returns {void}
   */
  function dispatchNotification(options?: MessageOptionsType): void {
    dispatch(errorAdd(ErrorService.mapNotification({ ...DEFAULT_OPTIONS, ...(options || {}) })));
  }

  return { dispatchNotification };
}
