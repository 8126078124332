import { gql } from '@apollo/client';
import { ARTIST_AWARD_FRAGMENT } from '../../fragments/artist/award.graphql';

export const UPDATE_ARTIST_AWARD = gql`
  ${ARTIST_AWARD_FRAGMENT}
  mutation updateArtistAward($id: ID!, $artistId: Int!, $inputArtistAward: InputArtistAward!) {
    updateArtistAward(id: $id, artistId: $artistId, inputArtistAward: $inputArtistAward) {
      ...ArtistAwardFragment
    }
  }
`;
