import 'source-map-support/register';
import {
  ListInfo as ListInfoBaseModel,
  PaginationInfo as PaginationInfoBaseModel,
} from '@riseart/models';
import { ApiInvalidData } from '../errors/ApiInvalidData';

// TODO: @nikola Localize ApiInvalidData messages
// see my comment in Images model file

/**
 * ListInfo
 */
class ListInfo extends ListInfoBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {ListInfo}
   */
  public hydrateFromApiData(data?: Record<string, any>): ListInfo {
    try {
      const { items, type } = data || {};
      return this.hydrate({
        items: items || null,
        type: type || null,
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load List Info from provided data');
    }
  }
}

/**
 * PaginationInfo
 */
class PaginationInfo extends PaginationInfoBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} parameters
   * @returns {PaginationInfo}
   */
  public hydrateFromApiData(data?: Record<string, any>): PaginationInfo {
    try {
      const { pagination, collection } = data || {};
      return this.hydrate({
        currentPage: pagination ? pagination.currentPage : null,
        itemsPerPage: pagination ? pagination.itemsPerPage : null,
        totalItems: pagination ? pagination.totalItems : null,
        totalPages: pagination ? pagination.totalPages : null,
        ...new ListInfo().hydrateFromApiData(collection),
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load Pagination Info from provided data');
    }
  }
}

export { ListInfo, PaginationInfo };
