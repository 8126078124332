import { ReactNode } from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Title, CircularLoader, Paragraph, Link } from '@riseart/dashboard';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { artist as ARTIST_ENUM } from '../../../config/enumeration.js';
import { PARTNER_ARTIST_SUMMARY } from '../../../data/gql/queries/partner/artistSummary.graphql';

type Props = { partnerId: number };

/**
 * DashboardCardPartnerArtists
 *
 * @returns {JSX.Element}
 */
export function DashboardCardPartnerArtists({ partnerId }: Props): JSX.Element {
  const { data: summaryArtists, loading: summaryArtistsLoading } = useQuery(
    PARTNER_ARTIST_SUMMARY,
    {
      variables: {
        partnerId,
        filtersAll: {
          artistStatus: [ARTIST_ENUM.status.PENDING, ARTIST_ENUM.status.ACTIVE].join(','),
        },
        filtersActive: { artistStatus: ARTIST_ENUM.status.ACTIVE },
        filtersPending: { artistStatus: ARTIST_ENUM.status.PENDING },
        page: 1,
        items: 1,
      },
    },
  );
  const DATA_KEY_MAPPER = [
    {
      key: 'total',
      url: UrlAssembler.artistList(),
    },
    {
      key: 'active',
      url: UrlAssembler.artistList({
        search: { show: ARTIST_ENUM.status.ACTIVE },
      }),
    },
    {
      key: 'pending',
      url: UrlAssembler.artistList({
        search: { show: ARTIST_ENUM.status.PENDING },
      }),
    },
  ];

  return (
    <CircularLoader active={summaryArtistsLoading}>
      <Title color="primary" sx={{ marginBottom: 3 }}>
        <FormattedMessage id="components.dashboard.partnerArtists.title" />
      </Title>
      <Grid container justifyContent="left" spacing={2} columns={{ xs: 2, sm: 5 }}>
        {DATA_KEY_MAPPER.map(({ key, url }) =>
          summaryArtists && summaryArtists[key] ? (
            <Grid key={key} item xs={1} sm={1}>
              <FormattedMessage id={`components.dashboard.partnerArtists.${key}`}>
                {(text: ReactNode[]): any | null => (
                  <div>
                    <Link to={url} title={text} underline="none">
                      <Typography component="p" variant="h4" color="text.primary">
                        {summaryArtists[key].pagination.totalItems}
                      </Typography>{' '}
                      <Paragraph>{text}</Paragraph>
                    </Link>
                  </div>
                )}
              </FormattedMessage>
            </Grid>
          ) : null,
        )}
      </Grid>
    </CircularLoader>
  );
}
