/**
 * mapApiValidationErrors
 *
 * @param {Record<string, any>} error
 * @returns {Record<string, any>[]}
 */
export function mapApiValidationErrors(error: Record<string, any>): Record<string, any>[] {
  return (
    error &&
    error.graphQLErrors &&
    error.graphQLErrors.reduce((accumulator: Record<string, any>[], err: Record<string, any>) => {
      if (err && err.errorInfo && err.errorInfo.validation) {
        const mappedErrors = Object.keys(err.errorInfo.validation).map((fieldName) => {
          const fieldErrors = err.errorInfo.validation[fieldName];
          return {
            fieldName,
            errors: Object.keys(fieldErrors).map((errorKey) => {
              return fieldErrors[errorKey];
            }),
          };
        });
        accumulator.push(...mappedErrors);
      }

      return accumulator;
    }, [])
  );
}

/**
 * mapApiErrors
 *
 * @param {Record<string, any>} error
 * @returns {Record<string, any>[]}
 */
export function mapApiErrors(error: Record<string, any>): string[] {
  return (
    error &&
    error.graphQLErrors &&
    error.graphQLErrors.reduce((accumulator: Record<string, any>[], err: Record<string, any>) => {
      if (
        err &&
        err.errorInfo &&
        err.errorInfo.additional &&
        err.errorInfo.additional.validations &&
        err.errorInfo.additional.validations.length > 0
      ) {
        // Use validation data from additional if present
        accumulator.push(...err.errorInfo.additional.validations);
      } else {
        // Use error message if no additional info provided
        accumulator.push(err.message);
      }

      return accumulator;
    }, [])
  );
}
