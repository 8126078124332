import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import { product as PRODUCT_ENUM } from '../../../config/enumeration.js';
import { ACTION_ART } from '../../../data/gql/queries/art/action.graphql';

type Props = {
  id: number;
  store: string;
  productCanUnlist: boolean;
  productCanSubmit: boolean;
  productCanArchive: boolean;
  productCanSellOut: boolean;
  canCopy: boolean;
  children: (
    availableActions: {
      actionKey: string;
      actionHandler: () => Promise<Record<string, any>>;
      text: any;
    }[],
    mutationState: { data: any; loading: boolean; error: any },
  ) => any;
};

type ActionType = {
  value: boolean;
  key: string;
  action: string;
  buttonProps?: Record<string, any>;
};

/**
 * DataArtActions
 *
 * @param {Props} props
 * @returns {any}
 */
export const DataArtActions = ({
  id,
  store,
  productCanUnlist,
  productCanSubmit,
  productCanArchive,
  productCanSellOut,
  canCopy,
  children,
}: Props): any => {
  const [actionArt, { data, loading, error }] = useMutation(ACTION_ART);
  const { formatMessage } = useIntl();
  const actions: ActionType[] = [
    { key: 'productCanSubmit', value: productCanSubmit, action: PRODUCT_ENUM.action.SUBMIT },
    { key: 'productCanUnlist', value: productCanUnlist, action: PRODUCT_ENUM.action.UNLIST },
    { key: 'productCanSellOut', value: productCanSellOut, action: PRODUCT_ENUM.action.SELL_OUT },
    { key: 'productCanArchive', value: productCanArchive, action: PRODUCT_ENUM.action.ARCHIVE },
    {
      key: 'canCopy',
      value: canCopy,
      action: PRODUCT_ENUM.action.COPY,
      buttonProps: { variant: 'outlined' },
    },
  ];

  const availableActions = actions.reduce(
    (
      accumulator: {
        actionKey: string;
        actionHandler: () => Promise<Record<string, any>>;
        text: any;
      }[],
      { value, key: actionKey, action, buttonProps }: ActionType,
    ) => {
      return value === true
        ? [
            ...accumulator,
            {
              actionKey,
              actionHandler: () => {
                return actionArt({
                  variables: {
                    id,
                    store,
                    action,
                  },
                });
              },
              text: formatMessage({ id: `components.art.product.actions.${actionKey}` }),
              buttonProps,
            },
          ]
        : accumulator;
    },
    [],
  );

  return children(availableActions, { data, loading, error });
};
