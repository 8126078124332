import { gql } from '@apollo/client';
import { PARTNER_INVITATION_FRAGMENT } from '../../fragments/partner/invitation.graphql';
import { PAGINATION_FRAGMENT } from '../../fragments/common/pagination.graphql';

export const LIST_PARTNER_INVITATIONS = gql`
  ${PARTNER_INVITATION_FRAGMENT}
  ${PAGINATION_FRAGMENT}
  query listPartnerInvitations($partnerId: Int!, $page: Int = 1, $items: Int = 10) {
    listPartnerInvitations(partnerId: $partnerId, page: $page, items: $items) {
      items {
        ...PartnerInvitationFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
`;
