import { GUI_ACTIONS } from '../../actions/container';
import { ActionType } from '../../actions/application/gui';

const DEFAULT_STATE = {
  menuOpen: null,
  locale: null,
};

/**
 * guiReducer
 *
 * @param {Record<string, any>} state
 * @param {ActionType} action
 * @returns {Record<string, any>}
 */
export function guiReducer(
  state: Record<string, any> = {},
  action: ActionType,
): Record<string, any> {
  switch (action.type) {
    case GUI_ACTIONS.UPDATE_PROPERTY:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      };
    case GUI_ACTIONS.UPDATE_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...DEFAULT_STATE, ...state };
  }
}
