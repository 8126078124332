import { Utils } from '@riseart/utils';
import {
  Art as ArtBaseModel,
  ArtLocale,
  ArtMeta as ArtMetaBaseModel,
  ArtPaginationResult as ArtPaginationResultBaseModel,
  ProductStore as ProductStoreBaseModel,
  ProductEditorial as ProductEditorialBaseModel,
  ProductEditorialLocale as ProductEditorialLocaleBaseModel,
  ProductEditorialPaginationResult as ProductEditorialPaginationResultBaseModel,
  ProductEditorialType,
  ProductSku as ProductSkuBaseModel,
  ProductSkuStore as ProductSkuStoreBaseModel,
  ProductSkuOption as ProductSkuOptionBaseModel,
  ProductSkuOptionValue as ProductSkuOptionValueBaseModel,
} from '@riseart/models';
import { ApiInvalidData } from '../errors/ApiInvalidData';
import { ImageArray, ImageHierarchicalArray } from './Image';
import { PaginationInfo } from './List';
import { Author } from './Author';
import { Artist } from './Artist';
import find from 'lodash/find';
import { FormDataMixin, LocaleMixin, StoreMixin } from './Core';
import { product as PRODUCT_ENUM } from '../../config/enumeration.js';
import { formatCurrency } from '../../services/riseart/utils/Utils';
import { formatDimensions } from '../normalizers/art';

/**
 * ProductSkuOptionValue
 */
class ProductSkuOptionValue extends ProductSkuOptionValueBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {ProductSkuOptionValue}
   */
  public hydrateFromApiData(data?: Record<string, any>): ProductSkuOptionValue {
    try {
      const { stores, ...valueData } = data || {};
      const [storeItem] = stores || [];

      // Product Sku Option Value
      return this.hydrate({
        storeCode: storeItem && storeItem.store ? storeItem.store.code : null,
        currency: storeItem ? storeItem.currency : null,
        price: storeItem ? storeItem.price : null,
        title: storeItem ? storeItem.title : null,
        ...valueData,
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load ProductSkuOptionValue from provided data.');
    }
  }
}

/**
 * ProductSkuOption
 */
class ProductSkuOption extends ProductSkuOptionBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {ProductSkuOption}
   */
  public hydrateFromApiData(data?: Record<string, any>): ProductSkuOption {
    try {
      const { stores, values: valueList, ...detailOptionData } = data || {};
      const [storeItem] = stores;

      // values
      const values: Array<ProductSkuOptionValue> = valueList
        ? valueList.map((item: Record<string, any>) =>
            new ProductSkuOptionValue().hydrateFromApiData(item),
          )
        : [];

      // Product Sku Option
      return this.hydrate({
        storeCode: storeItem && storeItem.store ? storeItem.store.code : null,
        title: storeItem ? storeItem.title : null,
        values,
        ...detailOptionData,
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load ProductSkuOption from provided data.');
    }
  }
}

/**
 * ProductSkuStore
 */
class ProductSkuStore extends ProductSkuStoreBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} rootStore
   * @param {Record<string, any>} detailStore
   * @returns {this}
   */
  public hydrateFromApiData(
    rootData: Record<string, any>,
    detailData?: Record<string, any>,
  ): ProductSkuStore {
    try {
      const {
        id = null,
        status = null,
        store: rootStore,
        currency: rootCurrency,
        price: rootPrice,
        priceInInstalments = null,
      } = rootData || {};
      const { store: detailStore, currency: detailCurrency, price: detailPrice } = detailData || {};

      return this.hydrate({
        id,
        storeCode: (detailStore && detailStore.code) || (rootStore && rootStore.code) || null,
        status,
        priceInInstalments,
        currency: detailCurrency || rootCurrency || null,
        price: detailPrice || rootPrice || null,
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load Product Sku Store from provided data.');
    }
  }
}

/**
 * ProductSku
 */
class ProductSku extends StoreMixin(ProductSkuBaseModel, ProductSkuStore) {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {ProductSku}
   */
  public hydrateFromApiData(data: Record<string, any>): ProductSku {
    try {
      // Deconstruct
      const {
        detail,
        stores: rootStores,
        seller: sellerData,
        sku,
        stock,
        editionSize,
        width,
        height,
        depth,
        units,
        ...productSkuData
      } = data || {};
      const { stores: detailStores, options: optionsData, ...detailData } = detail || {};

      // Stores
      // Combine store codes from rootStores and detailStores
      const combinedStoreCodes = [...(detailStores || []), ...(rootStores || [])].reduce(
        (accumulator: Array<Record<string, any>>, item: Record<string, any>) =>
          accumulator.indexOf(item.store.code) < 0
            ? [...accumulator, item.store.code]
            : accumulator,
        [],
      );
      const stores = combinedStoreCodes.length
        ? combinedStoreCodes.map((storeCode: string) =>
            new ProductSkuStore().hydrateFromApiData(
              rootStores && find(rootStores, (rootStore) => rootStore.store.code === storeCode),
              detailStores &&
                find(detailStores, (detailStore) => detailStore.store.code === storeCode),
            ),
          )
        : [];

      // Options
      const options: Array<ProductSkuOption> = optionsData
        ? optionsData.map((item: Record<string, any>) =>
            new ProductSkuOption().hydrateFromApiData(item),
          )
        : [];

      // Seller
      const seller = {
        sellerId: (sellerData && sellerData.id) || null,
        sellerIntegrationId: (sellerData && sellerData.integrationId) || null,
        sellerName: (sellerData && sellerData.name) || null,
        sellerCurrency: (sellerData && sellerData.currency) || null,
        sellerStore: (sellerData && sellerData.store && sellerData.store.code) || null,
        sellerCanUseShippingTables: sellerData && sellerData.canUseShippingTables,
      };

      // Product Sku
      const productSku = {
        stores,
        detailStores: detailStores
          ? detailStores.map((detailStore: Record<string, any>) =>
              new ProductSkuStore().hydrateFromApiData(detailStore),
            )
          : null,
        options,
        sku: detailData.sku || sku,
        stock: detailData.stock || stock,
        editionSize: detailData.editionSize || editionSize,
        width: (detailData ? detailData.width : null) || width || null,
        height: (detailData ? detailData.height : null) || height || null,
        depth: (detailData ? detailData.depth : null) || depth || null,
        units: (detailData ? detailData.units : null) || units || null,
        framed: detailData ? detailData.framed : false,
        inStock: detailData ? detailData.inStock : false,
        physicalSku: detailData ? detailData.physicalSku : null,
        substrate: detailData ? detailData.substrate : null,
        readyToHang: detailData ? detailData.readyToHang : false,
        unstretched: detailData ? detailData.unstretched : null,
        rental: detailData ? detailData.rental : false,
        activeRentals: detailData.activeRentals,
        signed: detailData ? detailData.signed : false,
        weight: detailData ? detailData.weight : 0,
        packageMethod: detailData ? detailData.packageMethod : null,
        year: detailData ? detailData.year : null,
        canBeFramed: detailData ? detailData.canBeFramed : false,
        shipsBy: detailData ? detailData.shipsBy : null,
        shipsFramedBy: detailData ? detailData.shipsFramedBy : null,
        shipsFromCode: detailData && detailData.shipsFrom ? detailData.shipsFrom.code : null,
        sellerShippingTableId: detailData ? detailData.sellerShippingTableId : null,
        ...seller,
        ...productSkuData,
      };

      return this.hydrate(productSku);
    } catch (error) {
      throw new ApiInvalidData('Unable to load ProductSku from provided data.');
    }
  }

  /**
   * getStoreData
   *
   * @param {string} storeCode
   * @returns {ProductSkuStore}
   */
  getStoreData(storeCode: string): ProductSkuStore {
    return find(this.stores, (store: Record<string, any>) => store.storeCode === storeCode);
  }
}

/**
 * ProductStore
 */
class ProductStore extends ProductStoreBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {data?: Record<string, any>} data
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>): ProductStore {
    try {
      const { store, ...storeData } = data || {};
      return this.hydrate({
        storeCode: store.code || null,
        ...storeData,
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load ProductStore from provided data.');
    }
  }
}

/**
 * ArtMeta
 */
class ArtMeta extends ArtMetaBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {data?: Record<string, any>} data
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>): ArtMeta {
    try {
      return this.hydrate(data || {});
    } catch (error) {
      throw new ApiInvalidData('Unable to load ArtMeta from provided data.');
    }
  }
}

/**
 * ArtAbstract
 */
class ArtAbstract extends ArtBaseModel {
  stores: Array<ProductStore>;
}

/**
 * Art
 */
class Art extends FormDataMixin(LocaleMixin(StoreMixin(ArtAbstract, ProductStore), ArtLocale)) {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {Art}
   */
  public hydrateFromApiData(data?: Record<string, any>): Art {
    try {
      const {
        locales,
        artist: artistData,
        images: imagesList,
        product: productObject,
        ranking: rankingData,
        statusMessage,
        ...artData
      } = data || {};

      // Locales
      super.hydrateFromApiData({ locales });

      // Artist
      const artist: Artist | null = artistData ? new Artist().hydrateFromApiData(artistData) : null;

      // Images
      const images: ImageHierarchicalArray = new ImageHierarchicalArray().hydrateFromApiData(
        imagesList || [],
      );

      // Base model data
      let art = {
        images,
        artist,
        statusMessage: statusMessage ? statusMessage : null,
        ...rankingData,
        ...artData,
      };

      if (productObject) {
        const {
          primarySku: primarySkuData,
          skus: skusData,
          stores: storesData,
          ...productData
        } = productObject || {};

        // Product
        const product = productData
          ? Object.keys(productData).reduce((accumulator: Record<string, any>, key: string) => {
              accumulator[`product${Utils.capitalize(key)}`] = productData[key];
              return accumulator;
            }, {})
          : {};

        // Product Stores
        const stores: Array<ProductStore> = storesData
          ? storesData.map((item: Record<string, any>) =>
              new ProductStore().hydrateFromApiData(item),
            )
          : [];

        // Product Primary Sku
        const productPrimarySku: ProductSku | null = primarySkuData
          ? new ProductSku().hydrateFromApiData(primarySkuData)
          : null;

        // Product Skus
        const productSkus: Array<ProductSku> = skusData
          ? skusData.map((item: Record<string, any>) => new ProductSku().hydrateFromApiData(item))
          : [];

        // Art
        art = {
          stores,
          productPrimarySku,
          productSkus,
          ...product,
          ...art,
        };
      }

      return this.hydrate(art);
    } catch (error) {
      throw new ApiInvalidData('Unable to load Art from provided data');
    }
  }

  /**
   * mapFormToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   * @returns {Record<string, any>}
   */
  public static mapFormToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
  ): Record<string, any> {
    const mappedData = super.mapFormToApiData(data, fieldsSchema, includeNullValues);
    const { rentStock, ...restData } = mappedData;

    return { ...restData, ...(restData.rent ? { rentStock } : null) };
  }

  /**
   * getStore
   *
   * @param {string} storeCode
   * @returns {ProductStore | null}
   */
  getStore(storeCode: string): ProductStore | null {
    return (
      (this.stores &&
        find(this.stores, (store: Record<string, any>) => store.storeCode === storeCode)) ||
      null
    );
  }

  /**
   * getStoreData
   *
   * @param {string} storeCode
   * @returns {Record<string, any>}
   */
  public getStoreData(storeCode: string): Record<string, any> {
    const {
      id,
      title,
      isProduct,
      productId,
      productType,
      productSkus,
      stores,
      images,
      units: rootUnits,
      width: rootWidth,
      height: rootHeight,
      depth: rootDepth,
      medium,
      slug,
      status,
      subMedium,
      subject,
      subSubject,
      style,
      materials,
      year,
      size,
      productIsRental,
      productShowcase,
      ...rest
    } = this;
    const productStore = this.getStore(storeCode);
    const productPrimarySkuStore =
      (this.productPrimarySku && this.productPrimarySku.getStoreData(storeCode)) || null;
    const mergedData = {
      units: (this.productPrimarySku && this.productPrimarySku.units) || rootUnits || '',
      height: (this.productPrimarySku && this.productPrimarySku.height) || rootHeight,
      width: (this.productPrimarySku && this.productPrimarySku.width) || rootWidth,
      depth: (this.productPrimarySku && this.productPrimarySku.depth) || rootDepth,
    };

    return {
      ...mergedData,
      id,
      slug,
      title,
      images,
      isProduct,
      productId,
      unit: mergedData.units.toLowerCase(),
      artworkDimensionsLabel: formatDimensions(
        [mergedData.height, mergedData.width, mergedData.depth],
        mergedData.units,
      ),
      size,
      status,
      medium,
      subMedium,
      subject,
      subSubject,
      style,
      materials,
      year,
      mediumWithSubMedium: [
        { key: 'medium', value: medium },
        { key: 'subMedium', value: subMedium },
      ],
      subjectWithSubSubject: [
        { key: 'subject', value: subject },
        { key: 'subSubject', value: subSubject },
      ],
      styleStructured: style && [{ key: 'style', value: style }],
      editionSize: this.productPrimarySku && this.productPrimarySku.editionSize,
      type: productType,
      productStatus: productStore && productStore.status,
      productSkus: productSkus,
      productPrimarySku: this.productPrimarySku,
      stock: this.productPrimarySku && this.productPrimarySku.stock,
      weight: this.productPrimarySku && this.productPrimarySku.weight,
      packageMethod: (this.productPrimarySku && this.productPrimarySku.packageMethod) || null,
      substrate: (this.productPrimarySku && this.productPrimarySku.substrate) || null,
      unstretched: this.productPrimarySku && this.productPrimarySku.unstretched,
      currency: productPrimarySkuStore && productPrimarySkuStore.currency,
      price: productPrimarySkuStore && productPrimarySkuStore.price,
      productStores: stores,
      signed: this.productPrimarySku && this.productPrimarySku.signed,
      signedLocation: (this.productPrimarySku && this.productPrimarySku.signedLocation) || null,
      hangable: this.productPrimarySku && this.productPrimarySku.readyToHang,
      framed: this.productPrimarySku && this.productPrimarySku.framed,
      frameDescription: (this.productPrimarySku && this.productPrimarySku.frameDescription) || null,
      rent: productIsRental,
      showcase: productShowcase,
      formattedPrice:
        productPrimarySkuStore && productPrimarySkuStore.price
          ? formatCurrency(
              productPrimarySkuStore.price,
              productPrimarySkuStore.storeCode,
              undefined,
              {
                hasSpaceAfterCurrency: false,
              },
            )
          : null,
      ...rest,
    };
  }

  /**
   * getArtworkSku
   *
   * @param {number} sellerId
   * @returns {ProductSku}
   */
  public getArtworkSku(sellerId?: number): ProductSku | undefined {
    // Return first the primary sku if it matches seller
    if (
      this.productPrimarySku &&
      this.productPrimarySku.type === PRODUCT_ENUM.sku.type.ART &&
      (!sellerId || (sellerId && this.productPrimarySku.sellerId === sellerId))
    ) {
      return this.productPrimarySku as ProductSku;
    }

    // Find ART sku in skus array
    return (
      this.productSkus &&
      (this.productSkus.find(
        (sku: Record<string, any>) =>
          sku.type === PRODUCT_ENUM.sku.type.ART &&
          (!sellerId || (sellerId && sku.sellerId === sellerId)),
      ) as ProductSku)
    );
  }

  /**
   * getRentalSku
   *
   * @param {number} artworkSku
   * @param {number} sellerId
   * @returns {ProductSku}
   */
  public getRentalSku(artworkSku?: ProductSku, sellerId?: number): ProductSku | undefined {
    if (!artworkSku) {
      artworkSku = this.getArtworkSku(sellerId);
      if (!artworkSku) {
        return;
      }
    }

    // Find RENT sku in skus array
    return (
      this.productSkus &&
      (this.productSkus.find(
        (sku: Record<string, any>) =>
          sku.type === PRODUCT_ENUM.sku.type.RENT &&
          sku.physicalSku &&
          sku.physicalSku === artworkSku?.sku &&
          (!sellerId || (sellerId && sku.sellerId === sellerId)),
      ) as ProductSku)
    );
  }

  /**
   * mapFormToApiPost
   *
   * @param {Record<string, any>} form
   * @returns {Record<string, any>}
   */
  public hydrateFromFromData(form: Record<string, any>): Record<string, any> {
    // TODO: Would a method like this be useful at all? Or should the form to APi data mapping be handled by the form models or elsewhere?
    const { id } = form;
    return this.hydrate({ id });
  }

  /**
   * toFormData
   *
   * @param {string} storeCode
   * @returns {Record<string, any>}
   */
  public toFormData(storeCode: string): Record<string, any> {
    const {
      title,
      description,
      productSkus,
      productIsRental,
      productType,
      productShowcase,
      medium,
      subMedium,
      subject,
      subSubject,
      style,
      materials,
      year,
      tags,
      width,
      height,
      depth,
      bump,
    } = this;
    const productPrimarySkuStore =
      this.productPrimarySku && this.productPrimarySku.getStoreData(storeCode);
    const rentalSku = this.getRentalSku(this.getArtworkSku());
    const sellerStoreCode = this.productPrimarySku && this.productPrimarySku.sellerStore;
    const sellerStoreData =
      (sellerStoreCode && this.productPrimarySku.getStoreData(sellerStoreCode)) || null;

    return {
      title,
      description,
      bump,
      year,
      medium:
        (medium && subMedium && JSON.stringify({ medium, subMedium })) ||
        (medium && JSON.stringify({ medium })) ||
        null,
      subMedium,
      subject:
        (subject && subSubject && JSON.stringify({ subject, subSubject })) ||
        (subject && JSON.stringify({ subject })) ||
        null,
      subSubject,
      style: style ? JSON.stringify({ style }) : null,
      materials,
      editionSize: this.productPrimarySku && this.productPrimarySku.editionSize,
      tags: tags && tags.join(', '),
      height: this.productPrimarySku.height || height,
      width: this.productPrimarySku.width || width,
      depth: this.productPrimarySku.depth || depth,
      price: sellerStoreData ? sellerStoreData.price : null,
      sellerShippingTable: this.productPrimarySku && this.productPrimarySku.sellerShippingTableId,
      stock: this.productPrimarySku.stock,
      weight: this.productPrimarySku && this.productPrimarySku.weight,
      packageMethod: (this.productPrimarySku && this.productPrimarySku.packageMethod) || null,
      substrate: (this.productPrimarySku && this.productPrimarySku.substrate) || null,
      unstretched: this.productPrimarySku && this.productPrimarySku.unstretched,
      signed: this.productPrimarySku && this.productPrimarySku.signed,
      signedLocation: (this.productPrimarySku && this.productPrimarySku.signedLocation) || null,
      hangable: this.productPrimarySku && this.productPrimarySku.readyToHang,
      framed: this.productPrimarySku && this.productPrimarySku.framed,
      frameDescription: (this.productPrimarySku && this.productPrimarySku.frameDescription) || null,
      rent: productIsRental,
      rentStock: rentalSku && rentalSku.stock,
      type: productType,
      showcase: productShowcase,
      primarySkuId: this.productPrimarySku.sku
        ? (
            find(productSkus, ({ sku }: { sku: string }) => sku === this.productPrimarySku.sku) ||
            {}
          ).id
        : null,
      productPrimarySkuStore,
    };
  }

  /**
   * toApiPostData
   *
   * @returns {Record<string, any>}
   */
  public toApiPostData(): Record<string, any> {
    // TODO: Would a method like this be useful at all?
    return {};
  }

  /**
   * toApiPutData
   *
   * @returns {Record<string, any>}
   */
  public toApiPutData(): Record<string, any> {
    // TODO: Would a method like this be useful at all?
    return {};
  }

  /**
   * mapFormToApiPost
   *
   * @param {Record<string, any>} form
   * @returns {Record<string, any>}
   */
  public static mapFormToApiPost(form: Record<string, any>): Record<string, any> {
    // TODO: Would a method like this be useful at all? Or should the form to APi data mapping be handled by the form models or elsewhere?
    return { ...form };
  }

  /**
   * mapFormToApiPut
   *
   * @param {Record<string, any>} form
   * @returns {Record<string, any>}
   */
  public static mapFormToApiPut(form: Record<string, any>): Record<string, any> {
    // TODO: Would a method like this be useful at all? Or should the form to APi data mapping be handled by the form models or elsewhere?
    return { ...form };
  }
}

/**
 * ArtPaginationResult
 */
class ArtPaginationResult extends ArtPaginationResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {ArtPaginationResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): ArtPaginationResult {
    try {
      // Deconstruct
      const { items: itemsData, ...paginationData } = data || {};

      // Items
      const items: Array<Art> = itemsData
        ? itemsData.map((item: Record<string, any>) => new Art().hydrateFromApiData(item))
        : [];

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      // Hydrate model
      return this.hydrate({ items, pagination });
    } catch (error) {
      throw new ApiInvalidData('Unable to load ArtPaginationResult from provided data');
    }
  }
}

/**
 * ProductEditorialDataTypes
 */
enum ProductEditorialDataTypes {
  TEXT = 'text',
  LINK = 'link',
}

const ProductEditorialDataTypesMap = {
  [ProductEditorialType.ABOUT_ART]: ProductEditorialDataTypes.TEXT,
  [ProductEditorialType.DETAIL]: ProductEditorialDataTypes.TEXT,
  [ProductEditorialType.LINK]: ProductEditorialDataTypes.LINK,
  [ProductEditorialType.NOTE]: ProductEditorialDataTypes.TEXT,
  [ProductEditorialType.TEXT]: ProductEditorialDataTypes.TEXT,
  [ProductEditorialType.THOUGHT]: ProductEditorialDataTypes.TEXT,
};

/**
 * ProductEditorialLocale
 */
class ProductEditorialLocale extends ProductEditorialLocaleBaseModel {
  text: string;
  url: string;
}

/**
 * ProductEditorial
 */
class ProductEditorial extends FormDataMixin(
  LocaleMixin(ProductEditorialBaseModel, ProductEditorialLocale),
) {
  text: string;
  url: string;

  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {ProductEditorial}
   */
  public hydrateFromApiData(data?: Record<string, any>): ProductEditorial {
    try {
      // Deconstruct parameters
      const {
        locales: localesData,
        author: authorData,
        images: imagesData,
        text: textData,
        ...editorialData
      } = data || {};

      // Author
      const author: Author | null = authorData ? new Author().hydrateFromApiData(authorData) : null;

      // Images
      const images: ImageArray = new ImageArray().hydrateFromApiData(imagesData || []);

      // Editorial values generator
      const { type: editorialType = null } = editorialData || {};
      const valueGenerator = (data: any): Record<string, any> => {
        switch (ProductEditorialDataTypesMap[editorialType as ProductEditorialType]) {
          case ProductEditorialDataTypes.LINK:
            return {
              ...data,
            };

          default:
            return {
              text: data || null,
            };
        }
      };

      // Locales
      const locales: Array<ProductEditorialLocale> = localesData
        ? localesData.map((item: Record<string, any>) => {
            const { locale: localeData, text: itemText, ...itemData } = item || {};
            return new ProductEditorialLocale().hydrate({
              localeCode: (localeData && localeData.code) || null,
              ...valueGenerator(itemText),
              ...itemData,
            });
          })
        : [];

      // Hydrate model
      return this.hydrate({
        author,
        locales,
        images,
        ...valueGenerator(textData),
        ...editorialData,
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load ProductEditorial from provided data');
    }
  }

  /**
   * mapFormToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   * @returns {Record<string, any>}
   */
  public static mapFormToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
  ): Record<string, any> {
    const mappedData = super.mapFormToApiData(data, fieldsSchema, includeNullValues);
    // eslint-disable-next-line
    const { locales, ...restData } = mappedData;
    return restData;
  }
}

/**
 * ProductEditorialPaginationResult
 */
class ProductEditorialPaginationResult extends ProductEditorialPaginationResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {ProductEditorialPaginationResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): ProductEditorialPaginationResult {
    try {
      // Deconstruct
      const { items: itemsData, ...paginationData } = data || {};

      // Items
      const items: Array<ProductEditorial> = itemsData
        ? itemsData.map((item: Record<string, any>) =>
            new ProductEditorial().hydrateFromApiData(item),
          )
        : [];

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      // Hydrate model
      return this.hydrate({ items, pagination });
    } catch (error) {
      throw new ApiInvalidData(
        'Unable to load ProductEditorialPaginationResult from provided data',
      );
    }
  }
}

export {
  Art,
  ArtMeta,
  ArtPaginationResult,
  ProductSku,
  ProductStore,
  ProductSkuStore,
  ProductSkuOption,
  ProductSkuOptionValue,
  ProductEditorial,
  ProductEditorialPaginationResult,
};
