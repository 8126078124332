import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Title, FormBuilder, FormWrapper, FormHeader, LayoutPaper } from '@riseart/dashboard';
import { errors as ERRORS_ENUM } from '../../../../config/enumeration.js';
import { useNotification } from '../../../../data/hooks/useNotification';
import { useScrollToEl } from '../../../../data/hooks/useScrollToEl';
import { DefaultLayout } from '../../../layout/Default';
import { selectSeller } from '../../../../services/redux/selectors/seller';
import { AddSellerTableFormModel } from '../../../forms/models/sellerTable';
import { CREATE_SELLER_SHIPPING_TABLE } from '../../../../data/gql/queries/seller/createShippingTable.graphql';
import { LIST_SELLER_SHIPPING_TABLES } from '../../../../data/gql/queries/seller/listShippingTables.graphql';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { mapApiValidationErrors, mapApiErrors } from '../../../../services/riseart/utils/Form';

/**
 * SellerShippingAddPage
 *
 * @returns {JSX.Element}
 */
export const SellerShippingAddPage = (): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { setScrollRef, scrollToRef } = useScrollToEl();
  const { dispatchNotification } = useNotification();
  const { id: sellerId, canUseShippingTables } = useSelector(selectSeller) || {};

  if (!canUseShippingTables) {
    dispatchNotification({
      detail: 'components.notifications.noAccess',
      level: ERRORS_ENUM.levels.ERROR,
      placeholder: ERRORS_ENUM.placeholders.APP,
      handler: ERRORS_ENUM.handlers.ERROR_PAGE,
    });

    return null;
  }

  const [createShippingTable, { loading: createLoading }] = useMutation(
    CREATE_SELLER_SHIPPING_TABLE,
    {
      refetchQueries: [LIST_SELLER_SHIPPING_TABLES],
    },
  );
  const { data: shippingTablesList, loading: shippingTablesListLoading } = useQuery(
    LIST_SELLER_SHIPPING_TABLES,
    { variables: { sellerId, items: 100 } },
  );
  const pageTitle = formatMessage({ id: 'components.mainMenu.sellerShippingList' });

  return (
    <DefaultLayout
      title={formatMessage({ id: 'components.mainMenu.seller' })}
      loading={shippingTablesListLoading || createLoading}
      breadcrumbs={[
        <Link
          key="home"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link
          key="sellerProfile"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('sellerProfile')}
          title={formatMessage({ id: 'components.mainMenu.seller' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'components.mainMenu.seller' })}
        </Link>,
        <Link
          key="sellerShippingList"
          component={RouterLink}
          to={UrlAssembler.shippingList()}
          title={pageTitle}
          underline="hover"
          color="inherit"
        >
          {pageTitle}
        </Link>,
        <Typography key="shippingTable" variant="inherit">
          {formatMessage({ id: 'components.seller.addShippingTable' })}
        </Typography>,
      ]}
      boxRefCallback={setScrollRef}
    >
      {shippingTablesList ? (
        <LayoutPaper>
          <FormHeader
            title={
              <Title variant="h5">
                {formatMessage({ id: 'components.seller.addShippingTable' })}
              </Title>
            }
          />
          <FormWrapper size="medium">
            <FormBuilder
              settings={AddSellerTableFormModel.settings}
              fields={AddSellerTableFormModel.fields}
              customData={{
                shippingTables:
                  shippingTablesList &&
                  shippingTablesList.listSellerShippingTables &&
                  shippingTablesList.listSellerShippingTables.items,
              }}
              showReset
              submitText={formatMessage({ id: 'components.seller.addShipping' })}
              onSubmit={async (formState, addApiErrors) => {
                createShippingTable({
                  variables: {
                    sellerId,
                    inputShippingTable: {
                      ...(formState.data.parentId ? { parentId: formState.data.parentId } : null),
                      name: formState.data.name,
                      default: formState.data.default,
                    },
                  },
                })
                  .then((response) => {
                    // Scroll to container
                    scrollToRef();

                    // Show update success notification
                    dispatchNotification({
                      detail: 'components.notifications.sellerTableCreated',
                      level: ERRORS_ENUM.levels.SUCCESS,
                      placeholder: ERRORS_ENUM.placeholders.SNACKBAR,
                      expire: 5,
                    });

                    if (
                      response &&
                      response.data &&
                      response.data.createSellerShippingTable &&
                      response.data.createSellerShippingTable.id
                    ) {
                      history.push(
                        UrlAssembler.shippingDetail(response.data.createSellerShippingTable.id),
                      );
                    }
                  })
                  .catch((error) => {
                    // Map validation errors from response
                    const validationError = mapApiValidationErrors(error);

                    if (validationError && validationError.length && addApiErrors) {
                      addApiErrors(validationError);
                    } else {
                      // Map any other type of errors from response (validation without provided fields, or non-validation errors)
                      mapApiErrors(error).forEach((err) => {
                        dispatchNotification({
                          detail: err,
                          level: ERRORS_ENUM.levels.ERROR,
                          placeholder: ERRORS_ENUM.placeholders.PAGE,
                        });
                      });
                    }

                    // Scroll to error
                    scrollToRef();
                  });
              }}
            />
          </FormWrapper>
        </LayoutPaper>
      ) : null}
    </DefaultLayout>
  );
};
