import { gql } from '@apollo/client';

export const ACCEPTED_LEGAL_FRAGMENT = gql`
  fragment AcceptedLegalFragment on AcceptedLegal {
    id
    acceptedDate
    legalId
    type
    active
    version
    effectiveDate
    title
    userId
    userRole
    userFullName
  }
`;
