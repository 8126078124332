import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ImageIcon from '@mui/icons-material/Image';
import PaletteIcon from '@mui/icons-material/Palette';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import EventIcon from '@mui/icons-material/Event';
import { Logo, MainMenu, menuItemsFinder, MainMenuItemType } from '@riseart/dashboard';
import { gui as GUI_CONFIG } from '../../../config/config.js';
import { gui as GUI_ENUM } from '../../../config/enumeration.js';
import { ROUTER_CONFIG } from '../../../config/router.js';
import { isRoleAllowedForACL } from '../../../services/riseart/utils/Auth';
import { selectAuth } from '../../../services/redux/selectors/auth';
import { selectArtist } from '../../../services/redux/selectors/artist';
import { selectLocale } from '../../../services/redux/selectors/gui';
import { selectSeller } from '../../../services/redux/selectors/seller';
import { LocalizedConfig } from '../../../services/riseart/utils/LocalizedConfig';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import './Menu.css';

type Props = {
  window?: () => Record<string, any>;
  onClose: (open: boolean) => void;
  onClick?: (poperties?: any) => void;
  open: boolean;
  drawerVariant: 'permanent' | 'temporary';
};

/**
 * Menu
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const Menu = ({ open, onClick, drawerVariant, onClose }: Props): JSX.Element => {
  const { formatMessage } = useIntl();
  const locale = useSelector(selectLocale);
  const auth = useSelector(selectAuth);
  const userRole = get(auth, 'payload.acl_role');
  const artist = useSelector(selectArtist);
  const { canUseShippingTables } = useSelector(selectSeller) || {};
  const MENU_ICONS: Record<string, any> = {
    home: DashboardIcon,
    artistsList: PaletteIcon,
    artistProfile: PaletteIcon,
    artList: ImageIcon,
    sellerProfile: SupervisorAccountIcon,
    eventList: EventIcon,
    offersList: PriceChangeIcon,
    settings: SettingsIcon,
    signout: LogoutIcon,
  };
  const mainMenuItems = menuItemsFinder(
    ROUTER_CONFIG,
    'main',
    userRole,
    {
      isRoleAllowedForACL,
      UrlAssembler,
      formatMessage: ({ id }) => formatMessage({ id: `components.mainMenu.${id}` }),
    },
    MENU_ICONS,
  ).reduce<MainMenuItemType[]>((accumulator, item) => {
    const SELLER_SHIPPING_TABLE_KEY = 'sellerShippingList';

    if (canUseShippingTables) {
      return [...accumulator, item];
    }

    if (item.key === SELLER_SHIPPING_TABLE_KEY) {
      return accumulator;
    }

    if (item.submenu && item.submenu.find(({ key }) => key === SELLER_SHIPPING_TABLE_KEY)) {
      item.submenu = item.submenu.filter(
        (submenuItem) => submenuItem.key !== SELLER_SHIPPING_TABLE_KEY,
      );
    }

    return [...accumulator, item];
  }, []);

  const secondaryMenuItems = [
    ...menuItemsFinder(
      ROUTER_CONFIG,
      'secondary',
      userRole,
      {
        isRoleAllowedForACL,
        UrlAssembler,
        formatMessage: ({ id }) => formatMessage({ id: `components.secondaryMenu.${id}` }),
      },
      MENU_ICONS,
    ),
    ...(artist
      ? [
          {
            key: 'myProfile',
            icon: ContactPageOutlinedIcon,
            text: formatMessage({ id: 'components.secondaryMenu.myProfile' }),
            external: true,
            to: UrlAssembler.feArtistProfile(artist.id, artist.alias, locale),
            target: '_blank',
          },
        ]
      : []),
    {
      key: 'help',
      icon: HelpOutlineIcon,
      text: formatMessage({ id: 'components.secondaryMenu.help' }),
      external: true,
      to: LocalizedConfig.get('externalUrls.help'),
      target: '_blank',
    },
  ];

  return (
    <MainMenu
      logo={
        <Logo
          type={drawerVariant === GUI_ENUM.drawer.type.PERMANENT ? 'initials' : 'default'}
          size="small"
          href={UrlAssembler.byRouteKey('home')}
        />
      }
      open={open}
      onClick={onClick}
      drawerVariant={drawerVariant}
      onClose={onClose}
      drawerWidth={GUI_CONFIG.drawerWidth}
      mainMenuItems={mainMenuItems}
      secondaryMenuItems={secondaryMenuItems}
    />
  );
};
