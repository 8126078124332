import { AUTH_ACTIONS } from '../../actions/container';

type AuthState = {
  token: string | null;
  payload: Record<string, any> | null;
  register: Record<string, any> | null;
};

const DEFAULT_AUTH_STATE = {
  token: null,
  payload: null,
  register: null,
};

/**
 * authReducer
 *
 * @param {Record<string, any>} state
 * @param {Record<string, any>} action
 * @return {Record<string, any>} next state
 */
export function authReducer(
  state: AuthState = DEFAULT_AUTH_STATE,
  action: Record<string, any> = {},
): Record<string, any> {
  switch (action.type) {
    case AUTH_ACTIONS.TOKEN_UPDATED:
      return { ...DEFAULT_AUTH_STATE, ...action.payload };
    case AUTH_ACTIONS.TOKEN_CLEAR:
      return { token: null, payload: null };
    case AUTH_ACTIONS.AUTH_REGISTER_TOKEN_ADD:
      return { ...state, register: { ...state.register, ...action.payload } };
    default:
      return state;
  }
}
