export const application = {
  acl: {
    rolesWildcard: '*',
    rolesHierarchy: { seller_artist: { seller_partner: { partner_admin: {} } } },
  },
  i18n: {
    locales: [
      { name: 'en-GB', language: 'en', basePath: 'en', isDefault: true },
      { name: 'fr-FR', language: 'fr', basePath: 'fr' },
    ],
    currency: { signs: { uk: '£', eu: '€', us: '$' }, codes: { uk: 'GBP', eu: 'EUR', us: 'USD' } },
  },
  auth: { register: { domain: 'ra' } },
  domain: 'sellers.riseart.com',
  host: 'https://sellers.riseart.com',
  environmentLoggerScript: '/assets/scripts/environment.js',
  callbackDelay: 500,
};
export const libs = {
  events: { tagId: 'ra-events-lib', file: 'track.min.js', namespace: 'raev', source: 'frontend' },
  facebook: {
    statusConnected: 'connected',
    scope: 'email, public_profile',
    sdkVersion: 'v2.8',
    tagId: 'facebook-module',
    source: 'https://connect.facebook.net/en_US/sdk.js',
    scriptTag: 'script',
    appId: '183402448365183',
  },
  google: {
    tagId: 'google-module',
    source: 'https://accounts.google.com/gsi/client',
    scriptTag: 'script',
    jsType: 'text/javascript',
    allowPrompt: false,
    callbackName: 'googleAuthCallback',
    clientId: '860827466167.apps.googleusercontent.com',
  },
  sentry: {
    accountEndpoint: 'https://5d703b6b5ba648108ad5bcce3250cc11@o100575.ingest.sentry.io/6118232',
  },
};
export const cookies = { token: { name: '__rastkn' }, gui: { name: '__rasgui', expires: 356 } };
export const gui = { drawerWidth: 240 };
export const uri = {
  query: { params: { marketing: 'mktg', visitor: '_vid', message: '_msg' } },
  hash: {},
};
export const store = {
  keys: {
    application: 'application',
    notifications: 'notifications',
    pagePreloader: 'pagePreloader',
    auth: 'auth',
    me: 'me',
    gui: 'gui',
    errors: 'errors',
  },
};
export const art = { maxFilesize: 5242880, acceptedFiles: 'image/jpeg, image/png' };
export const feed = { maxFilesize: 2097152, acceptedFiles: 'image/jpeg, image/png' };
export const event = { maxFilesize: 2097152, acceptedFiles: 'image/jpeg, image/png' };
export const artDirection = {
  common: {
    avatar: [
      { width: 24, height: 'AUTO' },
      { width: 48, height: 'AUTO' },
      { width: 96, height: 'AUTO' },
      { width: 192, height: 'AUTO' },
    ],
  },
  art: {
    main: [
      { width: 140, height: 'AUTO' },
      { width: 280, height: 'AUTO' },
      { width: 350, height: 'AUTO' },
      { width: 560, height: 'AUTO' },
      { width: 700, height: 'AUTO' },
      { width: 1120, height: 'AUTO' },
    ],
  },
  artist: {
    banner: [
      { width: 450, height: 'AUTO' },
      { width: 900, height: 'AUTO' },
      { width: 1100, height: 'AUTO' },
      { width: 2200, height: 'AUTO' },
    ],
    additional: [
      { width: 450, height: 'AUTO' },
      { width: 900, height: 'AUTO' },
      { width: 1600, height: 'AUTO' },
      { width: 2880, height: 'AUTO' },
    ],
    detail: [
      { width: 450, height: 'AUTO' },
      { width: 600, height: 'AUTO' },
      { width: 900, height: 'AUTO' },
      { width: 1200, height: 'AUTO' },
    ],
    feed: [
      { width: 375, height: 'AUTO' },
      { width: 750, height: 'AUTO' },
      { width: 1500, height: 'AUTO' },
    ],
  },
  event: {
    main: [
      { width: 375, height: 'AUTO' },
      { width: 550, height: 'AUTO' },
      { width: 750, height: 'AUTO' },
      { width: 1400, height: 'AUTO' },
    ],
  },
};
export const components = {
  terms: { ignoreRouteKeys: ['terms', 'signout', 'login', 'notfound'] },
  status: {
    artist: { 0: 'warning', 1: 'success', 2: 'warning', 3: 'error' },
    event: { 1: 'success', 2: 'warning', 3: 'error', 4: 'info' },
    offer: { pending: 'warning', active: 'success', inactive: 'error' },
    partnerUser: { 1: 'success', 2: 'error' },
  },
  art: {
    fallbackImages: [
      {
        type: 'main-square',
        key: 'static_fallback_art_original-square',
        extension: 'jpg',
        height: 1120,
        width: 1120,
      },
      {
        type: 'main-original',
        key: 'static_fallback_art_original-master',
        extension: 'jpg',
        height: 1120,
        width: 1120,
      },
    ],
  },
};
export const errors = {
  errorsTypes: {
    __defaultError: {
      status: 500,
      code: 'SELR_1001',
      type: '__defaultError',
      title: 'errors.default.title',
      detail: 'errors.default.details',
      handler: 'notification',
      level: 10,
      expire: 15,
      image: 'https://d1ee3oaj5b5ueh.cloudfront.net/2017/10/59dcad93c93c0.jpeg',
      log: true,
    },
    __internalError: {
      status: 500,
      code: 'SELR_1002',
      type: 'JSError',
      handler: 'silent',
      level: 10,
    },
    CoreException: { status: 500, code: 'SELR_1010', type: 'CoreException' },
    ForbiddenException: { status: 403, type: 'ForbiddenException' },
    InvalidParamException: { status: 400, type: 'InvalidParamException' },
    MissingParamException: { status: 400, type: 'MissingParamException' },
    PageNotFound: {
      status: 404,
      type: 'PageNotFound',
      title: 'errors.pageNotFound.title',
      detail: 'errors.pageNotFound.details',
      handler: 'errorPage',
    },
    ResourceNotFoundException: {
      status: 404,
      type: 'ResourceNotFoundException',
      title: 'errors.pageNotFound.title',
      detail: 'errors.pageNotFound.details',
      handler: 'errorPage',
    },
    NetworkError: {
      status: 503,
      type: 'NetworkError',
      title: 'errors.networkError.title',
      detail: 'errors.networkError.details',
    },
    TimeoutError: {
      status: 503,
      type: 'NetworkError',
      title: 'errors.timeoutError.title',
      detail: 'errors.timeoutError.details',
    },
    ApiServerError: {
      type: 'ApiServerError',
      title: 'errors.apiServerError.title',
      detail: 'errors.apiServerError.details',
      handler: 'errorPage',
    },
    InvalidAuthorizationToken: {
      type: 'InvalidAuthorizationToken',
      title: 'errors.invalidToken.title',
      detail: 'errors.invalidToken.details',
    },
    InvalidAuthorizeParameters: { type: 'InvalidAuthorizeParameters' },
    AlreadyExistsException: {
      status: 409,
      type: 'AlreadyExistsException',
      handler: 'notification',
      level: 20,
      log: false,
    },
    AuthInvalidPassword: { type: 'AuthInvalidPassword', level: 20, log: false },
    AuthNoExistingUser: { type: 'AuthNoExistingUser', level: 20, log: false },
    ExistsInOtherDomain: { type: 'ExistsInOtherDomain', level: 20, log: false },
  },
  overwriteErrors: [
    {
      matchConditions: { status: 404, 'additional.path[0]': 'listSellerShippingTables' },
      overwriteFields: { handler: 'silent', log: false },
    },
    {
      matchConditions: {
        status: 400,
        type: 'InvalidParamException',
        'additional.path[0]': 'createArt',
      },
      overwriteFields: { handler: 'silent', log: false },
    },
    {
      matchConditions: {
        status: 400,
        type: 'IoInvalidException',
        'additional.path[0]': 'createArt',
      },
      overwriteFields: { handler: 'notification', placeholder: 'page', log: false },
    },
    {
      matchConditions: {
        status: 400,
        type: 'InvalidParamException',
        'additional.path[0]': 'updateArt',
      },
      overwriteFields: { handler: 'silent', log: false },
    },
    {
      matchConditions: {
        status: 400,
        type: 'IoInvalidException',
        'additional.path[0]': 'updateArt',
      },
      overwriteFields: { handler: 'notification', placeholder: 'page', log: false },
    },
    {
      matchConditions: {
        status: 400,
        type: 'InvalidParamException',
        'additional.path[0]': 'updateSeller',
      },
      overwriteFields: { handler: 'silent', log: false },
    },
    {
      matchConditions: {
        status: 400,
        type: 'IoInvalidException',
        'additional.path[0]': 'updateSeller',
      },
      overwriteFields: { handler: 'notification', placeholder: 'page', log: false },
    },
    {
      matchConditions: {
        status: 400,
        type: 'InvalidParamException',
        'additional.path[0]': 'createSellerPaymentMethod',
      },
      overwriteFields: { handler: 'silent', log: false },
    },
    {
      matchConditions: {
        status: 400,
        type: 'IoInvalidException',
        'additional.path[0]': 'createSellerPaymentMethod',
      },
      overwriteFields: { handler: 'notification', placeholder: 'page', log: false },
    },
    {
      matchConditions: {
        status: 400,
        type: 'InvalidParamException',
        'additional.path[0]': 'updateSellerPaymentMethod',
      },
      overwriteFields: { handler: 'silent', log: false },
    },
    {
      matchConditions: {
        status: 400,
        type: 'IoInvalidException',
        'additional.path[0]': 'updateSellerPaymentMethod',
      },
      overwriteFields: { handler: 'notification', placeholder: 'page', log: false },
    },
    {
      matchConditions: {
        status: 400,
        type: 'InvalidParamException',
        'additional.path[0]': 'createSellerShippingTable',
      },
      overwriteFields: { handler: 'silent', log: false },
    },
    {
      matchConditions: {
        status: 400,
        type: 'IoInvalidException',
        'additional.path[0]': 'createSellerShippingTable',
      },
      overwriteFields: { handler: 'notification', placeholder: 'page', log: false },
    },
    {
      matchConditions: {
        status: 400,
        type: 'InvalidParamException',
        'additional.path[0]': 'updateSellerShippingTable',
      },
      overwriteFields: { handler: 'silent', log: false },
    },
    {
      matchConditions: {
        status: 400,
        type: 'IoInvalidException',
        'additional.path[0]': 'updateSellerShippingTable',
      },
      overwriteFields: { handler: 'notification', placeholder: 'page', log: false },
    },
    {
      matchConditions: {
        status: 400,
        type: 'InvalidParamException',
        'additional.path[0]': 'updateArtist',
      },
      overwriteFields: { handler: 'silent', log: false },
    },
    {
      matchConditions: {
        status: 400,
        type: 'IoInvalidException',
        'additional.path[0]': 'updateArtist',
      },
      overwriteFields: { handler: 'notification', placeholder: 'page', log: false },
    },
    {
      matchConditions: { type: 'AuthError' },
      overwriteFields: {
        handler: 'notification',
        log: false,
        title: 'errors.invalidToken.title',
        detail: 'errors.invalidToken.details',
      },
    },
    {
      matchConditions: { 'additional.path[0]': 'deleteArtImage' },
      overwriteFields: { handler: 'notification', log: false },
    },
    {
      matchConditions: { 'additional.path[0]': 'listSellerLegals' },
      overwriteFields: { handler: 'silent', log: false },
    },
    {
      matchConditions: { 'additional.path[0]': 'readLegal' },
      overwriteFields: { handler: 'silent', log: false },
    },
    {
      matchConditions: { 'additional.path[0]': 'acceptSellerLegal' },
      overwriteFields: { handler: 'notification', log: false },
    },
  ],
};
export const locales = {
  'en-GB': {
    externalUrls: {
      imageUploadInstructions:
        'https://help.seller.riseart.com/article/216-artwork-image-sizes-choosing-the-right-size-to-upload',
      help: 'https://help.seller.riseart.com',
      artMediaHelp: 'https://help.seller.riseart.com/article/211-submitted-studio-diary-images',
      studioDiaryHelp: 'https://help.seller.riseart.com/article/211-submitted-studio-diary-images',
      rentalHelp: 'http://help.seller.riseart.com/article/49-how-does-renting-art-work',
      forgotPassword: 'https://www.riseart.com/password/forgot',
      privacy: 'https://www.riseart.com/privacy',
      terms: 'https://www.riseart.com/terms/general',
      artistGuide: 'https://get.riseart.com/artist-guide/',
      photoGuide:
        'https://d1ee3oaj5b5ueh.cloudfront.net/static/guides/en/Rise+Art+-+Artist+Photo+Guide.pdf',
      kycForm: 'https://riseart.typeform.com/to/Y87ym9cu',
    },
  },
  'fr-FR': {
    externalUrls: {
      imageUploadInstructions:
        'https://aide.vendeurs.riseart.com/article/355-tailles-des-images-d-uvres-dart-choisir-la-bonne-taille-a-telecharger',
      help: 'https://aide.vendeurs.riseart.com',
      artMediaHelp:
        'https://aide.vendeurs.riseart.com/article/332-soumettre-le-contenu-du-journal-de-studio',
      studioDiaryHelp:
        'https://aide.vendeurs.riseart.com/article/332-soumettre-le-contenu-du-journal-de-studio',
      rentalHelp: 'https://aide.vendeurs.riseart.com/article/336-louer-avec-rise-art',
      forgotPassword: 'https://www.riseart.com/fr/mot-de-passe/oublie',
      privacy: 'https://www.riseart.com/fr/confidentialite',
      terms: 'https://www.riseart.com/fr/conditions/general',
      artistGuide: 'https://get.riseart.com/fr/guide-de-l-artiste',
      photoGuide:
        'https://d1ee3oaj5b5ueh.cloudfront.net/static/guides/en/Rise+Art+-+Artist+Photo+Guide.pdf',
      kycForm: 'https://riseart.typeform.com/to/Y87ym9cu',
    },
  },
};
export const environment = 'production';
export const debug = {
  console: false,
  sentry: true,
  redux: false,
  logStoreState: false,
  traceback: false,
};
export const cdn = {
  fileSystem: { host: 'https://d1ee3oaj5b5ueh.cloudfront.net', thumbs: 'thumbs' },
};
export const frontend = { host: 'https://www.riseart.com' };
export const pages = {
  error: {
    error: {
      defaultBackgroundImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/2017/10/59dcad93c93c0.jpeg',
    },
  },
};
export const graphql = {
  endpoint: 'https://graphql.riseart.com',
  apiKey: 'da2-vswqfgyv2rbsrltqtcsyg6pue4',
  authKey: '392d984c-f11f-4009-a979-6272e7cd36d1',
  authKeyFrontend: '061ee510-89aa-499a-b840-8c229856c3f7',
};
export const version = { number: '16.0.1', hash: '7b47de6' };
