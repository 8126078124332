import { gql } from '@apollo/client';

export const ARTIST_DEGREE_FRAGMENT = gql`
  fragment ArtistDegreeFragment on ArtistDegree {
    id
    artistId
    description
    university
    city
    year
    countryCode
  }
`;
