import { gql } from '@apollo/client';

export const ARTIST_FEED_FRAGMENT = gql`
  fragment ArtistFeedFragment on ArtistFeed {
    id
    artistId
    status
    title
    text
    publishedDate
    mediaType
    mediaPlatform
    mediaId
    images {
      id
      status
      public
      mimeType
      key
      extension
      size
      width
      height
      type
      active
    }
  }
`;
