import Url from 'url-parse';
import { matchPath } from 'react-router-dom';
import { generateUrlFromSource, find } from '@riseart/fe-utils';
import { cdn as CONFIG_CDN, application as CONFIG_APP } from '../../../config/config.js';
import { ROUTER_CONFIG } from '../../../config/router.js';
import { NavigatorService } from '../Navigator';
import { LocationManager } from '../Location';

/**
 * areDifferent
 *
 * @param {Record<string, any> | string | null | undefined} objectOne
 * @param {Record<string, any> | string | null | undefined} objectTwo
 * @returns {boolean}
 */
export function areDifferent(
  objectOne: Record<string, any> | string | null | undefined,
  objectTwo: Record<string, any> | string | null | undefined,
): boolean {
  return JSON.stringify(objectOne) !== JSON.stringify(objectTwo);
}

/**
 * ParseNumberToLocaleString
 *
 * @param {number} value
 * @returns {string}
 */
export function parseNumberToLocaleString(value = '0'): string {
  return parseInt(value, 10).toLocaleString();
}

/**
 * parseFloatToLocaleString
 *
 * @param {string} value
 * @param {number} fixedDigits
 * @returns {string} formatted value
 */
export function parseFloatToLocaleString(value: string, fractionDigits = 2): string {
  return parseFloat(value).toLocaleString(NavigatorService.get('language'), {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
}

/**
 * Determines if the client has a retina display
 *
 * @returns {boolean}
 */
export function isRetina(): boolean {
  const matchMedia = typeof window !== 'undefined' ? window.matchMedia : null;

  if (!matchMedia) {
    return false;
  }

  const mq = matchMedia(
    'only screen and (-moz-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)',
  );

  return !!(mq && mq.matches);
}

/**
 * handlePrintClick
 *
 * @returns {void}
 */
export function handlePrintClick(): void {
  window && typeof window.print === 'function' && window.print();
}

/**
 * isRoutedUrl
 *
 * @param {string} url
 * @returns {boolean | Record<string, any>}
 */
export function isRoutedUrl(url: string): boolean | Record<string, any> {
  const origin = LocationManager.get('origin');
  const oUrl = new Url(url, origin);

  if (oUrl.origin !== origin) {
    return false;
  }

  return find(ROUTER_CONFIG, (route: Record<string, any>) => {
    const matchedExactPath = matchPath(oUrl.pathname, {
      path: route.path,
      exact: true,
      strict: false,
    });

    // if path is matched exactly by route patters, then it is assumed
    // this will always be routed through the app router
    if (matchedExactPath) {
      return true;
    }

    // at this point the matchedExactPath is false and if route.exact === true then,
    // it means that no match is found, thus we need to skip next condition checks
    if (route.exact === true) {
      return false;
    }

    // check if path with exact: false is matched.
    if (matchPath(oUrl.pathname, { path: route.path, exact: route.exact })) {
      return true;
    }

    return false;
  });
}

/**
 * generateContentUrl
 * @param {string} key
 * @param {string} extension
 * @param {string} cdnHost
 * @returns {string} combined content url
 */
export function generateContentUrl(
  key: string,
  extension: string,
  // @ts-ignore
  cdnHost: string = CONFIG_CDN.file_system.host,
): string {
  return generateUrlFromSource(key, extension, cdnHost);
}

/**
 * formatCurrency
 *
 * @param {number} amount
 * @param {string} storeCode
 * @param {string} sign
 * @param {{ precision: number, hasSpaceAfterCurrency?: boolean }} options
 * @returns {string}
 */
export function formatCurrency(
  amount: string,
  storeCode?: string,
  sign?: string,
  options: { precision?: number; hasSpaceAfterCurrency?: boolean } = {},
): string {
  const { hasSpaceAfterCurrency, precision } = {
    precision: 2,
    hasSpaceAfterCurrency: true,
    ...options,
  };
  let currency = '';

  if (storeCode || sign) {
    // @ts-ignore
    currency = storeCode ? CONFIG_APP.i18n.currency.signs[storeCode] : sign;

    if (currency && hasSpaceAfterCurrency) {
      currency += ' ';
    }
  }
  return `${currency}${
    precision > 0 ? parseFloatToLocaleString(amount, precision) : parseNumberToLocaleString(amount)
  }`;
}

/**
 * isPromise
 *
 * @param {any} promise
 * @returns {boolean}
 */
export function isPromise(promise: Promise<any>): boolean {
  return !!promise && typeof promise.then === 'function';
}

/**
 * capitalize
 *
 * @param {string} string
 * @returns {string}
 */
export function capitalize(string: string): string {
  if (!string || typeof string !== 'string') {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * concatValues
 *
 * @param {string[]} values
 * @param {string} separator
 * @returns {string}
 */
export function concatValues(values: string[], separator = ' '): string {
  return values.filter((value) => !!value).join(separator);
}

/**
 * getStringInitial
 *
 * @param {string} string
 * @returns {?string}
 */
export function getStringInitial(string = ''): string | undefined {
  if (!string || typeof string !== 'string') {
    return;
  }

  return string.charAt(0).toUpperCase();
}

/**
 * isTranslationKey
 *
 * @param {string} value
 * @returns {boolean}
 */
export function isTranslationKey(value: string): boolean {
  const TRANSLATION_REGEX = /^[A-Za-z0-9_]+\.{1}[A-Za-z0-9_.]+[^.]$/;
  return typeof value === 'string' && TRANSLATION_REGEX.test(value);
}

/**
 * delay
 *
 * @param {() => any} callback
 * @param {number} delay
 * @returns {ReturnType<typeof setTimeout>}
 */
export function delay(
  callback: () => any,
  delay: number = CONFIG_APP.callbackDelay,
): ReturnType<typeof setTimeout> {
  return setTimeout(callback, delay);
}

/**
 * asyncDelay
 *
 * @param {number} milisec
 * @returns {Promise<{ type: string }>}
 */
export async function asyncDelay(milisec = CONFIG_APP.callbackDelay): Promise<{ type: string }> {
  return new Promise((resolve) => {
    setTimeout(() => resolve({ type: 'asyncDelay' }), milisec);
  });
}

/**
 * compareTime
 *
 * @param {Date | string} startTime
 * @param {Date | string} endTime
 * @returns {null | 1 | 0 | -1}
 */
export function compareTime(startTime: Date | string, endTime: Date | string): null | 1 | 0 | -1 {
  let startHour = null;
  let startMinutes = null;
  let endHour = null;
  let endMinutes = null;

  // Start hour
  if (startTime instanceof Date && startTime.getHours() >= 0) {
    startHour = startTime.getHours();
  } else if (typeof startTime === 'string' && parseInt(startTime.split(':')[0], 10) >= 0) {
    startHour = parseInt(startTime.split(':')[0], 10);
  }

  // End hour
  if (endTime instanceof Date && endTime.getHours() >= 0) {
    endHour = endTime.getHours();
  } else if (typeof endTime === 'string' && parseInt(endTime.split(':')[0], 10) >= 0) {
    endHour = parseInt(endTime.split(':')[0], 10);
  }

  // Start minutes
  if (startTime instanceof Date && startTime.getMinutes() >= 0) {
    startMinutes = startTime.getHours();
  } else if (typeof startTime === 'string' && parseInt(startTime.split(':')[1], 10) >= 0) {
    startMinutes = parseInt(startTime.split(':')[1], 10);
  }

  // End minutes
  if (endTime instanceof Date && endTime.getMinutes() >= 0) {
    endMinutes = endTime.getMinutes();
  } else if (typeof endTime === 'string' && parseInt(endTime.split(':')[1], 10) >= 0) {
    endMinutes = parseInt(endTime.split(':')[1], 10);
  }

  // Invalid values
  if (startHour === null || endHour === null || startMinutes === null || endMinutes === null) {
    return null;
  }

  const start = startHour * 60 + startMinutes;
  const end = endHour * 60 + endMinutes;

  // startTime after endTime
  if (start > end) {
    return 1;
  }

  // startTime before endTime
  if (start < end) {
    return -1;
  }

  // startTime same as endTime
  if (start === end) {
    return 0;
  }

  return null;
}

/**
 * printString
 *
 * @param {string }htmlContent
 * @returns {void}
 */
export function printString(htmlContent: string): void {
  const openWindow = window.open('', 'title', 'attributes');

  if (openWindow) {
    openWindow.document.write(htmlContent);
    openWindow.document.close();
    openWindow.focus();
    openWindow.print();
    openWindow.close();
  }
}

/**
 * getStoreByCurrencyCode
 *
 * @param {string} currencyCode
 * @returns {string | undefined}
 */
export function getStoreByCurrencyCode(currencyCode: string): string | undefined {
  return Object.keys(CONFIG_APP.i18n.currency.codes).find(
    (key: string) =>
      CONFIG_APP.i18n.currency.codes[key as keyof typeof CONFIG_APP.i18n.currency.codes] ===
      currencyCode,
  );
}
