import { gql } from '@apollo/client';

export const CREATE_FILE_UPLOAD = gql`
  mutation createFileUpload(
    $fileName: String!
    $objectType: String!
    $objectId: Int
    $imageCategory: String
  ) {
    createFileUpload(
      fileName: $fileName
      objectType: $objectType
      objectId: $objectId
      imageCategory: $imageCategory
    ) {
      transactionId
      transactionStage
      transactionStatus
      transactionProgress
      transactionErrorCode
      transactionErrorMessage
      uploadUrl
    }
  }
`;
