import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SplitButton } from '@riseart/dashboard';
import { splitButtonConfirmationProps } from '../common/buttons/settings';
import { artist as ENUM_ARTIST } from '../../config/enumeration.js';

type Props = {
  initialValue?: string | null;
  onChange: (value: string) => void;
};

/**
 * ArtistFeedListSelect
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const ArtistFeedListSelect = ({ initialValue = null, onChange }: Props): JSX.Element => {
  const OPTION_ALL = 'all';
  const [value, setValue] = useState(initialValue);

  return (
    <SplitButton
      variant="outlined"
      mainButtonProps={{
        children: <FormattedMessage id={`components.feed.filter.values.${value || OPTION_ALL}`} />,
      }}
      translationPrefix="components.dialog.art.action"
      confirmationDialogProps={splitButtonConfirmationProps}
      options={[OPTION_ALL, ...Object.keys(ENUM_ARTIST.feed.media.type)].map((typeKey: string) => {
        //@ts-ignore
        const menuValue = ENUM_ARTIST.feed.media.type[typeKey] || null;

        return {
          text: (
            <FormattedMessage id={`components.feed.filter.values.${menuValue || OPTION_ALL}`} />
          ),
          onClick: () => {
            setValue(menuValue);
            onChange(menuValue);
          },
        };
      })}
    />
  );
};
