import { SyntheticEvent } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams, Link as RouterLink, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {
  Link,
  Title,
  ConfirmationDialog,
  FormHeader,
  LayoutPaper,
  FileList,
  FileListItem,
  useUploadedFiles,
  FormBuilder,
  FormSideItem,
} from '@riseart/dashboard';
import { FileUploader } from '@riseart/files';
import { event as EVENT_CONFIG } from '../../../config/config.js';
import {
  event as EVENT_ENUM,
  errors as ERRORS_ENUM,
  file as FILE_ENUM,
} from '../../../config/enumeration.js';
import { useScrollToEl } from '../../../data/hooks/useScrollToEl';
import { FileDropzone } from '../../common/file/Dropzone';
import { fileListClassName } from '../../common/file/ListItem';
import { FileRestrictionsHint } from '../../common/file/RestrictionsHint';
import { selectSeller } from '../../../services/redux/selectors/seller';
import { mapApiValidationErrors, mapApiErrors } from '../../../services/riseart/utils/Form';
import { DefaultLayout } from '../../layout/Default';
import { useNotification } from '../../../data/hooks/useNotification';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { Picture } from '../../common/artdirection/Picture';
import { extractImageFileFromData } from '../../../services/riseart/utils/Image';
import { EventFormModel } from '../../forms/models/event';
import { Event as EventModel } from '../../../data/models/Event';
import { createFileUpload, updateFileUpload } from '../../../data/gql/handlers';
import { READ_EVENT } from '../../../data/gql/queries/event/read.graphql';
import { UPDATE_EVENT } from '../../../data/gql/queries/event/update.graphql';

/**
 * EventEditPage
 *
 * @returns {JSX.Element}
 */
export const EventEditPage = (): JSX.Element => {
  const { setScrollRef, scrollToRef } = useScrollToEl();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { storeCode } = useSelector(selectSeller) || {};
  const { dispatchNotification } = useNotification({
    detail: 'components.notifications.eventUpdated',
  });
  const { uploadedFiles, handleFileDrop, handleFileValidation } = useUploadedFiles({
    filesByType: { main: [] },
    maxFileSize: EVENT_CONFIG.maxFilesize,
    sizeValidationMessage: formatMessage(
      { id: 'forms.common.largerFilesize' },
      { maxFilesize: EVENT_CONFIG.maxFilesize },
    ),
  });
  const { id: eventId }: Record<string, any> = useParams();
  const {
    data: readData,
    loading: readLoading,
    refetch: refetchReadQuery,
  } = useQuery(READ_EVENT, {
    variables: { eventId },
  });
  const eventData = (readData && readData.readEvent) || null;
  const [updateEvent, { loading: updateLoading }] = useMutation(UPDATE_EVENT);
  const currentImage =
    eventData &&
    extractImageFileFromData.byType(eventData.images, EVENT_ENUM.image.category.SQUARE);
  const currentImageElement = currentImage ? (
    <Picture
      type="form.main"
      artDirectionKey="event.main"
      image={currentImage}
      alt={eventData.name}
    />
  ) : null;

  const Event = new EventModel();

  if (eventData) {
    Event.hydrateFromApiData(eventData);
  }

  /**
   * handleFileUpload
   *
   * @param {Record<string, any>[]} files
   * @param {ImageType} type
   * @param {(state: Record<string, any> | null) => () => void} callback
   * @returns {void}
   */
  const handleFileUpload = (
    files: Record<string, any>[],
    type: string,
    callback: (state: Record<string, any> | null) => () => void,
  ) => {
    const FileUploadClient = new FileUploader(
      FileUploader.flattenFiles({ [type]: files }),
      eventData.id,
      FILE_ENUM.object.type.EVENT,
      createFileUpload,
      updateFileUpload,
    );
    FileUploadClient.subscribe(FileUploader.eventsTypes.UPDATE, (fileUploadState) => {
      callback(fileUploadState.files);
    });
    FileUploadClient.subscribe(FileUploader.eventsTypes.COMPLETE, (fileUploadState) => {
      refetchReadQuery()
        .then(() => callback(fileUploadState.files))
        .catch(() => callback(null));
    });
    FileUploadClient.process();
  };

  return (
    <DefaultLayout
      title={formatMessage({ id: 'components.mainMenu.eventList' })}
      loading={readLoading || updateLoading}
      boxRefCallback={setScrollRef}
      breadcrumbs={[
        <Link
          key="home"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link
          key="eventList"
          component={RouterLink}
          to={UrlAssembler.eventList()}
          title={formatMessage({ id: 'components.mainMenu.eventList' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'components.mainMenu.eventList' })}
        </Link>,

        ...(eventData && eventData.name
          ? [
              <Typography key="event" variant="inherit">
                {eventData.name}
              </Typography>,
            ]
          : []),
      ]}
    >
      {eventData ? (
        <LayoutPaper>
          <FormHeader
            title={<Title variant="h5">{formatMessage({ id: 'components.event.edit' })}</Title>}
          />
          <Grid container spacing={4}>
            <Grid item xs={12} sm={5}>
              <FormSideItem>
                <ConfirmationDialog
                  title={formatMessage({ id: 'components.dialog.event.replaceImage.title' })}
                  description={formatMessage({
                    id: 'components.dialog.event.replaceImage.description',
                  })}
                  disagreeLabel={formatMessage({ id: 'common.no' })}
                  agreeLabel={formatMessage({ id: 'common.yes' })}
                  onAgree={(event: SyntheticEvent, ...rest) => {
                    handleFileUpload(
                      ...rest,
                      EVENT_ENUM.image.category.MAIN,
                      handleFileDrop(EVENT_ENUM.image.category.MAIN),
                    );
                  }}
                >
                  {(handleOpen) => (
                    <FileDropzone
                      label={formatMessage({ id: 'forms.event.label.image' })}
                      uploadedImage={currentImageElement}
                      text={formatMessage({ id: 'forms.event.hints.image' })}
                      textMiddle={formatMessage({ id: 'common.or' })}
                      textButton={formatMessage({ id: 'common.browseFiles' })}
                      onDrop={handleOpen}
                      validator={handleFileValidation}
                      accept={EVENT_CONFIG.acceptedFiles}
                      multiple={false}
                      hint={
                        <FileRestrictionsHint translationKey="forms.event.hints.imageUploadRestrictions" />
                      }
                    />
                  )}
                </ConfirmationDialog>
                {uploadedFiles && uploadedFiles.main ? (
                  <FileList>
                    {uploadedFiles.main.map(
                      (
                        { name, progress, size, errorMessage }: Record<string, any>,
                        idx: number,
                      ) => (
                        <FileListItem
                          key={`${name}${idx}`}
                          className={fileListClassName}
                          name={name}
                          size={size}
                          errors={errorMessage}
                          progress={progress || 0}
                          status="loading"
                        />
                      ),
                    )}
                  </FileList>
                ) : null}
              </FormSideItem>
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormBuilder
                settings={EventFormModel.settings}
                fields={EventFormModel.fields}
                submitText={formatMessage({ id: 'common.saveChanges' })}
                initialValues={Event}
                customData={{ storeCode }}
                showReset
                cancel={
                  <Button
                    component={RouterLink}
                    to={UrlAssembler.eventList()}
                    type="button"
                    variant="outlined"
                    size="large"
                    fullWidth
                  >
                    {formatMessage({ id: 'common.cancel' })}
                  </Button>
                }
                onSubmit={async (formState, addApiErrors) => {
                  updateEvent({
                    variables: {
                      id: eventData.id,
                      inputEvent: {
                        ...EventModel.mapFormToApiData(formState.data, EventFormModel.fields),
                      },
                    },
                  })
                    .then(() => {
                      refetchReadQuery().catch(() => null);

                      // Scroll to top of container
                      scrollToRef();

                      // Show update success notification
                      dispatchNotification({
                        detail: 'components.notifications.eventUpdated',
                        level: ERRORS_ENUM.levels.SUCCESS,
                        placeholder: ERRORS_ENUM.placeholders.SNACKBAR,
                        expire: 5,
                      });

                      history.push(UrlAssembler.eventEdit(eventData.id));
                    })
                    .catch((error) => {
                      // Map validation errors from response
                      const validationError = mapApiValidationErrors(error);

                      if (validationError && validationError.length && addApiErrors) {
                        addApiErrors(validationError);
                      } else {
                        // Map any other type of errors from response (validation without provided fields, or non-validation errors)
                        mapApiErrors(error).forEach((err: string) => {
                          dispatchNotification({
                            detail: err,
                            level: ERRORS_ENUM.levels.ERROR,
                            placeholder: ERRORS_ENUM.placeholders.PAGE,
                          });
                        });
                      }

                      // Scroll to error
                      scrollToRef();
                    });
                }}
              />
            </Grid>
          </Grid>
        </LayoutPaper>
      ) : null}
    </DefaultLayout>
  );
};
