import { DataTableColumnsType, LAYOUT_PAPER_PADDING } from '@riseart/dashboard';
import { GridColumns } from '@mui/x-data-grid';
import { DataGrid } from '../../common/data/Grid';
import { DataListFeed } from '../../data/ListFeed';
import {
  convertFiltersToApiPayload,
  convertSortToApiPayload,
} from '../../data/tables/filters/utils';

type Props = {
  query: any;
  queryVars?: Record<string, any> | null;
  columns?: DataTableColumnsType;
  filterConfig: Record<string, any>[];
  sortConfig: Record<string, any>[];
  schema: GridColumns<any>;
  customData?: Record<string, any> | null;
  itemsPerPage?: number;
  itemMapper?: (item: Record<string, any>) => Record<string, any>;
  onSelect?: ((data: any) => any) | null;
};

/**
 * DataList
 *
 * @param {Props} props
 * @returns
 */
export function DataList({
  query,
  queryVars = null,
  filterConfig,
  sortConfig,
  schema,
  itemMapper,
  onSelect,
  customData = null,
  itemsPerPage = 10,
}: Props): JSX.Element {
  return (
    <DataListFeed
      query={query}
      itemsPerPage={itemsPerPage}
      variablesMapper={({
        page = 1,
        itemsPerPage,
        sort,
        filters,
        ...qsParams
      }: Record<string, any>): Record<string, any> => ({
        page,
        items: itemsPerPage || 10,
        ...queryVars,
        ...convertSortToApiPayload(sort, sortConfig),
        ...convertFiltersToApiPayload(filters, filterConfig),
        ...qsParams,
      })}
    >
      {({
        loading,
        items,
        pagination,
        qsParams,
        handleParamsChange,
        refetchListQuery,
      }: Record<string, any>) => {
        return (
          <DataGrid
            layoutPaperPadding={LAYOUT_PAPER_PADDING}
            columns={schema}
            rows={
              items &&
              (itemMapper ? items.map(itemMapper) : items).map((item: Record<string, any>) => ({
                ...item,
                rowData: item,
                customData: { ...customData, refetchListQuery, onSelect },
              }))
            }
            pagination={pagination}
            paramsState={qsParams}
            onChange={(params) => {
              handleParamsChange(params);
            }}
            loading={loading}
          />
        );
      }}
    </DataListFeed>
  );
}
