import { find } from '@riseart/fe-utils';

/**
 * mapToFormData
 *
 * @param {Object} rawData
 * @param {string} formFields
 * @returns {Record<string, any>}
 */
export function mapToFormData(
  rawData: Record<string, any> = {},
  formFields: Record<string, any>[],
): Record<string, any> {
  return {
    ...formFields.reduce((accumulator, { name }) => {
      const foundAttribute = find(
        rawData.attributes,
        (attribute: Record<string, any>) => attribute.name === name,
      );
      return {
        ...accumulator,
        [name]: typeof foundAttribute !== 'undefined' ? foundAttribute.value : null,
      };
    }, {}),
  };
}
