import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import { Title, Paragraph } from '@riseart/dashboard';
import { concatValues } from '../../../services/riseart/utils/Utils';
import { selectUser } from '../../../services/redux/selectors/user';

type Props = {
  children?: React.ReactNode;
};

/**
 * DashboardCardProfile
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export function DashboardCardProfile({ children = null }: Props): JSX.Element {
  const { firstName, lastName, email } = useSelector(selectUser) || {};

  return (
    <>
      <Title color="primary" sx={{ marginBottom: 3 }}>
        <FormattedMessage id="common.welcome" />
      </Title>
      <Typography component="p" variant="h4">
        {concatValues([firstName, lastName])}
      </Typography>
      {children}
      <Paragraph sx={{ flex: 1 }}>{email}</Paragraph>
      {/*
      // Temporary removed
      <div>
        <Link
          component={RouterLink}
          underline="hover"
          to={UrlAssembler.byRouteKey('sellerProfile')}
          title="View Profile"
        >
          View profile
        </Link>
      </div>
      */}
    </>
  );
}
