import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import { Link } from '@riseart/dashboard';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { selectPartner } from '../../../../services/redux/selectors/partner';
import { DefaultLayout } from '../../../layout/Default';
import { PageSubmenu } from '../../../common/PageSubmenu';
import { Artist as ArtistModel } from '../../../../data/models/Artist';
import { ArtistFeedList } from '../../../artist/feed/List';
import { READ_ARTIST } from '../../../../data/gql/queries/artist/read.graphql';
import { GET_PARTNER_ARTIST } from '../../../../data/gql/queries/partner/getArtist.graphql';

/**
 * PartnerArtistFeedList
 *
 * @returns {JSX.Element}
 */
export const PartnerArtistFeedList = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const { id: artistId }: Record<string, any> = useParams();
  const partner = useSelector(selectPartner) || {};
  const pageTitle = formatMessage({ id: 'components.mainMenu.artistFeedList' });
  const listLabel = formatMessage({ id: 'components.mainMenu.artistsList' });

  // Queries and Mutations
  const { data: listArtist, loading: listLoading } = useQuery(GET_PARTNER_ARTIST, {
    fetchPolicy: 'network-only',
    variables: {
      partnerId: partner && partner.id,
      filters: { artistId: { value: artistId, operator: 'EQUAL' } },
    },
  });
  const partnerArtist =
    listArtist && listArtist.artist && listArtist.artist.items && listArtist.artist.items[0];
  const canAdmin = partnerArtist && partnerArtist.canUpdate;
  const { data: readArtistData, loading: readLoading } = useQuery(READ_ARTIST, {
    fetchPolicy: 'network-only',
    variables: { id: artistId },
    skip: !(partnerArtist && partnerArtist.artistId),
  });
  const rawArtistData = (readArtistData && readArtistData.readArtist) || null;
  const Artist = new ArtistModel();

  if (rawArtistData) {
    Artist.hydrateFromApiData(rawArtistData);
  }

  return (
    <DefaultLayout
      title={pageTitle}
      topContent={
        canAdmin === false ? (
          <Alert severity="warning">
            {formatMessage({ id: 'components.artist.canAdminMessage' })}
          </Alert>
        ) : null
      }
      includesFab={canAdmin === true}
      loading={readLoading || listLoading}
      breadcrumbs={[
        <Link
          key="home"
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link
          key="allArtists"
          to={UrlAssembler.artistList()}
          title={listLabel}
          underline="hover"
          color="inherit"
        >
          {listLabel}
        </Link>,
        ...(Artist.name
          ? [
              <Link
                key="profile"
                to={UrlAssembler.artistDetails(Artist.id)}
                title={Artist.name}
                underline="hover"
                color="inherit"
              >
                {Artist.name}
              </Link>,
              <Typography key="feed" variant="inherit">
                {formatMessage({ id: 'components.artist.tab.feed' })}
              </Typography>,
            ]
          : []),
      ]}
    >
      <PageSubmenu menu="artist" selected="partnerArtistFeedList" urlParams={{ id: Artist.id }} />
      <Paper sx={{ position: 'relative' }}>
        <ArtistFeedList
          artist={Artist}
          canAdmin={canAdmin}
          addLink={UrlAssembler.partnerArtistFeedAdd(Artist.id)}
          pageUrlAssembler={(options: Record<string, any>) =>
            UrlAssembler.partnerArtistFeedList(Artist.id, options)
          }
          entityUrlAssembler={(id: number, options?: Record<string, any>) =>
            UrlAssembler.partnerArtistFeedDetail(artistId, id, options)
          }
        />
      </Paper>
    </DefaultLayout>
  );
};
