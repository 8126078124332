import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Title, CircularLoader, Paragraph, Link } from '@riseart/dashboard';
import { selectArtist } from '../../../services/redux/selectors/artist';
import { selectSeller } from '../../../services/redux/selectors/seller';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { art as ART_ENUM } from '../../../config/enumeration.js';
import { ART_SUMMARY } from '../../../data/gql/queries/art/summary.graphql';

/**
 * DashboardCardArtworks
 *
 * @returns {JSX.Element}
 */
export function DashboardCardArtworks(): JSX.Element {
  const artist = useSelector(selectArtist);
  const seller = useSelector(selectSeller);
  const BASE_FILTERS = {
    seller: seller && seller.id,
    ...(artist && artist.id ? { artist: artist.id } : null),
  };
  const { data: summaryArt, loading: summaryArtLoading } = useQuery(ART_SUMMARY, {
    variables: {
      filtersAll: { ...BASE_FILTERS, state: ART_ENUM.state.ALL },
      filtersActive: { ...BASE_FILTERS, state: ART_ENUM.state.ACTIVE },
      filtersUnlisted: { ...BASE_FILTERS, state: ART_ENUM.state.UNLISTED },
      filtersPending: { ...BASE_FILTERS, state: ART_ENUM.state.PENDING },
      filtersSold: { ...BASE_FILTERS, state: ART_ENUM.state.SOLD },
      page: 1,
      store: seller && seller.storeCode,
      items: 1,
      shippingCountry: seller && seller.shipsCountryCode,
    },
  });
  const DATA_KEY_MAPPER = [
    {
      key: 'allArt',
      translation: 'total',
      url: UrlAssembler.artList(),
    },
    {
      key: 'activeArt',
      translation: 'active',
      url: UrlAssembler.artList({ search: `?show=${ART_ENUM.state.ACTIVE}` }),
    },
    {
      key: 'unlistedArt',
      translation: 'unlisted',
      url: UrlAssembler.artList({ search: `?show=${ART_ENUM.state.UNLISTED}` }),
    },
    {
      key: 'pendingArt',
      translation: 'pending',
      url: UrlAssembler.artList({ search: `?show=${ART_ENUM.state.PENDING}` }),
    },
    {
      key: 'soldArt',
      translation: 'sold',
      url: UrlAssembler.artList({ search: `?show=${ART_ENUM.state.SOLD}` }),
    },
  ];

  return (
    <CircularLoader active={summaryArtLoading}>
      <Title color="primary" sx={{ marginBottom: 3 }}>
        <FormattedMessage id="components.art.summary.title" />
      </Title>
      <Grid container justifyContent="left" spacing={2} columns={{ xs: 2, sm: 5 }}>
        {DATA_KEY_MAPPER.map(({ key, translation, url }) =>
          summaryArt && summaryArt[key] ? (
            <Grid key={key} item xs={1} sm={1}>
              <FormattedMessage id={`components.art.summary.${translation}`}>
                {(text: ReactNode[]): any | null => (
                  <div>
                    <Link to={url} title={text} underline="none">
                      <Typography component="p" variant="h4" color="text.primary">
                        {summaryArt[key].pagination.totalItems}
                      </Typography>{' '}
                      <Paragraph>{text}</Paragraph>
                    </Link>
                  </div>
                )}
              </FormattedMessage>
            </Grid>
          ) : null,
        )}
      </Grid>
    </CircularLoader>
  );
}
