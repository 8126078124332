import { gql } from '@apollo/client';
import { ARTIST_BASE_FRAGMENT } from '../artist/base.graphql';
import { ART_IMAGE_FRAGMENT } from './image.graphql';
import { ART_MEDIA_FRAGMENT } from './media.graphql';

export const ART_FRAGMENT = gql`
  ${ARTIST_BASE_FRAGMENT}
  ${ART_IMAGE_FRAGMENT}
  ${ART_MEDIA_FRAGMENT}
  fragment ArtFragment on Art {
    id
    artist {
      ...ArtistBaseFragment
    }
    depth
    description
    height
    images {
      ...ArtImageFragment
      children {
        ...ArtImageFragment
      }
    }
    media {
      ...ArtMediaFragment
    }
    materials
    medium
    orientation
    status
    state
    statusMessage
    style
    subMedium
    subSubject
    subject
    title
    units
    slug
    width
    year
    isProduct
    is3D
    tags
    locales {
      localeCode
      slug
    }
    productId
    productCanBuy
    productCanRent
    productCanActivate
    productCanApprove
    productCanDisapprove
    productCanDelete
    productCanUnlist
    productCanEnquire
    productCanSubmit
    productCanSellOut
    productCanArchive
    productInStore
    productInStores
    productIsEnquire
    productIsRented
    productIsRental
    productIsSoldOut
    productIsUnlisted
    productIsPendingApproval
    productIsActive
    productIsArchived
    productIsDeleted
    productPrimarySku {
      id
      type
      sku
      stock
      substrate
      editionSize
      width
      height
      depth
      units
      stores {
        id
        status
        storeCode
        currency
        price
        priceInInstalments
      }
      framed
      frameDescription
      inStock
      options {
        id
        type
        group
        required
        singleValue
        inputType
        isFrame
        isInsurance
        storeCode
        title
        values {
          id
          optionId
          storeCode
          currency
          price
          title
        }
      }
      physicalSku
      unstretched
      readyToHang
      packageMethod
      rental
      signed
      signedLocation
      weight
      year
      canBeFramed
      shipsBy
      shipsFramedBy
      shipsFromCode
      canBuyInInstalments
      sellerShippingTableId
    }
    productPublished
    productSkus {
      id
      type
      sku
      stock
      substrate
      editionSize
      width
      height
      depth
      units
      stores {
        id
        status
        storeCode
        currency
        price
        priceInInstalments
      }
      framed
      frameDescription
      inStock
      options {
        id
        type
        group
        required
        singleValue
        inputType
        isFrame
        isInsurance
        storeCode
        title
        values {
          id
          optionId
          storeCode
          currency
          price
          title
        }
      }
      physicalSku
      unstretched
      readyToHang
      packageMethod
      rental
      signed
      signedLocation
      weight
      year
      canBeFramed
      shipsBy
      shipsFramedBy
      shipsFromCode
      canBuyInInstalments
      sellerShippingTableId
      sellerCanRentArt
    }
    productStores {
      id
      status
      storeCode
    }
    productType
  }
`;
