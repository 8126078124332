import { gql } from '@apollo/client';
import { PARTNER_INVITATION_FRAGMENT } from '../../fragments/partner/invitation.graphql';

export const CREATE_PARTNER_INVITATION = gql`
  ${PARTNER_INVITATION_FRAGMENT}
  mutation createPartnerInvitation($partnerId: Int!, $email: String!, $aclRole: String!) {
    createPartnerInvitation(partnerId: $partnerId, email: $email, aclRole: $aclRole) {
      ...PartnerInvitationFragment
    }
  }
`;
