import ReactMarkdown from 'react-markdown';

import styles from './Preview.module.css';

type Props = { children: any };

/**
 * MarkdownPreview
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export function MarkdownPreview({ children }: Props): JSX.Element {
  return <ReactMarkdown className={styles.markdown}>{children}</ReactMarkdown>;
}
