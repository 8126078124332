import React from 'react';
import Button from '@mui/material/Button';
import FacebookIcon from '@mui/icons-material/Facebook';
import { libs as LIBS_CONFIG } from '../../../config/config.js';

type Props = {
  setToken: (token: string | null) => void;
  children?: React.ReactNode;
};

/**
 * FacebookButton
 *
 * @param {Props} props
 * @returns {React.FC<Props>}
 */
export const FacebookButton: React.FC<Props> = ({ setToken, children }: Props) => {
  /**
   * statusChangeCallback
   * @param response
   */
  const statusChangeCallback = (response: Record<string, any>) => {
    if (response.status === LIBS_CONFIG.facebook.statusConnected) {
      return setToken(response.authResponse.accessToken);
    }
    return setToken(null);
  };

  /**
   * checkLoginState
   * @param response
   */
  const checkLoginState = () => {
    window.FB.getLoginStatus((response: Record<string, any>) => {
      statusChangeCallback(response);
    });
  };

  /**
   * handleLogin
   */
  const handleLogin = () => {
    window.FB.login(
      () => {
        checkLoginState();
      },
      { scope: LIBS_CONFIG.facebook.scope },
    );
  };

  /**
   * @returns {React$Element<Button>}
   */
  return (
    <Button
      variant="outlined"
      size="large"
      fullWidth
      startIcon={<FacebookIcon />}
      onClick={handleLogin}
      sx={{ height: '40px', borderRadius: '4px', textTransform: 'none' }}
    >
      {children}
    </Button>
  );
};
