import { gql } from '@apollo/client';
import { SELLER_FRAGMENT } from '../../fragments/seller/seller.graphql';

export const READ_SELLER = gql`
  ${SELLER_FRAGMENT}
  query readSeller($sellerId: Int!) {
    readSeller(sellerId: $sellerId) {
      ...SellerFragment
    }
  }
`;
