import { gql } from '@apollo/client';
import { ARTIST_FRAGMENT } from '../artist/artist.graphql';

export const PARTNER_ARTIST_FRAGMENT = gql`
  ${ARTIST_FRAGMENT}
  fragment PartnerArtistFragment on PartnerArtist {
    artistId
    partnerId
    status
    admin
    rank
    artist {
      ...ArtistFragment
    }
  }
`;
