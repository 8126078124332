import { gql } from '@apollo/client';
import { SELLER_SHIPPING_TABLE_RATE_FRAGMENT } from '../../fragments/seller/shippingTableRate.graphql';

export const DELETE_SELLER_SHIPPING_TABLE_RATE = gql`
  ${SELLER_SHIPPING_TABLE_RATE_FRAGMENT}
  mutation deleteSellerShippingTableRate($id: Int!, $tableId: Int!, $sellerId: Int!) {
    deleteSellerShippingTableRate(id: $id, tableId: $tableId, sellerId: $sellerId) {
      ...SellerShippingTableRateFragment
    }
  }
`;
