import { gql } from '@apollo/client';
import { ARTIST_BASE_FRAGMENT } from './base.graphql';

export const ARTIST_FRAGMENT = gql`
  ${ARTIST_BASE_FRAGMENT}
  fragment ArtistFragment on Artist {
    ...ArtistBaseFragment
    status
    active
    created
    userId
    aliasUpdated
    score
    gender
    website
    birthYear
    birthCity
    birthCountry
    livesCity
    livesCountry
    instagramUsername
    socialProfiles
    soldInfo
    press
    galleries
    statement
    biography
    biographyLocked
    artStatusMessage
    sellerId
    sellerName
    sellerStore
    sellerCurrency
    sellerShipsCountryCode
    mediums
    styles
    regions
    careerStages
    images {
      id
      status
      public
      mimeType
      key
      extension
      size
      width
      height
      type
      active
      parentId
      caption
    }
    locales {
      id
      localeCode
      biography
      statement
      artStatusMessage
    }
  }
`;
