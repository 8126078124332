import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import { CircularLoader, FormBuilder, FormTable, FormTableCell } from '@riseart/dashboard';

type Props = {
  mutation: any;
  variablesBuilder: (formData: Record<string, any>, errors?: any) => Record<string, any>;
  loading: boolean;
  fieldWrapperProps: Record<string, any>[];
  formModel: Record<string, any>;
  dataModel: any;
  data: Record<string, any>;
  initialValues?: Record<string, any> | undefined;
  customData?: Record<string, any> | null;
  colSpan?: number | null;
  onCancel: () => any;
  onSuccess: (response: any) => any;
  onError?: (errors: any, addApiErrors: any) => any;
};

/**
 * EditRow
 *
 * @param {Props} props
 * @returns
 */
export const EditRow = ({
  mutation,
  variablesBuilder,
  loading,
  fieldWrapperProps,
  formModel,
  data,
  dataModel: DataModel,
  initialValues,
  customData = null,
  colSpan,
  onCancel,
  onSuccess,
  onError,
}: Props): JSX.Element => {
  return (
    <TableCell
      sx={{ p: 0, '& .MuiTableCell-body': { borderBottom: 'none !important' } }}
      colSpan={colSpan || (fieldWrapperProps && fieldWrapperProps.length)}
    >
      <CircularLoader active={loading}>
        <FormBuilder
          inputLabelProps={{ shrink: true }}
          containerWrapper={FormTable}
          fieldWrapper={FormTableCell}
          {...({ initialValues } || null)}
          {...({ customData } || null)}
          fieldWrapperProps={fieldWrapperProps}
          settings={formModel.settings}
          fields={formModel.fields}
          initialValues={{ ...data }}
          footer={({ hasErrors, disableSubmit }) => (
            <Stack direction="row" justifyContent="right">
              <IconButton type="submit" size="large" disabled={hasErrors || disableSubmit}>
                <DoneIcon
                  color={hasErrors || disableSubmit ? 'disabled' : 'success'}
                  fontSize="large"
                />
              </IconButton>
              <IconButton size="large" onClick={onCancel}>
                <CloseIcon color="error" fontSize="large" />
              </IconButton>
            </Stack>
          )}
          onSubmit={async (formState, addApiErrors) => {
            // @ts-ignore
            mutation(
              variablesBuilder(
                DataModel.mapFormToApiData(formState.data, formModel.fields),
                addApiErrors,
              ),
            )
              .then(onSuccess)
              .catch(
                (errors: any) => typeof onError === 'function' && onError(errors, addApiErrors),
              );
          }}
        />
      </CircularLoader>
    </TableCell>
  );
};
