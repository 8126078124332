import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import { FormViewField, FormSelectNoneOption } from '@riseart/dashboard';
import { profile as PROFILE_ENUM } from '../../../config/enumeration.js';
import { countriesListOptions } from '../utils';
import { getDimension } from '../../../data/filters/utils';
import { MarkdownPreview } from '../../common/markdown/Preview';
import { DEFAULT_CHECKBOX_PROPS } from '../input/Checkbox';

/**
 * defaultCustomRender
 *
 * @param {Record<string, any>} field
 * @param {Record<string, any>} customData
 * @param {Record<string, any>} formState
 * @param {string | JSX.Element} label
 * @param {(options: Record<string, any>) => any} formatMessage
 * @returns
 */
const defaultCustomRender = (
  field: Record<string, any>,
  customData: Record<string, any>,
  formState: Record<string, any>,
  label: string | JSX.Element,
  formatMessage: (options: Record<string, any>) => any,
): JSX.Element | null => (
  <FormViewField
    key={field.name}
    label={label}
    helperText={<FormattedMessage id={`forms.artist.hints.${field.name}`} />}
  >
    {typeof field.renderer === 'function'
      ? field.renderer(formState.data[field.name], formatMessage)
      : formState.data[field.name]}
  </FormViewField>
);

/**
 * aliasRenderer
 *
 * @param {Record<string, any>} field
 * @param {Record<string, any>} customData
 * @param {Record<string, any>} formState
 * @param {string | JSX.Element} label
 * @param {(options: Record<string, any>) => any} formatMessage
 * @returns
 */
const aliasRenderer = (
  field: Record<string, any>,
  customData: Record<string, any>,
  formState: Record<string, any>,
  label: string | JSX.Element,
  formatMessage: (options: Record<string, any>) => any,
): JSX.Element | null =>
  customData.aliasUpdated === true
    ? defaultCustomRender(field, customData, formState, label, formatMessage)
    : null;

/**
 * biographyRenderer
 *
 * @param {Record<string, any>} field
 * @param {Record<string, any>} customData
 * @param {Record<string, any>} formState
 * @param {string | JSX.Element} label
 * @returns
 */
const biographyRenderer = (
  field: Record<string, any>,
  customData: Record<string, any>,
  formState: Record<string, any>,
  label: string | JSX.Element,
): JSX.Element | null => {
  return customData.biographyLocked === true ? (
    <FormViewField
      key={field.name}
      label={label}
      helperText={<FormattedMessage id="forms.artist.hints.biographyLocked" />}
    >
      <MarkdownPreview>{formState.data[field.name]}</MarkdownPreview>
    </FormViewField>
  ) : null;
};

/**
 * renderDimensionValue
 *
 * @param {string} domain
 * @param {string} dimension
 * @returns {string[]}
 */
const renderDimensionValue = (domain: string, dimension: string) => (selected: string[]) =>
  (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {selected.map((value: string) => (
        <Chip
          key={value}
          label={<FormattedMessage id={`filters.${domain}.${dimension}.${value}`} />}
        />
      ))}
    </Box>
  );

/**
 * filterSelectOptions
 *
 * @param {string}domain
 * @param {string}dimension
 * @returns {{
    formatMessage: (options: Record<string, any>) => any;
    initialOption: any;
  }}
 */
const filterSelectOptions =
  (domain: string, dimension: string) =>
  ({
    formState,
    fieldName,
    formatMessage,
    initialOption,
  }: {
    formState: Record<string, any>;
    fieldName: string;
    formatMessage: (options: Record<string, any>) => any;
    initialOption: any;
  }) => {
    const { data } = formState;
    const { name: dimensionName, values } = getDimension(domain, dimension);
    return values.map(
      ({ name }: { name: string }) => (
        <MenuItem key={name} value={name}>
          <Checkbox
            {...DEFAULT_CHECKBOX_PROPS}
            checked={data && data[fieldName] && data[fieldName].indexOf(name) > -1}
          />
          <ListItemText
            primary={formatMessage({
              id: `filters.${domain}.${dimensionName}.${name}`,
            })}
          />
        </MenuItem>
      ),
      initialOption ? [initialOption] : [],
    );
  };

/**
 * genderOptions
 *
 * @param  {Record<string, any>} options
 * @returns {JSX.Element[]}
 */
function genderOptions({ formatMessage, initialOption }: Record<string, any>): JSX.Element[] {
  return [
    ...(initialOption ? [initialOption] : []),
    ...PROFILE_ENUM.gender.map((code: string) => (
      <MenuItem key={code} value={code}>
        {formatMessage({ id: `common.gender.${code}` })}
      </MenuItem>
    )),
  ];
}

function birthYearOptions({ initialOption }: Record<string, any>): JSX.Element[] {
  const MAX_AGE = 100;
  const MIN_AGE = 15;
  const currentYear = new Date().getFullYear() - MIN_AGE;

  return [
    ...(initialOption ? [initialOption] : []),
    ...Array.apply(0, Array(MAX_AGE - MIN_AGE + 1)).map((data, idx) => {
      const year = currentYear - idx;
      return (
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      );
    }),
  ];
}

const ARTIST_FIELD = {
  NAME: {
    tag: 'input',
    type: 'text',
    name: 'name',
    placeholder: 'forms.artist.label.name',
  },
  ALIAS: {
    tag: 'input',
    type: 'text',
    name: 'alias',
    placeholder: 'forms.artist.label.alias',
    hint: 'forms.artist.hints.alias',
    validateTrigger: 'onBlur',
    rules: [
      {
        type: 'regex',
        pattern: '^[0-9a-z_-]{3,30}$',
        message: 'forms.artist.validation.alias',
      },
    ],
  },
  BIOGRAPHY: {
    tag: 'input',
    type: 'text',
    name: 'biography',
    validateTrigger: 'onBlur',
    multiline: true,
    minRows: 15,
    rules: [
      {
        required: true,
        message: 'forms.validation.required',
      },
      {
        type: 'stringLength',
        options: { max: 1500 },
        message: 'forms.validation.maxLength1500',
      },
    ],
    placeholder: 'forms.artist.label.biography',
    hint: 'forms.artist.hints.biography',
  },
  MEDIUMS: {
    tag: 'select',
    type: 'text',
    name: 'mediums',
    selectProps: {
      native: false,
      displayEmpty: true,
      multiple: true,
      renderValue: renderDimensionValue('artist', 'medium'),
    },
    selectOptions: filterSelectOptions('artist', 'medium'),
    validateTrigger: 'onBlur',
    rules: [
      {
        required: true,
        message: 'forms.validation.required',
      },
    ],
    placeholder: 'forms.artist.label.medium',
    hint: 'forms.artist.hints.medium',
  },
  STYLES: {
    tag: 'select',
    type: 'text',
    name: 'styles',
    selectProps: {
      native: false,
      displayEmpty: true,
      multiple: true,
      renderValue: renderDimensionValue('artist', 'style'),
    },
    selectOptions: filterSelectOptions('artist', 'style'),
    validateTrigger: 'onBlur',
    rules: [
      {
        required: true,
        message: 'forms.validation.required',
      },
    ],
    placeholder: 'forms.artist.label.style',
    hint: 'forms.artist.hints.style',
  },
  LIVES_CITY: {
    tag: 'input',
    type: 'text',
    name: 'livesCity',
    validateTrigger: 'onBlur',
    rules: [
      {
        required: true,
        message: 'forms.validation.required',
      },
      {
        type: 'stringLength',
        options: { max: 45 },
        message: 'forms.validation.maxLength45',
      },
    ],
    placeholder: 'forms.artist.label.city',
    hint: 'forms.artist.hints.city',
  },
  LIVES_COUNTRY: {
    tag: 'select',
    type: 'text',
    name: 'livesCountry',
    selectProps: { native: false, displayEmpty: true },
    selectOptions: countriesListOptions,
    validateTrigger: 'onBlur',
    rules: [
      {
        required: true,
        message: 'forms.validation.required',
      },
    ],
    placeholder: 'forms.artist.label.country',
    hint: 'forms.artist.hints.country',
  },
  BIRTH_YEAR: {
    tag: 'select',
    type: 'text',
    name: 'birthYear',
    initialOption: FormSelectNoneOption(),
    selectOptions: birthYearOptions,
    validateTrigger: 'onBlur',
    rules: [
      {
        required: true,
        message: 'forms.validation.required',
      },
    ],
    placeholder: 'forms.artist.label.birthYear',
    hint: 'forms.artist.hints.birthYear',
  },
  BIRTH_COUNTRY: {
    tag: 'select',
    type: 'text',
    name: 'birthCountry',
    selectProps: { native: false, displayEmpty: true },
    selectOptions: countriesListOptions,
    validateTrigger: 'onBlur',
    rules: [
      {
        required: true,
        message: 'forms.validation.required',
      },
    ],
    placeholder: 'forms.artist.label.birthCountry',
    hint: 'forms.artist.hints.birthCountry',
  },
  BIRTH_CITY: {
    tag: 'input',
    type: 'text',
    name: 'birthCity',
    validateTrigger: 'onBlur',
    rules: [
      {
        type: 'stringLength',
        options: { max: 45 },
        message: 'forms.validation.maxLength45',
      },
    ],
    placeholder: 'forms.artist.label.birthCity',
    hint: 'forms.artist.hints.birthCity',
  },
  GENDER: {
    tag: 'select',
    type: 'text',
    name: 'gender',
    initialOption: FormSelectNoneOption(),
    selectOptions: genderOptions,
    validateTrigger: 'onBlur',
    placeholder: 'forms.artist.label.gender',
    hint: 'forms.artist.hints.gender',
  },
  WEBSITE: {
    tag: 'input',
    type: 'text',
    name: 'website',
    hint: 'forms.artist.hints.website',
    placeholder: 'forms.artist.label.website',
  },
  INSTAGRAM_USER: {
    tag: 'input',
    type: 'text',
    name: 'instagramUsername',
    validateTrigger: 'onBlur',
    rules: [
      {
        type: 'stringLength',
        options: { max: 30 },
        message: 'forms.validation.maxLength30',
      },
    ],
    hint: 'forms.artist.hints.instagram',
    placeholder: 'forms.artist.label.instagram',
  },
  SOCIAL: {
    tag: 'input',
    type: 'text',
    multiline: true,
    minRows: 4,
    name: 'socialProfiles',
    validateTrigger: 'onBlur',
    rules: [
      {
        type: 'stringLength',
        options: { max: 1000 },
        message: 'forms.validation.maxLength1000',
      },
    ],
    hint: 'forms.artist.hints.socialProfiles',
    placeholder: 'forms.artist.label.socialProfiles',
  },
};

/**
 * AddArtistFormModel
 */
export const AddArtistFormModel = {
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      ...ARTIST_FIELD.NAME,
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
    },
    ARTIST_FIELD.ALIAS,
    ARTIST_FIELD.BIOGRAPHY,
    ARTIST_FIELD.MEDIUMS,
    ARTIST_FIELD.STYLES,
    ARTIST_FIELD.LIVES_CITY,
    ARTIST_FIELD.LIVES_COUNTRY,
    ARTIST_FIELD.BIRTH_YEAR,
    ARTIST_FIELD.BIRTH_COUNTRY,
    ARTIST_FIELD.BIRTH_CITY,
    ARTIST_FIELD.GENDER,
    ARTIST_FIELD.WEBSITE,
    ARTIST_FIELD.INSTAGRAM_USER,
    ARTIST_FIELD.SOCIAL,
  ],
};

/**
 * ArtistProfileFormModel
 */
export const ArtistProfileFormModel = {
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      ...ARTIST_FIELD.NAME,
      customRender: defaultCustomRender,
      isReadonly: true,
    },
    {
      ...ARTIST_FIELD.ALIAS,
      customRender: aliasRenderer,
      isReadonly: ({ aliasUpdated }: { aliasUpdated: boolean }): boolean => aliasUpdated === true,
    },
    {
      ...ARTIST_FIELD.BIOGRAPHY,
      customRender: biographyRenderer,
      isReadonly: ({ biographyLocked }: { biographyLocked: boolean }): boolean => !!biographyLocked,
    },
    ARTIST_FIELD.MEDIUMS,
    ARTIST_FIELD.STYLES,
    ARTIST_FIELD.LIVES_CITY,
    ARTIST_FIELD.LIVES_COUNTRY,
    ARTIST_FIELD.BIRTH_YEAR,
    ARTIST_FIELD.BIRTH_COUNTRY,
    ARTIST_FIELD.BIRTH_CITY,
    ARTIST_FIELD.GENDER,
    ARTIST_FIELD.WEBSITE,
    ARTIST_FIELD.INSTAGRAM_USER,
    ARTIST_FIELD.SOCIAL,
  ],
};

/**
 * PartnerArtistFilterFormModel
 */
export const PartnerArtistFilterFormModel = {
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      tag: 'input',
      type: 'text',
      name: 'name',
      placeholder: 'forms.common.name',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
    },
    {
      tag: 'input',
      type: 'number',
      name: 'birthYear',
      rules: [
        {
          type: 'stringLength',
          options: { max: 4 },
          message: 'forms.validation.maxLengthYear',
        },
        {
          type: 'custom',
          custom: (value: string | number): boolean =>
            !!(value && (value > new Date().getFullYear() || `${value}`.length < 4)),
          message: 'forms.validation.yearNotInFuture',
        },
      ],
      validateTrigger: 'onBlur',
      placeholder: 'forms.artist.label.birthYear',
    },
  ],
};
