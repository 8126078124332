import { Artist as ArtistModel } from '../../data/models/Artist';
import * as ARTIST_CONFIG from '../data/tables/filters/config/artist';
import { DataPickerGrid } from '../common/data/PickerGrid';

/**
 * ArtistPicker
 *
 * @param {Record<string, any>} Props
 * @returns {JSX.Element}
 */
export function ArtistPicker({
  query,
  queryVars = null,
  tableSchema,
  initialValue,
  onChange,
  multiple = false,
  onSave,
  children,
  formatMessage,
  chipColor,
  itemMapper = (item: Record<string, any>) => new ArtistModel().hydrateFromApiData(item),
}: Record<string, any>): JSX.Element {
  return (
    <DataPickerGrid
      initialValue={initialValue}
      multiple={multiple}
      selectLabel={formatMessage({ id: 'components.picker.selectArtist' })}
      chipColor={chipColor}
      chipValueField="name"
      onChange={onChange}
      onSave={onSave}
      clearOnSave={multiple && typeof children === 'function'}
      gridProps={{
        itemMapper,
        query,
        queryVars,
        filterConfig: ARTIST_CONFIG.PARTNER_ARTIST_FILTER_CONFIG,
        sortConfig: ARTIST_CONFIG.PARTNER_ARTIST_SORT_CONFIG,
        schema: tableSchema({ formatMessage }),
      }}
    >
      {children}
    </DataPickerGrid>
  );
}
