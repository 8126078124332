// @ts-ignore
import { find } from '@riseart/fe-utils';
import { units as ENUM_UNITS, product as ENUM_PRODUCT } from '../../config/enumeration.js';
import { formatCurrency } from '../../services/riseart/utils/Utils';
/**
 * formatDimensions
 *
 * @param {Array<number>} dimensions
 * @param {string} units
 * @param {{ type?: 'long' | 'short', precision?: number, separator?: string }} options
 * @returns {string}
 */
export function formatDimensions(
  dimensions: Array<number>,
  units: string,
  options: {
    type?: 'long' | 'short';
    precision?: number;
    separator?: string;
  } = {},
): string {
  const { type, precision, separator } = { type: 'long', precision: 1, separator: 'x', ...options };
  // @ts-ignore
  const unit = units ? ENUM_UNITS[units.toLowerCase()] : '';
  const dimensionsLabel = dimensions
    .reduce<Array<string>>((accumulator, dimension) => {
      if (dimension) {
        accumulator.push(`${dimension.toFixed(precision)}${type === 'long' ? unit : ''}`);
      }

      return accumulator;
    }, [])
    .join(type === 'long' ? ` ${separator} ` : separator);

  return type === 'long' ? dimensionsLabel : dimensionsLabel + unit;
}

function getProductData(rawData: Record<string, any>, store: string) {
  const product = {
    ...(rawData.productPrimarySku ||
      (rawData.productSkus &&
        find(
          rawData.productSkus,
          ({ type }: { type: string }) => type === ENUM_PRODUCT.sku.type.ART,
        ))),
  };

  if (product) {
    product.store = find(
      product.stores,
      ({ storeCode }: { storeCode: string }) => storeCode === store,
    );
  }

  return product;
}

export function mapFormToArtData(
  formData: Record<string, any>,
  fieldsSchema: Record<string, any>[],
  mode?: 'BASIC' | 'ADVANCED',
  includeNullValues = true,
): Record<string, any> {
  const schema = fieldsSchema.reduce((accumulator, item) => {
    // readOnly fields are excluded from the sent input data
    if (mode && typeof item.isReadonly === 'function' && item.isReadonly(mode)) {
      return accumulator;
    } else {
      accumulator[item.name] = item;
    }
    return accumulator;
  }, {});

  return Object.keys(formData).reduce((accumulator: Record<string, any>, fieldName: string) => {
    if (schema[fieldName] && schema[fieldName].valueType === 'json') {
      // Parse JSON formatted data (e.g. art filter dimensions)
      const parsedValue = JSON.parse(formData[fieldName]);
      // reset the value, as it may update different fields in API
      accumulator = { ...accumulator, ...(schema[fieldName].resetValue || null), ...parsedValue };
    } else if (
      // Any empty string values will be send as null
      formData[fieldName] === '' ||
      (formData[fieldName] && formData[fieldName].trim && formData[fieldName].trim() === '')
    ) {
      if (includeNullValues) {
        accumulator[fieldName] = null;
      }
    } else if (
      schema[fieldName] !== undefined &&
      (schema[fieldName] !== null || (includeNullValues && schema[fieldName] === null))
    ) {
      // Other found in form field schema fields will be collected
      accumulator[fieldName] = formData[fieldName];
    }

    return accumulator;
  }, {});
}

/**
 * mapToFormData
 *
 * @param {Object} rawData
 * @param {string} storeCode
 * @returns {Record<string, any>}
 */
export function mapToFormData(
  rawData: Record<string, any> = {},
  storeCode: string,
): Record<string, any> {
  const {
    title,
    description,
    tags,
    year,
    medium,
    subMedium,
    subject,
    subSubject,
    style,
    materials,
    height,
    width,
    weight,
    depth,
    productIsRental,
  } = rawData;
  const product = getProductData(rawData, storeCode);

  return {
    title,
    description,
    year: product.year || year,
    medium:
      (medium && subMedium && JSON.stringify({ medium, subMedium })) ||
      (medium && JSON.stringify({ medium })) ||
      null,
    subMedium,
    subject:
      (subject && subSubject && JSON.stringify({ subject, subSubject })) ||
      (subject && JSON.stringify({ subject })) ||
      null,
    subSubject,
    style: style ? JSON.stringify({ style }) : null,
    materials,
    editionSize: product.editionSize,
    tags: tags && tags.join(', '),
    height: product.height || height,
    width: product.width || width,
    depth: product.depth || depth,
    price: product.store.price,
    sellerShippingTable: product.sellerShippingTableId,
    stock: product.stock,
    weight: product.weight || weight,
    signed: product.signed,
    signedLocation: product.signedLocation,
    hangable: product.readyToHang,
    framed: product.framed,
    frameDescription: product.frameDescription,
    rent: productIsRental,
    packageMethod: product.packageMethod,
    substrate: product.substrate,
    unstretched: product.unstretched,
  };
}

/**
 * normalizeRawData
 *
 * @param {Object} rawData
 * @param {string} storeCode
 * @returns {Record<string, any>}
 */
export function normalizeRawData(
  rawData: Record<string, any> = {},
  storeCode: string,
): Record<string, any> {
  const {
    id,
    title,
    artist: { id: artistId, alias: artistAlias, name },
    isProduct,
    productId,
    productPrimarySku,
    productType,
    productCanBuy,
    productImages,
    images,
    units: rootUnits,
    width: rootWidth,
    height: rootHeight,
    depth: rootDepth,
    medium,
    slug,
    status,
    subMedium,
    subject,
    subSubject,
    style,
    materials,
    year,
    ...rest
  } = rawData;

  const productData = productPrimarySku || {};
  const mergedData = {
    units: productData.units || rootUnits || '',
    height: productData.height || rootHeight,
    width: productData.width || rootWidth,
    depth: productData.depth || rootDepth,
  };
  const productStoreData =
    find(productData.stores, (store: Record<string, any>) => store.storeCode === storeCode) || {};
  // @ts-ignore
  const unit = ENUM_UNITS[mergedData.units.toLowerCase()];
  const artworkDimensionsLabel = formatDimensions(
    [mergedData.height, mergedData.width, mergedData.depth],
    mergedData.units,
  );

  return {
    ...mergedData,
    id,
    slug,
    title,
    images,
    isProduct,
    productImages,
    productId,
    artistId,
    artistAlias,
    artistName: name,
    unit,
    artworkDimensionsLabel,
    status,
    medium,
    subMedium,
    subject,
    subSubject,
    style,
    materials,
    year: productData.year || year,
    mediumWithSubMedium: [
      { key: 'medium', value: medium },
      { key: 'subMedium', value: subMedium },
    ],
    subjectWithSubSubject: [
      { key: 'subject', value: subject },
      { key: 'subSubject', value: subSubject },
    ],
    styleStructured: style && [{ key: 'style', value: style }],
    editionSize: productData.editionSize,
    framed: productData.framed && { description: productData.frameDescription },
    signed: productData.signed && { description: productData.signedLocation },
    type: productType,
    readyToHang: !!productData.readyToHang,
    shipsFromCode: productData.shipsFromCode,
    shipsFramedBy: productData.shipsFramedBy,
    shipsBy: productData.shipsBy,
    productPrimarySku: productData,
    productStore: productStoreData,
    productCanBuy,
    stock: productData.stock,
    price: productStoreData && productStoreData.price,
    formattedPrice:
      productStoreData && productStoreData.price
        ? formatCurrency(productStoreData.price, storeCode, undefined, {
            hasSpaceAfterCurrency: false,
          })
        : null,
    ...rest,
  };
}
