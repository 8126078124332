import { gql } from '@apollo/client';
import { ACCEPTED_LEGAL_FRAGMENT } from '../../fragments/legal/accepted.graphql';

export const ACCEPT_SELLER_LEGAL = gql`
  ${ACCEPTED_LEGAL_FRAGMENT}
  mutation acceptSellerLegal($sellerId: Int!, $legalId: Int!, $userId: Int!) {
    acceptSellerLegal(sellerId: $sellerId, legalId: $legalId, userId: $userId) {
      ...AcceptedLegalFragment
    }
  }
`;
