import { useState, useEffect, SyntheticEvent } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import TextField from '@mui/material/TextField';
import { FormBuilder, FormWrapper, FormHeader } from '@riseart/dashboard';
import { errors as ERRORS_ENUM } from '../../../config/enumeration.js';
import { useNotification } from '../../../data/hooks/useNotification';
import { mapApiValidationErrors, mapApiErrors } from '../../../services/riseart/utils/Form';
import { mapToFormData } from '../../../data/normalizers/payment';
import { selectSeller } from '../../../services/redux/selectors/seller';
import {
  SellerPaymentsFormModel,
  SellerPaymentsFormModelByType,
  paymentTypeOptions,
  getPaymentAttributes,
} from '../../forms/models/payments';

import { LIST_SELLER_PAYMENT_METHODS } from '../../../data/gql/queries/seller/listPaymentMethods.graphql';
import { CREATE_SELLER_PAYMENT_METHOD } from '../../../data/gql/queries/seller/createPaymentMethod.graphql';
import { UPDATE_SELLER_PAYMENT_METHOD } from '../../../data/gql/queries/seller/updatePaymentMethod.graphql';

type Props = {
  align?: 'left' | 'center' | 'right';
  title?: string | JSX.Element | null;
  description?: string | JSX.Element | null;
  submitText: string | JSX.Element;
  scrollToRef?: () => any;
  onLoading: (loading: boolean) => any;
  onUpdate?: (data: Record<string, any>) => any;
};

/**
 * SellerPaymentForm
 *
 * @returns {JSX.Element}
 */
export const SellerPaymentForm = ({
  align,
  title,
  description = null,
  submitText,
  scrollToRef,
  onLoading,
  onUpdate,
}: Props): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const { dispatchNotification } = useNotification();
  const meSeller = useSelector(selectSeller) || {};
  const [paymentType, setPaymentType] = useState<string>('');

  // Queries and Mutations
  const {
    data: listMethodsData,
    loading: listLoading,
    refetch: refetchListMethods,
  } = useQuery(LIST_SELLER_PAYMENT_METHODS, {
    fetchPolicy: 'network-only',
    variables: { sellerId: meSeller.id },
  });
  const listMethods =
    (listMethodsData &&
      listMethodsData.listSellerPaymentMethods &&
      listMethodsData.listSellerPaymentMethods.items) ||
    [];
  const [createPayment, { loading: createLoading }] = useMutation(CREATE_SELLER_PAYMENT_METHOD);
  const [updatePayment, { loading: updateLoading }] = useMutation(UPDATE_SELLER_PAYMENT_METHOD);
  const FORM_MODEL = SellerPaymentsFormModelByType[paymentType] || SellerPaymentsFormModel;
  const paymentConfig = paymentType ? getPaymentAttributes(paymentType) : null;
  const currentPaymentMethod = listMethods.length > 0 ? listMethods[0] : null;
  const formFieldsModel =
    paymentType && paymentConfig
      ? FORM_MODEL.fields.filter((field) => paymentConfig.attributes.indexOf(field.name) > -1)
      : [];

  useEffect(() => {
    if (currentPaymentMethod && currentPaymentMethod.typeCode) {
      setPaymentType(currentPaymentMethod.typeCode);
    }
  }, [currentPaymentMethod]);

  useEffect(() => {
    onLoading(listLoading || createLoading || updateLoading);
  }, [listLoading, createLoading, updateLoading]);

  return listMethods ? (
    <>
      {title ? (
        <FormHeader title={title} justifyContent={align === 'center' ? 'center' : undefined} />
      ) : null}
      {description}
      <FormWrapper size="medium" align={align}>
        <TextField
          type="select"
          label={formatMessage({ id: 'forms.sellerPayment.label.paymentType' })}
          variant="standard"
          margin="normal"
          select
          SelectProps={{ native: false }}
          value={paymentType}
          fullWidth
          helperText={formatMessage({ id: 'forms.sellerPayment.hints.paymentType' })}
          required
          onChange={(e: SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            // Reset payment type to reset form
            setPaymentType('');
            setTimeout(() => setPaymentType(target.value), 0);
          }}
        >
          {paymentTypeOptions()}
        </TextField>
        {paymentType && paymentConfig ? (
          <FormBuilder
            settings={FORM_MODEL.settings}
            fields={formFieldsModel}
            showReset
            submitText={submitText}
            customData={{}}
            initialValues={
              // Set initial values only if current payment is same type as selected payment
              currentPaymentMethod && currentPaymentMethod.typeCode === paymentType
                ? mapToFormData(currentPaymentMethod, formFieldsModel)
                : {}
            }
            onSubmit={async (formState, addApiErrors) => {
              const paymentExists = !!(
                currentPaymentMethod && currentPaymentMethod.typeCode === paymentType
              );
              const mutation = paymentExists ? updatePayment : createPayment;

              mutation({
                variables: {
                  ...(paymentExists ? { id: currentPaymentMethod.id } : null),
                  sellerId: meSeller.id,
                  inputPaymentMethod: {
                    ...(paymentExists ? null : { typeCode: paymentType }),
                    attributes: Object.keys(formState.data).map((name) => ({
                      name,
                      value: formState.data[name],
                    })),
                  },
                },
              })
                .then((updateResponse) => {
                  refetchListMethods().catch(() => null);

                  if (typeof onUpdate === 'function') {
                    onUpdate(
                      paymentExists
                        ? updateResponse.data.updatePayment
                        : updateResponse.data.createPayment,
                    );
                  }

                  // Scroll to container
                  if (typeof scrollToRef === 'function') {
                    scrollToRef();
                  }

                  // Show artowrk update success notification
                  dispatchNotification({
                    detail: 'components.notifications.sellerPaymentUpdated',
                    level: ERRORS_ENUM.levels.SUCCESS,
                    placeholder: ERRORS_ENUM.placeholders.SNACKBAR,
                    expire: 5,
                  });
                })
                .catch((error) => {
                  // Map validation errors from response
                  const validationError = mapApiValidationErrors(error);

                  if (validationError && validationError.length && addApiErrors) {
                    addApiErrors(validationError);
                  } else {
                    // Map any other type of errors from response (validation without provided fields, or non-validation errors)
                    mapApiErrors(error).forEach((err) => {
                      dispatchNotification({
                        detail: err,
                        level: ERRORS_ENUM.levels.ERROR,
                        placeholder: ERRORS_ENUM.placeholders.PAGE,
                      });
                    });
                  }

                  // Scroll to error
                  if (typeof scrollToRef === 'function') {
                    scrollToRef();
                  }
                });
            }}
          />
        ) : (
          <></>
        )}
      </FormWrapper>
    </>
  ) : null;
};
