import { gql } from '@apollo/client';
import { PARTNER_ADMIN_FRAGMENT } from '../../fragments/partner/admin.graphql';
import { PAGINATION_FRAGMENT } from '../../fragments/common/pagination.graphql';

export const LIST_PARTNER_ADMINS = gql`
  ${PARTNER_ADMIN_FRAGMENT}
  ${PAGINATION_FRAGMENT}
  query listPartnerAdmins(
    $partnerId: Int!
    $page: Int = 1
    $items: Int = 10
    $sort: String = "userId"
    $order: String = "ASC"
    $filters: InputPartnerAdminListFilters
  ) {
    listPartnerAdmins(
      partnerId: $partnerId
      page: $page
      items: $items
      sort: $sort
      order: $order
      filters: $filters
    ) {
      items {
        ...PartnerAdminFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
`;
