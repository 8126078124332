import { gql } from '@apollo/client';

export const PARTNER_ARTIST_SUMMARY = gql`
  query partnerArtistSummary(
    $partnerId: Int!
    $filtersAll: InputPartnerArtistListFilters
    $filtersActive: InputPartnerArtistListFilters
    $filtersPending: InputPartnerArtistListFilters
    $page: Int = 1
    $items: Int = 1
  ) {
    total: listPartnerArtists(
      partnerId: $partnerId
      page: $page
      items: $items
      filters: $filtersAll
    ) {
      pagination {
        totalItems
      }
    }
    active: listPartnerArtists(
      filters: $filtersActive
      partnerId: $partnerId
      page: $page
      items: $items
    ) {
      pagination {
        totalItems
      }
    }
    pending: listPartnerArtists(
      filters: $filtersPending
      partnerId: $partnerId
      page: $page
      items: $items
    ) {
      pagination {
        totalItems
      }
    }
  }
`;
