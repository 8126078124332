import { gql } from '@apollo/client';
import { ACCEPTED_LEGAL_FRAGMENT } from '../../fragments/legal/accepted.graphql';

export const LIST_SELLER_LEGALS = gql`
  ${ACCEPTED_LEGAL_FRAGMENT}
  query listSellerLegals($sellerId: Int!, $items: Int = 10, $filters: InputSellerLegalFilter) {
    listSellerLegals(sellerId: $sellerId, items: $items, filters: $filters) {
      items {
        ...AcceptedLegalFragment
      }
    }
  }
`;
