import { SplitSelectButton } from '@riseart/dashboard';
import type { SplitSelectButtonProps } from '@riseart/dashboard';
import { splitButtonConfirmationProps } from './settings';

/**
 * StatusSelectButton
 *
 * @param {SplitSelectButtonProps} props
 * @returns {JSX.Element}
 */
export const StatusSelectButton = ({
  confirmationSettings = splitButtonConfirmationProps,
  ...props
}: SplitSelectButtonProps): JSX.Element => (
  <SplitSelectButton variant="outlined" confirmationSettings={confirmationSettings} {...props} />
);
