import get from 'lodash/get';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applicationStoreUpdateLocales } from '../../services/redux/actions/application/store';
import { guiUpdateSettings } from '../../services/redux/actions/application/gui';
import { application as APP_CONFIG, cookies as COOKIES_CONFIG } from '../../config/config.js';
import { Cookies } from '../../services/riseart/utils/Cookies/Cookies';
import { selectVisitor } from '../../services/redux/selectors/visitor';
import { selectLocale as selectGuiLocale } from '../../services/redux/selectors/gui';
import {
  getLocale,
  getLocaleConfig,
  extractLocaleFromUrl,
  findBestLocale,
} from '../../services/riseart/utils/Route';

type Props = {
  isSSR: boolean;
  urlLanguage: string;
  location: Record<string, any>;
  children: (data: {
    userLocale: Record<string, any> | null;
    routeLocale: Record<string, any>;
    guiLocale: Record<string, any>;
  }) => JSX.Element;
};

/**
 * RouterLocale
 *
 * Determines the locale based on url and visitor locales (or falls back to default)
 * and dispatches an event to store the locale in redux store
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const RouterLocale = ({ urlLanguage, location, children }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const visitor = useSelector(selectVisitor);
  const guiLocale = useSelector(selectGuiLocale);
  const { locale: queryStringLocale } = queryString.parse(location.search);
  const routeLocale =
    getLocaleConfig(urlLanguage, 'basePath') ||
    extractLocaleFromUrl(location && location.pathname) ||
    getLocaleConfig(true, 'isDefault');
  const userLocale = getLocale(urlLanguage, visitor && visitor.locale, APP_CONFIG.i18n.locales);

  // Gui locale
  const guiCookie = Cookies.get(COOKIES_CONFIG.gui.name) || null;
  const { locale: cookieLocale } = guiCookie ? JSON.parse(guiCookie) : { locale: null };
  const newGuiLocale =
    (queryStringLocale &&
      typeof queryStringLocale === 'string' &&
      get(getLocaleConfig(queryStringLocale), 'name')) ||
    cookieLocale ||
    (Array.isArray(window.navigator.languages) &&
      get(findBestLocale(window.navigator.languages), 'name')) ||
    getLocaleConfig(true, 'isDefault').name;

  // useEffect is only executed on client
  useEffect(() => {
    if (guiLocale !== newGuiLocale) {
      dispatch(guiUpdateSettings({ locale: newGuiLocale }));
    }
  }, [guiLocale, newGuiLocale]);

  useEffect(() => {
    // Send an action to the store to update the userLocale (extracted from url language or visitor.locale)
    // and routeLocale (extracted from url only, or falls back to default locale)
    dispatch(applicationStoreUpdateLocales({ userLocale, routeLocale }));
  }, [userLocale, routeLocale]);

  return guiLocale ? (
    children({ userLocale, routeLocale, guiLocale: getLocaleConfig(guiLocale) })
  ) : (
    <></>
  );
};
