import get from 'lodash/get';
import Box from '@mui/material/Box';
import {
  DataGrid as MuiDataGrid,
  GridSortModel,
  GridFilterModel,
  GridColumns,
} from '@mui/x-data-grid';
import { CircularLoader, Title } from '@riseart/dashboard';

type Props = {
  columns: GridColumns<any>;
  rows: Record<string, any>[];
  rowsPerPageOptions?: number[];
  pagination?: Record<string, any>;
  loading: boolean;
  title?: string | JSX.Element;
  layoutPaperPadding?: Record<string, any> | null;
  mainAction?: JSX.Element | null;
  paramsState?: Record<string, any> | null;
  hideFooter?: boolean;
  getRowId?: (params: Record<string, any>) => string | number;
  onChange?: (params: Record<string, any>) => any;
};

/**
 * DataGrid
 *
 * @param {props} Props
 * @returns {JSX.Element}
 */
export function DataGrid({
  columns,
  rows,
  pagination,
  rowsPerPageOptions = [10, 25],
  loading,
  title,
  paramsState = null,
  mainAction = null,
  layoutPaperPadding = null,
  hideFooter = false,
  getRowId,
  onChange,
}: Props): JSX.Element {
  const CUSTOM_SELECTED_ROW = 'customDataGridPicker-selected';

  return (
    <CircularLoader active={rows && loading}>
      {title ? (
        <Box
          sx={{
            ...layoutPaperPadding,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {title ? <Title variant="h5">{title}</Title> : null}
          {mainAction}
        </Box>
      ) : null}
      {!rows && loading ? <CircularLoader active={loading} /> : null}
      {rows ? (
        <MuiDataGrid
          sx={{
            border: 'none',
            [`& .${CUSTOM_SELECTED_ROW}`]: { backgroundColor: 'grey.100' },
          }}
          getRowClassName={(params) => {
            const rowItemId = get(params, 'row.id');
            const selectedItems = get(params, 'row.customData.picker.selectedItems');
            const isSelected =
              selectedItems &&
              rowItemId &&
              selectedItems.some((item: Record<string, any>) => item.id === rowItemId);
            return isSelected ? CUSTOM_SELECTED_ROW : '';
          }}
          columns={columns}
          rows={rows}
          autoHeight
          disableSelectionOnClick
          hideFooter={hideFooter}
          editMode="row"
          filterMode="server"
          sortingMode="server"
          initialState={{
            sorting: {
              // @ts-ignore
              sortModel: paramsState && paramsState.sort ? [paramsState.sort] : null,
            },
            filter: {
              filterModel:
                paramsState && paramsState.filters
                  ? {
                      items: [paramsState.filters],
                    }
                  : undefined,
            },
          }}
          onSortModelChange={(newSortModel: GridSortModel) => {
            if (typeof onChange === 'function') {
              onChange({ ...paramsState, ...{ sort: newSortModel[0] || null } });
            }
          }}
          onFilterModelChange={(newFilterModel: GridFilterModel) => {
            if (typeof onChange === 'function') {
              const [filterModelData] = (newFilterModel && newFilterModel.items) || [];
              onChange({ ...paramsState, filters: filterModelData });
            }
          }}
          getRowId={getRowId}
          {...(pagination
            ? {
                paginationMode: 'server',
                pageSize: pagination.itemsPerPage,
                rowsPerPageOptions: rowsPerPageOptions,
                rowCount: pagination.totalItems,
                page: pagination.currentPage - 1,
                onPageSizeChange: (newPage) => {
                  pagination.handleChangeRowsPerPage({ target: { value: newPage } });
                },
                onPageChange: (newPage) => {
                  pagination.handleChangePage(null, newPage);
                },
              }
            : null)}
        />
      ) : null}
    </CircularLoader>
  );
}
