import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import InputAdornment from '@mui/material/InputAdornment';
import { FormTableCell, FormViewField } from '@riseart/dashboard';
import { currency as CURRENCY_ENUM } from '../../../config/enumeration.js';
import { countries as COUNTRIES_LIST, regions as REGIONS_LIST } from '../../../config/directory.js';

/**
 * pricePreffix
 *
 * @param {{ storeCode: string }} { storeCode }
 * @returns {JSX.Element}
 */
const pricePreffix = ({ storeCode }: { storeCode: string }): JSX.Element => (
  // @ts-ignore
  <InputAdornment position="start">{CURRENCY_ENUM.signs[storeCode]}</InputAdornment>
);

/**
 * defaultBasicModeCustomRender
 *
 * @param {Record<string, any>} field
 * @param {Record<string, any>} customData
 * @param {Record<string, any>} formState
 * @param {string | JSX.Element} label
 * @param {(options: Record<string, any>) => any} formatMessage
 * @returns {JSX.Element | null}
 */
const defaultBasicModeCustomRender = (
  field: Record<string, any>,
  customData: Record<string, any>,
  formState: Record<string, any>,
  label: string | JSX.Element,
  formatMessage: (options: Record<string, any>) => any,
): JSX.Element | null => {
  const value = JSON.parse(formState.data[field.name]);
  const translationKey = Object.keys(value)[0];
  return (
    <FormViewField
      key={field.name}
      label={label}
      component={(props: any) => (
        <FormTableCell
          {...props}
          {...(customData && customData.generatedFieldWrapperProps
            ? { ...customData.generatedFieldWrapperProps }
            : null)}
        />
      )}
    >
      {formatMessage({
        id:
          translationKey === 'regionCode'
            ? `regions.${value[translationKey]}`
            : `countries.${value[translationKey]}`,
      })}
    </FormViewField>
  );
};

/**
 * destinationOptions
 *
 * @param {{ customData: any; formatMessage: (options: Record<string, any>) => any }} options
 * @returns {Record<string, any>[]}
 */
function destinationOptions({
  customData: { ratesItems },
  formatMessage,
}: {
  customData: any;
  formatMessage: (options: Record<string, any>) => any;
}): Record<string, any>[] {
  // Countries List
  const countriesList = COUNTRIES_LIST.shipping.reduce(
    (accumulator: JSX.Element[], code: string) => {
      if (!ratesItems.some(({ countryCode }: { countryCode: string }) => countryCode === code)) {
        return [
          ...accumulator,
          <MenuItem key={code} value={JSON.stringify({ countryCode: code })}>
            {formatMessage({ id: `countries.${code}` })}
          </MenuItem>,
        ];
      }

      return accumulator;
    },
    [],
  );

  // Regions List
  const regionsList = REGIONS_LIST.shipping.reduce((accumulator: JSX.Element[], code: string) => {
    if (!ratesItems.some(({ regionCode }: { regionCode: string }) => regionCode === code)) {
      return [
        ...accumulator,
        <MenuItem key={code} value={JSON.stringify({ regionCode: code })}>
          {formatMessage({ id: `regions.${code}` })}
        </MenuItem>,
      ];
    }

    return accumulator;
  }, []);

  return [
    ...(countriesList.length
      ? [
          <ListSubheader key="countries">
            {formatMessage({ id: 'common.countries' })}
          </ListSubheader>,
          ...countriesList,
        ]
      : []),
    ...(regionsList.length
      ? [
          <ListSubheader key="regions">{formatMessage({ id: 'common.regions' })}</ListSubheader>,
          ...regionsList,
        ]
      : []),
  ];
}

/**
 * AddSellerRateFormModel
 */
export const AddSellerRateFormModel = {
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      tag: 'select',
      type: 'json',
      name: 'destination',
      margin: 'none',
      selectProps: { native: false, displayEmpty: true },
      selectOptions: destinationOptions,
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      placeholder: 'forms.sellerRate.label.destination',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'baseCost',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      placeholder: 'forms.sellerRate.label.baseCost',
      hint: 'forms.sellerRate.hints.baseCost',
      preffix: pricePreffix,
    },
    {
      tag: 'input',
      type: 'text',
      name: 'pctCost',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'range',
          options: { min: 0, max: 100 },
          message: 'forms.validation.percentage',
        },
      ],
      placeholder: 'forms.sellerRate.label.pctCost',
      hint: 'forms.sellerRate.hints.pctCost',
    },
  ],
};

/**
 * EditSellerRateFormModel
 */
export const EditSellerRateFormModel = {
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      tag: 'input',
      type: 'json',
      name: 'destination',
      margin: 'none',
      validateTrigger: 'onBlur',
      placeholder: 'forms.sellerRate.label.destination',
      customRender: defaultBasicModeCustomRender,
      isReadonly: true,
    },
    {
      tag: 'input',
      type: 'text',
      name: 'baseCost',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      placeholder: 'forms.sellerRate.label.baseCost',
      hint: 'forms.sellerRate.hints.baseCost',
      preffix: pricePreffix,
    },
    {
      tag: 'input',
      type: 'text',
      name: 'pctCost',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'range',
          options: { min: 0, max: 100 },
          message: 'forms.validation.percentage',
        },
      ],
      placeholder: 'forms.sellerRate.label.pctCost',
      hint: 'forms.sellerRate.hints.pctCost',
    },
  ],
};
