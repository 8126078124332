import { gql } from '@apollo/client';

export const PAGINATION_FRAGMENT = gql`
  fragment PaginationFragment on PaginationInfo {
    items
    type
    currentPage
    itemsPerPage
    totalItems
    totalPages
  }
`;
