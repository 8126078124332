import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Title } from '@riseart/dashboard';
import { MediaForm } from './Form';

type Props = {
  formModel: Record<string, any>;
  typeList: Record<string, any>;
  initialType: string;
  onAdd: (formData: Record<string, any>) => void;
};

/**
 * AddMedia
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export function AddMedia({ onAdd, formModel, typeList, initialType }: Props): JSX.Element {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box component="section">
      <Dialog open={open} fullWidth>
        <DialogTitle component="div">
          <Title variant="h5">
            <FormattedMessage id="common.add" />
          </Title>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <MediaForm
            formModel={formModel}
            mediaTypeList={typeList}
            initialMediaType={initialType}
            onSubmit={(formData: Record<string, any>, mediaType: string) => {
              if (formData && !formData.hasErrors) {
                onAdd({ ...formData.data, mediaType });
              }

              handleClose();
            }}
            onCancel={handleClose}
            submitText={<FormattedMessage id="components.media.add" />}
          />
        </DialogContent>
      </Dialog>
      <Box display="flex" justifyContent="space-between">
        <Title variant="h5" component="h5" sx={{ fontSize: '18px', marginBottom: '5px' }}>
          <FormattedMessage id="components.media.title" />
        </Title>
        <Button type="button" variant="contained" size="small" onClick={handleOpen}>
          <FormattedMessage id="common.add" />
        </Button>
      </Box>
    </Box>
  );
}
