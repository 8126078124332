import { gql } from '@apollo/client';
import { PARTNER_ARTIST_FRAGMENT } from '../../fragments/partner/artist.graphql';

export const CREATE_PARTNER_ARTIST = gql`
  ${PARTNER_ARTIST_FRAGMENT}
  mutation createPartnerArtist($partnerId: Int!, $artist: InputArtist!, $rank: Int) {
    createPartnerArtist(partnerId: $partnerId, artist: $artist, rank: $rank) {
      ...PartnerArtistFragment
    }
  }
`;
