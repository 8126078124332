import { gql } from '@apollo/client';

export const SELLER_SHIPPING_TABLE_RATE_FRAGMENT = gql`
  fragment SellerShippingTableRateFragment on SellerShippingTableRate {
    id
    tableId
    baseCost
    pctCost
    inherited
    countryCode
    regionCode
  }
`;
