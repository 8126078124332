import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import PaletteIcon from '@mui/icons-material/Palette';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { LayoutPaper, Title, Logo, Paragraph } from '@riseart/dashboard';
import { seller as SELLER_ENUM, errors as ERRORS_ENUM } from '../../config/enumeration.js';
import { useNotification } from '../../data/hooks/useNotification';
import { useScrollToEl } from '../../data/hooks/useScrollToEl';
import { selectArtist } from '../../services/redux/selectors/artist';
import { mapApiErrors } from '../../services/riseart/utils/Form';
import { selectSeller } from '../../services/redux/selectors/seller';
import { UrlAssembler } from '../../services/riseart/utils/UrlAssembler';
import { DefaultLayout } from '../layout/Default';
import { SellerProfileForm } from './seller/ProfileForm';
import { ArtistProfileForm } from './artist/ProfileForm';
import { SellerPaymentForm } from './seller/PaymentForm';
import { mePatch } from '../../services/redux/actions/me/me';
import { asyncDelay } from '../../services/riseart/utils/Utils';
import { UPDATE_SELLER } from '../../data/gql/queries/seller/update.graphql';
import { READ_ME } from '../../data/gql/queries/me/read.graphql';

/**
 * OnboardingPage
 *
 * @returns {JSX.Element}
 */
export const OnboardingPage = (): JSX.Element => {
  const stepDescriptionProps = { marginY: 4, marginX: 'auto', maxWidth: '600px' };
  const dispatch = useDispatch();
  const { setScrollRef, scrollToRef } = useScrollToEl();
  const { dispatchNotification } = useNotification();
  const artist = useSelector(selectArtist) || {};
  const [loading, setLoading] = useState(false);
  const { onboardingStage, id: sellerId, type: sellerType } = useSelector(selectSeller) || {};
  const isArtistSeller = sellerType === SELLER_ENUM.type.ARTIST;
  const [updateSeller, { loading: updateLoading, client }] = useMutation(UPDATE_SELLER);
  const handleNextStep = (nextStep: number) => () => {
    setLoading(true);
    updateSeller({
      variables: {
        id: sellerId,
        inputSeller: {
          onboardingStage: nextStep,
        },
      },
    })
      .then(() => {
        setLoading(true);

        // Fetch and update me after successfully updated seller
        asyncDelay().then(() => {
          client
            .query({
              query: READ_ME,
              fetchPolicy: 'network-only',
            })
            .then((response: any) => {
              setLoading(false);

              if (response && response.data && response.data.readMe) {
                dispatch(mePatch(response.data.readMe));
              }
            })
            .catch(() => {
              setLoading(false);
            });
        });
      })
      .catch((error) => {
        setLoading(false);
        mapApiErrors(error).forEach((err) => {
          dispatchNotification({
            detail: err,
            level: ERRORS_ENUM.levels.ERROR,
            placeholder: ERRORS_ENUM.placeholders.PAGE,
          });
        });
      });
  };

  const steps = [
    { id: SELLER_ENUM.onboardingStage.PROFILE, icon: SupervisorAccountIcon },
    { id: SELLER_ENUM.onboardingStage.PAYMENT, icon: MonetizationOnIcon },
  ];

  if (isArtistSeller) {
    steps.push({ id: SELLER_ENUM.onboardingStage.ARTIST, icon: PaletteIcon });
  }

  return (
    <DefaultLayout
      hideHeader
      showMenu={true}
      topContent={
        <Box sx={{ textAlign: 'center', mt: -5, paddingY: 2 }}>
          <Logo type={'default'} size="small" href={UrlAssembler.byRouteKey('home')} />
        </Box>
      }
      loading={loading || updateLoading}
      containerStyles={{ pl: { xs: 3, lg: 4 }, pr: { xs: 3, lg: 4 } }}
      boxRefCallback={setScrollRef}
    >
      <LayoutPaper sx={{ p: { xs: '45px 25px', md: '60px 75px' } }}>
        <Box sx={{ paddingBottom: 4 }}>
          <Stepper activeStep={onboardingStage === 0 ? 0 : onboardingStage - 1} alternativeLabel>
            {steps.map(({ id, icon }, index) => (
              <Step key={id}>
                <StepLabel
                  StepIconComponent={icon}
                  StepIconProps={{
                    color:
                      onboardingStage > 1 && index < onboardingStage - 1 ? 'primary' : 'disabled',
                  }}
                >
                  <FormattedMessage id={`components.onboarding.step.${id}.label`} />
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {onboardingStage <= SELLER_ENUM.onboardingStage.PROFILE ? (
          <SellerProfileForm
            align="center"
            scrollToRef={scrollToRef}
            title={
              <Title variant="h5" sx={{ textAlign: 'center' }}>
                <FormattedMessage
                  id={`components.onboarding.step.${SELLER_ENUM.onboardingStage.PROFILE}.title`}
                />
              </Title>
            }
            description={
              <Paragraph align="center" sx={stepDescriptionProps}>
                <FormattedMessage
                  id={`components.onboarding.step.${SELLER_ENUM.onboardingStage.PROFILE}.description`}
                />
              </Paragraph>
            }
            submitText={<FormattedMessage id="components.onboarding.continueBtn" />}
            onLoading={setLoading}
            onUpdate={handleNextStep(SELLER_ENUM.onboardingStage.PAYMENT)}
          />
        ) : (
          <></>
        )}
        {onboardingStage === SELLER_ENUM.onboardingStage.PAYMENT ? (
          <SellerPaymentForm
            align="center"
            scrollToRef={scrollToRef}
            title={
              <Title variant="h5" sx={{ textAlign: 'center' }}>
                <FormattedMessage
                  id={`components.onboarding.step.${SELLER_ENUM.onboardingStage.PAYMENT}.title`}
                />
              </Title>
            }
            description={
              <Paragraph align="center" sx={stepDescriptionProps}>
                <FormattedMessage
                  id={`components.onboarding.step.${SELLER_ENUM.onboardingStage.PAYMENT}.description`}
                />
              </Paragraph>
            }
            submitText={<FormattedMessage id="components.onboarding.continueBtn" />}
            onLoading={setLoading}
            onUpdate={handleNextStep(
              isArtistSeller
                ? SELLER_ENUM.onboardingStage.ARTIST
                : SELLER_ENUM.onboardingStage.COMPLETED,
            )}
          />
        ) : (
          <></>
        )}
        {onboardingStage === SELLER_ENUM.onboardingStage.ARTIST && isArtistSeller ? (
          <ArtistProfileForm
            artistId={artist.id}
            align="center"
            scrollToRef={scrollToRef}
            title={
              <Title variant="h5" sx={{ textAlign: 'center' }}>
                <FormattedMessage
                  id={`components.onboarding.step.${SELLER_ENUM.onboardingStage.ARTIST}.title`}
                />
              </Title>
            }
            description={
              <Paragraph align="center" sx={stepDescriptionProps}>
                <FormattedMessage
                  id={`components.onboarding.step.${SELLER_ENUM.onboardingStage.ARTIST}.description`}
                />
              </Paragraph>
            }
            submitText={<FormattedMessage id="components.onboarding.continueBtn" />}
            onLoading={setLoading}
            onUpdate={handleNextStep(SELLER_ENUM.onboardingStage.COMPLETED)}
          />
        ) : (
          <></>
        )}
      </LayoutPaper>
    </DefaultLayout>
  );
};
