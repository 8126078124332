import { gql } from '@apollo/client';

export const ARTIST_EXHIBITION_FRAGMENT = gql`
  fragment ArtistExhibitionFragment on ArtistExhibition {
    id
    artistId
    title
    type
    venue
    year
  }
`;
