export const GUI_ACTIONS = {
  UPDATE_PROPERTY: 'GUI_UPDATE_PROPERTY',
  UPDATE_SETTINGS: 'GUI_UPDATE_SETTINGS',
};

export type ActionType = {
  type: string;
  payload: Record<string, any>;
};

// Action Creators
export function guiUpdate(
  property: string,
  value: string | boolean | Record<string, any>,
): ActionType {
  return {
    type: GUI_ACTIONS.UPDATE_PROPERTY,
    payload: {
      property,
      value,
    },
  };
}

export function guiUpdateSettings(properties: Record<string, any>): ActionType {
  return {
    type: GUI_ACTIONS.UPDATE_SETTINGS,
    payload: properties,
  };
}
