import { gql } from '@apollo/client';
import { ARTIST_FEED_FRAGMENT } from '../../fragments/artist/feed.graphql';
import { PAGINATION_FRAGMENT } from '../../fragments/common/pagination.graphql';

export const LIST_ARTIST_FEED = gql`
  ${ARTIST_FEED_FRAGMENT}
  ${PAGINATION_FRAGMENT}
  query listArtistFeed(
    $artistId: Int!
    $page: Int = 1
    $items: Int = 10
    $filters: InputArtistFeedFilters
  ) {
    listArtistFeed(artistId: $artistId, page: $page, items: $items, filters: $filters) {
      items {
        ...ArtistFeedFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
`;
