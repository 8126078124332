import { FormSelectNoneOption } from '@riseart/dashboard';
import { countriesListOptions } from '../../utils';

/**
 * ArtistAwardModel
 */
export const ArtistAwardModel = {
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      tag: 'input',
      type: 'text',
      name: 'name',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 100 },
          message: 'forms.validation.maxLength100',
        },
      ],
      placeholder: 'forms.award.label.name',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'body',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 100 },
          message: 'forms.validation.maxLength100',
        },
      ],
      placeholder: 'forms.award.label.description',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'city',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'stringLength',
          options: { max: 100 },
          message: 'forms.validation.maxLength100',
        },
      ],
      placeholder: 'forms.award.label.city',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'countryCode',
      margin: 'none',
      selectProps: { native: false, displayEmpty: true },
      initialOption: FormSelectNoneOption(),
      selectOptions: countriesListOptions,
      placeholder: 'forms.award.label.country',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'year',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'regex',
          pattern: '^\\d{4}$',
          message: 'forms.validation.yearNotInFuture',
        },
        {
          type: 'custom',
          custom: (value: string | number): boolean =>
            !!(value && value > new Date().getFullYear()),
          message: 'forms.validation.yearNotInFuture',
        },
      ],
      placeholder: 'forms.award.label.year',
    },
  ],
};
