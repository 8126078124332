import { gql } from '@apollo/client';
import { ARTIST_AWARD_FRAGMENT } from '../../fragments/artist/award.graphql';

export const CREATE_ARTIST_AWARD = gql`
  ${ARTIST_AWARD_FRAGMENT}
  mutation createArtistAward($artistId: Int!, $inputArtistAward: InputArtistAward!) {
    createArtistAward(artistId: $artistId, inputArtistAward: $inputArtistAward) {
      ...ArtistAwardFragment
    }
  }
`;
