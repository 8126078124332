import { APP_STORE_ACTIONS, GUI_ACTIONS } from '../actions/container';
import { Client as ApolloClient } from '../../riseart/apollo/client';
import { cookies as COOKIES_CONFIG } from '../../../config/config.js';
import { Cookies } from '../../riseart/utils/Cookies/Cookies';
import { localeSelector } from '../selectors/locale';

/**
 * MiddlewareApplication
 */
export class MiddlewareApplication {
  /**
   * Redux Middleware
   *
   * @param store
   */
  static middleware =
    (store: Record<string, any>) =>
    (next: any) =>
    (action: Record<string, any>): any => {
      switch (action.type) {
        case APP_STORE_ACTIONS.UPDATE_LOCALES: {
          const { locales } = action.payload;
          const prevLocale = localeSelector(store.getState(), 'userLocale');

          if (prevLocale && locales.userLocale && prevLocale.name !== locales.userLocale.name) {
            ApolloClient.getInstance().resetStore();
          }

          break;
        }
        case GUI_ACTIONS.UPDATE_SETTINGS: {
          const guiCookie = Cookies.get(COOKIES_CONFIG.gui.name) || null;

          Cookies.set(
            COOKIES_CONFIG.gui.name,
            JSON.stringify({ ...(guiCookie ? JSON.parse(guiCookie) : null), ...action.payload }),
            COOKIES_CONFIG.gui,
          );

          break;
        }
        default:
          break;
      }
      next(action);
    };
}
