import { gql } from '@apollo/client';

export const ACTION_OFFER = gql`
  mutation actionOffer($id: Int!, $action: String!, $comment: String) {
    actionOffer(id: $id, action: $action, comment: $comment) {
      id
      action
      offerId
      executed
      status
    }
  }
`;
