export const ROUTER_CONFIG = [
  {
    key: 'onboarding',
    path: ['/:lang(en)?/onboarding', '/:lang(fr)/onboarding'],
    component: 'Onboarding',
    meta: {
      metaTitle: 'meta.onboarding.title',
      metaKeywords: 'meta.onboarding.keywords',
      metaDescription: 'meta.onboarding.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_artist'] },
  },
  {
    key: 'home',
    path: ['/:lang(en)?', '/:lang(fr)'],
    component: 'Home',
    menu: { type: 'main', order: 1, label: 'home' },
    meta: {
      metaTitle: 'meta.home.title',
      metaKeywords: 'meta.home.keywords',
      metaDescription: 'meta.home.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_artist'] },
  },
  {
    key: 'ArtEdit',
    path: ['/:lang(en)?/art/edit/:id(\\d+)', '/:lang(fr)/art/edit/:id(\\d+)'],
    component: 'ArtEdit',
    meta: {
      metaTitle: 'meta.artDetails.title',
      metaKeywords: 'meta.artDetails.keywords',
      metaDescription: 'meta.artDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_artist'] },
  },
  {
    key: 'artAdd',
    path: ['/:lang(en)?/art/add', '/:lang(fr)/art/add'],
    component: 'ArtAdd',
    meta: {
      metaTitle: 'meta.art.title',
      metaKeywords: 'meta.art.keywords',
      metaDescription: 'meta.art.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_artist'] },
  },
  {
    key: 'artList',
    path: ['/:lang(en)?/art', '/:lang(fr)/art'],
    component: 'ArtList',
    menu: { type: 'main', order: 3, label: 'artList' },
    meta: {
      metaTitle: 'meta.art.title',
      metaKeywords: 'meta.art.keywords',
      metaDescription: 'meta.art.description',
    },
    exact: false,
    strict: false,
    acl: { roles: ['seller_artist'] },
  },
  {
    key: 'eventAdd',
    path: ['/:lang(en)?/event/add', '/:lang(fr)/event/add'],
    component: 'EventAdd',
    meta: {
      metaTitle: 'meta.event.title',
      metaKeywords: 'meta.event.keywords',
      metaDescription: 'meta.event.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_artist'] },
  },
  {
    key: 'eventEdit',
    path: ['/:lang(en)?/event/edit/:id(\\d+)', '/:lang(fr)/event/edit/:id(\\d+)'],
    component: 'EventEdit',
    meta: {
      metaTitle: 'meta.event.title',
      metaKeywords: 'meta.event.keywords',
      metaDescription: 'meta.event.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_artist'] },
  },
  {
    key: 'eventList',
    path: ['/:lang(en)?/events', '/:lang(fr)/events'],
    component: 'EventList',
    menu: { type: 'main', order: 5, label: 'eventList' },
    meta: {
      metaTitle: 'meta.event.title',
      metaKeywords: 'meta.event.keywords',
      metaDescription: 'meta.event.description',
    },
    exact: false,
    strict: false,
    acl: { roles: ['seller_artist'] },
  },
  {
    key: 'sellerProfile',
    path: ['/:lang(en)?/seller', '/:lang(fr)/seller'],
    component: 'SellerProfile',
    menu: [
      { type: 'main', order: 4, label: 'seller' },
      { type: 'main', parent: 'sellerProfile', order: 1, label: 'sellerProfile' },
    ],
    meta: {
      metaTitle: 'meta.sellerProfile.title',
      metaKeywords: 'meta.sellerProfile.keywords',
      metaDescription: 'meta.sellerProfile.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_artist'] },
  },
  {
    key: 'sellerShippingList',
    path: ['/:lang(en)?/seller/shipping', '/:lang(fr)/seller/shipping'],
    component: 'SellerShippingList',
    menu: { type: 'main', parent: 'sellerProfile', order: 2, label: 'sellerShippingList' },
    meta: {
      metaTitle: 'meta.sellerShipping.title',
      metaKeywords: 'meta.sellerShipping.keywords',
      metaDescription: 'meta.sellerShipping.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_artist'] },
  },
  {
    key: 'sellerShippingAdd',
    path: ['/:lang(en)?/seller/shipping/add', '/:lang(fr)/seller/shipping/add'],
    component: 'SellerShippingAdd',
    meta: {
      metaTitle: 'meta.sellerShipping.title',
      metaKeywords: 'meta.sellerShipping.keywords',
      metaDescription: 'meta.sellerShipping.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_artist'] },
  },
  {
    key: 'sellerShippingEdit',
    path: ['/:lang(en)?/seller/shipping/:id(\\d+)', '/:lang(fr)/seller/shipping/:id(\\d+)'],
    component: 'SellerShippingEdit',
    meta: {
      metaTitle: 'meta.sellerShipping.title',
      metaKeywords: 'meta.sellerShipping.keywords',
      metaDescription: 'meta.sellerShipping.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_artist'] },
  },
  {
    key: 'sellerPayment',
    path: ['/:lang(en)?/seller/payment', '/:lang(fr)/seller/payment'],
    component: 'SellerPayment',
    menu: { type: 'main', parent: 'sellerProfile', order: 3, label: 'sellerPayment' },
    meta: {
      metaTitle: 'meta.sellerPayment.title',
      metaKeywords: 'meta.sellerPayment.keywords',
      metaDescription: 'meta.sellerPayment.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_artist'] },
  },
  {
    key: 'sellerAwayMode',
    path: ['/:lang(en)?/seller/away', '/:lang(fr)/seller/away'],
    component: 'SellerAwayMode',
    menu: { type: 'main', parent: 'sellerProfile', order: 4, label: 'sellerAwayMode' },
    meta: {
      metaTitle: 'meta.sellerAwayMode.title',
      metaKeywords: 'meta.sellerAwayMode.keywords',
      metaDescription: 'meta.sellerAwayMode.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_artist'] },
  },
  {
    key: 'artistProfile',
    path: ['/:lang(en)?/artist/profile', '/:lang(fr)/artist/profile'],
    component: 'ArtistProfile',
    menu: [
      { type: 'main', order: 2, label: 'artistProfile' },
      { type: 'main', parent: 'artistProfile', order: 1, label: 'artistProfile' },
    ],
    meta: {
      metaTitle: 'meta.artistProfile.title',
      metaKeywords: 'meta.artistProfile.keywords',
      metaDescription: 'meta.artistProfile.description',
    },
    exact: true,
    strict: false,
    acl: { roles: { seller_artist: 'allow', seller_partner: 'disallow' } },
  },
  {
    key: 'artistCv',
    path: ['/:lang(en)?/artist/cv', '/:lang(fr)/artist/cv'],
    component: 'ArtistCv',
    menu: { type: 'main', parent: 'artistProfile', order: 2, label: 'artistCv' },
    meta: {
      metaTitle: 'meta.artistCv.title',
      metaKeywords: 'meta.artistCv.keywords',
      metaDescription: 'meta.artistCv.description',
    },
    exact: true,
    strict: false,
    acl: { roles: { seller_artist: 'allow', seller_partner: 'disallow' } },
  },
  {
    key: 'artistFeedList',
    path: ['/:lang(en)?/artist/feed', '/:lang(fr)/artist/feed'],
    component: 'ArtistFeedList',
    menu: { type: 'main', parent: 'artistProfile', order: 3, label: 'artistFeedList' },
    meta: {
      metaTitle: 'meta.artistFeed.title',
      metaKeywords: 'meta.artistFeed.keywords',
      metaDescription: 'meta.artistFeed.description',
    },
    exact: true,
    strict: false,
    acl: { roles: { seller_artist: 'allow', seller_partner: 'disallow' } },
  },
  {
    key: 'artistFeedEdit',
    path: ['/:lang(en)?/artist/feed/edit/:id(\\d+)', '/:lang(fr)/artist/feed/edit/:id(\\d+)'],
    component: 'ArtistFeedEdit',
    meta: {
      metaTitle: 'meta.artistFeed.title',
      metaKeywords: 'meta.artistFeed.keywords',
      metaDescription: 'meta.artistFeed.description',
    },
    exact: true,
    strict: false,
    acl: { roles: { seller_artist: 'allow', seller_partner: 'disallow' } },
  },
  {
    key: 'artistFeedAdd',
    path: ['/:lang(en)?/artist/feed/add', '/:lang(fr)/artist/feed/add'],
    component: 'ArtistFeedAdd',
    meta: {
      metaTitle: 'meta.artistFeed.title',
      metaKeywords: 'meta.artistFeed.keywords',
      metaDescription: 'meta.artistFeed.description',
    },
    exact: true,
    strict: false,
    acl: { roles: { seller_artist: 'allow', seller_partner: 'disallow' } },
  },
  {
    key: 'artistAdd',
    path: ['/:lang(en)?/artist/add', '/:lang(fr)/artist/add'],
    component: 'ArtistAdd',
    meta: {
      metaTitle: 'meta.artist.title',
      metaKeywords: 'meta.artist.keywords',
      metaDescription: 'meta.artist.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_partner'] },
  },
  {
    key: 'artistArtList',
    path: ['/:lang(en)?/artist/:id(\\d+)/art', '/:lang(fr)/artist/:id(\\d+)/art'],
    component: 'ArtistArtList',
    meta: {
      metaTitle: 'meta.artistDetails.title',
      metaKeywords: 'meta.artistDetails.keywords',
      metaDescription: 'meta.artistDetails.description',
    },
    menu: { type: 'artist', order: 2, label: 'art' },
    exact: true,
    strict: false,
    acl: { roles: ['seller_partner'] },
  },
  {
    key: 'artistArtAdd',
    path: ['/:lang(en)?/artist/:id(\\d+)/art/add', '/:lang(fr)/artist/:id(\\d+)/art/add'],
    component: 'ArtistArtAdd',
    meta: {
      metaTitle: 'meta.artistDetails.title',
      metaKeywords: 'meta.artistDetails.keywords',
      metaDescription: 'meta.artistDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_partner'] },
  },
  {
    key: 'artistArtEdit',
    path: [
      '/:lang(en)?/artist/:artistId(\\d+)/art/:id(\\d+)/edit',
      '/:lang(fr)/artist/:artistId(\\d+)/art/:id(\\d+)/edit',
    ],
    component: 'ArtistArtEdit',
    meta: {
      metaTitle: 'meta.artistDetails.title',
      metaKeywords: 'meta.artistDetails.keywords',
      metaDescription: 'meta.artistDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_partner'] },
  },
  {
    key: 'artistDetails',
    path: ['/:lang(en)?/artist/:id(\\d+)/profile', '/:lang(fr)/artist/:id(\\d+)/profile'],
    component: 'ArtistDetails',
    meta: {
      metaTitle: 'meta.artistDetails.title',
      metaKeywords: 'meta.artistDetails.keywords',
      metaDescription: 'meta.artistDetails.description',
    },
    menu: { type: 'artist', order: 1, label: 'profile' },
    exact: true,
    strict: false,
    acl: { roles: ['seller_partner'] },
  },
  {
    key: 'artistsList',
    path: ['/:lang(en)?/artists', '/:lang(fr)/artists'],
    component: 'ArtistList',
    menu: { type: 'main', order: 2, label: 'artistsList' },
    meta: {
      metaTitle: 'meta.artist.title',
      metaKeywords: 'meta.artist.keywords',
      metaDescription: 'meta.artist.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_partner'] },
  },
  {
    key: 'partnerArtistCv',
    path: ['/:lang(en)?/artist/:id(\\d+)/cv', '/:lang(fr)/artist/:id(\\d+)/cv'],
    component: 'ArtistDetailsCvPage',
    menu: { type: 'artist', order: 3, label: 'cv' },
    meta: {
      metaTitle: 'meta.artistCv.title',
      metaKeywords: 'meta.artistCv.keywords',
      metaDescription: 'meta.artistCv.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_partner'] },
  },
  {
    key: 'partnerArtistFeedList',
    path: ['/:lang(en)?/artist/:id(\\d+)/feed', '/:lang(fr)/artist/:id(\\d+)/feed'],
    component: 'PartnerArtistFeedList',
    menu: { type: 'artist', order: 4, label: 'feed' },
    meta: {
      metaTitle: 'meta.artistFeed.title',
      metaKeywords: 'meta.artistFeed.keywords',
      metaDescription: 'meta.artistFeed.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_partner'] },
  },
  {
    key: 'partnerArtistFeedEdit',
    path: [
      '/:lang(en)?/artist/:artistId(\\d+)/feed/:id(\\d+)/edit',
      '/:lang(fr)/artist/:artistId(\\d+)/feed/:id(\\d+)/edit',
    ],
    component: 'PartnerArtistFeedEdit',
    meta: {
      metaTitle: 'meta.artistFeed.title',
      metaKeywords: 'meta.artistFeed.keywords',
      metaDescription: 'meta.artistFeed.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_partner'] },
  },
  {
    key: 'partnerArtistFeedAdd',
    path: ['/:lang(en)?/artist/:id(\\d+)/feed/add', '/:lang(fr)/artist/:id(\\d+)/feed/add'],
    component: 'PartnerArtistFeedAdd',
    meta: {
      metaTitle: 'meta.artistFeed.title',
      metaKeywords: 'meta.artistFeed.keywords',
      metaDescription: 'meta.artistFeed.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_partner'] },
  },
  {
    key: 'terms',
    path: ['/:lang(en)?/terms', '/:lang(fr)/terms'],
    component: 'Terms',
    meta: {
      metaTitle: 'meta.terms.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_artist'] },
  },
  {
    key: 'login',
    path: ['/:lang(en)?/login', '/:lang(fr)/login'],
    component: 'Login',
    meta: {
      metaTitle: 'meta.login.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'register',
    path: ['/:lang(en)?/join', '/:lang(fr)/join'],
    component: 'Register',
    meta: {
      metaTitle: 'meta.register.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'signout',
    path: ['/:lang(en)?/signout', '/:lang(fr)/signout'],
    component: 'Signout',
    menu: [
      { type: 'secondary', order: 1, label: 'signout' },
      { type: 'side', order: 1, label: 'signout' },
    ],
    meta: {
      metaTitle: 'meta.signout.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'offerDetails',
    path: ['/:lang(en)?/offer/:id(\\d+)', '/:lang(fr)/offer/:id(\\d+)'],
    component: 'OfferDetails',
    meta: {
      metaTitle: 'meta.offerDetails.title',
      metaKeywords: 'meta.offerDetails.keywords',
      metaDescription: 'meta.offerDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_artist'] },
  },
  {
    key: 'offersList',
    path: ['/:lang(en)?/offers', '/:lang(fr)/offers'],
    component: 'OfferList',
    menu: { type: 'main', order: 6, label: 'offersList' },
    meta: {
      metaTitle: 'meta.offers.title',
      metaKeywords: 'meta.offers.keywords',
      metaDescription: 'meta.offers.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['seller_artist'] },
  },
  {
    key: 'settings',
    path: ['/:lang(en)?/settings/team', '/:lang(fr)/settings/team'],
    component: 'Settings',
    menu: [
      { type: 'main', order: 7, label: 'settings' },
      { type: 'main', parent: 'settings', order: 1, label: 'team' },
    ],
    meta: {
      metaTitle: 'meta.settings.title',
      metaKeywords: 'meta.settings.keywords',
      metaDescription: 'meta.settings.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['partner_admin'] },
  },
  {
    key: 'notfound',
    component: 'NotFound',
    meta: {
      metaTitle: 'meta.common.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
    },
    exact: false,
    strict: false,
    acl: { roles: ['*'] },
  },
];
