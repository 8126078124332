import { gql } from '@apollo/client';
import { EVENT_FRAGMENT } from '../../fragments/event/event.graphql';

export const UPDATE_EVENT = gql`
  ${EVENT_FRAGMENT}
  mutation updateEvent($id: Int!, $inputEvent: InputEvent!) {
    updateEvent(id: $id, inputEvent: $inputEvent) {
      ...EventFragment
    }
  }
`;
