import { countriesListOptions } from '../../utils';

/**
 * ArtistDegreeModel
 */
export const ArtistDegreeModel = {
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      tag: 'input',
      type: 'text',
      name: 'description',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'stringLength',
          options: { max: 100 },
          message: 'forms.validation.maxLength100',
        },
      ],
      placeholder: 'forms.degree.label.description',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'university',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 100 },
          message: 'forms.validation.maxLength100',
        },
      ],
      placeholder: 'forms.degree.label.university',
      hint: 'forms.degree.hints.university',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'city',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'stringLength',
          options: { max: 100 },
          message: 'forms.validation.maxLength100',
        },
      ],
      placeholder: 'forms.degree.label.city',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'countryCode',
      margin: 'none',
      selectProps: { native: false, displayEmpty: true },
      selectOptions: countriesListOptions,
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      placeholder: 'forms.degree.label.country',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'year',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'regex',
          pattern: '^\\d{4}$',
          message: 'forms.validation.yearNotInFuture',
        },
        {
          type: 'custom',
          custom: (value: string | number): boolean =>
            !!(value && value > new Date().getFullYear()),
          message: 'forms.validation.yearNotInFuture',
        },
      ],
      placeholder: 'forms.degree.label.year',
    },
  ],
};
