import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import { Country, Title, FormViewField, FormHeader, LayoutPaper, Link } from '@riseart/dashboard';
import { nl2br } from '@riseart/fe-utils';
import { DefaultLayout } from '../../layout/Default';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { Artist as ArtistModel } from '../../../data/models/Artist';
import { PageSubmenu } from '../../common/PageSubmenu';
import { useScrollToEl } from '../../../data/hooks/useScrollToEl';
import { ArtistProfileForm } from './ProfileForm';
import { selectPartner } from '../../../services/redux/selectors/partner';
import { READ_ARTIST } from '../../../data/gql/queries/artist/read.graphql';
import { GET_PARTNER_ARTIST } from '../../../data/gql/queries/partner/getArtist.graphql';

/**
 * ArtistDetailsPage
 *
 * @returns {JSX.Element}
 */
export function ArtistDetailsPage(): JSX.Element {
  const { formatMessage } = useIntl();
  const NOT_APPLICABLE = formatMessage({ id: 'common.notApplicable' });
  const { id: artistId }: Record<string, any> = useParams();
  const { setScrollRef, scrollToRef } = useScrollToEl();
  const partner = useSelector(selectPartner) || {};
  const [loading, setLoading] = useState<boolean>(false);

  // Queries and Mutations
  const { data: listArtist, loading: listLoading } = useQuery(GET_PARTNER_ARTIST, {
    fetchPolicy: 'network-only',
    variables: {
      partnerId: partner.id,
      filters: { artistId: { value: artistId, operator: 'EQUAL' } },
    },
  });
  const partnerArtist =
    listArtist && listArtist.artist && listArtist.artist.items && listArtist.artist.items[0];
  const canAdmin = partnerArtist && partnerArtist.canUpdate;

  const { data: readData, loading: readLoading } = useQuery(READ_ARTIST, {
    fetchPolicy: 'network-only',
    variables: { id: artistId },
    skip: !(partnerArtist && partnerArtist.artistId),
  });
  const rawArtistData = (readData && readData.readArtist) || null;
  const Artist = new ArtistModel();

  if (rawArtistData) {
    Artist.hydrateFromApiData(rawArtistData);
  }

  const listLabel = formatMessage({ id: 'components.mainMenu.artistsList' });

  return (
    <DefaultLayout
      title={listLabel}
      topContent={
        canAdmin === false ? (
          <Alert severity="warning">
            {formatMessage({ id: 'components.artist.canAdminMessage' })}
          </Alert>
        ) : null
      }
      loading={loading || listLoading || readLoading}
      boxRefCallback={setScrollRef}
      breadcrumbs={[
        <Link
          key="home"
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link
          key="allArtists"
          to={UrlAssembler.artistList()}
          title={listLabel}
          underline="hover"
          color="inherit"
        >
          {listLabel}
        </Link>,

        ...(Artist.name
          ? [
              <Typography key="art" variant="inherit">
                {Artist.name}
              </Typography>,
            ]
          : []),
      ]}
    >
      {Artist.id ? (
        <>
          <PageSubmenu menu="artist" selected="artistDetails" urlParams={{ id: Artist.id }} />
          {canAdmin ? (
            <ArtistProfileForm
              artistId={artistId}
              title={
                <Title variant="h5">
                  {formatMessage({ id: 'components.mainMenu.artistProfile' })}
                </Title>
              }
              submitText={formatMessage({ id: 'common.saveChanges' })}
              onLoading={setLoading}
              wrapperComponent={LayoutPaper}
              scrollToRef={scrollToRef}
            />
          ) : (
            <LayoutPaper>
              <FormHeader title={<Title variant="h5">{Artist.name}</Title>} />
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <FormViewField label={formatMessage({ id: 'forms.common.id' })}>
                    {Artist.id}
                  </FormViewField>
                  <FormViewField label={formatMessage({ id: 'forms.artist.label.name' })}>
                    {Artist.name}
                  </FormViewField>
                  <FormViewField label={formatMessage({ id: 'forms.artist.label.alias' })}>
                    {Artist.alias || NOT_APPLICABLE}
                  </FormViewField>
                  <FormViewField label={formatMessage({ id: 'forms.artist.label.gender' })}>
                    {Artist.gender
                      ? formatMessage({ id: `common.gender.${Artist.gender}` })
                      : NOT_APPLICABLE}
                  </FormViewField>
                  <FormViewField label={formatMessage({ id: 'components.artist.bornIn' })}>
                    {[
                      Artist.birthYear,
                      Artist.birthCity,
                      Artist.birthCountry ? <Country code={Artist.birthCountry} /> : NOT_APPLICABLE,
                      Artist.regions && Artist.regions.length
                        ? Artist.regions.map(
                            (region: string, key: number) =>
                              `${key ? ', ' : ''}${formatMessage({
                                id: `components.artist.region.${region}`,
                              })}`,
                          )
                        : null,
                    ]
                      .filter((item) => item)
                      .map((item, idx) =>
                        idx ? (
                          <React.Fragment key={idx}>
                            {', '}
                            {item}
                          </React.Fragment>
                        ) : (
                          item
                        ),
                      )}
                  </FormViewField>
                  <FormViewField label={formatMessage({ id: 'components.artist.livesIn' })}>
                    {Artist.livesCity}
                    {Artist.livesCity && Artist.livesCountry ? ', ' : null}
                    {Artist.livesCountry ? <Country code={Artist.livesCountry} /> : NOT_APPLICABLE}
                  </FormViewField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormViewField label={formatMessage({ id: 'forms.artist.label.mediums' })}>
                    {Artist.mediums && Artist.mediums.length
                      ? Artist.mediums.map((medium: string) => (
                          <Chip
                            key={medium}
                            label={formatMessage({ id: `filters.artist.medium.${medium}` })}
                            sx={{ mr: '8px', mb: '8px' }}
                          />
                        ))
                      : NOT_APPLICABLE}
                  </FormViewField>
                  <FormViewField label={formatMessage({ id: 'forms.artist.label.styles' })}>
                    {Artist.styles && Artist.styles.length
                      ? Artist.styles.map((style: string) => (
                          <Chip
                            key={style}
                            label={formatMessage({ id: `filters.artist.style.${style}` })}
                            sx={{ mr: '8px', mb: '8px' }}
                          />
                        ))
                      : NOT_APPLICABLE}
                  </FormViewField>
                  <FormViewField label={formatMessage({ id: 'forms.artist.label.website' })}>
                    {Artist.website || NOT_APPLICABLE}
                  </FormViewField>
                  <FormViewField
                    label={formatMessage({ id: 'forms.artist.label.instagramUsername' })}
                  >
                    {Artist.instagramUsername ? (
                      <Link
                        component="a"
                        href={`https://www.instagram.com/${Artist.instagramUsername}/`}
                        title={Artist.instagramUsername}
                        target="_blank"
                      >
                        {Artist.instagramUsername}
                      </Link>
                    ) : (
                      NOT_APPLICABLE
                    )}
                  </FormViewField>
                  <FormViewField label={formatMessage({ id: 'forms.artist.label.socialProfiles' })}>
                    {Artist.socialProfiles || NOT_APPLICABLE}
                  </FormViewField>
                  <FormViewField label={formatMessage({ id: 'forms.artist.label.biography' })}>
                    {Artist.biography ? (
                      <span dangerouslySetInnerHTML={{ __html: nl2br(Artist.biography) }} />
                    ) : (
                      NOT_APPLICABLE
                    )}
                  </FormViewField>
                </Grid>
              </Grid>
            </LayoutPaper>
          )}
        </>
      ) : null}
    </DefaultLayout>
  );
}
