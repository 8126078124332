import { useIntl } from 'react-intl';
import { useQuery, useMutation } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LAYOUT_PAPER_PADDING } from '@riseart/dashboard';
import { artist as ENUM_ARTIST } from '../../config/enumeration.js';
import { AdvancedTable } from '../common/tables/Advanced';

// Awards
import { AwardAddRow } from './AwardAddRow';
import { AwardEditRow } from './AwardEditRow';
import { ARTIST_AWARD_SCHEMA } from '../data/tables/schemas/artist';
import { LIST_ARTIST_AWARDS } from '../../data/gql/queries/artist/listAwards.graphql';
import { DELETE_ARTIST_AWARD } from '../../data/gql/queries/artist/deleteAward.graphql';

// Bibliography
import { BibliographyAddRow } from './BibliographyAddRow';
import { BibliographyEditRow } from './BibliographyEditRow';
import { ARTIST_BIBLIOGRAPHY_SCHEMA } from '../data/tables/schemas/artist';
import { LIST_ARTIST_BIBLIOGRAPHIES } from '../../data/gql/queries/artist/listBibliographies.graphql';
import { DELETE_ARTIST_BIBLIOGRAPHY } from '../../data/gql/queries/artist/deleteBibliography.graphql';

// Degree
import { DegreeAddRow } from './DegreeAddRow';
import { DegreeEditRow } from './DegreeEditRow';
import { ARTIST_DEGREE_SCHEMA } from '../data/tables/schemas/artist';
import { LIST_ARTIST_DEGREES } from '../../data/gql/queries/artist/listDegrees.graphql';
import { DELETE_ARTIST_DEGREE } from '../../data/gql/queries/artist/deleteDegree.graphql';

// Collection
import { CollectionAddRow } from './CollectionAddRow';
import { CollectionEditRow } from './CollectionEditRow';
import { ARTIST_COLLECTION_SCHEMA } from '../data/tables/schemas/artist';
import { LIST_ARTIST_COLLECTIONS } from '../../data/gql/queries/artist/listCollections.graphql';
import { DELETE_ARTIST_COLLECTION } from '../../data/gql/queries/artist/deleteCollection.graphql';

// Exhibition
import { ExhibitionAddRow } from './ExhibitionAddRow';
import { ExhibitionEditRow } from './ExhibitionEditRow';
import { ARTIST_EXHIBITION_SCHEMA } from '../data/tables/schemas/artist';
import { FILTER_ARTIST_EXHIBITIONS } from '../../data/gql/queries/artist/filterExhibitions.graphql';
import { DELETE_ARTIST_EXHIBITION } from '../../data/gql/queries/artist/deleteExhibition.graphql';

type Props = {
  artist: Record<string, any> | null;
  canAdmin?: boolean;
  scrollToRef: () => any;
};

/**
 * ArtistCv
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export function ArtistCv({ artist, canAdmin = false, scrollToRef }: Props): JSX.Element | null {
  const artistId = artist && artist.id;
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const { formatMessage } = useIntl();

  // Awards Data
  const [deleteAwardMutation, { loading: deleteAwardLoading }] = useMutation(DELETE_ARTIST_AWARD);
  const {
    data: listAwardsData,
    loading: loadingAwards,
    refetch: refetchListAwardsQuery,
  } = useQuery(LIST_ARTIST_AWARDS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { artistId },
    skip: !artist,
  });

  // Bibliography Data
  const [deleteBibliographyMutation, { loading: deleteBibliographyLoading }] = useMutation(
    DELETE_ARTIST_BIBLIOGRAPHY,
  );
  const {
    data: listBibliographyData,
    loading: loadingBibliography,
    refetch: refetchListBibliographyQuery,
  } = useQuery(LIST_ARTIST_BIBLIOGRAPHIES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { artistId },
    skip: !artist,
  });

  // Degrees Data
  const [deleteDegreeMutation, { loading: deleteDegreeLoading }] =
    useMutation(DELETE_ARTIST_DEGREE);
  const {
    data: listDegreesData,
    loading: loadingDegrees,
    refetch: refetchListDegreesQuery,
  } = useQuery(LIST_ARTIST_DEGREES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { artistId },
    skip: !artist,
  });

  // Collections Data
  const [deleteCollectionMutation, { loading: deleteCollectionLoading }] =
    useMutation(DELETE_ARTIST_COLLECTION);
  const {
    data: listCollectionsData,
    loading: loadingCollections,
    refetch: refetchListCollectionsQuery,
  } = useQuery(LIST_ARTIST_COLLECTIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { artistId },
    skip: !artist,
  });

  // Exhibitions Data
  const [deleteExhibitionMutation, { loading: deleteExhibitionLoading }] =
    useMutation(DELETE_ARTIST_EXHIBITION);
  const {
    data: listExhibitionsData,
    loading: loadingExhibitions,
    refetch: refetchListExhibitionsQuery,
  } = useQuery(FILTER_ARTIST_EXHIBITIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      artistId,
      type: Object.keys(ENUM_ARTIST.exhibition.type)
        .reduce(
          (accumulator: Array<string>, key: string): Array<string> => [
            ...accumulator,
            // @ts-ignore
            ENUM_ARTIST.exhibition.type[key],
          ],
          [],
        )
        .join(','),
    },
    skip: !artist,
  });

  const adminRelatedProps = {
    includesFab: canAdmin && isLargeScreen,
    ...(!canAdmin ? { mainAction: <></> } : null),
  };
  const actionColumnTable = canAdmin ? [{ title: '', width: '20%', align: 'right' }] : [];

  return artist ? (
    <>
      {/* Awards */}
      <AdvancedTable
        data={
          listAwardsData && listAwardsData.listArtistAwards && listAwardsData.listArtistAwards.items
        }
        loading={loadingAwards}
        deleteLoading={deleteAwardLoading}
        deleteHandler={deleteAwardMutation}
        refetchListHandler={refetchListAwardsQuery}
        customData={{ artistId, canAdmin }}
        scrollToTop={scrollToRef}
        title={formatMessage({ id: 'components.cv.award.title' })}
        mainActionLabel={formatMessage({ id: 'components.cv.award.add' })}
        schema={ARTIST_AWARD_SCHEMA({ canAdmin })}
        layoutPaperPadding={LAYOUT_PAPER_PADDING}
        columns={[
          {
            title: formatMessage({ id: 'components.tables.columns.award.name' }),
            width: '20%',
            sx: { pl: LAYOUT_PAPER_PADDING.pl },
          },
          {
            title: formatMessage({ id: 'components.tables.columns.award.description' }),
            width: '20%',
          },
          {
            title: formatMessage({ id: 'components.tables.columns.award.city' }),
            width: '15%',
          },
          {
            title: formatMessage({ id: 'components.tables.columns.award.country' }),
            width: '15%',
          },
          {
            title: formatMessage({
              id: 'components.tables.columns.award.year',
            }),
            width: '10%',
          },
          // @ts-ignore
          ...actionColumnTable,
        ]}
        firstRow={({
          customData,
          dataItems,
          showAdd,
          handleAdd,
          handleSuccess,
          handleError,
        }: Record<string, any>) =>
          dataItems && showAdd ? (
            <AwardAddRow
              artistId={customData.artistId}
              onCancel={handleAdd(false)}
              onSuccess={handleSuccess(handleAdd(false))}
              onError={handleError}
            />
          ) : null
        }
        editRenderer={({ handleSuccess, handleError }: Record<string, any>) =>
          ({ data, switchToDisplayMode }: Record<string, any>) =>
            (
              <AwardEditRow
                data={data}
                onSuccess={handleSuccess(switchToDisplayMode)}
                onError={handleError}
                onCancel={switchToDisplayMode}
              />
            )}
        {...adminRelatedProps}
      />
      {/* Bibliography */}
      <AdvancedTable
        data={
          listBibliographyData &&
          listBibliographyData.listArtistBibliographies &&
          listBibliographyData.listArtistBibliographies.items
        }
        loading={loadingBibliography}
        deleteLoading={deleteBibliographyLoading}
        deleteHandler={deleteBibliographyMutation}
        refetchListHandler={refetchListBibliographyQuery}
        customData={{ artistId }}
        scrollToTop={scrollToRef}
        title={formatMessage({ id: 'components.cv.bibliography.title' })}
        mainActionLabel={formatMessage({ id: 'components.cv.bibliography.add' })}
        schema={ARTIST_BIBLIOGRAPHY_SCHEMA({ canAdmin })}
        layoutPaperPadding={LAYOUT_PAPER_PADDING}
        columns={[
          {
            title: formatMessage({ id: 'components.tables.columns.bibliography.title' }),
            width: '15%',
            sx: { pl: LAYOUT_PAPER_PADDING.pl },
          },
          {
            title: formatMessage({ id: 'components.tables.columns.bibliography.author' }),
            width: '15%',
          },
          {
            title: formatMessage({ id: 'components.tables.columns.bibliography.publication' }),
            width: '15%',
          },
          {
            title: formatMessage({ id: 'components.tables.columns.bibliography.publisher' }),
            width: '15%',
          },
          {
            title: formatMessage({
              id: 'components.tables.columns.bibliography.pages',
            }),
            width: '10%',
          },
          {
            title: formatMessage({
              id: 'components.tables.columns.bibliography.year',
            }),
            width: '10%',
          },
          // @ts-ignore
          ...actionColumnTable,
        ]}
        firstRow={({
          customData,
          dataItems,
          showAdd,
          handleAdd,
          handleSuccess,
          handleError,
        }: Record<string, any>) =>
          dataItems && showAdd ? (
            <BibliographyAddRow
              artistId={customData.artistId}
              onCancel={handleAdd(false)}
              onSuccess={handleSuccess(handleAdd(false))}
              onError={handleError}
            />
          ) : null
        }
        editRenderer={({ handleSuccess, handleError }: Record<string, any>) =>
          ({ data, switchToDisplayMode }: Record<string, any>) =>
            (
              <BibliographyEditRow
                data={data}
                onSuccess={handleSuccess(switchToDisplayMode)}
                onError={handleError}
                onCancel={switchToDisplayMode}
              />
            )}
        {...adminRelatedProps}
      />
      {/* Degree */}
      <AdvancedTable
        data={
          listDegreesData &&
          listDegreesData.listArtistDegrees &&
          listDegreesData.listArtistDegrees.items
        }
        loading={loadingDegrees}
        deleteLoading={deleteDegreeLoading}
        deleteHandler={deleteDegreeMutation}
        refetchListHandler={refetchListDegreesQuery}
        customData={{ artistId }}
        scrollToTop={scrollToRef}
        title={formatMessage({ id: 'components.cv.degree.title' })}
        mainActionLabel={formatMessage({ id: 'components.cv.degree.add' })}
        schema={ARTIST_DEGREE_SCHEMA({ canAdmin })}
        layoutPaperPadding={LAYOUT_PAPER_PADDING}
        columns={[
          {
            title: formatMessage({ id: 'components.tables.columns.degree.description' }),
            width: '20%',
            sx: { pl: LAYOUT_PAPER_PADDING.pl },
          },
          {
            title: formatMessage({ id: 'components.tables.columns.degree.university' }),
            width: '20%',
          },
          {
            title: formatMessage({ id: 'components.tables.columns.degree.city' }),
            width: '15%',
          },
          {
            title: formatMessage({ id: 'components.tables.columns.degree.country' }),
            width: '15%',
          },
          {
            title: formatMessage({
              id: 'components.tables.columns.degree.year',
            }),
            width: '10%',
          },
          // @ts-ignore
          ...actionColumnTable,
        ]}
        firstRow={({
          customData,
          dataItems,
          showAdd,
          handleAdd,
          handleSuccess,
          handleError,
        }: Record<string, any>) =>
          dataItems && showAdd ? (
            <DegreeAddRow
              artistId={customData.artistId}
              onCancel={handleAdd(false)}
              onSuccess={handleSuccess(handleAdd(false))}
              onError={handleError}
            />
          ) : null
        }
        editRenderer={({ handleSuccess, handleError }: Record<string, any>) =>
          ({ data, switchToDisplayMode }: Record<string, any>) =>
            (
              <DegreeEditRow
                data={data}
                onSuccess={handleSuccess(switchToDisplayMode)}
                onError={handleError}
                onCancel={switchToDisplayMode}
              />
            )}
        {...adminRelatedProps}
      />
      {/* Collection */}
      <AdvancedTable
        data={
          listCollectionsData &&
          listCollectionsData.listArtistCollections &&
          listCollectionsData.listArtistCollections.items
        }
        loading={loadingCollections}
        deleteLoading={deleteCollectionLoading}
        deleteHandler={deleteCollectionMutation}
        refetchListHandler={refetchListCollectionsQuery}
        customData={{ artistId }}
        scrollToTop={scrollToRef}
        title={formatMessage({ id: 'components.cv.collection.title' })}
        mainActionLabel={formatMessage({ id: 'components.cv.collection.add' })}
        schema={ARTIST_COLLECTION_SCHEMA({ canAdmin })}
        layoutPaperPadding={LAYOUT_PAPER_PADDING}
        columns={[
          {
            title: formatMessage({ id: 'components.tables.columns.collection.collection' }),
            width: '40%',
            sx: { pl: LAYOUT_PAPER_PADDING.pl },
          },
          {
            title: formatMessage({ id: 'components.tables.columns.collection.city' }),
            width: '20%',
          },
          {
            title: formatMessage({ id: 'components.tables.columns.collection.country' }),
            width: '20%',
          },
          // @ts-ignore
          ...actionColumnTable,
        ]}
        firstRow={({
          customData,
          dataItems,
          showAdd,
          handleAdd,
          handleSuccess,
          handleError,
        }: Record<string, any>) =>
          dataItems && showAdd ? (
            <CollectionAddRow
              artistId={customData.artistId}
              onCancel={handleAdd(false)}
              onSuccess={handleSuccess(handleAdd(false))}
              onError={handleError}
            />
          ) : null
        }
        editRenderer={({ handleSuccess, handleError }: Record<string, any>) =>
          ({ data, switchToDisplayMode }: Record<string, any>) =>
            (
              <CollectionEditRow
                data={data}
                onSuccess={handleSuccess(switchToDisplayMode)}
                onError={handleError}
                onCancel={switchToDisplayMode}
              />
            )}
        {...adminRelatedProps}
      />
      {/* Exhibition */}
      <AdvancedTable
        data={
          listExhibitionsData &&
          listExhibitionsData.filterArtistExhibitions &&
          listExhibitionsData.filterArtistExhibitions.items
        }
        loading={loadingExhibitions}
        deleteLoading={deleteExhibitionLoading}
        deleteHandler={deleteExhibitionMutation}
        refetchListHandler={refetchListExhibitionsQuery}
        customData={{ artistId }}
        scrollToTop={scrollToRef}
        title={formatMessage({ id: 'components.cv.exhibition.title' })}
        mainActionLabel={formatMessage({ id: 'components.cv.exhibition.add' })}
        schema={ARTIST_EXHIBITION_SCHEMA({ canAdmin })}
        layoutPaperPadding={LAYOUT_PAPER_PADDING}
        columns={[
          {
            title: formatMessage({ id: 'components.tables.columns.exhibition.title' }),
            width: '30%',
            sx: { pl: LAYOUT_PAPER_PADDING.pl },
          },
          {
            title: formatMessage({ id: 'components.tables.columns.exhibition.type' }),
            width: '15%',
          },
          {
            title: formatMessage({ id: 'components.tables.columns.exhibition.venue' }),
            width: '25%',
          },
          {
            title: formatMessage({ id: 'components.tables.columns.exhibition.year' }),
            width: '10%',
          },
          // @ts-ignore
          ...actionColumnTable,
        ]}
        firstRow={({
          customData,
          showAdd,
          handleAdd,
          handleSuccess,
          handleError,
        }: Record<string, any>) =>
          showAdd ? (
            <ExhibitionAddRow
              artistId={customData.artistId}
              onCancel={handleAdd(false)}
              onSuccess={handleSuccess(handleAdd(false))}
              onError={handleError}
            />
          ) : null
        }
        editRenderer={({ handleSuccess, handleError }: Record<string, any>) =>
          ({ data, switchToDisplayMode }: Record<string, any>) =>
            (
              <ExhibitionEditRow
                data={data}
                onSuccess={handleSuccess(switchToDisplayMode)}
                onError={handleError}
                onCancel={switchToDisplayMode}
              />
            )}
        {...adminRelatedProps}
      />
    </>
  ) : null;
}
