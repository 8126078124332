import { GridColDef } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';

import styles from './schemas/schemas.module.css';

export const DEFAULT_COLUMN_PROPS = {
  minWidth: 100,
  headerClassName: styles.gridHeader,
};

export const PICK_COLUMN_PROPS: Record<string, any> = {
  ...DEFAULT_COLUMN_PROPS,
  headerClassName: styles.gridHeader,
  headerAlign: 'center',
  align: 'center',
  sortable: false,
  filterable: false,
};

/**
 * dataPickerActionColumn
 *
 * @param {(options: Record<string, any>) => string} formatMessage
 * @returns {GridColDef}
 */
export function dataPickerActionColumn(
  formatMessage: (options: Record<string, any>) => string,
): GridColDef {
  return {
    ...PICK_COLUMN_PROPS,
    field: 'customData',
    headerName: formatMessage({ id: 'components.tables.columns.common.pick' }),
    renderCell: ({ row: { customData, rowData: data } }: Record<string, any>): JSX.Element => {
      const {
        picker: { selectedItems, handleSelect },
      } = customData;

      return (
        <IconButton
          {...(selectedItems &&
          selectedItems.some((item: Record<string, any>) => item.id === data.id)
            ? {
                sx: {
                  color: '#fff',
                  bgcolor: 'primary.main',
                  '&:hover': { bgcolor: 'primary.main' },
                },
              }
            : null)}
          onClick={() => handleSelect(data)}
        >
          <CheckIcon />
        </IconButton>
      );
    },
  };
}
