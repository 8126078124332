import { gql } from '@apollo/client';
import { ARTIST_FEED_FRAGMENT } from '../../fragments/artist/feed.graphql';

export const READ_ARTIST_FEED = gql`
  ${ARTIST_FEED_FRAGMENT}
  query readArtistFeed($id: ID!, $artistId: Int!) {
    readArtistFeed(id: $id, artistId: $artistId) {
      ...ArtistFeedFragment
    }
  }
`;
