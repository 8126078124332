import { gql } from '@apollo/client';
import { ART_MEDIA_FRAGMENT } from '../../fragments/art/media.graphql';

export const ADD_ART_MEDIA = gql`
  ${ART_MEDIA_FRAGMENT}
  mutation addArtMedia($artId: Int!, $inputArtMedia: InputMedia!) {
    addArtMedia(artId: $artId, inputArtMedia: $inputArtMedia) {
      ...ArtMediaFragment
    }
  }
`;
