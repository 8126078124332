import { useEffect, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { errors as ERRORS_ENUM } from '../../../config/enumeration.js';
import { DefaultLayout } from '../../layout/Default';
import { errorAdd } from '../../../services/redux/actions/errors/errors';
import { ErrorPage } from './Error';
import { ErrorService } from '../../../services/riseart/errors/ErrorService';

type Props = {
  location: Record<string, any>;
};

/**
 * NotFoundPage
 *
 * @param {Props} props
 * @returns {ReactElement<any>}
 */
export const NotFoundPage = ({ location }: Props): ReactElement<any> => {
  const ERROR_404_TYPE = 'PageNotFound';
  const intl = useIntl();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      errorAdd(
        ErrorService.mapJSError(new Error('Page Not Found'), {
          type: ERROR_404_TYPE,
          handler: ERRORS_ENUM.handlers.SILENT,
          additional: { location },
        }),
      ),
    );
  }, []);

  return (
    <DefaultLayout>
      <ErrorPage
        error={ErrorService.mapJSError(new Error(), {
          type: ERROR_404_TYPE,
          title: 'errors.pageNotFound.title',
          detail: intl.formatMessage(
            { id: `errors.pageNotFound.htmlDetails` },
            { notFoundUrl: location.pathname },
          ),
        })}
        translate
      />
    </DefaultLayout>
  );
};
