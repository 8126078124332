import { useMemo } from 'react';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import { LAYOUT_PAPER_PADDING, DataTable } from '@riseart/dashboard';
import { artist as ARTIST_ENUM } from '../../../config/enumeration.js';
import { DataListFeed } from '../../data/ListFeed';
import { ArtistFeedListSelect } from '../../artist/FeedListSelect';
import { ARTIST_FEED_SCHEMA } from '../../data/tables/schemas/artist';
import { LIST_ARTIST_FEED } from '../../../data/gql/queries/artist/listFeed.graphql';
import { DELETE_ARTIST_FEED } from '../../../data/gql/queries/artist/deleteFeed.graphql';

type Props = {
  artist: Record<string, any>;
  canAdmin?: boolean;
  addLink: string;
  pageUrlAssembler: (options: Record<string, any>) => string;
  entityUrlAssembler: (id: number, options?: Record<string, any>) => string;
};

/**
 * ArtistFeedList
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const ArtistFeedList = ({
  artist,
  canAdmin = false,
  addLink,
  pageUrlAssembler,
  entityUrlAssembler,
}: Props): JSX.Element => {
  const ITEMS_PER_PAGE = 10;
  const history = useHistory();
  const { formatMessage } = useIntl();
  const FEED_TYPES = Object.keys(ARTIST_ENUM.feed.media.type);
  const AVAILABLE_FEED_TYPES = useMemo(
    // @ts-ignore
    () => FEED_TYPES.map((key: string) => ARTIST_ENUM.feed.media.type[key]).join(','),
    // @ts-ignore
    FEED_TYPES,
  );
  const addLabel = formatMessage({ id: 'components.feed.addPost' });
  const pageTitle = formatMessage({ id: 'components.mainMenu.artistFeedList' });

  // Queries and Mutations
  const [deleteFeed, { loading: deleteLoading }] = useMutation(DELETE_ARTIST_FEED);

  return (
    <Paper sx={{ position: 'relative' }}>
      {artist && artist.id ? (
        <DataListFeed
          query={LIST_ARTIST_FEED}
          fetchPolicy="cache-and-network"
          variablesMapper={({
            page = 1,
            itemsPerPage,
            show,
          }: Record<string, any>): Record<string, any> => ({
            artistId: artist.id,
            page,
            items: itemsPerPage || 10,
            filters: { mediaType: show || AVAILABLE_FEED_TYPES },
          })}
          buildUrl={({
            page,
            itemsPerPage = ITEMS_PER_PAGE,
            show,
            qsParams,
          }: Record<string, any>) => {
            const search = queryString.stringify({
              ...qsParams,
              ...(show ? { show } : null),
              ...(page && page > 1 ? { page } : null),
              ...(itemsPerPage && itemsPerPage !== ITEMS_PER_PAGE ? { itemsPerPage } : null),
            });
            return pageUrlAssembler({
              search: search ? `?${search}` : '',
            });
          }}
        >
          {({
            loading,
            refetchListQuery,
            items,
            pagination,
            initialValues,
            handleParamsChange,
          }: Record<string, any>) => {
            return (
              <DataTable
                columns={[
                  { title: '', sx: { pl: LAYOUT_PAPER_PADDING.pl }, width: '10%' },
                  {
                    title: formatMessage({ id: 'components.tables.columns.feed.title' }),
                    width: '35%',
                  },
                  {
                    title: formatMessage({ id: 'components.tables.columns.feed.publishedDate' }),
                    width: '25%',
                  },
                  // @ts-ignore
                  ...(canAdmin
                    ? [
                        {
                          title: '',
                          align: 'right',
                          width: '30%',
                          sx: { display: { xs: 'none', sm: 'table-cell' } },
                        },
                      ]
                    : []),
                ]}
                schema={ARTIST_FEED_SCHEMA({ canAdmin })}
                layoutPaperPadding={LAYOUT_PAPER_PADDING}
                data={items}
                customData={{
                  history,
                  refetchListQuery,
                  deleteFeed,
                  deleteLoading,
                  entityUrlAssembler,
                }}
                refetchListQuery={refetchListQuery}
                pagination={pagination}
                noData={formatMessage({ id: 'common.noItems' })}
                loading={loading}
                {...(canAdmin
                  ? {
                      mainAction: (
                        <Box
                          sx={{
                            display: { xs: 'none', sm: 'block' },
                            flexGrow: 1,
                            pr: 1,
                            textAlign: 'right',
                          }}
                        >
                          <Button
                            variant="contained"
                            size="large"
                            component={RouterLink}
                            to={addLink}
                            title={addLabel}
                          >
                            {addLabel}
                          </Button>
                        </Box>
                      ),
                    }
                  : null)}
                title={pageTitle}
                secondaryAction={
                  <ArtistFeedListSelect
                    initialValue={initialValues.show}
                    onChange={(show: string) => handleParamsChange({ show })}
                  />
                }
              />
            );
          }}
        </DataListFeed>
      ) : null}
      {canAdmin ? (
        <Fab
          color="primary"
          component={RouterLink}
          to={addLink}
          title={addLabel}
          sx={{
            position: { xs: 'fixed', lg: 'absolute' },
            top: { xs: 'auto', lg: '135px' },
            left: { xs: 'auto', lg: '-28px' },
            bottom: { xs: '25px', lg: 'auto' },
            right: { xs: '25px', lg: 'auto' },
            fontSize: '60px',
          }}
          size="large"
        >
          <AddIcon />
        </Fab>
      ) : null}
    </Paper>
  );
};
