import { gql } from '@apollo/client';
import { SELLER_FRAGMENT } from '../../fragments/seller/seller.graphql';

export const UPDATE_SELLER = gql`
  ${SELLER_FRAGMENT}
  mutation updateSeller($id: Int!, $inputSeller: InputSeller!) {
    updateSeller(id: $id, inputSeller: $inputSeller) {
      ...SellerFragment
    }
  }
`;
