import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker as DatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { FormViewField } from '@riseart/dashboard';

/**
 * datePickerInput
 *
 * @param {Record<string, any>} field
 * @param {Record<string, any>} customData
 * @param {Record<string, any>} formState
 * @param {string | JSX.Element} label
 * @param {(options: Record<string, any>) => any} formatMessage
 * @param {(options: Record<string, any>) => any} register
 * @returns {JSX.Element | null}
 */
export function datePickerInput(
  field: Record<string, any>,
  customData: Record<string, any>,
  formState: Record<string, any>,
  label: string | JSX.Element,
  formatMessage: (options: Record<string, any>) => any,
  register: (options: Record<string, any>) => any,
): JSX.Element | null {
  const { inputFormat = 'dd/MM/yyyy' } = field.inputFieldData || {};
  const { value, onChange } = register(field);
  const { [field.name]: fieldError } = (formState && formState.errors) || {};
  const hasErrors = !!(fieldError && fieldError.length);
  const isRequired =
    field &&
    field.rules &&
    field.rules.some(({ required }: Record<string, any>) => required === true);

  return (
    <FormViewField
      key={field.name}
      label={label}
      error={hasErrors && fieldError.join(' ')}
      required={isRequired}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          inputFormat={inputFormat}
          value={value}
          onChange={(value: string | null) => onChange({ target: { value } })}
          renderInput={(params: Record<string, any>) => (
            <TextField variant="standard" {...params} error={hasErrors} />
          )}
        />
      </LocalizationProvider>
    </FormViewField>
  );
}

/**
 * dateRangePickerInput
 *
 * @param {Record<string, any>} field
 * @param {Record<string, any>} customData
 * @param {Record<string, any>} formState
 * @param {string | JSX.Element} label
 * @param {(options: Record<string, any>) => any} formatMessage
 * @param {(options: Record<string, any>) => any} register
 * @returns {JSX.Element | null}
 */
export function dateRangePickerInput(
  field: Record<string, any>,
  customData: Record<string, any>,
  formState: Record<string, any>,
  label: string | JSX.Element,
  formatMessage: (options: Record<string, any>) => any,
  register: (options: Record<string, any>) => any,
): JSX.Element | null {
  const { inputFormat = 'dd/MM/yyyy', start, end } = field.inputFieldData || {};
  const { value: dateDurationValue, onChange: onChange } = register(field);
  const [startValue, endValue] = dateDurationValue;
  const { [start.name]: startFieldError, [end.name]: endFieldError } =
    (formState && formState.errors) || {};
  const hasStartErrors = !!(startFieldError && startFieldError.length);
  const hasEndErrors = !!(endFieldError && endFieldError.length);
  const isStartRequired =
    start &&
    start.rules &&
    start.rules.some(({ required }: Record<string, any>) => required === true);
  const isEndRequired =
    end && end.rules && end.rules.some(({ required }: Record<string, any>) => required === true);

  return (
    <Box display="flex" maxWidth="380px" key={field.name}>
      <FormViewField
        key={start.name}
        label={formatMessage({ id: start.text })}
        error={hasStartErrors && startFieldError.join(' ')}
        required={isStartRequired}
        sx={{ width: 'auto' }}
        helperText={start.hint && formatMessage({ id: start.hint })}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            inputFormat={inputFormat}
            value={startValue}
            onChange={(value: string | null) => onChange({ target: { value: [value, endValue] } })}
            renderInput={(params: Record<string, any>) => (
              <TextField variant="standard" {...params} error={hasStartErrors} />
            )}
          />
        </LocalizationProvider>
      </FormViewField>
      <Box component="span" sx={{ display: 'inline-block', pt: '43px', paddingX: 2 }}>
        {formatMessage({ id: 'common.to' })}
      </Box>
      <FormViewField
        key={end.name}
        label={formatMessage({ id: end.text })}
        error={hasEndErrors && endFieldError.join(' ')}
        required={isEndRequired}
        sx={{ width: 'auto' }}
        helperText={end.hint && formatMessage({ id: end.hint })}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            inputFormat={inputFormat}
            value={endValue}
            onChange={(value: string | null) =>
              onChange({ target: { value: [startValue, value] } })
            }
            renderInput={(params: Record<string, any>) => (
              <TextField variant="standard" {...params} error={hasEndErrors} />
            )}
          />
        </LocalizationProvider>
      </FormViewField>
    </Box>
  );
}
