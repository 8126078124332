import { FormattedMessage } from 'react-intl';
import { Link, DataTableColumnsType } from '@riseart/dashboard';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { ArtListActionButton } from '../../../art/ListActionButton';
import { Picture } from '../../../common/artdirection/Picture';
import { extractImageFileFromData } from '../../../../services/riseart/utils/Image';
import { SchemaType } from './types';

const FIELD = {
  IMAGE: (showLink: boolean): Record<string, any> => ({
    key: 'image',
    render: ({ data }: Record<string, any>): JSX.Element => {
      const mainImage = extractImageFileFromData.artMaster(data.images, 'MAIN_SQUARE');

      return mainImage ? (
        (showLink && (
          <Link to={UrlAssembler.artDetail(data.id)} title={data.title}>
            <Picture
              type="table.list"
              artDirectionKey="art.main"
              image={mainImage}
              alt={data.title}
            />
          </Link>
        )) ||
          data.title
      ) : (
        <></>
      );
    },
  }),
  TITLE: (showLink: boolean): Record<string, any> => ({
    key: 'title',
    render: ({ data }: Record<string, any>) =>
      (showLink && (
        <Link to={UrlAssembler.artDetail(data.id)} title={data.title}>
          {data.title}
        </Link>
      )) ||
      data.title,
  }),
  ARTIST: {
    key: 'artist',
    render: ({ data }: Record<string, any>) => (
      <Link to={UrlAssembler.artistDetails(data.artistId)} title={data.artistName}>
        {data.artistName}
      </Link>
    ),
  },
  MEDIUM: {
    key: 'medium',
    render: ({ data: { medium, subMedium } }: Record<string, any>) => (
      <>
        {medium ? <FormattedMessage id={`components.art.dimensions.medium.${medium}`} /> : null}
        {subMedium ? (
          <>
            {medium ? <> &gt; </> : null}
            <FormattedMessage id={`components.art.dimensions.subMedium.${subMedium}`} />
          </>
        ) : null}
      </>
    ),
  },
  SUBJECT: {
    key: 'subject',
    render: ({ data: { subject, subSubject } }: Record<string, any>) => (
      <>
        {subject ? <FormattedMessage id={`components.art.dimensions.subject.${subject}`} /> : null}
        {subSubject ? (
          <>
            {subject ? <> &gt; </> : null}
            <FormattedMessage id={`components.art.dimensions.subSubject.${subSubject}`} />
          </>
        ) : null}
      </>
    ),
  },
  STYLE: {
    key: 'style',
    // @ts-ignore
    render: ({ data: { style } }: Record<string, any>) =>
      style ? <FormattedMessage id={`components.art.dimensions.style.${style}`} /> : null,
  },
  STATUS: {
    key: 'status',
    render: ({ data }: Record<string, any>) => (
      <FormattedMessage id={`components.art.product.state.${data.state}`} />
    ),
  },
  PRICE: { key: 'formattedPrice' },
  STOCK: { key: 'stock' },
  ACTION: {
    key: 'actions',
    render: ({ data, refetchListQuery }: Record<string, any>): JSX.Element => {
      return <ArtListActionButton data={data} refetchQuery={refetchListQuery} />;
    },
  },
};

export const ARTLIST_TABLE_SCHEMA_BASIC = (showLink: boolean): SchemaType => [
  FIELD.IMAGE(showLink),
  FIELD.TITLE(showLink),
  FIELD.MEDIUM,
  FIELD.SUBJECT,
  FIELD.STYLE,
  FIELD.STATUS,
  FIELD.PRICE,
  FIELD.STOCK,
];

export const ARTLIST_TABLE_SCHEMA_ADVANCED = (showAction: boolean): SchemaType => [
  ...ARTLIST_TABLE_SCHEMA_BASIC(showAction),
  ...(showAction ? [FIELD.ACTION] : []),
];

export const ARTLIST_PARTNER_TABLE_SCHEMA = (showAction: boolean): SchemaType => [
  FIELD.IMAGE(showAction),
  FIELD.TITLE(showAction),
  FIELD.ARTIST,
  FIELD.MEDIUM,
  FIELD.STATUS,
  FIELD.PRICE,
  FIELD.STOCK,
  ...(showAction ? [FIELD.ACTION] : []),
];

export const ARTLIST_TABLE_COLUMNS = (
  formatMessage: (options: Record<string, any>) => string,
): DataTableColumnsType => [
  {
    title: formatMessage({
      id: 'components.tables.columns.art.title',
    }),
  },
  {
    title: formatMessage({
      id: 'components.tables.columns.art.medium',
    }),
  },
  {
    title: formatMessage({
      id: 'components.tables.columns.art.subject',
    }),
    sx: { display: { xs: 'none', sm: 'table-cell' } },
  },
  {
    title: formatMessage({
      id: 'components.tables.columns.art.style',
    }),
    sx: { display: { xs: 'none', sm: 'table-cell' } },
  },
  {
    title: formatMessage({
      id: 'components.tables.columns.art.status',
    }),
  },
  {
    title: formatMessage({
      id: 'components.tables.columns.art.price',
    }),
    align: 'right',
  },
  {
    title: formatMessage({
      id: 'components.tables.columns.art.stock',
    }),
    align: 'right',
  },
];

export const ARTLIST_PARTNER_TABLE_COLUMNS = (
  formatMessage: (options: Record<string, any>) => string,
): DataTableColumnsType => [
  {
    title: formatMessage({
      id: 'components.tables.columns.art.title',
    }),
  },
  {
    title: formatMessage({
      id: 'components.tables.columns.art.artist',
    }),
  },
  {
    title: formatMessage({
      id: 'components.tables.columns.art.medium',
    }),
  },
  {
    title: formatMessage({
      id: 'components.tables.columns.art.status',
    }),
  },
  {
    title: formatMessage({
      id: 'components.tables.columns.art.price',
    }),
    align: 'right',
  },
  {
    title: formatMessage({
      id: 'components.tables.columns.art.stock',
    }),
    align: 'right',
  },
];
