import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';
import { Link } from '@riseart/dashboard';
import { useScrollToEl } from '../../../../data/hooks/useScrollToEl';
import { selectSeller } from '../../../../services/redux/selectors/seller';
import { selectPartner } from '../../../../services/redux/selectors/partner';
import { DefaultLayout } from '../../../layout/Default';
import { PageSubmenu } from '../../../common/PageSubmenu';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { GET_PARTNER_ARTIST } from '../../../../data/gql/queries/partner/getArtist.graphql';
import { ArtEdit } from '../../../art/Edit';

/**
 * ArtistArtEditPage
 *
 * @returns {JSX.Element}
 */
export const ArtistArtEditPage = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const [artData, setArtData] = useState<Record<string, any> | null>(null);
  const [loading, setLoading] = useState(false);
  const { setScrollRef, scrollToRef } = useScrollToEl();
  const {
    id: sellerId,
    currencyCode,
    storeCode,
    shipsCountryCode,
    canRentArt,
  } = useSelector(selectSeller) || {};
  const { artistId, id: artId }: Record<string, any> = useParams();
  const partner = useSelector(selectPartner) || {};
  const { data: listArtist, loading: listLoading } = useQuery(GET_PARTNER_ARTIST, {
    fetchPolicy: 'network-only',
    variables: {
      partnerId: partner && partner.id,
      filters: { artistId: { value: artistId, operator: 'EQUAL' } },
    },
  });
  const partnerArtist =
    listArtist && listArtist.artist && listArtist.artist.items && listArtist.artist.items[0];
  const pageTitle = formatMessage({ id: 'components.mainMenu.artList' });
  const listLabel = formatMessage({ id: 'components.artist.tab.artworks' });

  return (
    <DefaultLayout
      loading={loading || listLoading}
      title={pageTitle}
      breadcrumbs={[
        <Link
          key="home"
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        ...(partnerArtist && partnerArtist.artist
          ? [
              <Link
                key="profile"
                to={UrlAssembler.artistDetails(artistId)}
                title={partnerArtist.artist.name}
                underline="hover"
                color="inherit"
              >
                {partnerArtist.artist.name}
              </Link>,
              <Link
                key="artList"
                to={UrlAssembler.artistArtList(artistId)}
                title={listLabel}
                underline="hover"
                color="inherit"
              >
                {listLabel}
              </Link>,
              ...(artData && artData.title
                ? [
                    <Typography key="art" variant="inherit">
                      {artData.title}
                    </Typography>,
                  ]
                : []),
            ]
          : []),
      ]}
      boxRefCallback={setScrollRef}
    >
      <PageSubmenu menu="artist" selected="artistArtList" urlParams={{ id: artistId }} />
      <ArtEdit
        artId={artId}
        sellerId={sellerId}
        canRentArt={canRentArt}
        currencyCode={currencyCode}
        storeCode={storeCode}
        shipsCountryCode={shipsCountryCode}
        scrollToRef={scrollToRef}
        artListAssembler={(options: Record<string, any> | null): string =>
          UrlAssembler.artistArtList(artistId, options)
        }
        onLoading={setLoading}
        onData={setArtData}
      />
    </DefaultLayout>
  );
};
