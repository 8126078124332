import { FormattedMessage } from 'react-intl';

/**
 * fileListConfirmationProps
 *
 * @param {Record<string, any>}
 * @returns {Record<string, any>}
 */
export function fileListConfirmationProps({ name }: Record<string, any>): Record<string, any> {
  return {
    title: <FormattedMessage id="components.dialog.deleteFile.title" />,
    description: (
      <FormattedMessage
        id="components.dialog.deleteFile.description"
        values={{ filename: <strong>{name}</strong> }}
      />
    ),
    disagreeLabel: <FormattedMessage id="common.no" />,
    agreeLabel: <FormattedMessage id="common.yes" />,
  };
}
