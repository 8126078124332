import { gql } from '@apollo/client';
import { EVENT_FRAGMENT } from '../../fragments/event/event.graphql';

export const READ_EVENT = gql`
  ${EVENT_FRAGMENT}
  query readEvent($eventId: Int!) {
    readEvent(eventId: $eventId) {
      ...EventFragment
    }
  }
`;
