import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import {
  Title,
  ConfirmationDialog,
  Link as CommonLink,
  FormViewField,
  FormBuilder,
  FormHeader,
  FormWrapper,
  LayoutPaper,
} from '@riseart/dashboard';
import { errors as ERRORS_ENUM } from '../../../../config/enumeration.js';
import { useNotification } from '../../../../data/hooks/useNotification';
import { useScrollToEl } from '../../../../data/hooks/useScrollToEl';
import { DefaultLayout } from '../../../layout/Default';
import { selectSeller } from '../../../../services/redux/selectors/seller';
import { EditSellerTableFormModel } from '../../../forms/models/sellerTable';
import { READ_SELLER_SHIPPING_TABLE } from '../../../../data/gql/queries/seller/readShippingTable.graphql';
import { UPDATE_SELLER_SHIPPING_TABLE } from '../../../../data/gql/queries/seller/updateShippingTable.graphql';
import { LIST_SELLER_SHIPPING_TABLES } from '../../../../data/gql/queries/seller/listShippingTables.graphql';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { mapApiValidationErrors, mapApiErrors } from '../../../../services/riseart/utils/Form';
import { ShippingRates } from './Rates';

/**
 * SellerShippingEditPage
 *
 * @returns {JSX.Element}
 */
export const SellerShippingEditPage = (): JSX.Element | null => {
  const { setScrollRef, scrollToRef } = useScrollToEl();
  const { formatMessage } = useIntl();
  const { dispatchNotification } = useNotification();
  const [editMode, setEditMode] = useState<boolean>(false);
  const { id: shippingTableId }: Record<string, any> = useParams();
  const { id: sellerId, canUseShippingTables } = useSelector(selectSeller) || {};

  if (!canUseShippingTables) {
    dispatchNotification({
      detail: 'components.notifications.noAccess',
      level: ERRORS_ENUM.levels.ERROR,
      placeholder: ERRORS_ENUM.placeholders.APP,
      handler: ERRORS_ENUM.handlers.ERROR_PAGE,
    });

    return null;
  }

  const [updateShippingTable, { loading: updateLoading }] = useMutation(
    UPDATE_SELLER_SHIPPING_TABLE,
    {
      refetchQueries: [LIST_SELLER_SHIPPING_TABLES],
    },
  );
  const { data: shippingTablesList, loading: shippingTablesListLoading } = useQuery(
    LIST_SELLER_SHIPPING_TABLES,
    { variables: { sellerId, items: 100 } },
  );
  const {
    data: readData,
    loading: readLoading,
    refetch: refetchReadSellertable,
  } = useQuery(READ_SELLER_SHIPPING_TABLE, {
    fetchPolicy: 'network-only',
    variables: {
      id: shippingTableId,
      sellerId,
    },
  });
  const tableData = (readData && readData.readSellerShippingTable) || null;
  const pageTitle = formatMessage({ id: 'components.mainMenu.sellerShippingList' });

  return (
    <DefaultLayout
      includesFab
      title={formatMessage({ id: 'components.mainMenu.seller' })}
      loading={shippingTablesListLoading || readLoading || updateLoading}
      breadcrumbs={[
        <Link
          key="home"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link
          key="sellerProfile"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('sellerProfile')}
          title={formatMessage({ id: 'components.mainMenu.seller' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'components.mainMenu.seller' })}
        </Link>,
        <Link
          key="sellerShippingList"
          component={RouterLink}
          to={UrlAssembler.shippingList()}
          title={pageTitle}
          underline="hover"
          color="inherit"
        >
          {pageTitle}
        </Link>,

        ...(tableData && tableData.name
          ? [
              <Typography key="shippingTable" variant="inherit">
                {tableData.name}
              </Typography>,
            ]
          : []),
      ]}
      boxRefCallback={setScrollRef}
    >
      {tableData ? (
        <>
          <LayoutPaper>
            <FormHeader title={<Title variant="h5">{tableData.name}</Title>}>
              {editMode ? null : (
                <Stack spacing={2} direction="row">
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => {
                      setEditMode(!editMode);
                    }}
                  >
                    {formatMessage({
                      id: `components.seller.${editMode ? 'viewTable' : 'editTable'}`,
                    })}
                  </Button>
                  {!tableData.default ? (
                    <ConfirmationDialog
                      title={formatMessage({ id: 'components.dialog.seller.action.title' })}
                      description={formatMessage({
                        id: 'components.dialog.seller.action.setAsDefault',
                      })}
                      disagreeLabel={formatMessage({ id: 'common.no' })}
                      agreeLabel={formatMessage({ id: 'common.yes' })}
                      onAgree={() => {
                        updateShippingTable({
                          variables: {
                            id: tableData.id,
                            sellerId,
                            inputShippingTable: { default: true },
                          },
                        })
                          .then(() => {
                            refetchReadSellertable && refetchReadSellertable();
                          })
                          .catch(() => null);
                      }}
                    >
                      {(handleOpen) => (
                        <Button variant="outlined" size="large" onClick={handleOpen}>
                          {formatMessage({ id: 'components.seller.setAsDefault' })}
                        </Button>
                      )}
                    </ConfirmationDialog>
                  ) : null}
                </Stack>
              )}
            </FormHeader>

            <FormWrapper size="medium">
              <FormViewField
                label={formatMessage({ id: 'forms.sellerTable.label.isDefault' })}
                helperText={
                  editMode ? formatMessage({ id: 'forms.sellerTable.hints.isDefault' }) : null
                }
                sx={{ mt: 0 }}
              >
                {formatMessage({ id: `common.${tableData.default ? 'yes' : 'no'}` })}
              </FormViewField>

              {editMode ? (
                <FormBuilder
                  settings={EditSellerTableFormModel.settings}
                  fields={EditSellerTableFormModel.fields}
                  initialValues={{
                    name: tableData.name,
                    parentId: tableData.parentId,
                    default: tableData.default,
                  }}
                  customData={{
                    tableData,
                    shippingTables:
                      shippingTablesList &&
                      shippingTablesList.listSellerShippingTables &&
                      shippingTablesList.listSellerShippingTables.items.filter(
                        ({ id }: { id: string }) => id !== tableData.id,
                      ),
                  }}
                  cancel={
                    <Button
                      type="button"
                      variant="outlined"
                      size="large"
                      fullWidth
                      onClick={() => {
                        setEditMode(!editMode);
                      }}
                    >
                      {formatMessage({ id: 'common.cancel' })}
                    </Button>
                  }
                  submitText={formatMessage({ id: 'common.saveChanges' })}
                  onSubmit={async (formState, addApiErrors) => {
                    updateShippingTable({
                      variables: {
                        id: tableData.id,
                        sellerId,
                        inputShippingTable: {
                          parentId: formState.data.parentId || null,
                          name: formState.data.name,
                        },
                      },
                    })
                      .then(() => {
                        refetchReadSellertable().catch(() => null);

                        // Remove form and show details view of table
                        setEditMode(false);

                        // Scroll to container
                        scrollToRef();

                        // Show update success notification
                        dispatchNotification({
                          detail: 'components.notifications.sellerTableUpdated',
                        });
                      })
                      .catch((error) => {
                        // Map validation errors from response
                        const validationError = mapApiValidationErrors(error);

                        if (validationError && validationError.length && addApiErrors) {
                          addApiErrors(validationError);
                        } else {
                          // Map any other type of errors from response (validation without provided fields, or non-validation errors)
                          mapApiErrors(error).forEach((err) => {
                            dispatchNotification({
                              detail: err,
                              level: ERRORS_ENUM.levels.ERROR,
                              placeholder: ERRORS_ENUM.placeholders.PAGE,
                            });
                          });
                        }

                        // Scroll to error
                        scrollToRef();
                      });
                  }}
                />
              ) : (
                <>
                  <FormViewField label={formatMessage({ id: 'forms.sellerTable.label.name' })}>
                    {tableData.name}
                  </FormViewField>

                  <FormViewField
                    label={formatMessage({ id: 'forms.sellerTable.label.parentTable' })}
                  >
                    {tableData.parentName ? (
                      <CommonLink
                        to={UrlAssembler.shippingDetail(tableData.parentId)}
                        title={tableData.parentName}
                      >
                        {tableData.parentName}
                      </CommonLink>
                    ) : (
                      formatMessage({ id: 'components.seller.noParent' })
                    )}
                  </FormViewField>
                </>
              )}
            </FormWrapper>
          </LayoutPaper>
          <ShippingRates
            tableId={tableData.id}
            storeCode={tableData.storeCode}
            scrollToTop={scrollToRef}
          />
        </>
      ) : null}
    </DefaultLayout>
  );
};
