import { gql } from '@apollo/client';
import { ARTIST_BIBLIOGRAPHY_FRAGMENT } from '../../fragments/artist/bibliography.graphql';

export const CREATE_ARTIST_BIBLIOGRAPHY = gql`
  ${ARTIST_BIBLIOGRAPHY_FRAGMENT}
  mutation createArtistBibliography(
    $artistId: Int!
    $inputArtistBibliography: InputArtistBibliography!
  ) {
    createArtistBibliography(
      artistId: $artistId
      inputArtistBibliography: $inputArtistBibliography
    ) {
      ...ArtistBibliographyFragment
    }
  }
`;
