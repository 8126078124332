import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import { useMutation } from '@apollo/client';
import { CircularLoader, FormBuilder, FormTable, FormTableCell } from '@riseart/dashboard';
import { ArtistBibliographyModel } from '../forms/models/artist/bibliography';
import { mapApiToFormData, mapFormToApiData } from '../../data/normalizers/common';
import { UPDATE_ARTIST_BIBLIOGRAPHY } from '../../data/gql/queries/artist/updateBibliography.graphql';
import { TABLE_IN_ROW_CELL_PROPS } from '../common/tables/settings';

type Props = {
  data: Record<string, any>;
  onCancel: () => any;
  onSuccess: (response: any) => any;
  onError?: (errors: any, addApiErrors: any) => any;
};

/**
 * BibliographyEditRow
 *
 * @param {Props} props
 * @returns
 */
export const BibliographyEditRow = ({ data, onCancel, onSuccess, onError }: Props): JSX.Element => {
  const [updateMutation, { loading: updateLoading }] = useMutation(UPDATE_ARTIST_BIBLIOGRAPHY);

  return (
    <TableCell colSpan={7} {...TABLE_IN_ROW_CELL_PROPS}>
      <CircularLoader active={updateLoading} style={{ minHeight: 'auto' }}>
        <FormBuilder
          inputLabelProps={{ shrink: true }}
          containerWrapper={FormTable}
          fieldWrapper={FormTableCell}
          fieldWrapperProps={[
            { width: '15%', sx: { pl: 4 } },
            { width: '15%' },
            { width: '15%' },
            { width: '15%' },
            { width: '10%' },
            { width: '10%' },
            { width: '20%' },
          ]}
          settings={ArtistBibliographyModel.settings}
          fields={ArtistBibliographyModel.fields}
          initialValues={{
            ...mapApiToFormData(data, ArtistBibliographyModel.fields),
          }}
          footer={({ hasErrors, disableSubmit }) => (
            <Stack direction="row" justifyContent="right" spacing={2}>
              <IconButton type="submit" size="large" disabled={hasErrors || disableSubmit}>
                <DoneIcon
                  color={hasErrors || disableSubmit ? 'disabled' : 'success'}
                  fontSize="large"
                />
              </IconButton>
              <IconButton size="large" onClick={onCancel}>
                <CloseIcon color="error" fontSize="large" />
              </IconButton>
            </Stack>
          )}
          onSubmit={async (formState, addApiErrors) => {
            updateMutation({
              variables: {
                id: data.id,
                artistId: data.artistId,
                inputArtistBibliography: mapFormToApiData(
                  formState.data,
                  ArtistBibliographyModel.fields,
                  true,
                ),
              },
            })
              .then(onSuccess)
              .catch(
                (errors: any) => typeof onError === 'function' && onError(errors, addApiErrors),
              );
          }}
        />
      </CircularLoader>
    </TableCell>
  );
};
