/**
 * ServerCookie: This function exports same class api as js-cookies so we
 * can use both inside a wrapper Cookies.js for both server and client
 *
 * @param { Record<string, any>} httpRequest
 * @param { Record<string, any>} httpResponse
 */
export function ServerCookie(
  httpRequest: Record<string, any>,
  httpResponse: Record<string, any>,
): Record<string, any> {
  return {
    /**
     * get
     *
     * @param {string} name
     */
    get(name: string) {
      return httpRequest.cookies[name];
    },

    /**
     * set
     *
     * @param {string} name
     * @param {*} value
     * @param { Record<string, any>} options
     * @param {boolean} replaceCookie
     */
    set(name: string, value: any, options: Record<string, any>, replaceCookie = false) {
      if (replaceCookie && typeof httpResponse.get('Set-Cookie') === 'object') {
        // Filters out already existing cookie in the response header
        // with same name, domain and path options as the ones to be set
        httpResponse.set(
          'Set-Cookie',
          httpResponse.get('Set-Cookie').filter((i: string) => {
            const foundCookieName = i.indexOf(`${name}=`) !== -1;

            return (
              !foundCookieName ||
              (foundCookieName &&
                i.indexOf(`Domain=${options.domain || httpRequest.hostname};`) === -1 &&
                i.indexOf(`Path=${options.path || '/'};`) === -1)
            );
          }),
        );
      }

      httpResponse.cookie(name, value, options);
    },

    /**
     * remove
     *
     * @param {string} name
     * @param { Record<string, any>} options
     */
    remove(name: string, options: Record<string, any>) {
      httpResponse.clearCookie(name, options);
    },
  };
}
