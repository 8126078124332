import queryString from 'query-string';
import { useEffect, useState, useCallback, FC, ReactNode, SyntheticEvent } from 'react';
import { useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Paragraph, Link as RouterLink, Logo, Title, FormBuilder } from '@riseart/dashboard';
import { application as CONFIG_APP, graphql as GQL_CONFIG } from '../../config/config.js';
import { auth as AUTH_ENUM, errors as ERRORS_ENUM } from '../../config/enumeration.js';
import { DefaultLayout } from '../layout/Default';
import { validateToken } from '../../services/riseart/utils/Auth';
import { CREATE_JWT } from '../../data/gql/queries/auth/createJwt.graphql';
import { CREATE_USER } from '../../data/gql/queries/auth/createUser.graphql';
import { GRANT_USER_SELLER_ACCESS } from '../../data/gql/queries/auth/grantUserSellerAccess.graphql';
import { SocialContainer } from '../auth/containers/social/Social';
import { LocalizedConfig } from '../../services/riseart/utils/LocalizedConfig';
import { selectAuth } from '../../services/redux/selectors/auth';
import { authTokenUpdated, authRegisterPropertyAdd } from '../../services/redux/actions/auth/auth';
import { RegisterFormModel } from '../forms/models/register';
import { LoginFormModel } from '../forms/models/login';
import { withForwardUrlACL } from '../../data/acl/withForwardUrlACL';
import { errorAdd } from '../../services/redux/actions/errors/errors';
import { ErrorService } from '../../services/riseart/errors/ErrorService';
import { asyncDelay } from '../../services/riseart/utils/Utils';

type Props = {
  redirectByForwardACL: boolean;
};

/**
 * Register
 *
 * @param {Props} props
 */
const Register: FC<any> = ({ redirectByForwardACL }: Props): JSX.Element => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [registerPageForm, setRegisterPageForm] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const { register: registerAuthState } = useSelector(selectAuth) || {};
  const { code }: Record<string, any> = queryString.parse(location.search) || {};
  const [createJwt, { loading: loadingJwt }] = useMutation(CREATE_JWT);
  const [exchangeJwt, { data: dataExchange, loading: loadingExchange, error: errorExchange }] =
    useMutation(CREATE_JWT);
  const [createUser, { loading }] = useMutation(CREATE_USER);
  const [grantUserSellerAccess, { loading: loadingGrantAccess }] =
    useMutation(GRANT_USER_SELLER_ACCESS);

  // this effect is called when there is an update in the state from the exchangeJwt mutation
  // meaning that after valid data is returned by the mutation, it will trigger action to notify
  // any middleware listeners that the seller token is updated
  useEffect(() => {
    if (dataExchange && !errorExchange) {
      const { token } = dataExchange.createJwt;

      // Validate token and pass its paayload to redux action
      validateToken(token).then((tokenPayload) => {
        dispatch(authTokenUpdated({ token, payload: tokenPayload, trigger: 'register' }));
        setPageLoading(false);
      });
    }
  }, [dataExchange, errorExchange, loadingExchange]);

  /**
   * grantAccessAndAuthenticateHandler
   *
   * @param {string} userToken
   * @param {number} userId
   * @returns {Promise<Record<string, any>>}
   */
  async function grantAccessAndAuthenticateHandler(
    userToken: string,
    userId: number,
  ): Promise<Record<string, any>> {
    // Grant seller's access
    await grantUserSellerAccess({
      context: { headers: { authorization: `Bearer ${userToken}` } },
      variables: { userId, code },
    });

    // Exchange seller's token
    return await exchangeJwt({
      variables: {
        inputJwt: {
          apiKey: GQL_CONFIG.authKey,
          authModule: AUTH_ENUM.modules.SELLER_EXCHANGE,
          currentToken: userToken,
        },
      },
    });
  }

  if (!code) {
    // Show error page if url does not have code parameter
    dispatch(
      errorAdd(
        ErrorService.mapNotification({
          handler: ERRORS_ENUM.handlers.ERROR_PAGE,
          level: ERRORS_ENUM.levels.ERROR,
          placeholder: ERRORS_ENUM.placeholders.PAGE,
          title: 'errors.invalidInvitation.title',
          detail: 'errors.invalidInvitation.details',
        }),
      ),
    );
  }

  /**
   * handleAuthFormToggleClick
   *
   * @param {SyntheticEvent} event
   * @returns {void}
   */
  const handleAuthFormToggleClick = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();

      setRegisterPageForm(!registerPageForm);
    },
    [registerPageForm],
  );

  /**
   * handleRegisterSubmit
   *
   * @param {{ ata: Record<string, any>; hasErrors: boolean; }} formState
   * @returns {Promise<void>}
   */
  const handleRegisterSubmit = async ({
    data,
    hasErrors,
  }: {
    data: Record<string, any>;
    hasErrors: boolean;
  }): Promise<void> => {
    if (!hasErrors && data.email && data.password) {
      try {
        setPageLoading(true);
        const currentRegisterState = registerAuthState || {};

        // Get visitor token
        const { data: visitorJwtResponse }: Record<string, any> = !currentRegisterState.visitorToken
          ? await createJwt({
              variables: {
                inputJwt: {
                  apiKey: GQL_CONFIG.authKeyFrontend,
                  authModule: AUTH_ENUM.modules.WEBSITE_VISITOR,
                },
              },
            })
          : {};

        const visitorToken =
          currentRegisterState.visitorToken ||
          (visitorJwtResponse &&
            visitorJwtResponse.createJwt &&
            visitorJwtResponse.createJwt.token) ||
          null;

        // Validate token and get token payload
        const tokenPayload = await validateToken(visitorToken, 'visitor');

        if (visitorToken && tokenPayload && !currentRegisterState.visitorToken) {
          dispatch(authRegisterPropertyAdd({ visitorToken }));

          // Delay
          await asyncDelay();
        }

        // Create user
        await createUser({
          context: { headers: { authorization: `Bearer ${visitorToken}` } },
          variables: {
            inputUser: {
              email: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
              subscribe: false,
            },
            inputAccount: {
              domain: CONFIG_APP.auth.register.domain,
              entityId: data.email,
              password: data.password,
            },
            inputVisitor: {
              id: tokenPayload.visitor_id,
            },
          },
        });

        await asyncDelay();

        // Get user token
        const { data: userJwtResponse }: Record<string, any> = await createJwt({
          variables: {
            inputJwt: {
              apiKey: GQL_CONFIG.authKeyFrontend,
              authModule: AUTH_ENUM.modules.WEBSITE_USER,
              username: data.email,
              password: data.password,
            },
          },
        });

        const userToken =
          (userJwtResponse && userJwtResponse.createJwt && userJwtResponse.createJwt.token) || null;

        // Validate token and get token payload
        const userTokenPayload = userToken && (await validateToken(userToken, 'user'));

        await asyncDelay();

        await grantAccessAndAuthenticateHandler(
          userToken,
          userTokenPayload && userTokenPayload.user_id,
        );
      } catch (Error: any) {
        setPageLoading(false);
      }
    }
  };

  /**
   * handleSigninSubmit
   *
   * @param {{ data: Record<string, any>; hasErrors: boolean; }} formState
   * @returns {Promise<void>}
   */
  const handleSigninSubmit = async ({
    data,
    hasErrors,
  }: {
    data: Record<string, any>;
    hasErrors: boolean;
  }): Promise<void> => {
    if (hasErrors || !data.email || !data.password) {
      return;
    }

    try {
      setPageLoading(true);

      // Get user token
      const { data: userJwtResponse }: Record<string, any> = await createJwt({
        variables: {
          inputJwt: {
            apiKey: GQL_CONFIG.authKeyFrontend,
            authModule: AUTH_ENUM.modules.WEBSITE_USER,
            username: data.email,
            password: data.password,
          },
        },
      });

      const userToken =
        (userJwtResponse && userJwtResponse.createJwt && userJwtResponse.createJwt.token) || null;

      // Validate token and get token payload
      const userTokenPayload = userToken && (await validateToken(userToken, 'user'));

      await asyncDelay();

      await grantAccessAndAuthenticateHandler(
        userToken,
        userTokenPayload && userTokenPayload.user_id,
      );
    } catch (Error: any) {
      setPageLoading(false);
    }
  };

  return (
    <DefaultLayout
      hideHeader
      loading={loading || loadingJwt || loadingGrantAccess || redirectByForwardACL || pageLoading}
    >
      {!redirectByForwardACL ? (
        <Box component="div" sx={{ mt: '90px', textAlign: 'center' }}>
          <Logo />
          <Title component="h2" variant="h4" sx={{ mt: '30px' }}>
            <FormattedMessage id="components.auth.loginHeading" />
          </Title>
          <SocialContainer
            createJwt={createJwt}
            grantAccessHandler={grantAccessAndAuthenticateHandler}
          />
          <Paragraph align="center">
            <FormattedMessage id="components.auth.registerParagraph" />
          </Paragraph>

          <Box component="div" sx={{ maxWidth: '400px', m: '0 auto' }}>
            {registerPageForm ? (
              // Register Form
              <FormBuilder
                key="register"
                {...RegisterFormModel}
                subFooterLinks={
                  <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Link href="" underline="hover" onClick={handleAuthFormToggleClick}>
                        <FormattedMessage id="components.auth.signin" />
                      </Link>
                    </Box>
                    <Box sx={{ textAlign: 'left', fontSize: '13px', mt: 2 }}>
                      <FormattedMessage
                        id="components.auth.confirmLegal"
                        values={{
                          actionLabel: <FormattedMessage id="components.auth.signup" />,
                          terms: (
                            <FormattedMessage id="components.auth.terms">
                              {(label: ReactNode[]): JSX.Element => (
                                <RouterLink
                                  external
                                  target="_blank"
                                  href={LocalizedConfig.get('externalUrls.terms')}
                                  title={label}
                                >
                                  {label}
                                </RouterLink>
                              )}
                            </FormattedMessage>
                          ),
                          policy: (
                            <FormattedMessage id="components.auth.policy">
                              {(label: ReactNode[]): JSX.Element => (
                                <RouterLink
                                  external
                                  target="_blank"
                                  href={LocalizedConfig.get('externalUrls.privacy')}
                                  title={label}
                                >
                                  {label}
                                </RouterLink>
                              )}
                            </FormattedMessage>
                          ),
                        }}
                      />
                    </Box>
                  </>
                }
                submitText={<FormattedMessage id="components.auth.signup" />}
                onSubmit={handleRegisterSubmit}
              />
            ) : (
              // Signin Form
              <FormBuilder
                key="signin"
                {...LoginFormModel}
                subFooterLinks={
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Link href="" underline="hover" onClick={handleAuthFormToggleClick}>
                      <FormattedMessage id="components.auth.signup" />
                    </Link>
                  </Box>
                }
                submitText={<FormattedMessage id="components.auth.login" />}
                onSubmit={handleSigninSubmit}
              />
            )}
          </Box>
        </Box>
      ) : null}
    </DefaultLayout>
  );
};

export const RegisterPage = withForwardUrlACL(Register);
