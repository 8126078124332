import { gql } from '@apollo/client';
import { SELLER_SHIPPING_TABLE_RATE_FRAGMENT } from '../../fragments/seller/shippingTableRate.graphql';

export const LIST_SELLER_SHIPPING_TABLE_RATES = gql`
  ${SELLER_SHIPPING_TABLE_RATE_FRAGMENT}
  query listSellerShippingTableRates(
    $tableId: Int!
    $sellerId: Int!
    $items: Int = 100
    $offset: Int = 0
  ) {
    listSellerShippingTableRates(
      tableId: $tableId
      sellerId: $sellerId
      items: $items
      offset: $offset
    ) {
      items {
        ...SellerShippingTableRateFragment
      }
    }
  }
`;
