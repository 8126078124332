import {
  PartnerArtist as PartnerArtistBaseModel,
  PartnerArtistPaginationResult as PartnerArtistPaginationResultBaseModel,
  PartnerAdmin as PartnerAdminBaseModel,
  PartnerAdminPaginationResult as PartnerAdminPaginationResultBaseModel,
  PartnerInvitation as PartnerInvitationBaseModel,
  PartnerInvitationPaginationResult as PartnerInvitationPaginationResultBaseModel,
} from '@riseart/models';
import { FormDataMixin } from './Core';
import { PaginationInfo } from './List';
import { Artist } from './Artist';
import { ApiInvalidData } from '../errors/ApiInvalidData';

/**
 * PartnerArtist
 */
class PartnerArtist extends FormDataMixin(PartnerArtistBaseModel) {}

/**
 * PartnerArtistPaginationResult
 */
class PartnerArtistPaginationResult extends PartnerArtistPaginationResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {ArtistPaginationResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): PartnerArtistPaginationResult {
    try {
      // Deconstruct
      const { items: itemsData, ...paginationData } = data || {};

      // Items
      const items: Array<PartnerArtist> = itemsData
        ? itemsData.map((item: Record<string, any>) => new PartnerArtist().hydrate(item))
        : [];

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      // Hydrate model
      return this.hydrate({
        items: items.map(({ artist, ...restItemData }) => ({
          ...restItemData,
          artist: new Artist().hydrate(artist),
        })),
        pagination: pagination || {},
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load PartnerArtistPaginationResult from provided data');
    }
  }
}

/**
 * PartnerAdmin
 */
class PartnerAdmin extends FormDataMixin(PartnerAdminBaseModel) {
  /**
   * hydrateFromApiData
   *
   * @param {data?: Record<string, any>} data
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>): PartnerAdmin {
    try {
      return this.hydrate(data);
    } catch (error) {
      throw new ApiInvalidData('Unable to load PartnerAdmin from provided data.');
    }
  }

  /**
   * fullName
   *
   * @returns {string | null}
   */
  public get fullName(): string | null {
    return this.userFirstName || this.userLastName
      ? [this.userFirstName, this.userLastName].filter((name: string) => !!name).join(' ')
      : null;
  }
}

/**
 * PartnerAdminPaginationResult
 */
class PartnerAdminPaginationResult extends PartnerAdminPaginationResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {data?: Record<string, any>} data
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>): PartnerAdminPaginationResult {
    try {
      const { items, ...paginationData } = data || {};

      const listData = items
        ? items.map((item: Record<string, any>) => {
            const PartnerAdminModel = new PartnerAdmin();
            PartnerAdminModel.hydrateFromApiData(item);

            return PartnerAdminModel;
          })
        : {};

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      return this.hydrate({ items: listData, pagination });
    } catch (error) {
      throw new ApiInvalidData('Unable to load PartnerAdminPaginationResult from provided data.');
    }
  }
}

/**
 * PartnerInvitation
 */
class PartnerInvitation extends FormDataMixin(PartnerInvitationBaseModel) {
  /**
   * hydrateFromApiData
   *
   * @param {data?: Record<string, any>} data
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>): PartnerInvitation {
    try {
      return this.hydrate(data);
    } catch (error) {
      throw new ApiInvalidData('Unable to load PartnerInvitation from provided data.');
    }
  }
}

/**
 * PartnerInvitationPaginationResult
 */
class PartnerInvitationPaginationResult extends PartnerInvitationPaginationResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {data?: Record<string, any>} data
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>): PartnerInvitationPaginationResult {
    try {
      const { items, ...paginationData } = data || {};

      const listData = items
        ? items.map((item: Record<string, any>) => {
            const PartnerInvitationModel = new PartnerInvitation();
            PartnerInvitationModel.hydrateFromApiData(item);

            return PartnerInvitationModel;
          })
        : {};

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      return this.hydrate({ items: listData, pagination });
    } catch (error) {
      throw new ApiInvalidData(
        'Unable to load PartnerInvitationPaginationResult from provided data.',
      );
    }
  }
}

export {
  PartnerArtist,
  PartnerArtistPaginationResult,
  PartnerAdmin,
  PartnerAdminPaginationResult,
  PartnerInvitation,
  PartnerInvitationPaginationResult,
};
