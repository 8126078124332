import { gql } from '@apollo/client';

export const EVENT_BASE_FRAGMENT = gql`
  fragment EventBaseFragment on Event {
    id
    status
    type
    name
    slug
    externalUrl
    showcase
    ranking
    startDate
    startTime
    endDate
    endTime
    description
  }
`;
