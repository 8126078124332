import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import { LayoutPaper, Link } from '@riseart/dashboard';
import { useScrollToEl } from '../../../data/hooks/useScrollToEl';
import { DefaultLayout } from '../../layout/Default';
import { selectSeller } from '../../../services/redux/selectors/seller';
import { selectArtist } from '../../../services/redux/selectors/artist';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { ArtAdd } from '../../art/Add';

/**
 * ArtAddPage
 *
 * @returns {JSX.Element}
 */
export const ArtAddPage = (): JSX.Element => {
  const { id: sellerId, currencyCode, storeCode, canRentArt } = useSelector(selectSeller) || {};
  const { id: artistId } = useSelector(selectArtist) || {};
  const { formatMessage } = useIntl();
  const { setScrollRef, scrollToRef } = useScrollToEl();
  const [loading, setLoading] = useState(false);
  const artListUrl = UrlAssembler.artList();

  return (
    <DefaultLayout
      loading={loading}
      title={formatMessage({ id: 'components.mainMenu.artList' })}
      breadcrumbs={[
        <Link
          key="home"
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link
          key="artList"
          to={artListUrl}
          title={formatMessage({ id: 'components.mainMenu.artList' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'components.mainMenu.artList' })}
        </Link>,
        <Typography key="addArt" variant="inherit">
          {formatMessage({ id: 'components.art.addArt' })}
        </Typography>,
      ]}
      boxRefCallback={setScrollRef}
    >
      <LayoutPaper>
        <ArtAdd
          artist={{ id: artistId }}
          sellerId={sellerId}
          canRentArt={canRentArt}
          currencyCode={currencyCode}
          storeCode={storeCode}
          scrollToRef={scrollToRef}
          artListUrl={artListUrl}
          detailArtAssembler={(id: number) => UrlAssembler.artDetail(id)}
          onLoading={setLoading}
        />
      </LayoutPaper>
    </DefaultLayout>
  );
};
