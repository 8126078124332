export const GOOGLE_TAGMANAGER_ACTIONS = {
  PAGEVIEW: 'GTM_ACTIONS_PAGEVIEW',
  ME_UPDATED: 'GTM_ACTIONS_ME_UPDATED',
  ME_UPDATED_POST_EVENT: 'GTM_ACTIONS_ME_UPDATED_POST_EVENT',
  AUTH_LOGIN: 'GTM_ACTIONS_AUTH_LOGIN',
  APPLICATION_PROPERTY_UPDATE: 'GTM_ACTIONS_APPLICATION_PROPERTY_UPDATE',
};

// Action Creators
export function gtmAuthLogin(payload: Record<string, any>): {
  type: string;
  payload: string | Record<string, any>;
} {
  return {
    type: GOOGLE_TAGMANAGER_ACTIONS.AUTH_LOGIN,
    payload,
  };
}
