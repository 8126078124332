import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Link } from '@riseart/dashboard';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { DefaultLayout } from '../../../layout/Default';
import { selectArtist } from '../../../../services/redux/selectors/artist';
import { ArtistFeedList } from '../../../artist/feed/List';
import { READ_ARTIST } from '../../../../data/gql/queries/artist/read.graphql';

/**
 * ArtistFeedListPage
 *
 * @returns {JSX.Element}
 */
export const ArtistFeedListPage = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const artist = useSelector(selectArtist) || {};
  const pageTitle = formatMessage({ id: 'components.mainMenu.artistFeedList' });

  const { data: readArtistData, loading: readLoading } = useQuery(READ_ARTIST, {
    fetchPolicy: 'network-only',
    variables: { id: artist.id },
  });
  const readData = (readArtistData && readArtistData.readArtist) || null;

  return (
    <DefaultLayout
      title={pageTitle}
      includesFab
      loading={readLoading}
      breadcrumbs={[
        <Link
          key="home"
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link
          key="feed"
          to={UrlAssembler.byRouteKey('artistProfile')}
          title={pageTitle}
          underline="hover"
          color="inherit"
        >
          {pageTitle}
        </Link>,
        ...(readData && readData.name
          ? [
              <Typography key="profile" variant="inherit">
                {readData.name}
              </Typography>,
            ]
          : []),
      ]}
    >
      <Paper sx={{ position: 'relative' }}>
        <ArtistFeedList
          canAdmin
          artist={readData}
          addLink={UrlAssembler.artistFeedAdd()}
          pageUrlAssembler={(options: Record<string, any>) => UrlAssembler.artistFeedList(options)}
          entityUrlAssembler={(id: number, options?: Record<string, any>) =>
            UrlAssembler.artistFeedDetail(id, options)
          }
        />
      </Paper>
    </DefaultLayout>
  );
};
