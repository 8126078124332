import { FormattedDate, FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import { Link } from '@riseart/dashboard';
import { Status } from '../../../common/Status';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { formatCurrency } from '../../../../services/riseart/utils/Utils';
import { SchemaType } from './types';

export const OFFERLIST_TABLE_SCHEMA_BASIC: SchemaType = [
  {
    key: 'id',
    render: ({ data }: Record<string, any>) => (
      <Link to={UrlAssembler.offerDetails(data.id)} title={data.id}>
        {data.id}
      </Link>
    ),
  },
  {
    key: 'state',
    render: ({ data }: Record<string, any>) => (
      <FormattedMessage id={`components.offer.state.${data.state}`} />
    ),
  },
  {
    key: 'status',
    render: ({ data }: Record<string, any>) => <Status type="offer" status={data.status} />,
  },
  {
    key: 'acceptedDate',
    render: ({ data }: Record<string, any>) =>
      data.acceptedDate ? <FormattedDate value={data.acceptedDate} /> : null,
  },
  {
    key: 'expiryDate',
    render: ({ data }: Record<string, any>) =>
      data.expiryDate ? <FormattedDate value={data.expiryDate} /> : null,
  },
  {
    key: 'sellerCurrencyCode',
    render: ({ data }: Record<string, any>) => data.sellerCurrencyCode,
  },
  {
    key: 'sellerAmount',
    render: ({ data }: Record<string, any>) =>
      [null, undefined].indexOf(data.sellerAmount) === -1
        ? formatCurrency(data.sellerAmount)
        : null,
  },
  {
    key: 'sellerAgreed',
    render: ({ data }: Record<string, any>) =>
      [null, undefined].indexOf(data.sellerAgreed) === -1
        ? formatCurrency(data.sellerAgreed)
        : null,
  },
];

export const OFFERLIST_TABLE_SCHEMA_ADVANCED: SchemaType = [
  ...OFFERLIST_TABLE_SCHEMA_BASIC,
  {
    key: 'actions',
    render: ({ data }: Record<string, any>): JSX.Element | null => {
      return (
        <FormattedMessage id="common.details">
          {(label: any) => (
            <Button
              variant="contained"
              size="large"
              component={Link}
              to={UrlAssembler.offerDetails(data.id)}
              title={label}
            >
              {label}
            </Button>
          )}
        </FormattedMessage>
      );
    },
  },
];
