import { DEFAULT_CHECKBOX_PROPS } from '../input/Checkbox';
import { dateRangePickerInput } from '../date';

/**
 * SellerProfileFormModel
 */
export const SellerAwayModeFormModel = {
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      componentProps: DEFAULT_CHECKBOX_PROPS,
      tag: 'checkbox',
      type: 'text',
      name: 'away',
      hint: 'forms.seller.hints.awayMode',
      placeholder: 'forms.seller.label.awayMode',
    },
    {
      tag: 'input',
      type: 'json',
      name: 'awayDates',
      placeholder: 'forms.seller.label.awayDates',
      customRender: dateRangePickerInput,
      shouldRender: (customData: Record<string, any>, form: Record<string, any>): boolean =>
        form && form.data && form.data.away,
      inputFieldData: {
        inputFormat: 'dd/MM/yyyy',
        start: {
          tag: 'input',
          type: 'json',
          name: 'startDate',
          rules: [
            {
              required: true,
              message: 'forms.validation.required',
            },
          ],
          text: 'forms.event.label.startDate',
        },
        end: { tag: 'input', type: 'json', name: 'endDate', text: 'forms.event.label.endDate' },
      },
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'custom',
          custom: (value: string | number, formData: Record<string, any>): boolean => {
            return !!(
              formData.away === true &&
              (!value || (value && Array.isArray(value) && value[0] === null && value[1] === null))
            );
          },
          message: 'forms.seller.validation.invalidAwayDates',
        },
      ],
      isReadonly: true,
    },
  ],
};
