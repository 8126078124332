import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link, SplitButton } from '@riseart/dashboard';
import { currency as CURRENCY_ENUM, event as EVENT_ENUM } from '../../../../config/enumeration.js';
import { splitButtonConfirmationProps } from '../../../common/buttons/settings';
import { Status } from '../../../common/Status';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { formatCurrency } from '../../../../services/riseart/utils/Utils';
import { SchemaType } from './types';

export const EVENTLIST_TABLE_SCHEMA_BASIC: SchemaType = [
  {
    key: 'name',
    render: ({ data }: Record<string, any>) => (
      <Link to={UrlAssembler.eventEdit(data.id)} title={data.name}>
        {data.name}
      </Link>
    ),
  },
  {
    key: 'type',
    render: ({ data }: Record<string, any>) => (
      <FormattedMessage id={`components.event.type.${data.type}`} />
    ),
  },
  {
    key: 'status',
    render: ({ data }: Record<string, any>) => <Status type="event" status={data.getState()} />,
  },
  {
    key: 'location',
    render: ({ data }: Record<string, any>) => data.venueName,
  },
  {
    key: 'dates',
    render: ({ data }: Record<string, any>) =>
      data.startDate || data.endDate ? (
        <>
          {data.startDate ? (
            <FormattedDate value={data.startDate} />
          ) : (
            <FormattedMessage id="common.notApplicable" />
          )}
          {data.endDate ? (
            <>
              {' '}
              - <FormattedDate value={data.endDate} />
            </>
          ) : null}
        </>
      ) : (
        <FormattedMessage id="common.notApplicable" />
      ),
  },
  {
    key: 'hours',
    render: ({ data }: Record<string, any>) =>
      data.startTime || data.endTime ? (
        <>
          {data.startTime || <FormattedMessage id="common.notApplicable" />}
          {data.endTime ? <> - {data.endTime}</> : null}
        </>
      ) : (
        <FormattedMessage id="common.notApplicable" />
      ),
  },
  {
    key: 'ticket',
    render: ({ data }: Record<string, any>): string | JSX.Element => {
      return data.ticketAmount ? (
        formatCurrency(
          data.ticketAmount,
          Object.keys(CURRENCY_ENUM.codes).reduce(
            (accumulator, storeCode) =>
              // @ts-ignore
              CURRENCY_ENUM.codes[storeCode] === data.ticketCurrency ? storeCode : accumulator,
            null,
          ),
        )
      ) : (
        <FormattedMessage id="common.notApplicable" />
      );
    },
  },
];

export const EVENTLIST_TABLE_SCHEMA_ADVANCED: SchemaType = [
  ...EVENTLIST_TABLE_SCHEMA_BASIC,
  {
    key: 'actions',
    render: ({
      data: { id, canUpdate, canActivate, canCancel },
      customData: { updateMutation, refetchListQuery, history },
      deleteLoading,
    }: Record<string, any>): JSX.Element | null => {
      const actions = [];

      if (canUpdate) {
        actions.push({
          actionKey: 'edit',
          text: <FormattedMessage id="common.edit" />,
          onClick: () => history.push(UrlAssembler.eventEdit(id)),
        });
      }

      if (canActivate) {
        actions.push({
          actionKey: 'activate',
          requireConfirmation: true,
          text: <FormattedMessage id="components.event.action.activate" />,
          onClick: () => {
            updateMutation({
              variables: { id, inputEvent: { status: EVENT_ENUM.status.ACTIVE } },
            })
              .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
              .catch(() => null);
          },
        });
      }

      if (canCancel) {
        actions.push({
          actionKey: 'cancel',
          requireConfirmation: true,
          text: <FormattedMessage id="components.event.action.cancel" />,
          onClick: () => {
            updateMutation({
              variables: { id, inputEvent: { status: EVENT_ENUM.status.CANCELED } },
            })
              .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
              .catch(() => null);
          },
        });
      }

      if (!actions.length) {
        return null;
      }

      const [mainAction, ...secondaryActions] = actions;

      return (
        <SplitButton
          disabled={deleteLoading}
          mainButtonProps={mainAction ? { ...mainAction, children: mainAction.text } : {}}
          translationPrefix="components.dialog.event.action"
          confirmationDialogProps={splitButtonConfirmationProps}
          options={secondaryActions}
        />
      );
    },
  },
];
