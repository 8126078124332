import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { LayoutPaper } from '@riseart/dashboard';
import { useScrollToEl } from '../../../../data/hooks/useScrollToEl';
import { selectArtist } from '../../../../services/redux/selectors/artist';
import { ArtistFeedAdd } from '../../../artist/feed/Add';
import { DefaultLayout } from '../../../layout/Default';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';

/**
 * ArtistFeedAddPage
 *
 * @returns {JSX.Element}
 */
export const ArtistFeedAddPage = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const { setScrollRef, scrollToRef } = useScrollToEl();
  const artist = useSelector(selectArtist) || {};
  const pageTitle = formatMessage({ id: 'components.mainMenu.artistFeedList' });
  const listFeedUrl = UrlAssembler.artistFeedList();

  return (
    <DefaultLayout
      title={pageTitle}
      breadcrumbs={[
        <Link
          key="home"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link
          key="feedList"
          component={RouterLink}
          to={listFeedUrl}
          title={pageTitle}
          underline="hover"
          color="inherit"
        >
          {pageTitle}
        </Link>,
        <Typography key="addArt" variant="inherit">
          {formatMessage({ id: 'components.feed.addPost' })}
        </Typography>,
      ]}
      boxRefCallback={setScrollRef}
    >
      <LayoutPaper>
        <ArtistFeedAdd
          artistId={artist.id}
          scrollToRef={scrollToRef}
          listUrl={listFeedUrl}
          entityUrlAssembler={(feedId: number) => UrlAssembler.artistFeedDetail(feedId)}
        />
      </LayoutPaper>
    </DefaultLayout>
  );
};
