import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Title, LayoutPaper } from '@riseart/dashboard';
import { useScrollToEl } from '../../../data/hooks/useScrollToEl';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { DefaultLayout } from '../../layout/Default';
import { SellerPaymentForm } from './PaymentForm';

/**
 * SellerPaymentPage
 *
 * @returns {JSX.Element}
 */
export const SellerPaymentPage = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const { setScrollRef, scrollToRef } = useScrollToEl();
  const { formatMessage } = useIntl();

  return (
    <DefaultLayout
      loading={loading}
      title={formatMessage({ id: 'components.mainMenu.seller' })}
      breadcrumbs={[
        <Link
          key="home"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link
          key="sellerProfile"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('sellerProfile')}
          title={formatMessage({ id: 'components.mainMenu.seller' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'components.mainMenu.seller' })}
        </Link>,
        <Typography key="seller" variant="inherit">
          {formatMessage({ id: 'components.mainMenu.sellerPayment' })}
        </Typography>,
      ]}
      boxRefCallback={setScrollRef}
    >
      <LayoutPaper>
        <SellerPaymentForm
          title={
            <Title variant="h5">{formatMessage({ id: 'components.sellerPayment.title' })}</Title>
          }
          submitText={formatMessage({ id: 'common.saveChanges' })}
          onLoading={setLoading}
          scrollToRef={scrollToRef}
        />
      </LayoutPaper>
    </DefaultLayout>
  );
};
