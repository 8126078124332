import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { Title, FormHeader, LayoutPaper, Link } from '@riseart/dashboard';
import { useScrollToEl } from '../../../../data/hooks/useScrollToEl';
import { selectPartner } from '../../../../services/redux/selectors/partner';
import { DefaultLayout } from '../../../layout/Default';
import { PageSubmenu } from '../../../common/PageSubmenu';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { ArtistFeedEdit } from '../../../artist/feed/Edit';
import { GET_PARTNER_ARTIST } from '../../../../data/gql/queries/partner/getArtist.graphql';

/**
 * PartnerArtistFeedEditPage
 *
 * @returns {JSX.Element}
 */
export const PartnerArtistFeedEditPage = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const [feedData, setFeedData] = useState<Record<string, any> | null>(null);
  const [loading, setLoading] = useState(false);
  const { setScrollRef, scrollToRef } = useScrollToEl();
  const { artistId, id: feedId }: Record<string, any> = useParams();
  const partner = useSelector(selectPartner) || {};
  const { data: listArtist, loading: listLoading } = useQuery(GET_PARTNER_ARTIST, {
    fetchPolicy: 'network-only',
    variables: {
      partnerId: partner && partner.id,
      filters: { artistId: { value: artistId, operator: 'EQUAL' } },
    },
  });
  const partnerArtist =
    listArtist && listArtist.artist && listArtist.artist.items && listArtist.artist.items[0];
  const canAdmin = partnerArtist && partnerArtist.canUpdate;
  const pageTitle = formatMessage({ id: 'components.mainMenu.artistFeedList' });
  const feedListLabel = formatMessage({ id: 'components.artist.tab.feed' });

  return (
    <DefaultLayout
      loading={loading || listLoading}
      title={pageTitle}
      breadcrumbs={[
        <Link
          key="home"
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        ...(partnerArtist && partnerArtist.artist
          ? [
              <Link
                key="profile"
                to={UrlAssembler.artistDetails(artistId)}
                title={partnerArtist.artist.name}
                underline="hover"
                color="inherit"
              >
                {partnerArtist.artist.name}
              </Link>,
              <Link
                key="feed"
                to={UrlAssembler.partnerArtistFeedList(artistId)}
                title={feedListLabel}
                underline="hover"
                color="inherit"
              >
                {feedListLabel}
              </Link>,
              ...(feedData && feedData.title
                ? [
                    <Typography key="art" variant="inherit">
                      {feedData.title}
                    </Typography>,
                  ]
                : []),
            ]
          : []),
      ]}
      boxRefCallback={setScrollRef}
    >
      <PageSubmenu menu="artist" selected="partnerArtistFeedList" urlParams={{ id: artistId }} />
      <LayoutPaper>
        <FormHeader
          title={<Title variant="h5">{formatMessage({ id: 'components.feed.editPost' })}</Title>}
        />

        {canAdmin ? (
          <ArtistFeedEdit
            artistId={artistId}
            feedId={feedId}
            scrollToRef={scrollToRef}
            onLoading={setLoading}
            onData={setFeedData}
          />
        ) : (
          <Alert severity="error">
            {formatMessage({ id: 'components.artist.canAdminMessage' })}
          </Alert>
        )}
      </LayoutPaper>
    </DefaultLayout>
  );
};
