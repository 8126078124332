import { gql } from '@apollo/client';

export const ACTION_ART = gql`
  mutation actionArt($id: Int!, $store: String!, $action: String!) {
    actionArt(id: $id, store: $store, action: $action) {
      id
      action
      artId
      executed
      status
    }
  }
`;
