import { useIntl } from 'react-intl';
import { Link } from '@riseart/dashboard';
import { LocalizedConfig } from '../../../services/riseart/utils/LocalizedConfig';

type Props = { translationKey: string };

/**
 * FileRestrictionsHint
 *
 * @param {Props} props
 * @returns {ReactNode}
 */
export const FileRestrictionsHint = ({ translationKey }: Props): JSX.Element => {
  const { formatMessage } = useIntl();

  // @ts-ignore
  return formatMessage(
    { id: translationKey },
    {
      a: (chunk) => (
        <Link
          href={LocalizedConfig.get('externalUrls.imageUploadInstructions')}
          target="_blank"
          color="inherit"
          external
          title={formatMessage({ id: 'forms.art.hints.imageUploadHelp' })}
        >
          {chunk}
        </Link>
      ),
    },
  );
};
