import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonIcon from '@mui/icons-material/Person';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, SideMenu, menuItemsFinder } from '@riseart/dashboard';
import { find } from '@riseart/fe-utils';
import { artDirection as CONFIG_ART_DIRECTION } from '../../config/config.js';
import { ROUTER_CONFIG } from '../../config/router.js';
import { isRoleAllowedForACL } from '../../services/riseart/utils/Auth';
import { selectAuth } from '../../services/redux/selectors/auth';
import { selectUser } from '../../services/redux/selectors/user';
import { concatValues, getStringInitial } from '../../services/riseart/utils/Utils';
import { UrlAssembler } from '../../services/riseart/utils/UrlAssembler';
import { generatePicturePropsForCDN } from './artdirection/Picture';
import { ART_DIRECTION_SIZES } from '../../services/riseart/artdirection/sizes';
import { LocaleSelect } from './LocaleSelect';

import cssVars from '../../styles/variables.module.css';

/**
 * UserAvatar
 *
 * @returns {JSX.Element}
 */
export function UserAvatar(): JSX.Element {
  const { formatMessage } = useIntl();
  const auth = useSelector(selectAuth);
  const userRole = get(auth, 'payload.acl_role');
  const user = useSelector(selectUser);

  if (user) {
    const avatar =
      user && user.avatars && find(user.avatars, ({ type }: { type: string }) => type === 'square');
    const avatarImgProps = avatar
      ? generatePicturePropsForCDN({
          image: avatar,
          sizesList: ART_DIRECTION_SIZES.avatar.medium,
          artDirectionConfig: CONFIG_ART_DIRECTION.common.avatar,
        })
      : null;
    const fullName = concatValues([user.firstName, user.lastName]);

    return (
      <SideMenu
        title={formatMessage({ id: 'components.secondaryMenu.tooltip' })}
        avatar={
          <Avatar
            sx={{ bgcolor: cssVars.colorBackground, color: cssVars.colorPrimary }}
            {...(avatarImgProps
              ? {
                  ...avatarImgProps,
                  srcSet:
                    avatarImgProps.srcSet &&
                    (avatarImgProps.srcSet.webp || avatarImgProps.srcSet.img),
                  alt: fullName,
                }
              : null)}
          >
            {!avatarImgProps && user.firstName ? getStringInitial(user.firstName) : null}
            {!avatarImgProps && user.lastName ? getStringInitial(user.lastName) : null}
          </Avatar>
        }
      >
        {[
          <Box p={2} key="localeSelect">
            <LocaleSelect
              label={formatMessage({ id: 'components.locale.label' })}
              hint={formatMessage({ id: 'components.locale.hint' })}
            />
          </Box>,
          <Divider key="divider" />,
          ...menuItemsFinder(
            ROUTER_CONFIG,
            'secondary',
            userRole,
            {
              isRoleAllowedForACL,
              UrlAssembler,
              formatMessage: ({ id }) => formatMessage({ id: `components.secondaryMenu.${id}` }),
            },
            { signout: LogoutIcon },
          ).map(({ key, icon: IconComponent, text, ...restLinkProps }) => {
            return (
              <ListItem disablePadding key={key}>
                <ListItemButton component={Link} {...restLinkProps} title={text}>
                  <ListItemIcon>
                    <IconComponent />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            );
          }),
        ]}
      </SideMenu>
    );
  }

  return (
    <IconButton
      color="inherit"
      component={Link}
      to={UrlAssembler.byRouteKey('login')}
      title="Login"
    >
      <PersonIcon />
    </IconButton>
  );
}
