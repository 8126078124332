import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { SplitButton } from '@riseart/dashboard';
import { errors as ERRORS_ENUM } from '../../config/enumeration.js';
import { splitButtonConfirmationProps } from '../common/buttons/settings';
import { selectSeller } from '../../services/redux/selectors/seller';
import { useMutation } from '@apollo/client';
import { ACTION_ART } from '../../data/gql/queries/art/action.graphql';
import { product as PRODUCT_ENUM } from '../../config/enumeration.js';
import { asyncDelay } from '../../services/riseart/utils/Utils';
import { useNotification } from '../../data/hooks/useNotification';

type Props = {
  loading: boolean;
  setLoading: (loading: boolean) => any;
  selectedItems: Record<string, any>[];
  refetchQuery: () => Promise<any>;
};

type ActionItem = {
  action: string;
  enabled: boolean;
  ids: (number | string)[];
  title: string | JSX.Element;
};

/**
 * ArtBulkActionsButton
 *
 * @param {Props} props
 * @returns {JSX.Element | null}
 */
export const ArtBulkActionsButton = ({
  loading,
  setLoading,
  selectedItems,
  refetchQuery,
}: Props): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const { dispatchNotification } = useNotification();
  const { storeCode } = useSelector(selectSeller) || {};
  const [actionArt, { loading: actionArtLoading }] = useMutation(ACTION_ART);
  const totalSelectedItems = selectedItems.length;

  if (!totalSelectedItems) {
    return null;
  }

  const availableActions: Record<string, any> = selectedItems.reduce(
    (accumulator, { row: item }) => {
      const { productCanUnlist, productCanSubmit, productCanArchive, productCanSellOut } = item;

      if (productCanUnlist) {
        accumulator.unlist.ids.push(item.id);
        accumulator.unlist.enabled = accumulator.unlist.ids.length === totalSelectedItems;
      }
      if (productCanSubmit) {
        accumulator.submit.ids.push(item.id);
        accumulator.submit.enabled = accumulator.submit.ids.length === totalSelectedItems;
      }
      if (productCanSellOut) {
        accumulator.sellout.ids.push(item.id);
        accumulator.sellout.enabled = accumulator.sellout.ids.length === totalSelectedItems;
      }
      if (productCanArchive) {
        accumulator.archive.ids.push(item.id);
        accumulator.archive.enabled = accumulator.archive.ids.length === totalSelectedItems;
      }

      return accumulator;
    },
    {
      unlist: {
        action: PRODUCT_ENUM.action.UNLIST,
        enabled: false,
        ids: [],
        title: formatMessage({ id: 'components.art.product.actions.productCanUnlist' }),
      },
      submit: {
        action: PRODUCT_ENUM.action.SUBMIT,
        enabled: false,
        ids: [],
        title: formatMessage({ id: 'components.art.product.actions.productCanSubmit' }),
      },
      sellout: {
        action: PRODUCT_ENUM.action.SELL_OUT,
        enabled: false,
        ids: [],
        title: formatMessage({ id: 'components.art.product.actions.productCanSellOut' }),
      },
      archive: {
        action: PRODUCT_ENUM.action.ARCHIVE,
        enabled: false,
        ids: [],
        title: formatMessage({ id: 'components.art.product.actions.productCanArchive' }),
      },
    },
  );

  // Add actions in array to keep its order and precedence when showing them in the UI dropdown
  const actionsList = [
    availableActions.unlist,
    availableActions.submit,
    availableActions.archive,
    availableActions.sellout,
  ];

  const mainAction = actionsList.reduce<ActionItem | null>(
    (accumulator: ActionItem | null, action: ActionItem) => {
      if (!accumulator && action.enabled) {
        return action;
      }

      return accumulator;
    },
    null,
  );
  const restActions = mainAction
    ? actionsList.filter((item) => item.action !== mainAction.action)
    : null;

  /**
   * proccessActions
   *
   * @param ids
   * @param action
   * @returns
   */
  function proccessActions(ids: (number | string)[], action: string, store: string) {
    setLoading(true);
    return Promise.allSettled(
      ids.reduce<Promise<any>[]>((accumulator, id: string | number) => {
        accumulator.push(actionArt({ variables: { id, store, action } }), asyncDelay(500));

        return accumulator;
      }, []),
    ).then((actionRequestValues: PromiseSettledResult<any>[]) => {
      const actionResults: { fulfilled: Record<string, any>[]; rejected: Record<string, any>[] } =
        actionRequestValues
          .filter(({ value }: Record<string, any>) => !value || value.type !== 'asyncDelay')
          .reduce(
            (
              accumulator: { fulfilled: Record<string, any>[]; rejected: Record<string, any>[] },
              { status, value, reason }: Record<string, any>,
              index: number,
            ) => {
              /*
              if (value && value.type === 'asyncDelay') {
                return accumulator;
              }
              */
              const { row: itemData } = selectedItems[index] || {};

              if (status === 'fulfilled') {
                accumulator.fulfilled.push({ value, itemData });
              } else if (status === 'rejected') {
                accumulator.rejected.push({ reason: reason.message, itemData });
              }

              return accumulator;
            },
            { fulfilled: [], rejected: [] },
          );

      const totalFulfilledActions = actionResults.fulfilled.length;
      const totalRejectedActions = actionResults.rejected.length;
      const totalActions = totalFulfilledActions + totalRejectedActions;
      const translationVars = {
        action,
        successCount: totalFulfilledActions,
        errorCount: totalRejectedActions,
        totalCount: totalActions,
        details: actionResults.rejected.map(({ itemData, reason }) => {
          return formatMessage(
            { id: 'components.dialog.art.bulkAction.errorDetails' },
            { action, title: itemData.title, details: reason },
          );
        }),
      };
      const notificationDetail =
        (totalFulfilledActions &&
          totalRejectedActions &&
          formatMessage({ id: 'components.dialog.art.bulkAction.warning' }, translationVars)) ||
        (totalRejectedActions &&
          formatMessage({ id: 'components.dialog.art.bulkAction.error' }, translationVars)) ||
        formatMessage({ id: 'components.dialog.art.bulkAction.success' }, translationVars);

      dispatchNotification({
        // @ts-ignore
        detail: notificationDetail,
        level:
          (totalFulfilledActions && totalRejectedActions && ERRORS_ENUM.levels.WARNING) ||
          (totalRejectedActions && ERRORS_ENUM.levels.ERROR) ||
          ERRORS_ENUM.levels.SUCCESS,
        placeholder: ERRORS_ENUM.placeholders.SNACKBAR,
        expire: 0,
      });

      refetchQuery && refetchQuery();
      setLoading(false);
    });
  }

  return mainAction ? (
    <SplitButton
      size="large"
      disabled={loading || actionArtLoading || !mainAction}
      mainButtonProps={
        mainAction
          ? {
              onClick: () => proccessActions(mainAction.ids, mainAction.action, storeCode),
              actionKey: mainAction.action,
              requireConfirmation: true,
              title: mainAction.title,
              children: mainAction.title,
            }
          : {}
      }
      confirmationDialogProps={splitButtonConfirmationProps}
      translationPrefix="components.dialog.art.bulkAction"
      options={
        restActions
          ? restActions.map((actionItem: ActionItem) => {
              const { action, enabled, ids, title } = actionItem;

              return {
                actionKey: action,
                disabled: !enabled,
                requireConfirmation: true,
                text: title,
                onClick: () => proccessActions(ids, action, storeCode),
              };
            })
          : []
      }
    />
  ) : null;
};
