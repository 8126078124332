import { find } from '@riseart/fe-utils';
import { DIMENSIONS as ART_DIMENSIONS } from './art/dimensions';
import { DIMENSIONS as ARTIST_DIMENSIONS } from './artist/dimensions';

/**
 * getDimension
 *
 * @param {string} domain
 * @param {string} name
 * @returns {Record<string, any>}
 */
export function getDimension(domain: string, name: string): Record<string, any> {
  let dimensions: Record<string, any>[] = [];

  switch (domain) {
    case 'art':
      dimensions = ART_DIMENSIONS;
      break;
    case 'artist':
      dimensions = ARTIST_DIMENSIONS;
      break;
    default:
      break;
  }
  return find(dimensions, (dimension: Record<string, any>): boolean => dimension.name === name);
}
