import { gql } from '@apollo/client';
import { ART_IMAGE_FRAGMENT } from '../../fragments/art/image.graphql';

export const DELETE_ART_IMAGE = gql`
  ${ART_IMAGE_FRAGMENT}
  mutation deleteArtImage($id: ID!, $artId: Int!, $store: String!) {
    deleteArtImage(id: $id, artId: $artId, store: $store) {
      ...ArtImageFragment
    }
  }
`;
