import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import {
  CircularLoader,
  FormBuilder,
  ConfirmationDialog,
  DataTable,
  LAYOUT_PAPER_PADDING,
} from '@riseart/dashboard';
import { acl as ACL_ENUM, errors as ERROS_ENUM } from '../../../config/enumeration.js';
import { delay } from '../../../services/riseart/utils/Utils';
import { ErrorMessageType, ErrorService } from '../../../services/riseart/errors/ErrorService';
import { mapApiValidationErrors, mapApiErrors } from '../../../services/riseart/utils/Form';
import { PARTNER_INVITATION_TABLE_SCHEMA } from '../../data/tables/schemas/partner';
import { PartnerInvitationPaginationResult } from '../../../data/models/Partner';
import { PartnerUserInvitationModel } from '../../forms/models/partner';
import { LIST_PARTNER_INVITATIONS } from '../../../data/gql/queries/partner/listInvitations.graphql';
import { CREATE_PARTNER_INVITATION } from '../../../data/gql/queries/partner/createInvitation.graphql';
import { DELETE_PARTNER_INVITATION } from '../../../data/gql/queries/partner/deleteInvitation.graphql';

import { DataListFeed } from '../../data/ListFeed';

type Props = {
  partnerId: number | string;
};

/**
 * PartnerInvitations
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export function PartnerInvitations({ partnerId }: Props): JSX.Element {
  const ITEMS_PER_PAGE = 10;
  const { formatMessage } = useIntl();
  const inviteLabel = formatMessage({ id: 'common.invite' });
  const [dialogErrors, setDialogErrors] = useState<ErrorMessageType[] | null>(null);
  const [createMutation, { loading: createLoading }] = useMutation(CREATE_PARTNER_INVITATION);
  const [deleteMutation, { loading: deleteLoading }] = useMutation(DELETE_PARTNER_INVITATION);
  const PartnerInvitationPaginationModel = new PartnerInvitationPaginationResult();

  return (
    <DataListFeed
      query={LIST_PARTNER_INVITATIONS}
      fetchPolicy="cache-and-network"
      itemsPerPage={ITEMS_PER_PAGE}
      variablesMapper={({ page = 1, itemsPerPage }: Record<string, any>): Record<string, any> => ({
        partnerId,
        page,
        items: itemsPerPage || ITEMS_PER_PAGE,
        sort: 'current',
      })}
    >
      {({ refetchListQuery, items, loading, pagination }: Record<string, any>) => {
        if (items) {
          PartnerInvitationPaginationModel.hydrate({ items, pagination });
        }

        return (
          <DataTable
            columns={[
              {
                title: formatMessage({ id: 'components.tables.columns.common.id' }),
                width: '10%',
              },
              {
                title: formatMessage({ id: 'components.tables.columns.common.email' }),
                width: '25%',
              },
              {
                title: formatMessage({ id: 'components.tables.columns.common.aclRole' }),
                width: '20%',
              },
              {
                sx: { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' },
                title: formatMessage({ id: 'components.partner.user.invitationDate' }),
                width: '15%',
              },
              {
                sx: { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' },
                title: formatMessage({ id: 'components.partner.user.expirationDate' }),
                width: '15%',
              },
              {
                title: '',
                align: 'right',
                width: '15%',
              },
            ]}
            schema={PARTNER_INVITATION_TABLE_SCHEMA}
            layoutPaperPadding={LAYOUT_PAPER_PADDING}
            customData={{ partnerId, deleteMutation, refetchListQuery }}
            data={PartnerInvitationPaginationModel.items}
            refetchListQuery={refetchListQuery}
            pagination={pagination}
            hideTableOnNoData
            noData={<FormattedMessage id="components.partner.noInvitations" />}
            loading={loading || deleteLoading}
            mainAction={
              <ConfirmationDialog
                title={formatMessage({ id: 'components.dialog.partner.invitation.create' })}
                description={({ handleClose }: Record<string, any>) => {
                  const formModel = PartnerUserInvitationModel();

                  return (
                    <CircularLoader active={createLoading}>
                      {dialogErrors?.map(({ detail }) => (
                        <Alert severity="error">{detail}</Alert>
                      ))}
                      <Typography fontSize={14}>
                        <FormattedMessage id="components.dialog.partner.invitation.description" />
                      </Typography>
                      <FormBuilder
                        settings={formModel.settings}
                        fields={formModel.fields}
                        submitText={formatMessage({ id: 'common.send' })}
                        buttonSize="medium"
                        initialValues={{ aclRole: ACL_ENUM.role.SELLER_PARTNER }}
                        cancel={
                          <Button
                            onClick={handleClose}
                            type="button"
                            variant="outlined"
                            size="medium"
                          >
                            {formatMessage({ id: 'common.cancel' })}
                          </Button>
                        }
                        onSubmit={async (formState, addApiErrors) => {
                          setDialogErrors(null);
                          createMutation({
                            variables: { partnerId, ...formState.data },
                          })
                            .then(() => {
                              delay(() => {
                                delay(() => refetchListQuery());
                                handleClose();
                              });
                            })
                            .catch((error: Error) => {
                              // Map validation errors from response
                              const validationError = mapApiValidationErrors(error);

                              if (validationError && validationError.length && addApiErrors) {
                                addApiErrors(
                                  validationError.map((error) => ({
                                    ...error,
                                    ...(error.fieldName
                                      ? { fieldName: error.fieldName.replace('data.', '') }
                                      : null),
                                  })),
                                );
                              } else {
                                // Map any other type of errors from response (validation without provided fields, or non-validation errors)
                                const mappedErrors = mapApiErrors(error).map((err: any) =>
                                  ErrorService.mapNotification({
                                    detail: err,
                                    level: ERROS_ENUM.levels.ERROR,
                                    placeholder: ERROS_ENUM.placeholders.APP,
                                  }),
                                );

                                setDialogErrors(mappedErrors);
                              }

                              return true;
                            });
                        }}
                      />
                    </CircularLoader>
                  );
                }}
              >
                {(handleOpen: (props: Record<string, any>) => any) => (
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={handleOpen}
                    title={inviteLabel}
                  >
                    {inviteLabel}
                  </Button>
                )}
              </ConfirmationDialog>
            }
            title={formatMessage({ id: 'components.partner.inivation.title' })}
          />
        );
      }}
    </DataListFeed>
  );
}
