import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AdfScannerIcon from '@mui/icons-material/AdfScanner';
import HelpIcon from '@mui/icons-material/Help';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { Title, Link } from '@riseart/dashboard';
import { LocalizedConfig } from '../../../services/riseart/utils/LocalizedConfig';
import { TermsDialog } from '../../common/Terms';

/**
 * DashboardCardHelp
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export function DashboardCardHelp(): JSX.Element {
  const [open, setOpen] = useState(false);
  const handleTermsToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);
  const LINKS = [
    { key: 'artistGuide', icon: <AssistantDirectionIcon /> },
    { key: 'photoGuide', icon: <PhotoCameraIcon /> },
    { key: 'kycForm', icon: <AdfScannerIcon /> },
    {
      key: 'sellerTerms',
      icon: <FormatListBulletedIcon />,
      onClick: handleTermsToggle,
    },
    { key: 'help', icon: <HelpIcon /> },
  ];

  return (
    <>
      <Title color="primary" sx={{ pt: 2, px: 2, marginBottom: 2 }}>
        <FormattedMessage id="components.dashboard.help.title" />
      </Title>
      <List disablePadding>
        {LINKS.map(({ key, icon, onClick }) => (
          <ListItem key={key} sx={{ p: 0, m: 0 }}>
            <FormattedMessage id={`components.dashboard.help.links.${key}`}>
              {(text) => (
                <ListItemButton
                  component={Link}
                  external
                  to={LocalizedConfig.get(`externalUrls.${key}`)}
                  title={text}
                  target="_blank"
                  underline="hover"
                  {...(onClick ? { onClick } : null)}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              )}
            </FormattedMessage>
          </ListItem>
        ))}
      </List>
      {open ? <TermsDialog open={open} handleClose={handleTermsToggle} /> : null}
    </>
  );
}
