/**
 * mapToFormData
 *
 * @param {Object} rawData
 * @param {string} formFields
 * @returns {Record<string, any>}
 */
export function mapToFormData(
  rawData: Record<string, any> = {},
  formFields: Record<string, any>[],
): Record<string, any> {
  return {
    ...formFields.reduce(
      (accumulator, { name }) => ({
        ...accumulator,
        [name]: typeof rawData[name] !== 'undefined' ? rawData[name] : null,
      }),
      {},
    ),
  };
}
/**
 * mapFormToApiData
 *
 * @param {Record<string, any>} formData
 * @returns {Record<string, any>}
 */
export function mapFormToApiData(
  formData: Record<string, any>,
  readData: Record<string, any>,
): Record<string, any> {
  // eslint-disable-next-line
  const { name, alias, biography, mediums, styles, ...restData } = formData;

  return {
    ...restData,
    ...(readData.biographyLocked === true ? null : { biography }),
    ...(readData.aliasUpdated === true ? null : { alias }),
    mediums: mediums && Array.isArray(mediums) && mediums.length > 0 ? mediums : null,
    styles: styles && Array.isArray(styles) && styles.length > 0 ? styles : null,
  };
}
