import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUser(
    $inputUser: InputUser!
    $inputAccount: InputUserAccount!
    $inputVisitor: InputVisitor
  ) {
    createUser(inputUser: $inputUser, inputAccount: $inputAccount, inputVisitor: $inputVisitor) {
      id
      email
      role
      visitorId
    }
  }
`;
