import { useHistory, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import { SplitButton } from '@riseart/dashboard';
import { UrlAssembler } from '../../services/riseart/utils/UrlAssembler';
import { splitButtonConfirmationProps } from '../common/buttons/settings';
import { UPDATE_SELLER_SHIPPING_TABLE } from '../../data/gql/queries/seller/updateShippingTable.graphql';

type Props = {
  data: Record<string, any>;
  sellerId: number | string;
  refetchQuery: () => Promise<any>;
};

/**
 * SellerListActionButton
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const SellerListActionButton = ({ data, sellerId, refetchQuery }: Props): JSX.Element => {
  const history = useHistory();
  const [updateShippingTable, { loading }] = useMutation(UPDATE_SELLER_SHIPPING_TABLE);
  const detailsUrl = UrlAssembler.shippingDetail(data.id);
  const detailsLabel = <FormattedMessage id="common.details" />;

  return data.default ? (
    <Button variant="contained" component={Link} to={detailsUrl} title={data.name}>
      {detailsLabel}
    </Button>
  ) : (
    <SplitButton
      disabled={loading}
      mainButtonProps={{
        onClick: () => history.push(detailsUrl),
        title: data.name,
        children: detailsLabel,
      }}
      translationPrefix="components.dialog.seller.action"
      confirmationDialogProps={splitButtonConfirmationProps}
      options={[
        {
          actionKey: 'setAsDefault',
          requireConfirmation: true,
          text: <FormattedMessage id="components.seller.setAsDefault" />,
          onClick: () => {
            updateShippingTable({
              variables: {
                id: data.id,
                sellerId,
                inputShippingTable: { default: true },
              },
            })
              .then(() => {
                refetchQuery && refetchQuery();
              })
              .catch(() => null);
          },
        },
      ]}
    />
  );
};
