import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Title, FormBuilder, FormHeader } from '@riseart/dashboard';
import { errors as ERRORS_ENUM } from '../../../config/enumeration.js';
import { useNotification } from '../../../data/hooks/useNotification';
import { AddArtistFormModel } from '../../forms/models/artist';
import { delay } from '../../../services/riseart/utils/Utils';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { mapApiValidationErrors, mapApiErrors } from '../../../services/riseart/utils/Form';
import { Artist as ArtistModel } from '../../../data/models/Artist';
import { CREATE_PARTNER_ARTIST } from '../../../data/gql/queries/partner/createArtist.graphql';

type Props = {
  partnerId: number;
  initialValues?: Record<string, any> | null;
  scrollToRef: () => any;
  onLoading?: (loading: boolean) => void;
};

/**
 * CreatePartnerArtist
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export function CreatePartnerArtist({
  partnerId,
  initialValues = null,
  scrollToRef,
  onLoading,
}: Props): JSX.Element {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { dispatchNotification } = useNotification();
  const [loading, setLoading] = useState(false);

  const [createArtist, { loading: createLoading }] = useMutation(CREATE_PARTNER_ARTIST);

  useEffect(() => {
    if (typeof onLoading === 'function') {
      onLoading(createLoading || loading);
    }
  }, [createLoading, loading]);

  return (
    <>
      <FormHeader
        title={<Title variant="h5">{formatMessage({ id: 'components.artist.create' })}</Title>}
      />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={7}>
          <FormBuilder
            settings={AddArtistFormModel.settings}
            fields={AddArtistFormModel.fields}
            initialValues={{
              mediums: [],
              styles: [],
              careerStages: [],
              ...initialValues,
            }}
            submitText={formatMessage({ id: 'common.add' })}
            disableSubmit={false}
            cancel={
              <Button
                component={RouterLink}
                to={UrlAssembler.artistList()}
                type="button"
                variant="outlined"
                size="large"
                fullWidth
              >
                {formatMessage({ id: 'common.cancel' })}
              </Button>
            }
            onSubmit={async (formState, addApiErrors) => {
              setLoading(true);
              const INCLUDE_NULL_VALUES = false;
              const Artist = new ArtistModel();

              // hydrate form data
              Artist.hydrateFromFormData(formState.data);

              // @ts-ignore
              createArtist({
                variables: {
                  partnerId,
                  rank: 1,
                  artist: {
                    ...ArtistModel.mapFormToApiData(
                      Artist,
                      AddArtistFormModel.fields,
                      INCLUDE_NULL_VALUES,
                    ),
                  },
                },
              })
                .then(() => {
                  dispatchNotification({
                    detail: 'components.notifications.artistAdded',
                    level: ERRORS_ENUM.levels.SUCCESS,
                    placeholder: ERRORS_ENUM.placeholders.PAGE,
                  });

                  delay(() => {
                    setLoading(false);

                    // Scroll to top of container
                    scrollToRef();

                    history.push(UrlAssembler.artistList());
                  });
                })
                .catch((error: any) => {
                  setLoading(false);

                  // Map validation errors from response
                  const validationError = mapApiValidationErrors(error);

                  if (validationError && validationError.length && addApiErrors) {
                    addApiErrors(validationError);
                  } else {
                    // Map any other type of errors from response (validation without provided fields, or non-validation errors)
                    mapApiErrors(error).forEach((err) => {
                      dispatchNotification({
                        detail: err,
                        level: ERRORS_ENUM.levels.ERROR,
                        placeholder: ERRORS_ENUM.placeholders.PAGE,
                      });
                    });
                  }

                  // Scroll to error
                  scrollToRef();
                });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
