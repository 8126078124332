import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import {
  Title,
  FormViewField,
  FormHeader,
  LayoutPaper,
  Link,
  SplitButton,
  FormBuilder,
  CircularLoader,
} from '@riseart/dashboard';
import { offer as OFFER_ENUM, errors as ERRORS_ENUM } from '../../../config/enumeration.js';
import { delay } from '../../../services/riseart/utils/Utils';
import { ErrorMessageType, ErrorService } from '../../../services/riseart/errors/ErrorService';
import { Offer as OfferModel } from '../../../data/models/Offer';
import { DefaultLayout } from '../../layout/Default';
import { Status } from '../../common/Status';
import { splitButtonConfirmationProps } from '../../common/buttons/settings';
import { selectSeller } from '../../../services/redux/selectors/seller';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { mapApiValidationErrors, mapApiErrors } from '../../../services/riseart/utils/Form';
import { formatCurrency, getStoreByCurrencyCode } from '../../../services/riseart/utils/Utils';
import { OfferActionCounterFormModel, OfferActionDeclineFormModel } from '../../forms/models/offer';
import { READ_SELLER_OFFER } from '../../../data/gql/queries/offer/readSeller.graphql';
import { ACTION_OFFER } from '../../../data/gql/queries/offer/action.graphql';
import { COUNTER_OFFER } from '../../../data/gql/queries/offer/counter.graphql';

/**
 * OfferDetailsPage
 *
 * @returns {JSX.Element}
 */
export function OfferDetailsPage(): JSX.Element {
  const { formatMessage } = useIntl();
  const NOT_APPLICABLE = formatMessage({ id: 'common.notApplicable' });
  const { id: sellerId } = useSelector(selectSeller) || {};
  const { id: resourceId }: Record<string, any> = useParams();
  const { search } = useLocation();
  const { action: initialAction } = queryString.parse(search);
  const [dialogErrors, setDialogErrors] = useState<ErrorMessageType[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    data: readData,
    loading: readLoading,
    refetch: refetchReadQuery,
  } = useQuery(READ_SELLER_OFFER, {
    variables: { offerId: resourceId, sellerId },
    fetchPolicy: 'network-only',
  });
  const [createOfferAction, { loading: createOfferActionLoading }] = useMutation(ACTION_OFFER);
  const [counterOfferAction, { loading: counterOfferActionLoading }] = useMutation(COUNTER_OFFER);
  const actionLoading = createOfferActionLoading || counterOfferActionLoading;

  // Text labels
  const homePageLabel = formatMessage({ id: 'common.home' });
  const listLabel = formatMessage({ id: 'components.mainMenu.offersList' });

  // Load only once when the component is initially rendered
  useEffect(() => {
    if (initialAction) {
      createOfferAction({ variables: { id: resourceId, action: initialAction } })
        .then(() => delay(() => refetchReadQuery()))
        .catch(() => null);
    }
  }, []);

  const Offer = new OfferModel();

  if (readData) {
    Offer.hydrateFromApiData(readData.readSellerOffer);
  }

  const sellerStoreCode = getStoreByCurrencyCode(Offer.sellerCurrencyCode);
  const [mainAction, ...secondaryActions] = [
    ...[
      {
        actionKey: OFFER_ENUM.action.ACCEPT,
        requireConfirmation: true,
        children: formatMessage({ id: 'components.offer.action.accept' }),
        isAllowed: Offer.canAccept,
      },
      {
        actionKey: OFFER_ENUM.action.COUNTER,
        requireConfirmation: true,
        children: formatMessage({ id: 'components.offer.action.counter' }),
        isAllowed: Offer.canCounter,
      },
      {
        actionKey: OFFER_ENUM.action.DECLINE,
        requireConfirmation: true,
        children: formatMessage({ id: 'components.offer.action.decline' }),
        isAllowed: Offer.canDecline,
      },
    ].reduce(
      (
        accumulator: Record<string, any>[],
        { actionKey, children, requireConfirmation, isAllowed },
      ) => {
        return isAllowed
          ? [
              ...accumulator,
              {
                actionKey,
                requireConfirmation,
                text: children,
                children,
                onClick: () => {
                  createOfferAction({ variables: { id: resourceId, action: actionKey } })
                    .then(() => delay(() => refetchReadQuery()))
                    .catch(() => null);
                },
              },
            ]
          : accumulator;
      },
      [],
    ),
  ];

  return (
    <DefaultLayout
      title={listLabel}
      loading={loading || readLoading || actionLoading}
      breadcrumbs={[
        <Link
          key="home"
          to={UrlAssembler.byRouteKey('home')}
          title={homePageLabel}
          underline="hover"
          color="inherit"
        >
          {homePageLabel}
        </Link>,
        <Link
          key="root"
          to={UrlAssembler.offersList()}
          title={listLabel}
          underline="hover"
          color="inherit"
        >
          {listLabel}
        </Link>,
        ...(Offer.id
          ? [
              <Typography key="cmdPage" variant="inherit">
                {Offer.art.title}
              </Typography>,
            ]
          : []),
      ]}
    >
      {Offer.id ? (
        <LayoutPaper>
          <FormHeader
            title={
              <Title variant="h5">
                {formatMessage(
                  { id: 'components.offer.detailsTitle' },
                  { artwork: Offer.art.title },
                )}
              </Title>
            }
          >
            <SplitButton
              size="large"
              mainButtonProps={mainAction}
              translationPrefix="components.dialog.offer.actions"
              confirmationDialogProps={(props: Record<string, any>) => {
                const { actionKey, translationPrefix } = props;
                const actionToProps = {
                  [OFFER_ENUM.action.COUNTER]: {
                    title: 'counterTitle',
                    formModel: OfferActionCounterFormModel(Offer),
                    mutation: counterOfferAction,
                  },
                  [OFFER_ENUM.action.DECLINE]: {
                    title: 'declineTitle',
                    action: OFFER_ENUM.action.DECLINE,
                    formModel: OfferActionDeclineFormModel,
                    mutation: createOfferAction,
                  },
                };
                const dialogProps = actionToProps[actionKey as keyof typeof actionToProps];

                if (dialogProps) {
                  return {
                    title: formatMessage({
                      id: `${translationPrefix}.${dialogProps.title}`,
                    }),
                    onClose: () => setDialogErrors(null),
                    description: ({ handleClose }: Record<string, any>) => (
                      <CircularLoader active={loading || actionLoading}>
                        <>
                          {dialogErrors?.map(({ detail }) => (
                            <Alert severity="error">{detail}</Alert>
                          ))}
                        </>
                        <FormBuilder
                          settings={dialogProps.formModel.settings}
                          fields={dialogProps.formModel.fields}
                          submitText={formatMessage({ id: 'common.save' })}
                          buttonSize="medium"
                          customData={{ offer: Offer }}
                          cancel={
                            <Button
                              onClick={handleClose}
                              type="button"
                              variant="outlined"
                              size="medium"
                              fullWidth
                              sx={{ minWidth: '80px' }}
                            >
                              {formatMessage({ id: 'common.cancel' })}
                            </Button>
                          }
                          onSubmit={async (formState, addApiErrors) => {
                            setDialogErrors(null);
                            setLoading(true);
                            dialogProps
                              .mutation({
                                variables: {
                                  id: resourceId,
                                  ...(dialogProps.action ? { action: dialogProps.action } : {}),
                                  ...(actionKey === OFFER_ENUM.action.COUNTER
                                    ? { amount: formState.data.amount }
                                    : {}),
                                  comment: formState.data.comment,
                                },
                              })
                              .then(() => {
                                delay(() => {
                                  delay(() => refetchReadQuery().finally(() => setLoading(false)));
                                  handleClose();
                                });
                              })
                              .catch((error: Error) => {
                                // Map validation errors from response
                                const validationError = mapApiValidationErrors(error);

                                if (validationError && validationError.length && addApiErrors) {
                                  addApiErrors(
                                    validationError.map((error) => ({
                                      ...error,
                                      ...(error.fieldName
                                        ? { fieldName: error.fieldName.replace('data.', '') }
                                        : null),
                                    })),
                                  );
                                } else {
                                  // Map any other type of errors from response (validation without provided fields, or non-validation errors)
                                  const mappedErrors = mapApiErrors(error).map((err: any) =>
                                    ErrorService.mapNotification({
                                      detail: err,
                                      level: ERRORS_ENUM.levels.ERROR,
                                      placeholder: ERRORS_ENUM.placeholders.APP,
                                    }),
                                  );

                                  setDialogErrors(mappedErrors);
                                }

                                setLoading(false);
                                return true;
                              });
                          }}
                        />
                      </CircularLoader>
                    ),
                  };
                }

                return splitButtonConfirmationProps(props);
              }}
              options={secondaryActions}
            />
          </FormHeader>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={3}>
              <FormViewField label={formatMessage({ id: 'components.common.id' })}>
                {Offer.id}
              </FormViewField>
              <FormViewField label={formatMessage({ id: 'components.common.status' })}>
                {/* @ts-ignore */}
                {[null, undefined].indexOf(Offer.status) === -1 ? (
                  <Status type="offer" status={Offer.status} />
                ) : (
                  NOT_APPLICABLE
                )}
              </FormViewField>
              <FormViewField label={formatMessage({ id: 'components.common.state' })}>
                {/* @ts-ignore */}
                {[null, undefined].indexOf(Offer.state) === -1
                  ? formatMessage({ id: `components.offer.state.${Offer.state}` })
                  : NOT_APPLICABLE}
              </FormViewField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormViewField label={formatMessage({ id: 'components.offer.createdDate' })}>
                {/* @ts-ignore */}
                {[null, undefined].indexOf(Offer.created) === -1 ? (
                  <FormattedDate
                    value={Offer.created}
                    year="numeric"
                    month="short"
                    day="numeric"
                    weekday="short"
                  />
                ) : (
                  NOT_APPLICABLE
                )}
              </FormViewField>
              <FormViewField label={formatMessage({ id: 'components.offer.acceptedDate' })}>
                {/* @ts-ignore */}
                {[null, undefined].indexOf(Offer.acceptedDate) === -1 ? (
                  <FormattedDate
                    value={Offer.acceptedDate}
                    year="numeric"
                    month="short"
                    day="numeric"
                    weekday="short"
                  />
                ) : (
                  NOT_APPLICABLE
                )}
              </FormViewField>
              <FormViewField label={formatMessage({ id: 'components.offer.expiryDate' })}>
                {/* @ts-ignore */}
                {[null, undefined].indexOf(Offer.expiryDate) === -1 ? (
                  <FormattedDate
                    value={Offer.expiryDate}
                    year="numeric"
                    month="short"
                    day="numeric"
                    weekday="short"
                  />
                ) : (
                  NOT_APPLICABLE
                )}
              </FormViewField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormViewField label={formatMessage({ id: 'components.offer.sku' })}>
                {Offer.productSku || NOT_APPLICABLE}
              </FormViewField>
              <FormViewField label={formatMessage({ id: 'components.offer.art' })}>
                {Offer.art.title ? (
                  <Link to={UrlAssembler.artDetail(Offer.art.id)} title={Offer.art.title}>
                    {Offer.art.title}
                  </Link>
                ) : (
                  NOT_APPLICABLE
                )}
              </FormViewField>
              <FormViewField label={formatMessage({ id: 'components.offer.productPrice' })}>
                {[null, undefined].indexOf(Offer.sellerProductPrice) === -1
                  ? formatCurrency(Offer.sellerProductPrice, sellerStoreCode, undefined, {
                      hasSpaceAfterCurrency: true,
                    })
                  : NOT_APPLICABLE}
              </FormViewField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormViewField label={formatMessage({ id: 'components.offer.offerCurrency' })}>
                {[null, undefined].indexOf(Offer.sellerCurrencyCode) === -1
                  ? Offer.sellerCurrencyCode
                  : NOT_APPLICABLE}
              </FormViewField>
              <FormViewField label={formatMessage({ id: 'components.offer.offerPrice' })}>
                {[null, undefined].indexOf(Offer.sellerAmount) === -1
                  ? formatCurrency(Offer.sellerAmount, sellerStoreCode, undefined, {
                      hasSpaceAfterCurrency: true,
                    })
                  : NOT_APPLICABLE}
              </FormViewField>
              <FormViewField label={formatMessage({ id: 'components.offer.counterOffer' })}>
                {[null, undefined].indexOf(Offer.sellerCounter) === -1
                  ? formatCurrency(Offer.sellerCounter, sellerStoreCode, undefined, {
                      hasSpaceAfterCurrency: true,
                    })
                  : NOT_APPLICABLE}
              </FormViewField>
              <FormViewField label={formatMessage({ id: 'components.offer.offerAgreed' })}>
                {[null, undefined].indexOf(Offer.sellerAgreed) === -1
                  ? formatCurrency(Offer.sellerAgreed, sellerStoreCode, undefined, {
                      hasSpaceAfterCurrency: true,
                    })
                  : NOT_APPLICABLE}
              </FormViewField>
            </Grid>
          </Grid>
        </LayoutPaper>
      ) : null}
    </DefaultLayout>
  );
}
