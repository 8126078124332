import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/client';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { CircularLoader, FormBuilder, FormTable, FormTableCell } from '@riseart/dashboard';
import { AddSellerRateFormModel } from '../forms/models/sellerRate';
import { CREATE_SELLER_SHIPPING_TABLE_RATE } from '../../data/gql/queries/seller/createShippingTableRate.graphql';
import { TABLE_IN_ROW_CELL_PROPS } from '../common/tables/settings';

type Props = {
  tableId: string | number;
  sellerId: string | number;
  ratesItems: Record<string, any>[];
  storeCode: string;
  onCancel: () => any;
  onSuccess: (response: any) => any;
  onError?: (errors: any, addApiErrors: any) => any;
};

/**
 * ShippingRateAddRow
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const ShippingRateAddRow = ({
  tableId,
  sellerId,
  ratesItems,
  storeCode,
  onCancel,
  onSuccess,
  onError,
}: Props): JSX.Element => {
  const [createRate, { loading: createLoading }] = useMutation(CREATE_SELLER_SHIPPING_TABLE_RATE);

  return (
    <TableRow>
      <TableCell colSpan={4} {...TABLE_IN_ROW_CELL_PROPS}>
        <CircularLoader active={createLoading} style={{ minHeight: 'auto' }}>
          <FormBuilder
            inputLabelProps={{ shrink: true }}
            containerWrapper={FormTable}
            fieldWrapper={FormTableCell}
            fieldWrapperProps={[
              { width: '30%', sx: { pl: 4 } },
              { width: '25%' },
              { width: '25%' },
            ]}
            settings={AddSellerRateFormModel.settings}
            fields={AddSellerRateFormModel.fields}
            initialValues={{ pctCost: 0 }}
            customData={{
              tableId,
              sellerId,
              ratesItems,
              storeCode,
            }}
            showReset
            footer={({ hasErrors, disableSubmit }) => (
              <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <IconButton type="submit" size="large" disabled={hasErrors || disableSubmit}>
                  <DoneIcon
                    color={hasErrors || disableSubmit ? 'disabled' : 'success'}
                    fontSize="large"
                  />
                </IconButton>
                <IconButton size="large" onClick={onCancel}>
                  <CloseIcon color="error" fontSize="large" />
                </IconButton>
              </Stack>
            )}
            submitText={<FormattedMessage id="common.saveChanges" />}
            onSubmit={async (formState, addApiErrors) => {
              const { destination, baseCost, pctCost } = formState.data;

              createRate({
                variables: {
                  tableId,
                  sellerId,
                  inputShippingTableRate: { baseCost, pctCost, ...JSON.parse(destination) },
                },
              })
                .then(onSuccess)
                .catch(
                  (errors: any) => typeof onError === 'function' && onError(errors, addApiErrors),
                );
            }}
          />
        </CircularLoader>
      </TableCell>
    </TableRow>
  );
};
