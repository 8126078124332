import { FormattedMessage } from 'react-intl';
import { selectOptions } from '@riseart/dashboard';
import { partner as PARTNER_ENUM, acl as ACL_ENUM } from '../../../config/enumeration.js';
import { textFieldRenderer } from '../utils';
import { Status } from '../../common/Status';

/**
 * PartnerAdminModel
 */
export const PartnerAdminModel = (rowData?: Record<string, any>): Record<string, any> => ({
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      tag: 'input',
      type: 'text',
      name: 'id',
      width: '10%',
      customRender: textFieldRenderer,
      viewDataRenderer: (data: Record<string, any>) => data.userId,
      isReadonly: true,
    },
    {
      tag: 'input',
      type: 'text',
      name: 'name',
      width: '35%',
      customRender: textFieldRenderer,
      viewDataRenderer: (data: Record<string, any>) => data.fullName || null,
      isReadonly: true,
    },
    {
      tag: 'select',
      type: 'text',
      name: 'status',
      width: '20%',
      ...(rowData && rowData.status === PARTNER_ENUM.admin.status.ACTIVE
        ? {
            customRender: textFieldRenderer,
            viewDataRenderer: (data: Record<string, any>) =>
              [null, undefined].indexOf(data.status) === -1 ? (
                <Status type="partnerUser" status={data.status} />
              ) : null,
            isReadonly: true,
          }
        : {
            rules: [
              {
                required: true,
                message: 'forms.validation.required',
              },
            ],
            selectProps: {
              native: false,
              displayEmpty: true,
            },
            selectOptions: selectOptions(
              PARTNER_ENUM.admin.status,
              'components.partner.user.status.',
            ),
          }),
      placeholder: 'forms.common.status',
    },
    {
      type: 'text',
      name: 'aclRole',
      width: '20%',
      ...(rowData && rowData.status === PARTNER_ENUM.admin.status.ACTIVE
        ? {
            tag: 'select',
            rules: [
              {
                required: true,
                message: 'forms.validation.required',
              },
            ],
            selectProps: {
              native: false,
              displayEmpty: true,
            },
            selectOptions: selectOptions(
              {
                PARTNER_ADMIN: ACL_ENUM.role.PARTNER_ADMIN,
                SELLER_PARTNER: ACL_ENUM.role.SELLER_PARTNER,
              },
              'components.partner.acl.roles.',
            ),
            placeholder: 'components.tables.columns.common.aclRole',
          }
        : {
            tag: 'input',
            customRender: textFieldRenderer,
            viewDataRenderer: (data: Record<string, any>) =>
              data.aclRole ? (
                <FormattedMessage id={`components.partner.acl.roles.${data.aclRole}`} />
              ) : null,
          }),
      isReadonly: true,
    },
  ],
});

/**
 * PartnerUserInvitationModel
 */
export const PartnerUserInvitationModel = (): Record<string, any> => ({
  settings: {
    layout: 'inline',
    method: 'post',
    style: { minWidth: '360px' },
  },
  fields: [
    {
      tag: 'input',
      type: 'text',
      name: 'email',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'email',
          message: 'forms.auth.validation.invalidEmail',
        },
      ],
      placeholder: 'forms.common.email',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'aclRole',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      selectProps: {
        native: false,
        displayEmpty: true,
      },
      selectOptions: selectOptions(
        {
          PARTNER_ADMIN: ACL_ENUM.role.PARTNER_ADMIN,
          SELLER_PARTNER: ACL_ENUM.role.SELLER_PARTNER,
        },
        'components.partner.acl.roles.',
      ),
      placeholder: 'components.tables.columns.common.aclRole',
    },
  ],
});
