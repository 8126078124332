import { format } from 'date-fns';
import { Event as EventBaseModel } from '@riseart/models';
import { FormDataMixin } from './Core';
import { ApiInvalidData } from '../errors/ApiInvalidData';
import { event as EVENT_ENUM } from '../../config/enumeration.js';

/**
 * Event
 */
class Event extends FormDataMixin(EventBaseModel) {
  /**
   * decodeRsvpType
   *
   * @param {number} rsvpType
   * @returns {number[]}
   */
  static decodeRsvpType(rsvpType: number): number[] {
    if (
      rsvpType === null ||
      rsvpType === undefined ||
      rsvpType === EVENT_ENUM.rsvp.type.ATTENDEE_ALL
    ) {
      return [];
    }

    // Attendee none option is a single option value
    if (rsvpType === 0) {
      return [EVENT_ENUM.rsvp.type.ATTENDEE_NONE];
    }

    // Convert rsvpType to binary string
    // @ts-ignore
    let bitwiseValue = rsvpType.toString(2);
    const valueLength = bitwiseValue.length;
    bitwiseValue =
      (valueLength === 1 && `00${bitwiseValue}`) ||
      (valueLength === 2 && `0${bitwiseValue}`) ||
      bitwiseValue;
    // Each binary digit position defines if rsvpType enum value is raised or not
    const raisedFlags: string[] = bitwiseValue.split('');
    const options = [
      EVENT_ENUM.rsvp.type.ATTENDEE_LEADERS,
      EVENT_ENUM.rsvp.type.ATTENDEE_FOLLOWERS,
      EVENT_ENUM.rsvp.type.ATTENDEE_CONTACTS,
    ];

    return options.reduce<number[]>(
      (accumulator, value: number, index: number) =>
        parseInt(raisedFlags[index], 10) === 1 ? [...accumulator, value] : accumulator,
      [],
    );
  }

  /**
   * encodeRsvpType
   *
   * @param {number[]} rsvpType
   * @returns {number} sum of all ids
   */
  static encodeRsvpType(rsvpType: number[]): number {
    return rsvpType.reduce((accumulator, value) => (accumulator += value), 0);
  }

  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {Event}
   */
  public hydrateFromApiData(apiData?: Record<string, any>): Event {
    try {
      const { venueCountryCode, ...data } = apiData || {};

      // Hydrate model
      return this.hydrate({
        ...data,
        venueCountry: venueCountryCode,
        dateDuration: data ? [data.startDate, data.endDate] : [],
        timeDuration: data ? [data.startTime, data.endTime] : [],
        rsvpType: (data && Event.decodeRsvpType(data.rsvpType)) || [],
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load Event from provided data');
    }
  }

  /**
   * mapFormToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   * @returns {Record<string, any>}
   */
  public static mapFormToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
  ): Record<string, any> {
    const { dateDuration, timeDuration, visibility, rsvpType, ...restData } =
      super.mapFormToApiData(data, fieldsSchema, includeNullValues);
    const [startDate, endDate] = dateDuration || [];
    const [startTime, endTime] = timeDuration || [];

    return {
      ...restData,
      rsvpType:
        visibility === EVENT_ENUM.visibility.PRIVATE && [null, undefined].indexOf(rsvpType) === -1
          ? Event.encodeRsvpType(rsvpType)
          : EVENT_ENUM.rsvp.type.ATTENDEE_ALL,
      startDate: (startDate instanceof Date && format(startDate, 'yyyy-MM-dd')) || startDate,
      endDate: (endDate instanceof Date && format(endDate, 'yyyy-MM-dd')) || endDate,
      startTime: (startTime instanceof Date && format(startTime, 'HH:mm:ss')) || startTime,
      endTime: (endTime instanceof Date && format(endTime, 'HH:mm:ss')) || endTime,
    };
  }

  /**
   * getState
   *
   * @returns {number}
   */
  public getState(): number {
    if (this.status === EVENT_ENUM.status.ACTIVE && this.isFinished === true) {
      return EVENT_ENUM.state.FINISHED;
    }

    return this.status;
  }
}

export { Event };
