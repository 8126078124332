import queryString from 'query-string';
import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import {
  Title,
  FormBuilder,
  FormWrapper,
  FormHeader,
  LayoutPaper,
  Link,
  DataTable,
  LAYOUT_PAPER_PADDING,
} from '@riseart/dashboard';
import { useScrollToEl } from '../../../data/hooks/useScrollToEl';
import { DefaultLayout } from '../../layout/Default';
import { selectPartner } from '../../../services/redux/selectors/partner';
import { selectSeller } from '../../../services/redux/selectors/seller';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { CreatePartnerArtist } from './CreatePartner';
import { PartnerArtistFilterFormModel } from '../../forms/models/artist';
import { LINK_PARTNER_ARTIST } from '../../../data/gql/queries/partner/linkArtist.graphql';
import { LIST_ARTISTS } from '../../../data/gql/queries/artist/list.graphql';
import { DataListFeed } from '../../data/ListFeed';
import { ArtistPaginationResult as ArtistPaginationResultModel } from '../../../data/models/Artist';
import { ARTIST_PARTNER_LINK_LIST_TABLE_SCHEMA } from '../../data/tables/schemas/artist';

/**
 * ArtistAddPage
 *
 * @returns {JSX.Element}
 */
export const ArtistAddPage = (): JSX.Element => {
  const ITEMS_PER_PAGE = 10;
  const STEP: Record<string, string> = {
    INITIAL: 'initial',
    LINK: 'link',
    CREATE: 'create',
  };
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { setScrollRef, scrollToRef } = useScrollToEl();
  const partner = useSelector(selectPartner);
  const { storeCode } = useSelector(selectSeller) || {};
  const inputData = useRef<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<string>(STEP.INITIAL);
  const [filterArtistData, setFilterArtistData] = useState<Record<string, any> | undefined>();
  const [linkPartnerArtist, { loading: linkLoading }] = useMutation(LINK_PARTNER_ARTIST);

  const listLabel = formatMessage({ id: 'components.mainMenu.artistsList' });
  const addLabel = formatMessage({ id: 'common.add' });
  const createLabel = formatMessage({ id: 'common.create' });
  const linkTableTitle = formatMessage({ id: 'components.artist.link' });
  const linkBtnLabel = formatMessage({ id: 'components.artist.linkBtn' });
  const createArtistLabel = formatMessage({ id: 'components.artist.create' });
  const addArtistLabel = formatMessage({ id: 'components.artist.add' });
  const listUrl = UrlAssembler.artistList();
  const addLink = UrlAssembler.addArtist();

  return (
    <DefaultLayout
      loading={loading || linkLoading}
      title={listLabel}
      breadcrumbs={[
        <Link
          key="home"
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link key="artistList" to={listUrl} title={listLabel} underline="hover" color="inherit">
          {listLabel}
        </Link>,

        ...(step === STEP.INITIAL
          ? [
              <Typography key="add" variant="inherit">
                {addLabel}
              </Typography>,
            ]
          : []),
        ...([STEP.LINK, STEP.CREATE].indexOf(step) > -1
          ? [
              <Link
                key="addInitial"
                underline="hover"
                color="inherit"
                to={addLink}
                title={addLabel}
                onClick={() => setStep(STEP.INITIAL)}
              >
                {addLabel}
              </Link>,
            ]
          : []),
        ...(step === STEP.LINK
          ? [
              <Typography key="link" variant="inherit">
                {linkBtnLabel}
              </Typography>,
            ]
          : []),
        ...(step === STEP.CREATE
          ? [
              <Typography key="link" variant="inherit">
                {createLabel}
              </Typography>,
            ]
          : []),
      ]}
      boxRefCallback={setScrollRef}
      topContent={
        step === STEP.LINK && filterArtistData ? (
          <Alert severity="warning">{formatMessage({ id: 'components.artist.linkMessage' })}</Alert>
        ) : null
      }
    >
      {!filterArtistData || step === STEP.INITIAL ? (
        <LayoutPaper>
          <FormHeader title={<Title variant="h5">{addArtistLabel}</Title>} />
          <FormWrapper size="medium">
            <FormBuilder
              settings={PartnerArtistFilterFormModel.settings}
              fields={PartnerArtistFilterFormModel.fields}
              submitText={formatMessage({ id: 'common.add' })}
              customData={{ storeCode }}
              initialValues={{}}
              cancel={
                <Button
                  component={Link}
                  to={listUrl}
                  type="button"
                  variant="outlined"
                  size="large"
                  fullWidth
                >
                  {formatMessage({ id: 'common.cancel' })}
                </Button>
              }
              onSubmit={(formState) => {
                inputData.current = formState.data;
                setStep(STEP.LINK);
                setFilterArtistData(formState.data);
              }}
            />
          </FormWrapper>
        </LayoutPaper>
      ) : (
        <></>
      )}
      {step === STEP.LINK && filterArtistData ? (
        <Paper sx={{ position: 'relative' }}>
          <DataListFeed
            query={LIST_ARTISTS}
            itemsPerPage={ITEMS_PER_PAGE}
            buildUrl={({ page, itemsPerPage = ITEMS_PER_PAGE, qsParams }: Record<string, any>) => {
              const search = queryString.stringify({
                ...qsParams,
                ...(page && page > 1 ? { page } : null),
                ...(itemsPerPage && itemsPerPage !== ITEMS_PER_PAGE ? { itemsPerPage } : null),
              });
              return UrlAssembler.addArtist({ search: search ? `?${search}` : '' });
            }}
            variablesMapper={({
              page = 1,
              itemsPerPage,
            }: Record<string, any>): Record<string, any> => ({
              page,
              items: itemsPerPage || ITEMS_PER_PAGE,
              sort: 'alpha',
              order: 'ASC',
              filters: Object.keys(filterArtistData).reduce(
                (accumulator: Record<string, any>, fieldName: string) => {
                  if (filterArtistData[fieldName]) {
                    accumulator[fieldName] = {
                      value: filterArtistData[fieldName],
                      operator: fieldName === 'name' ? 'CONTAINS' : 'EQUAL',
                    };
                  }

                  return accumulator;
                },
                {},
              ),
            })}
          >
            {({
              loading: fetchLoading,
              refetchListQuery,
              items,
              pagination,
            }: Record<string, any>) => {
              const noItems = items && !items.length;
              const PartnerArtistPaginationResult =
                new ArtistPaginationResultModel().hydrateFromApiData({
                  items,
                  pagination,
                });

              if (!fetchLoading && filterArtistData.name && filterArtistData.birthYear && noItems) {
                // If no items are found for initial search and name and birthYear are provided,
                // refetch the query but only using the birthYear
                setFilterArtistData({ name: null, birthYear: filterArtistData.birthYear });
                setLoading(true);
              } else if (
                loading &&
                !fetchLoading &&
                !filterArtistData.name &&
                filterArtistData.birthYear &&
                noItems
              ) {
                setLoading(false);
              } else if (!loading && !fetchLoading && noItems) {
                // Move to create step if no results found
                setStep(STEP.CREATE);
              }

              return (
                <DataTable
                  columns={[
                    {
                      title: formatMessage({ id: 'components.tables.columns.common.id' }),
                      sx: {
                        textAlign: 'right',
                        pl: LAYOUT_PAPER_PADDING.pl,
                        whiteSpace: 'nowrap',
                      },
                      width: '10%',
                    },
                    {
                      title: formatMessage({ id: 'components.tables.columns.common.name' }),
                      sx: { display: { xs: 'none', sm: 'table-cell' }, whiteSpace: 'nowrap' },
                      width: '25%',
                    },
                    {
                      title: formatMessage({ id: 'components.tables.columns.common.country' }),
                      sx: { display: { xs: 'none', sm: 'table-cell' }, whiteSpace: 'nowrap' },
                      width: '20%',
                    },
                    {
                      title: formatMessage({ id: 'components.tables.columns.artist.gender' }),
                      width: '15%',
                    },
                    {
                      title: formatMessage({ id: 'components.tables.columns.artist.birthYear' }),
                      sx: { textAlign: 'right', whiteSpace: 'nowrap' },
                      width: '10%',
                    },
                    {
                      title: formatMessage({ id: 'components.tables.columns.common.action' }),
                      sx: { textAlign: 'right', whiteSpace: 'nowrap' },
                      width: '20%',
                    },
                  ]}
                  schema={ARTIST_PARTNER_LINK_LIST_TABLE_SCHEMA}
                  layoutPaperPadding={LAYOUT_PAPER_PADDING}
                  customData={{
                    setLoading,
                    partnerId: partner && partner.id,
                    history,
                    listUrl,
                    refetchListQuery,
                    linkPartnerArtist,
                    linkLoading,
                    entityUrlAssembler: (id: number, options?: Record<string, any>) =>
                      UrlAssembler.artistDetails(id, options),
                  }}
                  data={PartnerArtistPaginationResult.items}
                  refetchListQuery={refetchListQuery}
                  pagination={pagination}
                  noData={formatMessage({ id: 'common.noItems' })}
                  loading={fetchLoading}
                  mainAction={
                    <Box
                      sx={{
                        display: { xs: 'none', sm: 'block' },
                        flexGrow: 1,
                        pr: 1,
                        textAlign: 'right',
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        title={createArtistLabel}
                        onClick={() => setStep(STEP.CREATE)}
                      >
                        {createArtistLabel}
                      </Button>
                    </Box>
                  }
                  title={linkTableTitle}
                />
              );
            }}
          </DataListFeed>
        </Paper>
      ) : (
        <></>
      )}
      {step === STEP.CREATE ? (
        <LayoutPaper>
          <CreatePartnerArtist
            onLoading={setLoading}
            scrollToRef={scrollToRef}
            partnerId={partner && partner.id}
            initialValues={inputData.current}
          />
        </LayoutPaper>
      ) : (
        <></>
      )}
    </DefaultLayout>
  );
};
