import { FormSelectNoneOption } from '@riseart/dashboard';
import { countriesListOptions } from '../../utils';

/**
 * ArtistCollectionModel
 */
export const ArtistCollectionModel = {
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      tag: 'input',
      type: 'text',
      name: 'collection',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
      placeholder: 'forms.collection.label.collection',
      hint: 'forms.collection.hints.collection',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'city',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'stringLength',
          options: { max: 45 },
          message: 'forms.validation.maxLength45',
        },
      ],
      placeholder: 'forms.collection.label.city',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'countryCode',
      margin: 'none',
      selectProps: { native: false, displayEmpty: true },
      initialOption: FormSelectNoneOption(),
      selectOptions: countriesListOptions,
      placeholder: 'forms.collection.label.country',
    },
  ],
};
