export const DIMENSIONS = [
  {
    name: 'medium',
    values: [
      { name: 'collagists' },
      { name: 'digital' },
      { name: 'draftspersons' },
      { name: 'installation' },
      { name: 'painters' },
      { name: 'photographers' },
      { name: 'printmakers' },
      { name: 'sculptors' },
    ],
  },
  {
    name: 'style',
    values: [
      { name: 'abstract' },
      { name: 'abstract-expressionism' },
      { name: 'abstract-marble' },
      { name: 'art-deco' },
      { name: 'cartoon' },
      { name: 'conceptual' },
      { name: 'cubism' },
      { name: 'colour-field' },
      { name: 'dada' },
      { name: 'expressionistic' },
      { name: 'figurative' },
      { name: 'geometric' },
      { name: 'geometric-abstract' },
      { name: 'graphic' },
      { name: 'illustrative' },
      { name: 'impressionistic' },
      { name: 'minimalistic' },
      { name: 'mosaic' },
      { name: 'photorealism' },
      { name: 'pop-art' },
      { name: 'realistic' },
      { name: 'stencil' },
      { name: 'street-art' },
      { name: 'surrealist' },
    ],
  },
];
