import { useRef, MutableRefObject } from 'react';

type HookReturnType = {
  scrollRef: MutableRefObject<Record<string, any> | null>;
  setScrollRef: (ref: MutableRefObject<Record<string, any> | null>) => void;
  scrollToRef: () => void;
};

/**
 * useScrollToEl
 *
 * @returns
 */
export function useScrollToEl(): HookReturnType {
  let scrollRef: MutableRefObject<Record<string, any> | null> = useRef(null);

  /**
   * scrollToRef
   */
  function scrollToRef(): void {
    if (scrollRef && scrollRef.current && typeof scrollRef.current.scrollTo === 'function') {
      scrollRef.current.scrollTo(0, 0);
    }
  }

  /**
   * setScrollRef
   *
   * @param {MutableRefObject<Record<string, any> | null>} ref
   */
  function setScrollRef(ref: MutableRefObject<Record<string, any> | null>): void {
    scrollRef = ref;
  }

  return { scrollRef, setScrollRef, scrollToRef };
}
