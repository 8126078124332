import MenuItem from '@mui/material/MenuItem';
import { Link } from '@riseart/dashboard';
import { LocalizedConfig } from '../../../services/riseart/utils/LocalizedConfig';

/**
 * mediaPlatformListOptions
 * @param {Record<string, any>} platformEnum
 * @returns
 */
function mediaPlatformListOptions(
  platformEnum: Record<string, any>,
): (helpers: {
  formatMessage: (options: Record<string, any>) => any;
  initialOption: any;
}) => JSX.Element[] {
  return ({
    formatMessage,
    initialOption,
  }: {
    formatMessage: (options: Record<string, any>) => any;
    initialOption: any;
  }): JSX.Element[] => {
    return [
      ...(initialOption ? [initialOption] : []),
      ...Object.keys(platformEnum).map((key: string) => {
        const value = platformEnum[key];
        return (
          <MenuItem value={value} key={value}>
            {formatMessage({ id: `components.media.platform.${value}` })}
          </MenuItem>
        );
      }),
    ];
  };
}

/**
 * MediaAddModel
 *
 * @param {Record<string, any>} platformEnum
 * @param {Record<string, any>} typeEnum
 * @returns {Record<string, any>}
 */
export const MediaAddFormModel = (
  platformEnum: Record<string, any>,
  typeEnum: Record<string, any>,
): Record<string, any> => ({
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      tag: 'select',
      type: 'text',
      name: 'mediaPlatform',
      selectProps: { native: false, displayEmpty: true },
      selectOptions: mediaPlatformListOptions(platformEnum),
      shouldRender: ({ mediaType }: { mediaType: string }): boolean => mediaType === typeEnum.VIDEO,
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      placeholder: 'forms.media.label.platform',
      hint: 'forms.media.hints.platform',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'mediaId',
      shouldRender: ({ mediaType }: { mediaType: string }): boolean => mediaType === typeEnum.VIDEO,
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'custom',
          custom: (value: string | number, data: Record<string, any>): boolean => {
            if (data.mediaPlatform === platformEnum.YOUTUBE) {
              return typeof value === 'string' && value.length > 0
                ? !/^([a-zA-Z0-9-_]+)$/.test(value)
                : false;
            }

            return false;
          },
          message: 'forms.validation.mediaPlatform.youtube',
        },
        {
          type: 'custom',
          custom: (value: string | number, data: Record<string, any>): boolean => {
            if (data.mediaPlatform === platformEnum.VIMEO) {
              return typeof value === 'string' && value.length > 0
                ? !/^([0-9]+)$/.test(value)
                : false;
            }

            return false;
          },
          message: 'forms.validation.mediaPlatform.vimeo',
        },
        {
          type: 'custom',
          custom: (value: string | number, data: Record<string, any>): boolean => {
            // @ts-ignore
            if (data.mediaPlatform === platformEnum.INSTAGRAM) {
              return typeof value === 'string' && value.length > 0
                ? !/^([a-zA-Z0-9-_]+)$/.test(value)
                : false;
            }

            return false;
          },
          message: 'forms.validation.mediaPlatform.instagram',
        },
      ],
      placeholder: 'forms.media.label.videoId',
      hint: (
        options: { storeCode: string },
        formatMessage: (values: Record<string, any>, options?: Record<string, any>) => any,
      ): JSX.Element =>
        formatMessage(
          { id: 'forms.media.hints.videoId' },
          {
            a: (chunk: any) => (
              <Link
                href={LocalizedConfig.get('externalUrls.artMediaHelp')}
                target="_blank"
                color="inherit"
                external
                title={formatMessage({ id: 'forms.media.hints.videoIdLinkTitle' })}
              >
                {chunk}
              </Link>
            ),
          },
        ),
    },
  ],
});
