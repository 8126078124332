import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useQuery, useMutation } from '@apollo/client';
import { LAYOUT_PAPER_PADDING } from '@riseart/dashboard';
import { partner as PARTNER_ENUM } from '../../../config/enumeration.js';
import { PartnerAdmin, PartnerAdminPaginationResult } from '../../../data/models/Partner';
import { PartnerAdminModel as PartnerAdminFormModel } from '../../forms/models/partner';
import { delay } from '../../../services/riseart/utils/Utils';
import { selectUser } from '../../../services/redux/selectors/user';
import { AdvancedTable } from '../../common/tables/Advanced';
import { EditRow } from '../../common/tables/EditRow';
import { StatusSelectButton } from '../../common/buttons/StatusSelect';
import { PARTNER_USER_TABLE_SCHEMA } from '../../data/tables/schemas/partner';
import { LIST_PARTNER_ADMINS } from '../../../data/gql/queries/partner/listAdmins.graphql';
import { UPDATE_PARTNER_ADMIN } from '../../../data/gql/queries/partner/updateAdmin.graphql';

type Props = {
  partnerId: number | string;
};

/**
 * PartnerAdmins
 *
 * @returns {JSX.Element}
 */
export function PartnerAdmins({ partnerId }: Props): JSX.Element {
  const ITEMS_PER_PAGE = 10;
  const FIELD_WRAPPER_PROPS = [
    { width: '10%', sx: { pl: 0 } },
    { width: '35%' },
    { width: '20%' },
    { width: '20%' },
    { width: '15%' },
  ];
  const { formatMessage } = useIntl();
  const { id: loggedUserId } = useSelector(selectUser) || {};
  const [show, setShow] = useState<string | number>(PARTNER_ENUM.admin.status.ACTIVE);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [updateAdminMutation, { loading: updateAdminLoading }] = useMutation(UPDATE_PARTNER_ADMIN);
  const {
    data: listAdminsData,
    loading: listAdminsLoading,
    refetch: refetchListAdminsQuery,
  } = useQuery(LIST_PARTNER_ADMINS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      partnerId,
      page: currentPage,
      items: itemsPerPage || ITEMS_PER_PAGE,
      ...(show !== -1 ? { filters: { status: show } } : null),
    },
  });

  const PartnerAdminPaginationModel = new PartnerAdminPaginationResult();

  if (listAdminsData && listAdminsData.listPartnerAdmins) {
    PartnerAdminPaginationModel.hydrateFromApiData(listAdminsData.listPartnerAdmins);
  }

  return (
    <AdvancedTable
      layoutPaperPadding={LAYOUT_PAPER_PADDING}
      data={PartnerAdminPaginationModel.items}
      loading={listAdminsLoading}
      rowsPerPageOptions={[10]}
      pagination={
        PartnerAdminPaginationModel.pagination
          ? {
              ...PartnerAdminPaginationModel.pagination,
              handleChangeRowsPerPage: (
                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
              ) => {
                setItemsPerPage(parseInt(event.target.value, 10));
              },
              handleChangePage: (
                event: React.MouseEvent<HTMLButtonElement> | null,
                page: number,
              ) => {
                setCurrentPage(page + 1);
              },
            }
          : null
      }
      // @ts-ignore
      refetchListHandler={() => delay(refetchListAdminsQuery)}
      customData={{
        loggedUserId,
        updateRequest: updateAdminMutation,
        updateLoading: listAdminsLoading || updateAdminLoading,
        updateMutation: async (userId: number, status: string | number) =>
          await updateAdminMutation({
            variables: {
              userId,
              partnerId,
              inputPartnerAdmin: { status },
            },
          }),
      }}
      title={formatMessage({ id: 'components.settings.team.title' })}
      mainAction={<></>}
      secondaryAction={
        <StatusSelectButton
          initialValue={PARTNER_ENUM.admin.status.ACTIVE}
          optionsList={{ ALL: -1, ...PARTNER_ENUM.admin.status }}
          optionsTranslationPrefix="components.partner.user.status"
          dialogTranslationPrefix="components.dialog.partner.action"
          onChange={(show: string | number) => {
            setShow(show);
          }}
        />
      }
      schema={PARTNER_USER_TABLE_SCHEMA}
      columns={[
        {
          title: formatMessage({ id: 'components.tables.columns.common.id' }),
          width: '10%',
        },
        {
          title: formatMessage({ id: 'components.tables.columns.common.name' }),
          width: '35%',
        },
        {
          title: formatMessage({ id: 'components.tables.columns.common.status' }),
          width: '20%',
        },
        {
          title: formatMessage({ id: 'components.tables.columns.common.aclRole' }),
          width: '20%',
        },
        {
          title: '',
          align: 'right',
          width: '15%',
        },
      ]}
      canEdit={({ data }: Record<string, any>): boolean =>
        // Partner admin is the logged in user and cannot update itself
        !(parseInt(data.userId, 10) === parseInt(loggedUserId, 10))
      }
      editRenderer={({ handleSuccess }: Record<string, any>) =>
        ({ data, switchToDisplayMode }: Record<string, any>) =>
          (
            <EditRow
              colSpan={5}
              mutation={updateAdminMutation}
              variablesBuilder={(formData) => ({
                variables: {
                  userId: data.userId,
                  partnerId,
                  inputPartnerAdmin: { aclRole: formData.aclRole },
                },
              })}
              loading={updateAdminLoading}
              formModel={PartnerAdminFormModel(data)}
              dataModel={PartnerAdmin}
              customData={{ data }}
              fieldWrapperProps={FIELD_WRAPPER_PROPS}
              data={{ id: data.userId, ...data }}
              onSuccess={handleSuccess(switchToDisplayMode)}
              onCancel={switchToDisplayMode}
            />
          )}
    />
  );
}
