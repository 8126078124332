import { gql } from '@apollo/client';
import { SELLER_SHIPPING_TABLE_RATE_FRAGMENT } from '../../fragments/seller/shippingTableRate.graphql';

export const CREATE_SELLER_SHIPPING_TABLE_RATE = gql`
  ${SELLER_SHIPPING_TABLE_RATE_FRAGMENT}
  mutation createSellerShippingTableRate(
    $tableId: Int!
    $sellerId: Int!
    $inputShippingTableRate: InputShippingTableRate!
  ) {
    createSellerShippingTableRate(
      tableId: $tableId
      sellerId: $sellerId
      inputShippingTableRate: $inputShippingTableRate
    ) {
      ...SellerShippingTableRateFragment
    }
  }
`;
