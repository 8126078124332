export const countries = {
  all: [
    'GB',
    'US',
    'FR',
    'DE',
    'ES',
    'AF',
    'AX',
    'AL',
    'DZ',
    'AS',
    'AD',
    'AO',
    'AI',
    'AQ',
    'AG',
    'AR',
    'AM',
    'AW',
    'AU',
    'AT',
    'AZ',
    'BS',
    'BH',
    'BD',
    'BB',
    'BY',
    'BE',
    'BZ',
    'BJ',
    'BM',
    'BT',
    'BO',
    'BA',
    'BW',
    'BV',
    'BR',
    'IO',
    'BN',
    'BG',
    'BF',
    'BI',
    'KH',
    'CM',
    'CA',
    'CV',
    'KY',
    'CF',
    'TD',
    'CL',
    'CN',
    'CX',
    'CC',
    'CO',
    'KM',
    'CD',
    'CG',
    'CK',
    'CR',
    'CI',
    'HR',
    'CU',
    'CY',
    'CZ',
    'DK',
    'DJ',
    'DM',
    'DO',
    'EC',
    'EG',
    'SV',
    'GQ',
    'ER',
    'EE',
    'ET',
    'FK',
    'FO',
    'FJ',
    'FI',
    'GF',
    'PF',
    'TF',
    'GA',
    'GM',
    'GE',
    'GH',
    'GI',
    'GR',
    'GL',
    'GD',
    'GP',
    'GU',
    'GT',
    'GG',
    'GN',
    'GW',
    'GY',
    'HT',
    'HM',
    'VA',
    'HN',
    'HK',
    'HU',
    'IS',
    'IN',
    'ID',
    'IR',
    'IQ',
    'IE',
    'IM',
    'IL',
    'IT',
    'JM',
    'JP',
    'JE',
    'JO',
    'KZ',
    'KE',
    'KI',
    'KP',
    'KR',
    'KW',
    'KG',
    'LA',
    'LV',
    'LB',
    'LS',
    'LR',
    'LY',
    'LI',
    'LT',
    'LU',
    'MO',
    'MK',
    'MG',
    'MW',
    'MY',
    'MV',
    'ML',
    'MT',
    'MH',
    'MQ',
    'MR',
    'MU',
    'YT',
    'MX',
    'FM',
    'MD',
    'MC',
    'MN',
    'ME',
    'MS',
    'MA',
    'MZ',
    'MM',
    'NA',
    'NR',
    'NP',
    'NL',
    'AN',
    'NC',
    'NZ',
    'NI',
    'NE',
    'NG',
    'NU',
    'NF',
    'MP',
    'NO',
    'OM',
    'PK',
    'PW',
    'PS',
    'PA',
    'PG',
    'PY',
    'PE',
    'PH',
    'PN',
    'PL',
    'PT',
    'PR',
    'QA',
    'RE',
    'RO',
    'RU',
    'RW',
    'SH',
    'KN',
    'LC',
    'MF',
    'PM',
    'VC',
    'WS',
    'SM',
    'ST',
    'SA',
    'SN',
    'RS',
    'SC',
    'SL',
    'SG',
    'SK',
    'SI',
    'SB',
    'SO',
    'ZA',
    'GS',
    'LK',
    'SD',
    'SR',
    'SJ',
    'SZ',
    'SE',
    'CH',
    'SY',
    'TW',
    'TJ',
    'TZ',
    'TH',
    'TL',
    'TG',
    'TK',
    'TO',
    'TT',
    'TN',
    'TR',
    'TM',
    'TC',
    'TV',
    'UG',
    'UA',
    'AE',
    'UM',
    'UY',
    'UZ',
    'VU',
    'VE',
    'VN',
    'VG',
    'VI',
    'WF',
    'EH',
    'YE',
    'ZM',
    'ZW',
  ],
  billing: [
    'GB',
    'US',
    'FR',
    'DE',
    'ES',
    'AF',
    'AX',
    'AL',
    'DZ',
    'AS',
    'AD',
    'AO',
    'AI',
    'AQ',
    'AG',
    'AR',
    'AM',
    'AW',
    'AU',
    'AT',
    'AZ',
    'BS',
    'BH',
    'BD',
    'BB',
    'BY',
    'BE',
    'BZ',
    'BJ',
    'BM',
    'BT',
    'BO',
    'BA',
    'BW',
    'BV',
    'BR',
    'IO',
    'BN',
    'BG',
    'BF',
    'BI',
    'KH',
    'CM',
    'CA',
    'CV',
    'KY',
    'CF',
    'TD',
    'CL',
    'CN',
    'CX',
    'CC',
    'CO',
    'KM',
    'CD',
    'CG',
    'CK',
    'CR',
    'CI',
    'HR',
    'CU',
    'CY',
    'CZ',
    'DK',
    'DJ',
    'DM',
    'DO',
    'EC',
    'EG',
    'SV',
    'GQ',
    'ER',
    'EE',
    'ET',
    'FK',
    'FO',
    'FJ',
    'FI',
    'GF',
    'PF',
    'TF',
    'GA',
    'GM',
    'GE',
    'GH',
    'GI',
    'GR',
    'GL',
    'GD',
    'GP',
    'GU',
    'GT',
    'GG',
    'GN',
    'GW',
    'GY',
    'HT',
    'HM',
    'VA',
    'HN',
    'HK',
    'HU',
    'IS',
    'IN',
    'ID',
    'IR',
    'IQ',
    'IE',
    'IM',
    'IL',
    'IT',
    'JM',
    'JP',
    'JE',
    'JO',
    'KZ',
    'KE',
    'KI',
    'KP',
    'KR',
    'KW',
    'KG',
    'LA',
    'LV',
    'LB',
    'LS',
    'LR',
    'LY',
    'LI',
    'LT',
    'LU',
    'MO',
    'MK',
    'MG',
    'MW',
    'MY',
    'MV',
    'ML',
    'MT',
    'MH',
    'MQ',
    'MR',
    'MU',
    'YT',
    'MX',
    'FM',
    'MD',
    'MC',
    'MN',
    'ME',
    'MS',
    'MA',
    'MZ',
    'MM',
    'NA',
    'NR',
    'NP',
    'NL',
    'AN',
    'NC',
    'NZ',
    'NI',
    'NE',
    'NG',
    'NU',
    'NF',
    'MP',
    'NO',
    'OM',
    'PK',
    'PW',
    'PS',
    'PA',
    'PG',
    'PY',
    'PE',
    'PH',
    'PN',
    'PL',
    'PT',
    'PR',
    'QA',
    'RE',
    'RO',
    'RU',
    'RW',
    'SH',
    'KN',
    'LC',
    'MF',
    'PM',
    'VC',
    'WS',
    'SM',
    'ST',
    'SA',
    'SN',
    'RS',
    'SC',
    'SL',
    'SG',
    'SK',
    'SI',
    'SB',
    'SO',
    'ZA',
    'GS',
    'LK',
    'SD',
    'SR',
    'SJ',
    'SZ',
    'SE',
    'CH',
    'SY',
    'TW',
    'TJ',
    'TZ',
    'TH',
    'TL',
    'TG',
    'TK',
    'TO',
    'TT',
    'TN',
    'TR',
    'TM',
    'TC',
    'TV',
    'UG',
    'UA',
    'AE',
    'UM',
    'UY',
    'UZ',
    'VU',
    'VE',
    'VN',
    'VG',
    'VI',
    'WF',
    'EH',
    'YE',
    'ZM',
    'ZW',
  ],
  shipping: [
    'GB',
    'US',
    'FR',
    'DE',
    'ES',
    'AD',
    'AU',
    'AT',
    'BS',
    'BE',
    'BR',
    'BG',
    'CA',
    'CL',
    'CO',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'GR',
    'GG',
    'HK',
    'HU',
    'IN',
    'IE',
    'IM',
    'IL',
    'IT',
    'JP',
    'JE',
    'KR',
    'LV',
    'LI',
    'LT',
    'LU',
    'MY',
    'MT',
    'MX',
    'MC',
    'MA',
    'NL',
    'NZ',
    'NO',
    'PK',
    'PL',
    'PT',
    'QA',
    'RO',
    'RU',
    'SM',
    'SA',
    'SG',
    'SK',
    'SI',
    'ZA',
    'SE',
    'CH',
    'TW',
    'AE',
    'UY',
  ],
  dialing: [
    { code: 'gb', dialingCode: '44', dialingDefault: true, matchingDialingCode: '44' },
    { code: 'us', dialingCode: '1', dialingDefault: true, matchingDialingCode: '1' },
    { code: 'fr', dialingCode: '33', dialingDefault: true, matchingDialingCode: '33' },
    { code: 'de', dialingCode: '49', dialingDefault: true, matchingDialingCode: '49' },
    { code: 'es', dialingCode: '34', dialingDefault: true, matchingDialingCode: '34' },
    { code: 'af', dialingCode: '93', dialingDefault: true, matchingDialingCode: '93' },
    { code: 'ax', dialingCode: '358', dialingDefault: false, matchingDialingCode: '358' },
    { code: 'al', dialingCode: '355', dialingDefault: true, matchingDialingCode: '355' },
    { code: 'dz', dialingCode: '213', dialingDefault: true, matchingDialingCode: '213' },
    { code: 'as', dialingCode: '1-684', dialingDefault: true, matchingDialingCode: '1684' },
    { code: 'ad', dialingCode: '376', dialingDefault: true, matchingDialingCode: '376' },
    { code: 'ao', dialingCode: '244', dialingDefault: true, matchingDialingCode: '244' },
    { code: 'ai', dialingCode: '1-264', dialingDefault: true, matchingDialingCode: '1264' },
    { code: 'aq', dialingCode: '672', dialingDefault: true, matchingDialingCode: '672' },
    { code: 'ag', dialingCode: '1-268', dialingDefault: true, matchingDialingCode: '1268' },
    { code: 'ar', dialingCode: '54', dialingDefault: true, matchingDialingCode: '54' },
    { code: 'am', dialingCode: '374', dialingDefault: true, matchingDialingCode: '374' },
    { code: 'aw', dialingCode: '297', dialingDefault: true, matchingDialingCode: '297' },
    { code: 'au', dialingCode: '61', dialingDefault: true, matchingDialingCode: '61' },
    { code: 'at', dialingCode: '43', dialingDefault: true, matchingDialingCode: '43' },
    { code: 'az', dialingCode: '994', dialingDefault: true, matchingDialingCode: '994' },
    { code: 'bs', dialingCode: '1-242', dialingDefault: true, matchingDialingCode: '1242' },
    { code: 'bh', dialingCode: '973', dialingDefault: true, matchingDialingCode: '973' },
    { code: 'bd', dialingCode: '880', dialingDefault: true, matchingDialingCode: '880' },
    { code: 'bb', dialingCode: '1-246', dialingDefault: true, matchingDialingCode: '1246' },
    { code: 'by', dialingCode: '375', dialingDefault: true, matchingDialingCode: '375' },
    { code: 'be', dialingCode: '32', dialingDefault: true, matchingDialingCode: '32' },
    { code: 'bz', dialingCode: '501', dialingDefault: true, matchingDialingCode: '501' },
    { code: 'bj', dialingCode: '229', dialingDefault: true, matchingDialingCode: '229' },
    { code: 'bm', dialingCode: '1-441', dialingDefault: true, matchingDialingCode: '1441' },
    { code: 'bt', dialingCode: '975', dialingDefault: true, matchingDialingCode: '975' },
    { code: 'bo', dialingCode: '591', dialingDefault: true, matchingDialingCode: '591' },
    { code: 'ba', dialingCode: '387', dialingDefault: true, matchingDialingCode: '387' },
    { code: 'bw', dialingCode: '267', dialingDefault: true, matchingDialingCode: '267' },
    { code: 'bv', dialingCode: '47', dialingDefault: false, matchingDialingCode: '47' },
    { code: 'br', dialingCode: '55', dialingDefault: true, matchingDialingCode: '55' },
    { code: 'io', dialingCode: '246', dialingDefault: true, matchingDialingCode: '246' },
    { code: 'bn', dialingCode: '673', dialingDefault: true, matchingDialingCode: '673' },
    { code: 'bg', dialingCode: '359', dialingDefault: true, matchingDialingCode: '359' },
    { code: 'bf', dialingCode: '226', dialingDefault: true, matchingDialingCode: '226' },
    { code: 'bi', dialingCode: '257', dialingDefault: true, matchingDialingCode: '257' },
    { code: 'kh', dialingCode: '855', dialingDefault: true, matchingDialingCode: '855' },
    { code: 'cm', dialingCode: '237', dialingDefault: true, matchingDialingCode: '237' },
    { code: 'ca', dialingCode: '1', dialingDefault: false, matchingDialingCode: '1' },
    { code: 'cv', dialingCode: '238', dialingDefault: true, matchingDialingCode: '238' },
    { code: 'ky', dialingCode: '1-345', dialingDefault: true, matchingDialingCode: '1345' },
    { code: 'cf', dialingCode: '236', dialingDefault: true, matchingDialingCode: '236' },
    { code: 'td', dialingCode: '235', dialingDefault: true, matchingDialingCode: '235' },
    { code: 'cl', dialingCode: '56', dialingDefault: true, matchingDialingCode: '56' },
    { code: 'cn', dialingCode: '86', dialingDefault: true, matchingDialingCode: '86' },
    { code: 'cx', dialingCode: '61', dialingDefault: false, matchingDialingCode: '61' },
    { code: 'cc', dialingCode: '61', dialingDefault: false, matchingDialingCode: '61' },
    { code: 'co', dialingCode: '57', dialingDefault: true, matchingDialingCode: '57' },
    { code: 'km', dialingCode: '269', dialingDefault: true, matchingDialingCode: '269' },
    { code: 'cd', dialingCode: '243', dialingDefault: true, matchingDialingCode: '243' },
    { code: 'cg', dialingCode: '242', dialingDefault: true, matchingDialingCode: '242' },
    { code: 'ck', dialingCode: '682', dialingDefault: true, matchingDialingCode: '682' },
    { code: 'cr', dialingCode: '506', dialingDefault: true, matchingDialingCode: '506' },
    { code: 'ci', dialingCode: '225', dialingDefault: true, matchingDialingCode: '225' },
    { code: 'hr', dialingCode: '385', dialingDefault: true, matchingDialingCode: '385' },
    { code: 'cu', dialingCode: '53', dialingDefault: true, matchingDialingCode: '53' },
    { code: 'cy', dialingCode: '357', dialingDefault: true, matchingDialingCode: '357' },
    { code: 'cz', dialingCode: '420', dialingDefault: true, matchingDialingCode: '420' },
    { code: 'dk', dialingCode: '45', dialingDefault: true, matchingDialingCode: '45' },
    { code: 'dj', dialingCode: '253', dialingDefault: true, matchingDialingCode: '253' },
    { code: 'dm', dialingCode: '1-767', dialingDefault: true, matchingDialingCode: '1767' },
    { code: 'do', dialingCode: '1', dialingDefault: false, matchingDialingCode: '1' },
    { code: 'ec', dialingCode: '593', dialingDefault: true, matchingDialingCode: '593' },
    { code: 'eg', dialingCode: '20', dialingDefault: true, matchingDialingCode: '20' },
    { code: 'sv', dialingCode: '503', dialingDefault: true, matchingDialingCode: '503' },
    { code: 'gq', dialingCode: '240', dialingDefault: true, matchingDialingCode: '240' },
    { code: 'er', dialingCode: '291', dialingDefault: true, matchingDialingCode: '291' },
    { code: 'ee', dialingCode: '372', dialingDefault: true, matchingDialingCode: '372' },
    { code: 'et', dialingCode: '251', dialingDefault: true, matchingDialingCode: '251' },
    { code: 'fk', dialingCode: '500', dialingDefault: false, matchingDialingCode: '500' },
    { code: 'fo', dialingCode: '298', dialingDefault: true, matchingDialingCode: '298' },
    { code: 'fj', dialingCode: '679', dialingDefault: true, matchingDialingCode: '679' },
    { code: 'fi', dialingCode: '358', dialingDefault: true, matchingDialingCode: '358' },
    { code: 'gf', dialingCode: '594', dialingDefault: true, matchingDialingCode: '594' },
    { code: 'pf', dialingCode: '689', dialingDefault: true, matchingDialingCode: '689' },
    { code: 'tf', dialingCode: '262', dialingDefault: false, matchingDialingCode: '262' },
    { code: 'ga', dialingCode: '241', dialingDefault: true, matchingDialingCode: '241' },
    { code: 'gm', dialingCode: '220', dialingDefault: true, matchingDialingCode: '220' },
    { code: 'ge', dialingCode: '995', dialingDefault: true, matchingDialingCode: '995' },
    { code: 'gh', dialingCode: '233', dialingDefault: true, matchingDialingCode: '233' },
    { code: 'gi', dialingCode: '350', dialingDefault: true, matchingDialingCode: '350' },
    { code: 'gr', dialingCode: '30', dialingDefault: true, matchingDialingCode: '30' },
    { code: 'gl', dialingCode: '299', dialingDefault: true, matchingDialingCode: '299' },
    { code: 'gd', dialingCode: '1-473', dialingDefault: true, matchingDialingCode: '1473' },
    { code: 'gp', dialingCode: '590', dialingDefault: true, matchingDialingCode: '590' },
    { code: 'gu', dialingCode: '1-671', dialingDefault: true, matchingDialingCode: '1671' },
    { code: 'gt', dialingCode: '502', dialingDefault: true, matchingDialingCode: '502' },
    { code: 'gg', dialingCode: '44', dialingDefault: false, matchingDialingCode: '44' },
    { code: 'gn', dialingCode: '224', dialingDefault: true, matchingDialingCode: '224' },
    { code: 'gw', dialingCode: '245', dialingDefault: true, matchingDialingCode: '245' },
    { code: 'gy', dialingCode: '592', dialingDefault: true, matchingDialingCode: '592' },
    { code: 'ht', dialingCode: '509', dialingDefault: true, matchingDialingCode: '509' },
    { code: 'hm', dialingCode: '672', dialingDefault: false, matchingDialingCode: '672' },
    { code: 'va', dialingCode: '379', dialingDefault: true, matchingDialingCode: '379' },
    { code: 'hn', dialingCode: '504', dialingDefault: true, matchingDialingCode: '504' },
    { code: 'hk', dialingCode: '852', dialingDefault: true, matchingDialingCode: '852' },
    { code: 'hu', dialingCode: '36', dialingDefault: true, matchingDialingCode: '36' },
    { code: 'is', dialingCode: '354', dialingDefault: true, matchingDialingCode: '354' },
    { code: 'in', dialingCode: '91', dialingDefault: true, matchingDialingCode: '91' },
    { code: 'id', dialingCode: '62', dialingDefault: true, matchingDialingCode: '62' },
    { code: 'ir', dialingCode: '98', dialingDefault: true, matchingDialingCode: '98' },
    { code: 'iq', dialingCode: '964', dialingDefault: true, matchingDialingCode: '964' },
    { code: 'ie', dialingCode: '353', dialingDefault: true, matchingDialingCode: '353' },
    { code: 'im', dialingCode: '44', dialingDefault: false, matchingDialingCode: '44' },
    { code: 'il', dialingCode: '972', dialingDefault: true, matchingDialingCode: '972' },
    { code: 'it', dialingCode: '39', dialingDefault: true, matchingDialingCode: '39' },
    { code: 'jm', dialingCode: '1-876', dialingDefault: true, matchingDialingCode: '1876' },
    { code: 'jp', dialingCode: '81', dialingDefault: true, matchingDialingCode: '81' },
    { code: 'je', dialingCode: '44', dialingDefault: false, matchingDialingCode: '44' },
    { code: 'jo', dialingCode: '962', dialingDefault: true, matchingDialingCode: '962' },
    { code: 'kz', dialingCode: '7', dialingDefault: false, matchingDialingCode: '7' },
    { code: 'ke', dialingCode: '254', dialingDefault: true, matchingDialingCode: '254' },
    { code: 'ki', dialingCode: '686', dialingDefault: true, matchingDialingCode: '686' },
    { code: 'kp', dialingCode: '850', dialingDefault: true, matchingDialingCode: '850' },
    { code: 'kr', dialingCode: '82', dialingDefault: true, matchingDialingCode: '82' },
    { code: 'kw', dialingCode: '965', dialingDefault: true, matchingDialingCode: '965' },
    { code: 'kg', dialingCode: '996', dialingDefault: true, matchingDialingCode: '996' },
    { code: 'la', dialingCode: '856', dialingDefault: true, matchingDialingCode: '856' },
    { code: 'lv', dialingCode: '371', dialingDefault: true, matchingDialingCode: '371' },
    { code: 'lb', dialingCode: '961', dialingDefault: true, matchingDialingCode: '961' },
    { code: 'ls', dialingCode: '266', dialingDefault: true, matchingDialingCode: '266' },
    { code: 'lr', dialingCode: '231', dialingDefault: true, matchingDialingCode: '231' },
    { code: 'ly', dialingCode: '218', dialingDefault: true, matchingDialingCode: '218' },
    { code: 'li', dialingCode: '423', dialingDefault: true, matchingDialingCode: '423' },
    { code: 'lt', dialingCode: '370', dialingDefault: true, matchingDialingCode: '370' },
    { code: 'lu', dialingCode: '352', dialingDefault: true, matchingDialingCode: '352' },
    { code: 'mo', dialingCode: '853', dialingDefault: true, matchingDialingCode: '853' },
    { code: 'mk', dialingCode: '389', dialingDefault: true, matchingDialingCode: '389' },
    { code: 'mg', dialingCode: '261', dialingDefault: true, matchingDialingCode: '261' },
    { code: 'mw', dialingCode: '265', dialingDefault: true, matchingDialingCode: '265' },
    { code: 'my', dialingCode: '60', dialingDefault: true, matchingDialingCode: '60' },
    { code: 'mv', dialingCode: '960', dialingDefault: true, matchingDialingCode: '960' },
    { code: 'ml', dialingCode: '223', dialingDefault: true, matchingDialingCode: '223' },
    { code: 'mt', dialingCode: '356', dialingDefault: true, matchingDialingCode: '356' },
    { code: 'mh', dialingCode: '692', dialingDefault: true, matchingDialingCode: '692' },
    { code: 'mq', dialingCode: '596', dialingDefault: true, matchingDialingCode: '596' },
    { code: 'mr', dialingCode: '222', dialingDefault: true, matchingDialingCode: '222' },
    { code: 'mu', dialingCode: '230', dialingDefault: true, matchingDialingCode: '230' },
    { code: 'yt', dialingCode: '262', dialingDefault: false, matchingDialingCode: '262' },
    { code: 'mx', dialingCode: '52', dialingDefault: true, matchingDialingCode: '52' },
    { code: 'fm', dialingCode: '691', dialingDefault: true, matchingDialingCode: '691' },
    { code: 'md', dialingCode: '373', dialingDefault: true, matchingDialingCode: '373' },
    { code: 'mc', dialingCode: '377', dialingDefault: true, matchingDialingCode: '377' },
    { code: 'mn', dialingCode: '976', dialingDefault: true, matchingDialingCode: '976' },
    { code: 'me', dialingCode: '382', dialingDefault: true, matchingDialingCode: '382' },
    { code: 'ms', dialingCode: '1-664', dialingDefault: true, matchingDialingCode: '1664' },
    { code: 'ma', dialingCode: '212', dialingDefault: true, matchingDialingCode: '212' },
    { code: 'mz', dialingCode: '258', dialingDefault: true, matchingDialingCode: '258' },
    { code: 'mm', dialingCode: '95', dialingDefault: true, matchingDialingCode: '95' },
    { code: 'na', dialingCode: '264', dialingDefault: true, matchingDialingCode: '264' },
    { code: 'nr', dialingCode: '674', dialingDefault: true, matchingDialingCode: '674' },
    { code: 'np', dialingCode: '977', dialingDefault: true, matchingDialingCode: '977' },
    { code: 'nl', dialingCode: '31', dialingDefault: true, matchingDialingCode: '31' },
    { code: 'an', dialingCode: '599', dialingDefault: true, matchingDialingCode: '599' },
    { code: 'nc', dialingCode: '687', dialingDefault: true, matchingDialingCode: '687' },
    { code: 'nz', dialingCode: '64', dialingDefault: true, matchingDialingCode: '64' },
    { code: 'ni', dialingCode: '505', dialingDefault: true, matchingDialingCode: '505' },
    { code: 'ne', dialingCode: '227', dialingDefault: true, matchingDialingCode: '227' },
    { code: 'ng', dialingCode: '234', dialingDefault: true, matchingDialingCode: '234' },
    { code: 'nu', dialingCode: '683', dialingDefault: true, matchingDialingCode: '683' },
    { code: 'nf', dialingCode: '672', dialingDefault: false, matchingDialingCode: '672' },
    { code: 'mp', dialingCode: '1-670', dialingDefault: true, matchingDialingCode: '1670' },
    { code: 'no', dialingCode: '47', dialingDefault: true, matchingDialingCode: '47' },
    { code: 'om', dialingCode: '968', dialingDefault: true, matchingDialingCode: '968' },
    { code: 'pk', dialingCode: '92', dialingDefault: true, matchingDialingCode: '92' },
    { code: 'pw', dialingCode: '680', dialingDefault: true, matchingDialingCode: '680' },
    { code: 'ps', dialingCode: '970', dialingDefault: true, matchingDialingCode: '970' },
    { code: 'pa', dialingCode: '507', dialingDefault: true, matchingDialingCode: '507' },
    { code: 'pg', dialingCode: '675', dialingDefault: true, matchingDialingCode: '675' },
    { code: 'py', dialingCode: '595', dialingDefault: true, matchingDialingCode: '595' },
    { code: 'pe', dialingCode: '51', dialingDefault: true, matchingDialingCode: '51' },
    { code: 'ph', dialingCode: '63', dialingDefault: true, matchingDialingCode: '63' },
    { code: 'pn', dialingCode: '870', dialingDefault: true, matchingDialingCode: '870' },
    { code: 'pl', dialingCode: '48', dialingDefault: true, matchingDialingCode: '48' },
    { code: 'pt', dialingCode: '351', dialingDefault: true, matchingDialingCode: '351' },
    { code: 'pr', dialingCode: '1', dialingDefault: false, matchingDialingCode: '1' },
    { code: 'qa', dialingCode: '974', dialingDefault: true, matchingDialingCode: '974' },
    { code: 're', dialingCode: '262', dialingDefault: true, matchingDialingCode: '262' },
    { code: 'ro', dialingCode: '40', dialingDefault: true, matchingDialingCode: '40' },
    { code: 'ru', dialingCode: '7', dialingDefault: true, matchingDialingCode: '7' },
    { code: 'rw', dialingCode: '250', dialingDefault: true, matchingDialingCode: '250' },
    { code: 'sh', dialingCode: '290', dialingDefault: true, matchingDialingCode: '290' },
    { code: 'kn', dialingCode: '1-869', dialingDefault: true, matchingDialingCode: '1869' },
    { code: 'lc', dialingCode: '1-758', dialingDefault: true, matchingDialingCode: '1758' },
    { code: 'mf', dialingCode: '590', dialingDefault: false, matchingDialingCode: '590' },
    { code: 'pm', dialingCode: '508', dialingDefault: true, matchingDialingCode: '508' },
    { code: 'vc', dialingCode: '1-784', dialingDefault: true, matchingDialingCode: '1784' },
    { code: 'ws', dialingCode: '685', dialingDefault: true, matchingDialingCode: '685' },
    { code: 'sm', dialingCode: '378', dialingDefault: true, matchingDialingCode: '378' },
    { code: 'st', dialingCode: '239', dialingDefault: true, matchingDialingCode: '239' },
    { code: 'sa', dialingCode: '966', dialingDefault: true, matchingDialingCode: '966' },
    { code: 'sn', dialingCode: '221', dialingDefault: true, matchingDialingCode: '221' },
    { code: 'rs', dialingCode: '381', dialingDefault: true, matchingDialingCode: '381' },
    { code: 'sc', dialingCode: '248', dialingDefault: true, matchingDialingCode: '248' },
    { code: 'sl', dialingCode: '232', dialingDefault: true, matchingDialingCode: '232' },
    { code: 'sg', dialingCode: '65', dialingDefault: true, matchingDialingCode: '65' },
    { code: 'sk', dialingCode: '421', dialingDefault: true, matchingDialingCode: '421' },
    { code: 'si', dialingCode: '386', dialingDefault: true, matchingDialingCode: '386' },
    { code: 'sb', dialingCode: '677', dialingDefault: true, matchingDialingCode: '677' },
    { code: 'so', dialingCode: '252', dialingDefault: true, matchingDialingCode: '252' },
    { code: 'za', dialingCode: '27', dialingDefault: true, matchingDialingCode: '27' },
    { code: 'gs', dialingCode: '500', dialingDefault: true, matchingDialingCode: '500' },
    { code: 'lk', dialingCode: '94', dialingDefault: true, matchingDialingCode: '94' },
    { code: 'sd', dialingCode: '249', dialingDefault: true, matchingDialingCode: '249' },
    { code: 'sr', dialingCode: '597', dialingDefault: true, matchingDialingCode: '597' },
    { code: 'sj', dialingCode: '47', dialingDefault: false, matchingDialingCode: '47' },
    { code: 'sz', dialingCode: '268', dialingDefault: true, matchingDialingCode: '268' },
    { code: 'se', dialingCode: '46', dialingDefault: true, matchingDialingCode: '46' },
    { code: 'ch', dialingCode: '41', dialingDefault: true, matchingDialingCode: '41' },
    { code: 'sy', dialingCode: '963', dialingDefault: true, matchingDialingCode: '963' },
    { code: 'tw', dialingCode: '886', dialingDefault: true, matchingDialingCode: '886' },
    { code: 'tj', dialingCode: '992', dialingDefault: true, matchingDialingCode: '992' },
    { code: 'tz', dialingCode: '255', dialingDefault: true, matchingDialingCode: '255' },
    { code: 'th', dialingCode: '66', dialingDefault: true, matchingDialingCode: '66' },
    { code: 'tl', dialingCode: '670', dialingDefault: true, matchingDialingCode: '670' },
    { code: 'tg', dialingCode: '228', dialingDefault: true, matchingDialingCode: '228' },
    { code: 'tk', dialingCode: '690', dialingDefault: true, matchingDialingCode: '690' },
    { code: 'to', dialingCode: '676', dialingDefault: true, matchingDialingCode: '676' },
    { code: 'tt', dialingCode: '1-868', dialingDefault: true, matchingDialingCode: '1868' },
    { code: 'tn', dialingCode: '216', dialingDefault: true, matchingDialingCode: '216' },
    { code: 'tr', dialingCode: '90', dialingDefault: true, matchingDialingCode: '90' },
    { code: 'tm', dialingCode: '993', dialingDefault: true, matchingDialingCode: '993' },
    { code: 'tc', dialingCode: '1-649', dialingDefault: true, matchingDialingCode: '1649' },
    { code: 'tv', dialingCode: '688', dialingDefault: true, matchingDialingCode: '688' },
    { code: 'ug', dialingCode: '256', dialingDefault: true, matchingDialingCode: '256' },
    { code: 'ua', dialingCode: '380', dialingDefault: true, matchingDialingCode: '380' },
    { code: 'ae', dialingCode: '971', dialingDefault: true, matchingDialingCode: '971' },
    { code: 'um', dialingCode: '1', dialingDefault: false, matchingDialingCode: '1' },
    { code: 'uy', dialingCode: '598', dialingDefault: true, matchingDialingCode: '598' },
    { code: 'uz', dialingCode: '998', dialingDefault: true, matchingDialingCode: '998' },
    { code: 'vu', dialingCode: '678', dialingDefault: true, matchingDialingCode: '678' },
    { code: 've', dialingCode: '58', dialingDefault: true, matchingDialingCode: '58' },
    { code: 'vn', dialingCode: '84', dialingDefault: true, matchingDialingCode: '84' },
    { code: 'vg', dialingCode: '1-284', dialingDefault: true, matchingDialingCode: '1284' },
    { code: 'vi', dialingCode: '1-340', dialingDefault: true, matchingDialingCode: '1340' },
    { code: 'wf', dialingCode: '681', dialingDefault: true, matchingDialingCode: '681' },
    { code: 'eh', dialingCode: '212', dialingDefault: false, matchingDialingCode: '212' },
    { code: 'ye', dialingCode: '967', dialingDefault: true, matchingDialingCode: '967' },
    { code: 'zm', dialingCode: '260', dialingDefault: true, matchingDialingCode: '260' },
    { code: 'zw', dialingCode: '263', dialingDefault: true, matchingDialingCode: '263' },
  ],
};
export const regions = { shipping: ['EU', 'NA', 'SA', 'ME', 'AF', 'AS', 'OC'] };
export const payment = {
  types: [
    {
      id: '13',
      code: 'uk_bank',
      name: 'UK Bank',
      attributes: [
        'sort_code',
        'account_number',
        'bank_name',
        'bank_address',
        'holder_name',
        'holder_address',
      ],
      __typename: 'SellerPaymentMethodType',
    },
    {
      id: '14',
      code: 'eu_bank',
      name: 'EU Bank',
      attributes: ['swift', 'iban', 'bank_name', 'bank_address', 'holder_name', 'holder_address'],
      __typename: 'SellerPaymentMethodType',
    },
    {
      id: '15',
      code: 'international_bank',
      name: 'International Bank',
      attributes: [
        'bank_name',
        'bank_address',
        'holder_name',
        'holder_address',
        'swift',
        'account_number',
        'routing_number',
      ],
      __typename: 'SellerPaymentMethodType',
    },
    {
      id: '16',
      code: 'paypal',
      name: 'Paypal',
      attributes: ['account_name', 'email_address'],
      __typename: 'SellerPaymentMethodType',
    },
  ],
};
