import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useParams, Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import { useScrollToEl } from '../../../data/hooks/useScrollToEl';
import { DefaultLayout } from '../../layout/Default';
import { selectSeller } from '../../../services/redux/selectors/seller';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { ArtEdit } from '../../art/Edit';

/**
 * ArtEditPage
 *
 * @returns {JSX.Element}
 */
export const ArtEditPage = (): JSX.Element => {
  const { setScrollRef, scrollToRef } = useScrollToEl();
  const [loading, setLoading] = useState(false);
  const [artData, setArtData] = useState<Record<string, any> | null>(null);
  const {
    id: sellerId,
    currencyCode,
    storeCode,
    shipsCountryCode,
    canRentArt,
  } = useSelector(selectSeller) || {};
  const { formatMessage } = useIntl();
  const { id: artId }: Record<string, any> = useParams();
  const [toggleStatusHint, setToggleStatusHint] = useState<boolean>(true);
  const FORM_EDIT_MODE = artData && artData.productIsUnlisted ? 'ADVANCED' : 'BASIC';

  return (
    <DefaultLayout
      title={formatMessage({ id: 'components.mainMenu.artList' })}
      topContent={
        toggleStatusHint && artData ? (
          <Alert
            severity="warning"
            onClose={() => setToggleStatusHint((prevState: boolean) => !prevState)}
          >
            {formatMessage({
              id: `components.art.statusHint.${FORM_EDIT_MODE === 'BASIC' ? 'basic' : 'advanced'}`,
            })}
          </Alert>
        ) : null
      }
      loading={loading}
      breadcrumbs={[
        <Link
          key="home"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link
          key="artList"
          component={RouterLink}
          to={UrlAssembler.artList()}
          title={formatMessage({ id: 'components.mainMenu.artList' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'components.mainMenu.artList' })}
        </Link>,

        ...(artData && artData.title
          ? [
              <Typography key="art" variant="inherit">
                {artData.title}
              </Typography>,
            ]
          : []),
      ]}
      boxRefCallback={setScrollRef}
    >
      <ArtEdit
        artId={artId}
        sellerId={sellerId}
        canRentArt={canRentArt}
        currencyCode={currencyCode}
        storeCode={storeCode}
        shipsCountryCode={shipsCountryCode}
        scrollToRef={scrollToRef}
        artListAssembler={(options: Record<string, any> | null): string =>
          UrlAssembler.artList(options)
        }
        onLoading={setLoading}
        onData={setArtData}
      />
    </DefaultLayout>
  );
};
