import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams, Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Title, FormHeader, LayoutPaper } from '@riseart/dashboard';
import { useScrollToEl } from '../../../../data/hooks/useScrollToEl';
import { selectArtist } from '../../../../services/redux/selectors/artist';
import { DefaultLayout } from '../../../layout/Default';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { ArtistFeedEdit } from '../../../artist/feed/Edit';

/**
 * ArtistFeedEditPage
 *
 * @returns {JSX.Element}
 */
export const ArtistFeedEditPage = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const [feedData, setFeedData] = useState<Record<string, any> | null>(null);
  const [loading, setLoading] = useState(false);
  const { setScrollRef, scrollToRef } = useScrollToEl();
  const { id: feedId }: Record<string, any> = useParams();
  const artist = useSelector(selectArtist) || {};
  const pageTitle = formatMessage({ id: 'components.mainMenu.artistFeedList' });

  return (
    <DefaultLayout
      loading={loading}
      title={pageTitle}
      breadcrumbs={[
        <Link
          key="home"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link
          key="artistProfile"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('artistFeedList')}
          title={pageTitle}
          underline="hover"
          color="inherit"
        >
          {pageTitle}
        </Link>,
        ...(feedData && feedData.title
          ? [
              <Typography key="art" variant="inherit">
                {feedData.title}
              </Typography>,
            ]
          : []),
      ]}
      boxRefCallback={setScrollRef}
    >
      <LayoutPaper>
        <FormHeader
          title={<Title variant="h5">{formatMessage({ id: 'components.feed.editPost' })}</Title>}
        />
        <ArtistFeedEdit
          artistId={artist.id}
          feedId={feedId}
          scrollToRef={scrollToRef}
          onLoading={setLoading}
          onData={setFeedData}
        />
      </LayoutPaper>
    </DefaultLayout>
  );
};
