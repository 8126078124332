import { useState, SyntheticEvent } from 'react';
import { useIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { CircularLoader, FormBuilder, FormWrapper } from '@riseart/dashboard';

type Props = {
  formModel: Record<string, any>;
  onSubmit: (formData: Record<string, any>, mediaType: string) => void;
  onCancel: () => void;
  mediaTypeList: Record<string, any>;
  submitText: JSX.Element | string;
  initialMediaType?: string;
  loading?: boolean;
};

/**
 * MediaForm
 *
 * @returns {JSX.Element}
 */
export function MediaForm({
  mediaTypeList,
  initialMediaType = '',
  formModel,
  onSubmit,
  onCancel,
  submitText,
  loading = false,
}: Props): JSX.Element {
  const { formatMessage } = useIntl();
  const [mediaType, setMediaType] = useState<string>(initialMediaType);

  return (
    <CircularLoader active={loading}>
      <FormWrapper size="medium">
        {Object.keys(mediaTypeList).length > 1 || !mediaType ? (
          <TextField
            type="select"
            label={formatMessage({ id: 'forms.media.label.type' })}
            variant="standard"
            margin="normal"
            select
            SelectProps={{ native: false }}
            value={mediaType}
            fullWidth
            helperText={formatMessage({ id: 'forms.media.hints.type' })}
            required
            onChange={(e: SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              setMediaType(target.value);
            }}
          >
            {Object.keys(mediaTypeList).map((key: string) => {
              const mediaType = mediaTypeList[key];

              return (
                <MenuItem key={key} value={mediaType}>
                  {formatMessage({ id: `components.media.type.${mediaType}` })}
                </MenuItem>
              );
            })}
          </TextField>
        ) : null}
        {mediaType ? (
          <FormBuilder
            settings={formModel.settings}
            fields={formModel.fields}
            submitText={submitText}
            buttonSize="medium"
            customData={{ mediaType }}
            cancel={
              <Button onClick={onCancel} type="button" variant="outlined" size="medium" fullWidth>
                {formatMessage({ id: 'common.cancel' })}
              </Button>
            }
            onSubmit={(formData) => onSubmit(formData, mediaType)}
          />
        ) : null}
      </FormWrapper>
    </CircularLoader>
  );
}
