import { find } from '@riseart/fe-utils';

/**
 * getGraphqlOperationName
 *
 * @param {Record<string, any>} operationType
 * @returns {string} data key of the graphql query
 */
export function getGraphqlOperationName(operationType: Record<string, any>): string {
  const foundQuery = find(
    operationType.definitions,
    ({ kind, operation }: { kind: string; operation: string }) =>
      kind === 'OperationDefinition' && operation === 'query',
  );
  return foundQuery && foundQuery.name.value;
}

/**
 * cacheUpdateHandler
 *
 * @param {Record<string, any>} updatedQuery
 * @param {string} dataKey
 * @param {Record<string, any>} variables?
 * @param {Function} customHandler?
 */
export const cacheUpdateHandler = (
  updatedQuery: Record<string, any>,
  dataKey: string,
  variables?: Record<string, any>,
  customHandler?: (cache: Record<string, any>, updatedData: Record<string, any>) => any,
): ((cache: Record<string, any>, { data }: Record<string, any>) => void) => {
  return (cache: Record<string, any>, { data }: Record<string, any>) => {
    const dataByKey = data && data[dataKey];

    // No data for the provided gql operation (mutation) key is found
    if (!dataByKey) {
      return;
    }

    let updatedData = null;

    // Detect by __typename the data to update the cache with
    // eslint-disable-next-line
    switch (dataByKey.__typename) {
      case 'Cart':
        updatedData = dataByKey;
        break;
      case 'CartItemResult':
        updatedData = dataByKey && dataByKey.cart;
        break;
      default:
        updatedData = dataByKey;
        break;
    }

    // Handle custom update to cache, if optional customHandler argument is provided
    if (typeof customHandler === 'function') {
      customHandler(cache, updatedData);
      return;
    }

    if (!updatedData) {
      return;
    }

    // Update cache with new data
    cache.writeQuery({
      query: updatedQuery,
      ...(variables ? { variables } : {}),
      data: {
        [getGraphqlOperationName(updatedQuery)]: updatedData,
      },
    });
  };
};
