import { gql } from '@apollo/client';
import { SELLER_SHIPPING_TABLE_FRAGMENT } from '../../fragments/seller/shippingTable.graphql';

export const UPDATE_SELLER_SHIPPING_TABLE = gql`
  ${SELLER_SHIPPING_TABLE_FRAGMENT}
  mutation updateSellerShippingTable(
    $id: Int!
    $sellerId: Int!
    $inputShippingTable: InputShippingTable!
  ) {
    updateSellerShippingTable(
      id: $id
      sellerId: $sellerId
      inputShippingTable: $inputShippingTable
    ) {
      ...SellerShippingTableFragment
    }
  }
`;
