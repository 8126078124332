export const stores = ['uk', 'eu', 'us'];
export const units = {
  centimeter: 'CENTIMETER',
  inch: 'INCH',
  system: { imperial: 'imperial', metric: 'metric' },
};
export const currency = {
  signs: { uk: '£', eu: '€', us: '$' },
  codes: { uk: 'GBP', eu: 'EUR', us: 'USD' },
};
export const auth = {
  modules: {
    SELLER_USER: 'seller/user',
    SELLER_OAUTH: 'seller/oauth',
    SELLER_EXCHANGE: 'seller/exchange',
    WEBSITE_VISITOR: 'website/visitor',
    WEBSITE_USER: 'website/user',
    WEBSITE_OAUTH: 'website/oauth',
  },
};
export const acl = { role: { SELLER_PARTNER: 'seller_partner', PARTNER_ADMIN: 'partner_admin' } };
export const gui = { drawer: { type: { PERMANENT: 'permanent', TEMPORARY: 'temporary' } } };
export const errors = {
  handlers: { SILENT: 'silent', ERROR_PAGE: 'errorPage', NOTIFICATION: 'notification' },
  placeholders: { APP: 'app', PAGE: 'page', SNACKBAR: 'snackbar' },
  levels: { ERROR: 10, WARNING: 20, INFO: 30, SUCCESS: 40 },
  types: { auth: 'AuthError' },
};
export const pagination = { type: { INFINITE: 'infinite', SLIDER: 'slider' } };
export const user = {
  privacySetting: {
    category: {
      CATEGORY_PRIVACY: 'privacy',
      CATEGORY_EMAIL: 'email',
      CATEGORY_FACEBOOK: 'facebook',
    },
    name: {
      PRIVACY_SHOW_PERSONAL_DATA: 'show_personal_data',
      EMAIL_GENERAL: 'general',
      EMAIL_ACTIVITY_UPDATE: 'activity_update',
      EMAIL_ACTIVITY_WEEKLY: 'activity_weekly',
      FACEBOOK_ACTION_FOLLOW: 'action_follows',
      FACEBOOK_ACTION_FAVORITE: 'action_favorite',
      FACEBOOK_ACTION_VOTE: 'action_vote',
      FACEBOOK_ACTION_TAKE: 'action_take',
    },
  },
  role: { ROLE_ARTIST: 'artist' },
};
export const author = {
  type: { ADMIN: 'admin', USER: 'user', PARTNER: 'partner' },
  internalType: { ADMIN: 'admin', AUTHOR: 'author', PARTNER: 'partner', USER: 'user' },
};
export const partner = {
  status: { INACTIVE: 0, ACTIVE: 1 },
  admin: { status: { INACTIVE: 2, ACTIVE: 1 } },
};
export const art = {
  state: {
    ALL: 'all',
    ACTIVE: 'active',
    UNLISTED: 'unlisted',
    PENDING: 'pending',
    SOLD: 'sold',
    DISABLED: 'disabled',
  },
  status: { APPROVED: 1, DELETED: 4, DISAPPROVED: 2, FOR_SALE: 3, PENDING_APPROVAL: 0 },
  image: {
    category: { MAIN: 'main', DETAIL: 'detail' },
    type: {
      MAIN_MASTER: 'main-master',
      MAIN_ORIGINAL: 'main-original',
      MAIN_SQUARE: 'main-square',
      DETAIL_MASTER: 'detail-master',
      DETAIL_ORIGINAL: 'detail-original',
      DETAIL_SQUARE: 'detail-square',
    },
  },
  media: {
    type: { VIDEO: 'video' },
    platform: { YOUTUBE: 'youtube', VIMEO: 'vimeo' },
    status: { INACTIVE: 0, ACTIVE: 1 },
  },
  sort: { ARTIST: 'artist' },
  substrate: {
    PAPER: 'paper',
    CANVAS: 'canvas',
    WOOD: 'wood',
    GLASS: 'glass',
    PLASTIC: 'plastic',
    DIBOND: 'dibond',
    ALUMINIUM: 'aluminium',
    METAL: 'metal',
    OTHER: 'other',
  },
  packageMethod: { FLAT: 'flat', ROLLED: 'rolled' },
};
export const artist = {
  exhibition: { type: { SOLO: 'solo', GROUP: 'group' } },
  feed: {
    image: { type: { ORIGINAL: 'original', SQUARE: 'square' }, category: { MAIN: 'main' } },
    media: {
      type: { IMAGE: 'image', VIDEO: 'video' },
      platform: { YOUTUBE: 'youtube', VIMEO: 'vimeo', INSTAGRAM: 'instagram' },
    },
  },
  status: { PENDING: 0, ACTIVE: 1, SUSPENDED: 2, DELETED: 3, PURGED: 4 },
};
export const product = {
  type: { ORIGINAL: 'OR', LIMITED_EDITION: 'LE' },
  sku: { type: { ART: 'ART', RENT: 'REN' } },
  status: {
    INACTIVE: 0,
    ACTIVE: 1,
    SOLD_OUT: 2,
    DELETED: 3,
    RENTED: 4,
    PENDING_APPROVAL: 5,
    UNLISTED: 6,
    ENQUIRE: 7,
    ARCHIVED: 8,
  },
  action: {
    UNLIST: 'unlist',
    SUBMIT: 'submit',
    ARCHIVE: 'archive',
    SELL_OUT: 'sell_out',
    COPY: 'copy',
  },
  image: {
    type: {
      ORIGINAL_MASTER: 'original-master',
      ORIGINAL_DETAIL: 'original-detail',
      ORIGINAL_SQUARE: 'original-square',
    },
  },
};
export const file = {
  object: { type: { ART: 'art', EVENT: 'event', FILE: 'file', ARTIST_FEED: 'artist-feed' } },
};
export const seller = {
  tax: { vat: { status: { UNDEFINED: 0, REGISTERED: 1, NOT_REGISTERED: 2 } } },
  type: { ARTIST: 1, PARTNER: 2, MANAGED: 3 },
  onboardingStage: { PROFILE: 1, PAYMENT: 2, ARTIST: 3, COMPLETED: -1 },
  locale: { EN: 'en-GB', DE: 'de-DE', FR: 'fr-FR' },
  shippingService: { MANAGED: 'managed', SELLER: 'seller' },
};
export const event = {
  type: { SOLO_SHOW: 1, ART_FAIR: 3, TALK: 4, GROUP_SHOW: 5, OTHER: 6 },
  status: { ACTIVE: 1, SUSPENDED: 2, CANCELED: 3 },
  state: { ACTIVE: 1, SUSPENDED: 2, CANCELED: 3, FINISHED: 4 },
  rsvp: {
    type: {
      ATTENDEE_NONE: 0,
      ATTENDEE_ALL: 8,
      ATTENDEE_LEADERS: 4,
      ATTENDEE_FOLLOWERS: 2,
      ATTENDEE_CONTACTS: 1,
    },
  },
  visibility: { PUBLIC: 'public', PRIVATE: 'private' },
  image: { category: { MAIN: 'main', SQUARE: 'square' } },
};
export const offer = {
  status: { ALL: 'all', PENDING: 'pending', ACTIVE: 'active', INACTIVE: 'inactive' },
  state: {
    CREATED: 'created',
    SENT: 'sent',
    ACCEPTED: 'accepted',
    COUNTER: 'counter',
    DECLINED: 'declined',
    EXPIRED: 'expired',
    UNANSWERED: 'unanswered',
    USED: 'used',
    CANCELED: 'canceled',
    ERROR: 'error',
  },
  action: { ACCEPT: 'accept', COUNTER: 'counter', DECLINE: 'decline' },
};
export const profile = { gender: ['M', 'F'] };
export const legal = { type: { SELLER_TERMS: 'seller_terms' } };
export const data = {
  type: { integer: 'integer', float: 'float', string: 'string', discrete: 'discrete' },
  operators: {
    api: {
      '=': '=',
      '!=': '!=',
      '>': '>',
      '>=': '>=',
      '<': '<',
      '<=': '<=',
      equals: '=',
      contains: 'contains',
      startsWith: 'starts_with',
      endsWith: 'ends_with',
      isEmpty: 'is_empty',
      isNotEmpty: 'is_not_empty',
      is: '=',
      not: '!=',
      isAnyOf: 'is_any',
      after: '>',
      onOrAfter: '>=',
      before: '<',
      onOrBefore: '<=',
    },
  },
};
export const HTTP_STATUS = {
  MOVED_PERMANENTLY: 301,
  MOVED_TEMPORARILY: 302,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
};
export const ENVIRONMENTS = { development: 'development', test: 'test', production: 'production' };
