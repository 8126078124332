import { store as CONFIG_STORE } from '../../../config/config.js';

/**
 * localeSelector
 *
 * @param {Record<string, any>} state
 * @returns {Record<string, any> | null}
 */
export function localeSelector(
  state: Record<string, any>,
  localeType = 'routeLocale',
): Record<string, any> | null {
  const app = state[CONFIG_STORE.keys.application];
  return (app && app[localeType]) || null;
}
