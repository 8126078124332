import { Component, FC } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { canUserAccessRoute } from '@riseart/fe-utils';
import { application as CONFIG_APP } from '../../config/config.js';
import { authRedirect } from '../../services/riseart/utils/Auth';
import { isRoutedUrl } from '../../services/riseart/utils/Utils';
import { localeSelector } from '../../services/redux/selectors/locale';
import { selectUser } from '../../services/redux/selectors/user';
import { selectAuth } from '../../services/redux/selectors/auth';

/**
 * HOC
 * @param {*} DecoratedComponent
 */
const HOC = (DecoratedComponent: FC<any>) =>
  class extends Component<any, any> {
    /**
     * shouldRedirect
     */
    shouldRedirect() {
      const { aclRole, isUserLoggedIn, location, history, forwardUrl } = this.props;
      const queryParameters: Record<string, any> = queryString.parse(location.search);
      const url = queryParameters.forward || forwardUrl;
      const forwardRouterConfig = url && isRoutedUrl(url);
      const forwardUrlAclRoles =
        (forwardRouterConfig && forwardRouterConfig.acl && forwardRouterConfig.acl.roles) || false;

      if (
        isUserLoggedIn &&
        (!url ||
          canUserAccessRoute(
            aclRole,
            forwardUrlAclRoles,
            CONFIG_APP.acl.rolesHierarchy,
            queryParameters.role ? queryParameters.role.split(',') : null,
          ))
      ) {
        authRedirect(queryParameters, history);
        return true;
      }

      return false;
    }

    /**
     * render
     */
    render() {
      return <DecoratedComponent {...this.props} redirectByForwardACL={this.shouldRedirect()} />;
    }
  };

const mapStateToProps = (state: Record<string, any>) => {
  const user = selectUser(state);
  const auth = selectAuth(state);

  return {
    aclRole: auth && auth.payload && auth.payload.acl_role,
    isUserLoggedIn: !!user,
    meIsLoaded: !!user,
    locale: localeSelector(state),
  };
};

export const withForwardUrlACL = (DecoratedComponent: FC): any =>
  connect(mapStateToProps)(withRouter(HOC(DecoratedComponent)));
