import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import { Link } from '@riseart/dashboard';
import { useScrollToEl } from '../../../data/hooks/useScrollToEl';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { DefaultLayout } from '../../layout/Default';
import { selectArtist } from '../../../services/redux/selectors/artist';
import { ArtistCv } from '../../artist/Cv';
import { READ_ARTIST } from '../../../data/gql/queries/artist/read.graphql';

/**
 * ArtistCvPage
 *
 * @returns {JSX.Element}
 */
export const ArtistCvPage = (): JSX.Element => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const { formatMessage } = useIntl();
  const { setScrollRef, scrollToRef } = useScrollToEl();
  const artist = useSelector(selectArtist) || {};

  // Queries and Mutations
  const { data: readArtistData, loading: readLoading } = useQuery(READ_ARTIST, {
    fetchPolicy: 'network-only',
    variables: { id: artist && artist.id },
    skip: !artist,
  });
  const artistData = (readArtistData && readArtistData.readArtist) || null;

  return (
    <DefaultLayout
      includesFab={isLargeScreen}
      loading={readLoading}
      title={formatMessage({ id: 'components.mainMenu.artistProfile' })}
      breadcrumbs={[
        <Link
          key="home"
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link
          key="cv"
          to={UrlAssembler.byRouteKey('artistCv')}
          title={formatMessage({ id: 'components.mainMenu.artistCv' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'components.mainMenu.artistCv' })}
        </Link>,

        ...(artistData && artistData.name
          ? [
              <Typography key="artistProfile" variant="inherit">
                {artistData.name}
              </Typography>,
            ]
          : []),
      ]}
      boxRefCallback={setScrollRef}
    >
      <ArtistCv canAdmin artist={artistData} scrollToRef={scrollToRef} />
    </DefaultLayout>
  );
};
