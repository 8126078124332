import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { MediaList, MediaListItem } from './List';
import { AddMedia } from './Add';

type Props = {
  formModel: Record<string, any>;
  typeList: Record<string, any>;
  initialType: string;
  onUpdate: (items: Record<string, any>[]) => void;
};

/**
 * AddMediaList
 *
 * @returns {JSX.Element}
 */
export function AddMediaList({ formModel, typeList, initialType, onUpdate }: Props): JSX.Element {
  const [items, setItems] = useState<Record<string, any>[]>([]);
  const handleAdd = useCallback(
    (formData: Record<string, any>) => setItems([...items, formData]),
    [items],
  );
  const handleDelete = useCallback(
    (idx: number) => () => setItems(items.filter((item, index) => index !== idx)),
    [items],
  );

  useEffect(() => onUpdate(items), [items]);

  return (
    <Box component="section">
      <AddMedia
        formModel={formModel}
        typeList={typeList}
        initialType={initialType}
        onAdd={handleAdd}
      />
      <Box>
        {items && items.length ? (
          items.map(({ mediaType, mediaPlatform, mediaId }, idx) => {
            return (
              <MediaList key={`${mediaId}-${idx}`}>
                <MediaListItem
                  mediaType={mediaType}
                  mediaPlatform={mediaPlatform}
                  mediaId={mediaId}
                  onDelete={handleDelete(idx)}
                />
              </MediaList>
            );
          })
        ) : (
          <Typography marginY={2} fontSize="14px" align="center">
            <FormattedMessage id="components.media.noItems" />
          </Typography>
        )}
      </Box>
    </Box>
  );
}
