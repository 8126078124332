import { ReactChildren, ReactChild } from 'react';
import { LOCALE_COMPONENTS } from '../../locales/index';
import { RouteConfigContext } from '../../data/route/ConfigContext';

type Props = {
  dataKey: string;
  locale?: string;
  children: (messages: Record<string, any>) => ReactChild | ReactChildren;
};

/**
 * I18nComponentsLoader
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const I18nComponentsLoader = ({ dataKey, locale, children }: Props): JSX.Element => {
  const getLocaleComponent = (dataKey: string, locale: string) => {
    const LocaleComponent = LOCALE_COMPONENTS[dataKey] && LOCALE_COMPONENTS[dataKey][locale];

    if (!LocaleComponent) {
      throw new Error(
        `[I18nComponentsLoader] Component for '${dataKey}' with locale '${locale}' is not implemented yet.`,
      );
    }

    return LocaleComponent;
  };

  if (locale) {
    const Component = getLocaleComponent(dataKey, locale);

    return <Component>{children}</Component>;
  }

  return (
    <RouteConfigContext.Consumer>
      {({ currentLocale: { name } }) => {
        const Component = getLocaleComponent(dataKey, name);

        return <Component>{children}</Component>;
      }}
    </RouteConfigContext.Consumer>
  );
};
