import get from 'lodash/get';
import pick from 'lodash/pick';
import {
  ApolloClient,
  InMemoryCache,
  defaultDataIdFromObject,
  ApolloLink,
  concat,
  HttpLink,
} from '@apollo/client';
import { ErrorLink } from '@apollo/client/link/error';
import {
  graphql as GRAPHQL_CONFIG,
  store as STORE_CONFIG,
  errors as ERRORS_CONFIG,
} from '../../../config/config.js';
import { errors as ERRORS_ENUM } from '../../../config/enumeration.js';
import { RiseartLogger } from '../Logger';
import { ErrorService } from '../errors/ErrorService';
import { errorAdd } from '../../redux/actions/errors/errors';

const {
  NetworkError: { type: NETWORK_ERROR_TYPE },
} = ERRORS_CONFIG.errorsTypes;

const {
  levels: { ERROR: ERROR_LEVEL },
} = ERRORS_ENUM;

/**
 * Client
 */
export const Client: Record<string, any> = {
  /**
   * clientInstance
   */
  clientInstance: null,

  /**
   * init
   *
   * @param {Record<string, any>} reduxStore
   * @param {boolean} isSSR
   * @param {Record<string, any>} options
   * @returns {ApolloClient<any>}
   */
  init: (
    reduxStore: Record<string, any>,
    isSSR = false,
    options: Record<string, any> = {},
  ): ApolloClient<any> => {
    // In SSR mode we should create a new client or store instance
    // for each request as described in Apollo Client documentation
    if (Client.clientInstance && !isSSR) {
      return Client.clientInstance;
    }

    // Core client HTTP link
    const httpLink = new HttpLink({
      uri: GRAPHQL_CONFIG.endpoint,
      headers: options.headers || {},
    });

    // Set request headers for each request (API key, JWT token, locale)
    const headersLink = new ApolloLink((operation, forward) => {
      const reduxState = reduxStore.getState();
      const token = get(reduxState, `${STORE_CONFIG.keys.auth}.token`);
      const locale = get(reduxState, `${STORE_CONFIG.keys.gui}.locale`);
      const { headers: contextHeaders } = operation.getContext();
      operation.setContext({
        headers: {
          'x-api-key': GRAPHQL_CONFIG.apiKey,
          ...(locale ? { 'accept-language': locale } : {}),
          ...(token ? { authorization: `Bearer ${token}` } : {}),
          ...(contextHeaders || null),
        },
      });

      return forward(operation);
    });

    // Log each response to the core logger
    const logLink = new ApolloLink(
      (operation: Record<string, any>, forward: (operation: any) => any) => {
        const { customOptions: { errorSuppressFromResponse = false } = {} } =
          operation.getContext();
        return forward(operation).map((response: Record<string, any>) => {
          Client.logResponse(operation.query.definitions, response);

          // Suppress gql errors from SSR queries marked with errorSuppressFromResponse
          // since we handle them in the application using the error service
          // instead of allowing Apollo to bubble up the error on SSR
          return isSSR && errorSuppressFromResponse && response.errors
            ? { ...response, errors: null }
            : response;
        });
      },
    );

    // Handle and log GQL errors
    const errorLink = new ErrorLink(({ graphQLErrors, networkError, operation }) => {
      const {
        customOptions: {
          // @ts-ignore
          errorHandler,
          errorFilter = (i: Record<string, any>[]): Record<string, any>[] => i,
          error: errorOptions = {},
        } = {},
      } = operation.getContext();
      const inputData = pick(operation, ['operationName', 'query', 'variables', 'extensions']);
      const filteredGraphQLErrors = errorFilter(graphQLErrors);

      // ErrorHandler provided in operation context
      // for custom error handling logic
      if (errorHandler) {
        const { shouldOverwriteDefaultHandler } = errorHandler({
          graphQLErrors: filteredGraphQLErrors,
          networkError,
        });
        if (shouldOverwriteDefaultHandler === true) {
          return;
        }
      }

      if (filteredGraphQLErrors) {
        // Custom options are passed from Query/Mutation components
        // using the context prop with data like { customOptions: { errorOptions } }
        filteredGraphQLErrors.forEach((err: Record<string, any>) => {
          const errorPayload = {
            ...ErrorService.mapGraphqlError({ ...err, inputData }),
            ...errorOptions,
          };

          reduxStore.dispatch(errorAdd(errorPayload));
        });
      }

      if (networkError) {
        reduxStore.dispatch(
          errorAdd(ErrorService.mapNotification({ type: NETWORK_ERROR_TYPE, level: ERROR_LEVEL })),
        );
      }
    });

    // Create and restore inital query cache
    const cache = new InMemoryCache({
      dataIdFromObject: (object) => {
        // eslint-disable-next-line
        switch (object.__typename) {
          case 'ArtFlat':
            return `${object.id}/${object.storeCode}${
              object.collectionId ? `/${object.collectionId}` : ''
            }`;
          default:
            return defaultDataIdFromObject(object); // Fall back to default Apollo handling
        }
      },
    });
    const initialApolloState =
      !isSSR && typeof window !== 'undefined' && get(window.RiseArt, 'initialApolloState');
    if (initialApolloState) {
      cache.restore(JSON.parse(decodeURIComponent(initialApolloState)));
      delete window.RiseArt.initialApolloState;
    }

    // Create new apollo client instance
    const client: ApolloClient<any> = new ApolloClient({
      ssrMode: isSSR,
      link: concat(headersLink, logLink.concat(errorLink.concat(httpLink))),
      cache,
      ssrForceFetchDelay: isSSR ? 0 : 100,
    });

    Client.clientInstance = client;
    return client;
  },

  /**
   * query
   *
   * @param {Record<string, any>} payload
   * @returns {Promise}
   */
  query: (payload: Record<string, any>): Record<string, any> =>
    Client.clientInstance.query(payload).then((result: Record<string, any>) => result),

  /**
   * mutate
   *
   * @param {Record<string, any>} payload
   * @returns {Promise}
   */
  mutate: (payload: Record<string, any>): Record<string, any> =>
    Client.clientInstance.mutate(payload).then((result: Record<string, any>) => result),

  /**
   * logResponse
   *
   * @param {Record<string, any>} definitions
   * @param {Record<string, any>} response
   * @returns {void}
   */
  logResponse: (definitions: Record<string, any>, response: Record<string, any>): void => {
    const definitionList = definitions
      .map((definition: Record<string, any>) => {
        const { kind, name = {}, operation } = definition;

        switch (kind) {
          case 'OperationDefinition':
            return `${operation} ${name.value}`;
          case 'FragmentDefinition':
            return `fragment ${name.value}`;
          default:
            return `unknown type ${name.value}`;
        }
      })
      .join(', ');
    RiseartLogger.groupedLog(`[GRAPHQL] Result from ${definitionList}`, response);
  },

  /**
   * getInstance
   *
   * @returns {ApolloClient} ApolloClient instance
   */
  getInstance: (): ApolloClient<any> => Client.clientInstance,

  /**
   * clearInstance
   *
   * @param {boolean} stop
   * @returns {boolean}
   */
  clearInstance: (stop = false): boolean => {
    if (Client.clientInstance) {
      if (stop) {
        Client.clientInstance.stop();
      }
      Client.clientInstance = null;
      return true;
    }
    return false;
  },
};
