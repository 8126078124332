import { gql } from '@apollo/client';
import { ARTIST_EXHIBITION_FRAGMENT } from '../../fragments/artist/exhibition.graphql';

export const UPDATE_ARTIST_EXHIBITION = gql`
  ${ARTIST_EXHIBITION_FRAGMENT}
  mutation updateArtistExhibition(
    $id: ID!
    $artistId: Int!
    $inputArtistExhibition: InputArtistExhibition!
  ) {
    updateArtistExhibition(
      id: $id
      artistId: $artistId
      inputArtistExhibition: $inputArtistExhibition
    ) {
      ...ArtistExhibitionFragment
    }
  }
`;
