import InputAdornment from '@mui/material/InputAdornment';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import {
  Title,
  Link,
  Paragraph,
  FormViewField,
  FormSelectNoneOption,
  selectOptions,
} from '@riseart/dashboard';
import {
  currency as CURRENCY_ENUM,
  art as ART_ENUM,
  artist as ARTIST_ENUM,
} from '../../../config/enumeration.js';
import { LocalizedConfig } from '../../../services/riseart/utils/LocalizedConfig';
import { getDimension } from '../../../data/filters/utils';
import { DEFAULT_CHECKBOX_PROPS } from '../input/Checkbox';
import { ArtistPicker } from '../pickers';
import { PARTNER_ARTIST_DATAGRID_TABLE_SCHEMA } from '../../data/tables/schemas/artist';
import { LIST_PARTNER_ARTISTS } from '../../../data/gql/queries/partner/listArtists.graphql';

const NoShippingTableOption = FormSelectNoneOption();
const dimensionsPreffix = (
  { unitSystem }: { unitSystem: 'imperial' | 'metric' },
  formatMessage: (options: Record<string, any>) => any,
): JSX.Element => (
  <InputAdornment position="start">
    {formatMessage({
      id: unitSystem === 'metric' ? 'units.metric.cm' : 'units.imperial.in',
    }).toUpperCase()}
  </InputAdornment>
);

const filterValueParser = (
  value: string,
  formatMessage: (options: Record<string, any>) => any,
): string => {
  if (!value) {
    return value;
  }

  try {
    const parsedValue = JSON.parse(value);
    return Object.keys(parsedValue)
      .map((fieldKey) => formatMessage({ id: `filters.art.${fieldKey}.${parsedValue[fieldKey]}` }))
      .join(' > ');
  } catch {
    return value;
  }
};

/**
 * filterSelectOptions
 *
 * @param {string}domain
 * @param {string}dimension
 * @returns {{
    formatMessage: (options: Record<string, any>) => any;
    initialOption: any;
  }}
 */
const filterSelectOptions =
  (domain: string, dimension: string) =>
  ({
    formatMessage,
    initialOption,
  }: {
    formatMessage: (options: Record<string, any>) => any;
    initialOption: any;
  }) => {
    const {
      name: dimensionName,
      childDimension: childDimensionName,
      values,
    } = getDimension(domain, dimension);
    return values.reduce(
      (accumulator: JSX.Element[], { name, children }: { name: string; children: string[] }) => {
        const parentDimensionText = formatMessage({
          id: `filters.${domain}.${dimensionName}.${name}`,
        });
        accumulator.push(
          <MenuItem key={name} value={JSON.stringify({ [dimensionName]: name })}>
            {parentDimensionText}
          </MenuItem>,
        );

        if (children) {
          children.forEach((child: string) => {
            accumulator.push(
              <MenuItem
                key={`${name}${child}`}
                value={JSON.stringify({ [dimensionName]: name, [childDimensionName]: child })}
              >
                {parentDimensionText} &gt;{' '}
                {formatMessage({ id: `filters.${domain}.${childDimensionName}.${child}` })}
              </MenuItem>,
            );
          });
        }
        return accumulator;
      },
      initialOption ? [initialOption] : [],
    );
  };

const defaultBasicModeCustomRender = (
  field: Record<string, any>,
  customData: Record<string, any>,
  formState: Record<string, any>,
  label: string | JSX.Element,
  formatMessage: (options: Record<string, any>) => any,
): JSX.Element | null =>
  customData.editMode === 'BASIC' ? (
    <FormViewField
      key={field.name}
      label={label}
      helperText={
        <FormattedMessage id="forms.art.hints.basicModeFieldHint" values={{ field: label }} />
      }
    >
      {typeof field.renderer === 'function'
        ? field.renderer(formState.data[field.name], formatMessage)
        : formState.data[field.name]}
    </FormViewField>
  ) : null;

function shippingTablesOptionsList({
  initialOption,
  customData,
}: {
  initialOption: any;
  customData: Record<string, any>;
}): JSX.Element[] {
  const { shippingTables } = customData || {};
  return [
    ...(initialOption ? [initialOption] : []),
    ...(shippingTables
      ? shippingTables.map(({ id, name }: { id: string | number; name: string | JSX.Element }) => (
          <MenuItem value={id} key={id}>
            {name}
          </MenuItem>
        ))
      : []),
  ];
}

/**
 * isReadonly
 *
 * Check if field is readOnly based on the form mode
 *
 * @param {'BASIC' | 'ADVANCED'} mode
 * @returns {boolean}
 */
const isReadonly = (mode: 'BASIC' | 'ADVANCED'): boolean => mode === 'BASIC';

/**
 * validateDimensions
 *
 * @param {number} min
 * @param {number} max
 * @returns {(value: number | string) => boolean}
 */
const validateDimensions =
  (min?: number, max?: number) =>
  (value: number | string): boolean => {
    if (typeof min === 'number' && value <= min) {
      return true;
    }

    if (typeof max === 'number' && value > max) {
      return true;
    }

    return false;
  };

/**
 * rentalFieldDescription
 *
 * @param { 'BASIC' | 'ADVANCED' } editMode
 * @param {(options: Record<string, any>) => any} formatMessage
 * @returns {JSX.Element | null}
 */
const rentalFieldDescription = (
  {
    editMode,
  }: {
    editMode: 'BASIC' | 'ADVANCED';
  },
  formatMessage: (options: Record<string, any>, properties?: Record<string, any>) => any,
): JSX.Element | null =>
  editMode && editMode === 'BASIC' ? null : (
    <Box marginY={1}>
      <Title component="h3" variant="h5" sx={{ marginY: '10px', fontSize: '22px' }}>
        {formatMessage({ id: 'forms.art.descriptions.allowRentals.title' })}
      </Title>
      <Paragraph sx={{ marginY: '15px', fontSize: '14px' }}>
        {formatMessage({ id: 'forms.art.descriptions.allowRentals.p1' })}
      </Paragraph>
      <Paragraph sx={{ marginY: '15px', fontSize: '14px' }}>
        {formatMessage({ id: 'forms.art.descriptions.allowRentals.p2' })}
      </Paragraph>
      <Paragraph sx={{ marginY: '15px', fontSize: '14px' }}>
        {formatMessage({ id: 'forms.art.descriptions.allowRentals.p3' })}
      </Paragraph>
      <Paragraph sx={{ marginY: '15px', fontSize: '14px' }}>
        {formatMessage(
          { id: 'forms.art.descriptions.allowRentals.p4' },
          {
            a: (chunk: any) => (
              <Link
                href={LocalizedConfig.get('externalUrls.rentalHelp')}
                target="_blank"
                color="inherit"
                external
                title={formatMessage({ id: 'forms.art.descriptions.allowRentals.rentalHelp' })}
              >
                {chunk}
              </Link>
            ),
          },
        )}
      </Paragraph>
    </Box>
  );

/**
 * artistPickerRenderer
 *
 * @param {Record<string, any>} field
 * @param {Record<string, any>} customData
 * @param { Record<string, any>} formState
 * @param {string | JSX.Element} label
 * @param {(options: Record<string, any>) => any} formatMessage
 * @param {(options: Record<string, any>) => any} register
 * @returns {JSX.Element | null}
 */
function artistPickerRenderer(
  field: Record<string, any>,
  customData: Record<string, any>,
  formState: Record<string, any>,
  label: string | JSX.Element,
  formatMessage: (options: Record<string, any>) => any,
  register: (options: Record<string, any>) => any,
): JSX.Element | null {
  const errors = formState.errors.artistId;
  const hasErrors = errors && errors.length;
  const selectedData =
    (formState.data.artistId &&
      (typeof formState.data.artistId === 'object'
        ? formState.data.artistId
        : { id: formState.data.artistId, name: customData.artistId })) ||
    null;
  const { onChange } = register(field);

  return (
    <FormViewField
      key={field.name}
      label={formatMessage({ id: field.placeholder })}
      error={hasErrors && errors.map((error: string) => formatMessage({ id: error }))}
    >
      <ArtistPicker
        query={LIST_PARTNER_ARTISTS}
        queryVars={{
          partnerId: customData.partnerId,
          filters: { artistStatus: ARTIST_ENUM.status.ACTIVE },
        }}
        tableSchema={PARTNER_ARTIST_DATAGRID_TABLE_SCHEMA}
        initialValue={selectedData}
        chipColor={hasErrors ? 'error' : null}
        {...(customData.artistPickerItemMapper
          ? { itemMapper: customData.artistPickerItemMapper }
          : null)}
        onChange={(selectedItem: Record<string, any>) => {
          if (!selectedItem) {
            onChange({ target: { value: null } });
            return;
          }

          if (customData.prefillFieldsFromArtist) {
            customData
              .prefillFieldsFromArtist(selectedItem.id, formState.data)
              .then((prefilledData: Record<string, any>) => {
                onChange({ target: { value: selectedItem.id } }, () => {
                  return prefilledData;
                });
              });
          } else {
            onChange({ target: { value: selectedItem.id } });
          }
        }}
        formatMessage={formatMessage}
      />
    </FormViewField>
  );
}

/**
 * shouldRenderSellerShippingTable
 *
 * @param {Record<string, any>} customData
 * @returns {boolean}
 */
function shouldRenderSellerShippingTable(customData: Record<string, any>): boolean {
  return customData.canUseShippingTables;
}

/**
 * AddArtFormModel
 */
export const AddArtFormModel = {
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      tag: 'input',
      type: 'text',
      name: 'title',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
      placeholder: 'forms.art.label.title',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'description',
      validateTrigger: 'onBlur',
      multiline: true,
      minRows: 10,
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 1500 },
          message: 'forms.validation.maxLength1500',
        },
      ],
      placeholder: 'forms.art.label.description',
      hint: 'forms.art.hints.description',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'year',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
        {
          type: 'regex',
          pattern: '^\\d{4}$',
          message: 'forms.validation.yearNotInFuture',
        },
        {
          type: 'custom',
          custom: (value: string | number): boolean =>
            !!(value && value > new Date().getFullYear()),
          message: 'forms.validation.yearNotInFuture',
        },
      ],
      placeholder: 'forms.art.label.year',
      hint: 'forms.art.hints.year',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'substrate',
      selectProps: {
        native: false,
        displayEmpty: true,
      },
      selectOptions: selectOptions(ART_ENUM.substrate, 'components.art.substrate.'),
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
      ],
      placeholder: 'forms.art.label.substrate',
      hint: 'forms.art.hints.substrate',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'medium',
      valueType: 'json',
      resetValue: { medium: null, subMedium: null },
      selectProps: { native: false, displayEmpty: true },
      selectOptions: filterSelectOptions('art', 'medium'),
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
      ],
      placeholder: 'forms.art.label.medium',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'subject',
      valueType: 'json',
      resetValue: { subject: null, subSubject: null },
      selectProps: { native: false, displayEmpty: true },
      selectOptions: filterSelectOptions('art', 'subject'),
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
      ],
      placeholder: 'forms.art.label.subject',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'style',
      valueType: 'json',
      resetValue: { style: null, subStyle: null },
      selectProps: { native: false, displayEmpty: true },
      selectOptions: filterSelectOptions('art', 'style'),
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
      ],
      placeholder: 'forms.art.label.style',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'materials',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
      placeholder: 'forms.art.label.materials',
      hint: 'forms.art.hints.materials',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'editionSize',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
      ],
      placeholder: 'forms.art.label.editionSize',
      shouldRender: ({ productType }: { productType: 'LE' | 'OR' }): boolean =>
        productType === 'LE',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'tags',
      validateTrigger: 'onBlur',
      placeholder: 'forms.art.label.tags',
      hint: 'forms.art.hints.tags',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'height',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
        {
          type: 'custom',
          custom: validateDimensions(0),
          message: 'forms.art.validation.dimension',
        },
        {
          type: 'float',
          message: 'forms.validation.invalidFloat',
        },
      ],
      placeholder: 'forms.art.label.height',
      hint: 'forms.art.hints.height',
      preffix: dimensionsPreffix,
    },
    {
      tag: 'input',
      type: 'text',
      name: 'width',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
        {
          type: 'custom',
          custom: validateDimensions(0),
          message: 'forms.art.validation.dimension',
        },
        {
          type: 'float',
          message: 'forms.validation.invalidFloat',
        },
      ],
      placeholder: 'forms.art.label.width',
      hint: 'forms.art.hints.width',
      preffix: dimensionsPreffix,
    },
    {
      tag: 'input',
      type: 'text',
      name: 'depth',
      validateTrigger: 'onBlur',
      placeholder: 'forms.art.label.depth',
      hint: 'forms.art.hints.depth',
      preffix: dimensionsPreffix,
    },
    {
      tag: 'input',
      type: 'text',
      name: 'price',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
        {
          type: 'custom',
          custom: validateDimensions(0),
          message: 'forms.art.validation.dimension',
        },
        {
          type: 'float',
          message: 'forms.validation.invalidFloat',
        },
      ],
      placeholder: (
        { storeCode }: { storeCode: string },
        formatMessage: (options: Record<string, any>) => any,
      ): JSX.Element =>
        formatMessage(
          { id: 'forms.art.label.price' },
          // @ts-ignore
          { currency: CURRENCY_ENUM.codes[storeCode] },
        ),
      hint: (
        { storeCode }: { storeCode: string },
        formatMessage: (values: Record<string, any>, options?: Record<string, any>) => any,
      ): JSX.Element =>
        formatMessage(
          { id: 'forms.art.hints.price' },
          // @ts-ignore
          { currency: CURRENCY_ENUM.codes[storeCode] },
        ),
      preffix: ({ storeCode }: { storeCode: string }): JSX.Element => (
        // @ts-ignore
        <InputAdornment position="start">{CURRENCY_ENUM.signs[storeCode]}</InputAdornment>
      ),
    },
    {
      tag: 'select',
      type: 'text',
      name: 'packageMethod',
      selectProps: {
        native: false,
        displayEmpty: true,
      },
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
        {
          type: 'custom',
          message: 'forms.art.validation.packageMethodRolledFramed',
          custom: (value: string, data: Record<string, any>): boolean =>
            value === ART_ENUM.packageMethod.ROLLED && data.framed === true,
        },
      ],
      selectOptions: selectOptions(ART_ENUM.packageMethod, 'components.art.packageMethod.'),
      placeholder: 'forms.art.label.packageMethod',
      hint: 'forms.art.hints.packageMethod',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'sellerShippingTable',
      validateTrigger: 'onBlur',
      placeholder: 'forms.art.label.sellerShippingTable',
      initialOption: NoShippingTableOption,
      selectOptions: shippingTablesOptionsList,
      hint: 'forms.art.hints.shippingTable',
      shouldRender: shouldRenderSellerShippingTable,
    },
    {
      tag: 'input',
      type: 'text',
      name: 'stock',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
      ],
      hint: 'forms.art.hints.stock',
      placeholder: 'forms.art.label.stock',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'weight',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
        {
          type: 'custom',
          custom: validateDimensions(0),
          message: 'forms.art.validation.dimension',
        },
        {
          type: 'float',
          message: 'forms.validation.invalidFloat',
        },
      ],
      hint: 'forms.art.hints.weight',
      placeholder: 'forms.art.label.weight',
      preffix: (
        { unitSystem }: { unitSystem: 'imperial' | 'metric' },
        formatMessage: (options: Record<string, any>) => any,
      ): JSX.Element => (
        <InputAdornment position="start">
          {formatMessage({
            id: unitSystem === 'metric' ? 'units.metric.kg' : 'units.imperial.lbs',
          }).toUpperCase()}
        </InputAdornment>
      ),
    },
    {
      componentProps: DEFAULT_CHECKBOX_PROPS,
      tag: 'checkbox',
      type: 'text',
      name: 'signed',
      validateTrigger: 'onChange',
      hint: 'forms.art.hints.signed',
      placeholder: 'forms.art.label.signed',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'signedLocation',
      validateTrigger: 'onBlur',
      hint: 'forms.art.hints.signedLocation',
      placeholder: 'forms.art.label.signedLocation',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.requiredSignedLocation',
          condition: {
            field: 'signed',
            compare: (value: boolean): boolean => value === true,
          },
        },
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
    },
    {
      componentProps: DEFAULT_CHECKBOX_PROPS,
      tag: 'checkbox',
      type: 'text',
      name: 'unstretched',
      validateTrigger: 'onChange',
      rules: [
        {
          type: 'custom',
          message: 'forms.art.validation.unstretchedFramed',
          custom: (value: boolean, data: Record<string, any>): boolean =>
            value === true && data.framed === true,
        },
        {
          type: 'custom',
          message: 'forms.art.validation.unstretchedHangable',
          custom: (value: boolean, data: Record<string, any>): boolean =>
            value === true && data.hangable === true,
        },
      ],
      placeholder: 'forms.art.label.unstretched',
      hint: 'forms.art.hints.unstretched',
    },
    {
      componentProps: DEFAULT_CHECKBOX_PROPS,
      tag: 'checkbox',
      type: 'text',
      name: 'hangable',
      validateTrigger: 'onChange',
      rules: [
        {
          type: 'custom',
          message: 'forms.art.validation.unstretchedHangable',
          custom: (value: boolean, data: Record<string, any>): boolean =>
            value === true && data.unstretched === true,
        },
      ],
      hint: 'forms.art.hints.hangable',
      placeholder: 'forms.art.label.hangable',
    },
    {
      componentProps: DEFAULT_CHECKBOX_PROPS,
      tag: 'checkbox',
      type: 'text',
      name: 'framed',
      validateTrigger: 'onChange',
      rules: [
        {
          type: 'custom',
          message: 'forms.art.validation.unstretchedFramed',
          custom: (value: boolean, data: Record<string, any>): boolean =>
            value === true && data.unstretched === true,
        },
        {
          type: 'custom',
          message: 'forms.art.validation.packageMethodRolledFramed',
          custom: (value: boolean, data: Record<string, any>): boolean =>
            value === true && data.packageMethod === ART_ENUM.packageMethod.ROLLED,
        },
      ],
      hint: 'forms.art.hints.framed',
      placeholder: 'forms.art.label.framed',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'frameDescription',
      validateTrigger: 'onBlur',
      hint: 'forms.art.hints.frameDescription',
      placeholder: 'forms.art.label.frameDescription',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.requiredFrameDescription',
          condition: {
            field: 'framed',
            compare: (value: boolean): boolean => value === true,
          },
        },
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
    },
    {
      componentProps: DEFAULT_CHECKBOX_PROPS,
      tag: 'checkbox',
      type: 'text',
      name: 'rent',
      validateTrigger: 'onChange',
      defaultChecked: true,
      placeholder: 'forms.art.label.allowRentals',
      description: rentalFieldDescription,
    },
  ],
};

export const AddArtistArtFormModel = {
  settings: { ...AddArtFormModel.settings },
  fields: [
    {
      tag: 'input',
      type: 'custom',
      name: 'artistId',
      placeholder: 'forms.art.label.artist',
      customRender: artistPickerRenderer,
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
      ],
    },
    ...AddArtFormModel.fields,
  ],
};

/**
 * EditArtFormModel
 */
export const EditArtFormModel = {
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      tag: 'input',
      type: 'text',
      name: 'title',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
      placeholder: 'forms.art.label.title',
      customRender: defaultBasicModeCustomRender,
      isReadonly,
    },
    {
      tag: 'input',
      type: 'text',
      name: 'description',
      validateTrigger: 'onBlur',
      multiline: true,
      minRows: 10,
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 1500 },
          message: 'forms.validation.maxLength1500',
        },
      ],
      placeholder: 'forms.art.label.description',
      hint: 'forms.art.hints.description',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'year',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
        {
          type: 'regex',
          pattern: '^\\d{4}$',
          message: 'forms.validation.yearNotInFuture',
        },
        {
          type: 'custom',
          custom: (value: string | number): boolean =>
            !!(value && value > new Date().getFullYear()),
          message: 'forms.validation.yearNotInFuture',
        },
      ],
      placeholder: 'forms.art.label.year',
      hint: 'forms.art.hints.year',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'substrate',
      selectProps: {
        native: false,
        displayEmpty: true,
      },
      selectOptions: selectOptions(ART_ENUM.substrate, 'components.art.substrate.'),
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
      ],
      placeholder: 'forms.art.label.substrate',
      hint: 'forms.art.hints.substrate',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'medium',
      renderer: filterValueParser,
      valueType: 'json',
      resetValue: { medium: null, subMedium: null },
      selectProps: { native: false, displayEmpty: true },
      selectOptions: filterSelectOptions('art', 'medium'),
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
      ],
      placeholder: 'forms.art.label.medium',
      customRender: defaultBasicModeCustomRender,
      isReadonly,
    },
    {
      tag: 'select',
      type: 'text',
      name: 'subject',
      valueType: 'json',
      renderer: filterValueParser,
      resetValue: { subject: null, subSubject: null },
      selectProps: { native: false, displayEmpty: true },
      selectOptions: filterSelectOptions('art', 'subject'),
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
      ],
      placeholder: 'forms.art.label.subject',
      customRender: defaultBasicModeCustomRender,
      isReadonly,
    },
    {
      tag: 'select',
      type: 'text',
      name: 'style',
      valueType: 'json',
      renderer: filterValueParser,
      resetValue: { style: null, subStyle: null },
      selectProps: { native: false, displayEmpty: true },
      selectOptions: filterSelectOptions('art', 'style'),
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
      ],
      placeholder: 'forms.art.label.style',
      customRender: defaultBasicModeCustomRender,
      isReadonly,
    },
    {
      tag: 'input',
      type: 'text',
      name: 'materials',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
      placeholder: 'forms.art.label.materials',
      hint: 'forms.art.hints.materials',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'editionSize',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
      ],
      placeholder: 'forms.art.label.editionSize',
      shouldRender: ({ productType }: { productType: 'LE' | 'OR' }): boolean =>
        productType === 'LE',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'tags',
      validateTrigger: 'onBlur',
      placeholder: 'forms.art.label.tags',
      hint: 'forms.art.hints.tags',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'height',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
        {
          type: 'custom',
          custom: validateDimensions(0),
          message: 'forms.art.validation.dimension',
        },
        {
          type: 'float',
          message: 'forms.validation.invalidFloat',
        },
      ],
      placeholder: 'forms.art.label.height',
      hint: 'forms.art.hints.height',
      preffix: dimensionsPreffix,
    },
    {
      tag: 'input',
      type: 'text',
      name: 'width',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
        {
          type: 'custom',
          custom: validateDimensions(0),
          message: 'forms.art.validation.dimension',
        },
        {
          type: 'float',
          message: 'forms.validation.invalidFloat',
        },
      ],
      placeholder: 'forms.art.label.width',
      hint: 'forms.art.hints.width',
      preffix: dimensionsPreffix,
    },
    {
      tag: 'input',
      type: 'text',
      name: 'depth',
      validateTrigger: 'onBlur',
      placeholder: 'forms.art.label.depth',
      hint: 'forms.art.hints.depth',
      preffix: dimensionsPreffix,
    },
    {
      tag: 'input',
      type: 'text',
      name: 'price',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
        {
          type: 'custom',
          custom: validateDimensions(0),
          message: 'forms.art.validation.dimension',
        },
        {
          type: 'float',
          message: 'forms.validation.invalidFloat',
        },
      ],
      placeholder: (
        { storeCode }: { storeCode: string },
        formatMessage: (values: Record<string, any>, options?: Record<string, any>) => any,
      ): JSX.Element =>
        formatMessage(
          { id: 'forms.art.label.price' },
          // @ts-ignore
          { currency: CURRENCY_ENUM.codes[storeCode] },
        ),
      hint: (
        { storeCode }: { storeCode: string },
        formatMessage: (values: Record<string, any>, options?: Record<string, any>) => any,
      ): JSX.Element =>
        formatMessage(
          { id: 'forms.art.hints.price' },
          // @ts-ignore
          { currency: CURRENCY_ENUM.codes[storeCode] },
        ),
      preffix: ({ storeCode }: { storeCode: string }): JSX.Element => (
        // @ts-ignore
        <InputAdornment position="start">{CURRENCY_ENUM.signs[storeCode]}</InputAdornment>
      ),
    },
    {
      tag: 'select',
      type: 'text',
      name: 'packageMethod',
      selectProps: {
        native: false,
        displayEmpty: true,
      },
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
        {
          type: 'custom',
          message: 'forms.art.validation.packageMethodRolledFramed',
          custom: (value: string, data: Record<string, any>): boolean =>
            value === ART_ENUM.packageMethod.ROLLED && data.framed === true,
        },
      ],
      selectOptions: selectOptions(ART_ENUM.packageMethod, 'components.art.packageMethod.'),
      placeholder: 'forms.art.label.packageMethod',
      hint: 'forms.art.hints.packageMethod',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'sellerShippingTable',
      validateTrigger: 'onBlur',
      placeholder: 'forms.art.label.sellerShippingTable',
      initialOption: NoShippingTableOption,
      selectOptions: shippingTablesOptionsList,
      hint: 'forms.art.hints.shippingTable',
      shouldRender: shouldRenderSellerShippingTable,
    },
    {
      tag: 'input',
      type: 'text',
      name: 'stock',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
      ],
      hint: 'forms.art.hints.stock',
      placeholder: 'forms.art.label.stock',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'weight',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.required',
        },
        {
          type: 'custom',
          custom: validateDimensions(0),
          message: 'forms.art.validation.dimension',
        },
        {
          type: 'float',
          message: 'forms.validation.invalidFloat',
        },
      ],
      hint: 'forms.art.hints.weight',
      placeholder: 'forms.art.label.weight',
      preffix: (
        { unitSystem }: { unitSystem: 'imperial' | 'metric' },
        formatMessage: (options: Record<string, any>) => any,
      ): JSX.Element => (
        <InputAdornment position="start">
          {formatMessage({
            id: unitSystem === 'metric' ? 'units.metric.kg' : 'units.imperial.lbs',
          }).toUpperCase()}
        </InputAdornment>
      ),
    },
    {
      componentProps: DEFAULT_CHECKBOX_PROPS,
      tag: 'checkbox',
      type: 'text',
      name: 'signed',
      validateTrigger: 'onChange',
      hint: 'forms.art.hints.signed',
      placeholder: 'forms.art.label.signed',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'signedLocation',
      validateTrigger: 'onBlur',
      hint: 'forms.art.hints.signedLocation',
      placeholder: 'forms.art.label.signedLocation',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.requiredSignedLocation',
          condition: {
            field: 'signed',
            compare: (value: boolean): boolean => value === true,
          },
        },
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
    },
    {
      componentProps: DEFAULT_CHECKBOX_PROPS,
      tag: 'checkbox',
      type: 'text',
      name: 'unstretched',
      validateTrigger: 'onChange',
      rules: [
        {
          type: 'custom',
          message: 'forms.art.validation.unstretchedFramed',
          custom: (value: boolean, data: Record<string, any>): boolean =>
            value === true && data.framed === true,
        },
        {
          type: 'custom',
          message: 'forms.art.validation.unstretchedHangable',
          custom: (value: boolean, data: Record<string, any>): boolean =>
            value === true && data.hangable === true,
        },
      ],
      placeholder: 'forms.art.label.unstretched',
      hint: 'forms.art.hints.unstretched',
    },
    {
      componentProps: DEFAULT_CHECKBOX_PROPS,
      tag: 'checkbox',
      type: 'text',
      name: 'hangable',
      validateTrigger: 'onChange',
      rules: [
        {
          type: 'custom',
          message: 'forms.art.validation.unstretchedHangable',
          custom: (value: boolean, data: Record<string, any>): boolean =>
            value === true && data.unstretched === true,
        },
      ],
      hint: 'forms.art.hints.hangable',
      placeholder: 'forms.art.label.hangable',
    },
    {
      componentProps: DEFAULT_CHECKBOX_PROPS,
      tag: 'checkbox',
      type: 'text',
      name: 'framed',
      validateTrigger: 'onChange',
      rules: [
        {
          type: 'custom',
          message: 'forms.art.validation.unstretchedFramed',
          custom: (value: boolean, data: Record<string, any>): boolean =>
            value === true && data.unstretched === true,
        },
        {
          type: 'custom',
          message: 'forms.art.validation.packageMethodRolledFramed',
          custom: (value: boolean, data: Record<string, any>): boolean =>
            value === true && data.packageMethod === ART_ENUM.packageMethod.ROLLED,
        },
      ],
      hint: 'forms.art.hints.framed',
      placeholder: 'forms.art.label.framed',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'frameDescription',
      validateTrigger: 'onBlur',
      hint: 'forms.art.hints.frameDescription',
      placeholder: 'forms.art.label.frameDescription',
      rules: [
        {
          required: true,
          message: 'forms.art.validation.requiredFrameDescription',
          condition: {
            field: 'framed',
            compare: (value: boolean): boolean => value === true,
          },
        },
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
    },
    {
      componentProps: DEFAULT_CHECKBOX_PROPS,
      tag: 'checkbox',
      type: 'text',
      name: 'rent',
      validateTrigger: 'onChange',
      defaultChecked: true,
      placeholder: 'forms.art.label.allowRentals',
      description: rentalFieldDescription,
      isReadonly,
      customRender: (
        field: Record<string, any>,
        customData: Record<string, any>,
        formState: Record<string, any>,
        label: string | JSX.Element,
      ): JSX.Element | null =>
        customData.editMode === 'BASIC' ? (
          <FormViewField
            key={field.name}
            label={label}
            helperText={
              <FormattedMessage id="forms.art.hints.basicModeFieldHint" values={{ field: label }} />
            }
          >
            <FormattedMessage id={`common.${formState.data[field.name] === true ? 'yes' : 'no'}`} />
          </FormViewField>
        ) : null,
    },
  ],
};
