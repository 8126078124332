import { gql } from '@apollo/client';

export const SELLER_FRAGMENT = gql`
  fragment SellerFragment on Seller {
    id
    type
    status
    name
    integrationId
    onboardingStage
    entityId
    entityName
    entityStatus
    entityRole
    entitySlug
    shipsCountryCode
    storeCode
    locale
    taxStatus
    street1
    street2
    city
    countryCode
    region
    postcode
    vatCode
    vatCountryCode
    companyName
    companyNumber
    contactName
    contactPhone
    contactEmail
    billingName
    billingPhone
    billingEmail
    shippingService
    shipsIn
    away
    awayFrom
    awayTo
    isCurrentlyAway
    canInvite
    canRentArt
    canUseShippingTables
  }
`;
