import { gql } from '@apollo/client';
import { PARTNER_ARTIST_FRAGMENT } from '../../fragments/partner/artist.graphql';
import { PAGINATION_FRAGMENT } from '../../fragments/common/pagination.graphql';

export const LIST_PARTNER_ARTISTS = gql`
  ${PARTNER_ARTIST_FRAGMENT}
  ${PAGINATION_FRAGMENT}
  query listPartnerArtists(
    $partnerId: Int!
    $page: Int = 1
    $items: Int = 10
    $sort: String = "artistName"
    $order: String = "ASC"
    $filters: InputPartnerArtistListFilters
  ) {
    listPartnerArtists(
      partnerId: $partnerId
      page: $page
      items: $items
      sort: $sort
      order: $order
      filters: $filters
    ) {
      items {
        ...PartnerArtistFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
`;
