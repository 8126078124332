import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CircularLoader } from '@riseart/dashboard';
import { errors as ERRORS_ENUM } from '../../config/enumeration.js';
import { useNotification } from '../../data/hooks/useNotification';
import { printString } from '../../services/riseart/utils/Utils';
import { legal as ENUM_LEGAL } from '../../config/enumeration.js';
import { selectSeller } from '../../services/redux/selectors/seller';
import { LIST_SELLER_LEGALS } from '../../data/gql/queries/seller/listLegals.graphql';
import { READ_LEGAL } from '../../data/gql/queries/legal/read.graphql';

type Props = {
  open: boolean;
  handleClose: () => void;
};

/**
 * TermsDialog
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export function TermsDialog({ open, handleClose }: Props): JSX.Element | null {
  const ELEMENT_ID = 'terms-markdown';
  const { dispatchNotification } = useNotification({
    detail: 'components.notifications.notFoundLegal',
    level: ERRORS_ENUM.levels.ERROR,
    placeholder: ERRORS_ENUM.placeholders.APP,
  });
  const { id: sellerId } = useSelector(selectSeller) || {};
  const { data, loading: listLegalsLoading } = useQuery(LIST_SELLER_LEGALS, {
    skip: !sellerId,
    fetchPolicy: 'network-only',
    variables: { sellerId, filters: { active: true, type: ENUM_LEGAL.type.SELLER_TERMS } },
  });
  const { legalId } =
    (data &&
      data.listSellerLegals &&
      data.listSellerLegals.items &&
      data.listSellerLegals.items[0]) ||
    {};
  const { data: readLegalData, loading: readLegalLoading } = useQuery(READ_LEGAL, {
    skip: !legalId,
    fetchPolicy: 'network-only',
    variables: { type: legalId },
  });
  const { title, legalText: description } = (readLegalData && readLegalData.readLegal) || {};

  /**
   * handlePrint
   */
  const handlePrint = useCallback(() => {
    printString(`<h1>${title || ''}</h1><p>${document.getElementById(ELEMENT_ID)?.innerHTML}</p>`);
  }, [title]);

  // Show error message if there is no description for the terms and conditions
  if (!listLegalsLoading && !readLegalLoading && !description) {
    handleClose();
    dispatchNotification();
    return null;
  }

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      {!listLegalsLoading && !readLegalLoading ? (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}

      <DialogContent sx={{ pt: 0 }}>
        <DialogContentText>
          {(description && (
            <div id={ELEMENT_ID}>
              <ReactMarkdown>{description}</ReactMarkdown>
            </div>
          )) || <CircularLoader active={listLegalsLoading || readLegalLoading}></CircularLoader>}
        </DialogContentText>
      </DialogContent>

      {!listLegalsLoading && !readLegalLoading ? (
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', p: 3 }}>
          {description ? (
            <Button variant="outlined" onClick={handlePrint}>
              <FormattedMessage id="common.print" />
            </Button>
          ) : null}
          <Button variant="contained" onClick={handleClose}>
            <FormattedMessage id="common.close" />
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
}
