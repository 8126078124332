import { gql } from '@apollo/client';

export const COUNTER_OFFER = gql`
  mutation counterOffer($id: Int!, $amount: Float!, $comment: String) {
    counterOffer(id: $id, amount: $amount, comment: $comment) {
      id
      action
      offerId
      executed
      status
    }
  }
`;
