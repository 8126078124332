import { gql } from '@apollo/client';
import { ARTIST_FRAGMENT } from '../../fragments/artist/artist.graphql';

export const UPDATE_ARTIST = gql`
  ${ARTIST_FRAGMENT}
  mutation updateArtist($id: Int!, $inputArtist: InputArtist!) {
    updateArtist(id: $id, inputArtist: $inputArtist) {
      ...ArtistFragment
    }
  }
`;
