import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { graphql as GQL_CONFIG } from '../../../../config/config.js';
import { auth as AUTH_ENUM } from '../../../../config/enumeration.js';
import { errorAdd } from '../../../../services/redux/actions/errors/errors';
import { ErrorService } from '../../../../services/riseart/errors/ErrorService';
import { FacebookButton } from '../../facebook/Button';
import { GoogleButton } from '../../google/Button';
import { validateToken } from '../../../../services/riseart/utils/Auth';
import { RouteConfigContext } from '../../../../data/route/ConfigContext';

type Props = {
  createJwt: (options: Record<string, any>) => any;
  isLogin: boolean;
  grantAccessHandler?: (token: string, userId: number) => Promise<Record<string, any>>;
};

/**
 * SocialContainer
 *
 * @param {Props} props
 * @retuns {SX.Element}
 */
export const SocialContainer = ({
  createJwt,
  grantAccessHandler,
  isLogin = true,
}: Props): JSX.Element => {
  const FACEBOOK_PROVIDER = 'facebook';
  const GOOGLE_PROVIDER = 'google';
  const dispatch = useDispatch();
  const handleLogin: (provider: string) => (token: string | null) => void =
    (provider: string) => async (token: string | null) => {
      try {
        if (token) {
          const shouldGrantAccess = !!grantAccessHandler;
          const { data: userJwtResponse } = await createJwt({
            variables: {
              inputJwt: {
                apiKey: shouldGrantAccess ? GQL_CONFIG.authKeyFrontend : GQL_CONFIG.authKey,
                authModule: shouldGrantAccess
                  ? AUTH_ENUM.modules.WEBSITE_OAUTH
                  : AUTH_ENUM.modules.SELLER_OAUTH,
                authProviderToken: token,
                authProvider: provider,
              },
            },
          });

          if (shouldGrantAccess) {
            const userToken =
              userJwtResponse && userJwtResponse.createJwt && userJwtResponse.createJwt.token;
            const tokenPayload = await validateToken(userToken, 'user');

            await grantAccessHandler(userToken, tokenPayload.user_id);
          }
        } else {
          throw new Error('errors.invalidToken.title');
        }
      } catch (Error: any) {
        dispatch(
          errorAdd(
            ErrorService.mapNotification({
              detail: Error.message,
            }),
          ),
        );
      }
    };
  const handleGoogleLogin = (provider: string) => (clientData: Record<string, any>) => {
    return handleLogin(provider)(clientData && clientData.credential);
  };
  return (
    <Box maxWidth="600px" margin="40px auto">
      <Grid container justifyContent={'center'} spacing={2}>
        <Grid item xs={12} sm={6} maxWidth={400}>
          <FacebookButton setToken={handleLogin(FACEBOOK_PROVIDER)}>
            <FormattedMessage id="components.auth.facebookBtn" />
          </FacebookButton>
        </Grid>
        <Grid item xs={12} sm={6} maxWidth={400}>
          <RouteConfigContext.Consumer>
            {({ currentLocale }) => (
              <GoogleButton
                setToken={handleGoogleLogin(GOOGLE_PROVIDER)}
                text={isLogin ? 'signin_with' : 'signup_with'}
                locale={currentLocale && currentLocale.name}
              />
            )}
          </RouteConfigContext.Consumer>
        </Grid>
      </Grid>
    </Box>
  );
};
