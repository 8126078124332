import { RiseartLogger } from '../../riseart/Logger';

/**
 * Logger middleware for log exception and state
 */
export class MiddlewareLogger {
  /**
   * Redux Middleware
   *
   * @param store
   */
  static middleware =
    (store: Record<string, any>) => (next: any) => (action: Record<string, any>) => {
      try {
        return next(action);
      } catch (Exception: any) {
        RiseartLogger.exception(Exception, {
          action,
          state: store.getState(),
        });
        throw Exception;
      }
    };
}
