import { gql } from '@apollo/client';
import { ARTIST_DEGREE_FRAGMENT } from '../../fragments/artist/degree.graphql';

export const CREATE_ARTIST_DEGREE = gql`
  ${ARTIST_DEGREE_FRAGMENT}
  mutation createArtistDegree($artistId: Int!, $inputArtistDegree: InputArtistDegree!) {
    createArtistDegree(artistId: $artistId, inputArtistDegree: $inputArtistDegree) {
      ...ArtistDegreeFragment
    }
  }
`;
