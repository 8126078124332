import querystring from 'query-string';
import { stringifyHash } from '@riseart/fe-utils';
import { frontend as FRONTEND_CONFIG } from '../../../config/config.js';
import { assembleUrl } from './Route';
import { getLocaleConfig } from '../../../services/riseart/utils/Route';

/**
 * UrlAssembler
 */
export const UrlAssembler: Record<string, any> = {
  locale: null,

  /**
   * config
   *
   * @param {Record<string, any>} locale
   * @returns {void}
   */
  config(locale: Record<string, any>): void {
    UrlAssembler.locale = locale;
  },

  /**
   * mergeOptions
   *
   * @param {Record<string, any>} options
   * @returns {Record<string, any>}
   */
  mergeOptions(options: Record<string, any> = {}): Record<string, any> {
    const { search, hash, locale } = options;
    const parsedSearch = typeof search === 'string' ? querystring.parse(search) : search;
    const urlOptions = {
      search: parsedSearch && querystring.stringify(parsedSearch),
      hash: hash && stringifyHash(hash),
    };

    return {
      ...options,
      ...(urlOptions.search ? { search: `?${urlOptions.search}` } : null),
      ...(urlOptions.hash ? { hash: urlOptions.hash } : null),
      locale: locale || UrlAssembler.locale,
    };
  },

  /**
   * byRouteKey
   *
   * @param {string} routeKey
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  byRouteKey(routeKey: string, options?: Record<string, any>): string | null {
    switch (routeKey) {
      default: {
        return assembleUrl(routeKey, UrlAssembler.mergeOptions(options));
      }
    }
  },

  /**
   * artistList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  artistList(options: Record<string, any> = {}): string | null {
    return assembleUrl('artistsList', UrlAssembler.mergeOptions(options));
  },

  /**
   * artistDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}}
   */
  artistDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('artistDetails', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * addArtist
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}}
   */
  addArtist(options: Record<string, any> = {}): string | null {
    return assembleUrl('artistAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * editArtist
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}}
   */
  editArtist(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('artistEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * artList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  artList(options: Record<string, any> = {}): string | null {
    return assembleUrl('artList', UrlAssembler.mergeOptions(options));
  },

  /**
   * addArtwork
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  addArtwork(options: Record<string, any> = {}): string | null {
    return assembleUrl('artAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * artDetail
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  artDetail(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('ArtEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * artistArtList
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  artistArtList(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('artistArtList', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * artistArtAdd
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  artistArtAdd(
    artistId: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('artistArtAdd', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id: artistId, ...params },
    });
  },

  /**
   * artistArtDetail
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  artistArtDetail(
    artistId: number | string,
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('artistArtEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { artistId, id, ...params },
    });
  },

  /**
   * shippingList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  shippingList(options: Record<string, any> = {}): string | null {
    return assembleUrl('sellerShippingList', UrlAssembler.mergeOptions(options));
  },

  /**
   * shippingDetail
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  shippingDetail(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('sellerShippingEdit', {
      ...UrlAssembler.mergeOptions(options),
      params: { id, ...params },
    });
  },

  /**
   * addShipping
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  addShipping(options: Record<string, any> = {}): string | null {
    return assembleUrl('sellerShippingAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * eventList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  eventList(options: Record<string, any> = {}): string | null {
    return assembleUrl('eventList', UrlAssembler.mergeOptions(options));
  },

  /**
   * addEvent
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  addEvent(options: Record<string, any> = {}): string | null {
    return assembleUrl('eventAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * eventEdit
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  eventEdit(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('eventEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * partnerArtistFeedList
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  partnerArtistFeedList(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('partnerArtistFeedList', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * partnerArtistFeedAdd
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  partnerArtistFeedAdd(
    artistId: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('partnerArtistFeedAdd', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id: artistId, ...params },
    });
  },

  /**
   * partnerArtistFeedDetail
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  partnerArtistFeedDetail(
    artistId: number | string,
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('partnerArtistFeedEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { artistId, id, ...params },
    });
  },

  /**
   * artistFeedList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  artistFeedList(options: Record<string, any> = {}): string | null {
    return assembleUrl('artistFeedList', UrlAssembler.mergeOptions(options));
  },

  /**
   * artistFeedAdd
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  artistFeedAdd(options: Record<string, any> = {}): string | null {
    return assembleUrl('artistFeedAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * artistFeedDetail
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  artistFeedDetail(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('artistFeedEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * offersList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  offersList(options: Record<string, any> = {}): string | null {
    return assembleUrl('offersList', UrlAssembler.mergeOptions(options));
  },

  /**
   * offerDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  offerDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('offerDetails', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * feArtistProfile
   *
   * @param {number} id
   * @param {string}alias
   * @returns {string}
   */
  feArtistProfile(id: number, alias: string, locale: string): string {
    const localeConfig = getLocaleConfig(locale, 'name');

    if (localeConfig && localeConfig.language === 'fr') {
      return `${FRONTEND_CONFIG.host}/${localeConfig.basePath}/artiste/${id}/${alias}`;
    }

    return `${FRONTEND_CONFIG.host}/artist/${id}/${alias}`;
  },
};
