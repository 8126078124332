import MenuItem from '@mui/material/MenuItem';
import { artist as ENUM_ARTIST } from '../../../../config/enumeration.js';

/**
 * exhibitionTypeListOptions
 *
 * @param {{(options: Record<string, any>) => any}} options
 * @returns {JSX.Element[]}
 */
function exhibitionTypeListOptions({
  formatMessage,
  initialOption,
}: {
  formatMessage: (options: Record<string, any>) => any;
  initialOption: JSX.Element | null | undefined;
}): JSX.Element[] {
  return [
    ...(initialOption ? [initialOption] : []),
    ...Object.keys(ENUM_ARTIST.exhibition.type).map((key) => {
      // @ts-ignore
      const value = ENUM_ARTIST.exhibition.type[key];
      return (
        <MenuItem key={key} value={value}>
          {formatMessage({ id: `components.cv.exhibition.type.${value}` })}
        </MenuItem>
      );
    }),
  ];
}

/**
 * ArtistExhibitionModel
 */
export const ArtistExhibitionModel = {
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      tag: 'input',
      type: 'text',
      name: 'title',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
      placeholder: 'forms.exhibition.label.title',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'type',
      margin: 'none',
      selectProps: { native: false, displayEmpty: true },
      selectOptions: exhibitionTypeListOptions,
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      placeholder: 'forms.exhibition.label.type',
      hint: 'forms.exhibition.hints.type',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'venue',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
      placeholder: 'forms.exhibition.label.venue',
      hint: 'forms.exhibition.hints.venue',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'year',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'regex',
          pattern: '^\\d{4}$',
          message: 'forms.validation.yearNotInFuture',
        },
        {
          type: 'custom',
          custom: (value: string | number): boolean =>
            !!(value && value > new Date().getFullYear()),
          message: 'forms.validation.yearNotInFuture',
        },
      ],
      placeholder: 'forms.exhibition.label.year',
    },
  ],
};
