import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { LayoutPaper, Link } from '@riseart/dashboard';
import { useScrollToEl } from '../../../../data/hooks/useScrollToEl';
import { selectPartner } from '../../../../services/redux/selectors/partner';
import { ArtistFeedAdd } from '../../../artist/feed/Add';
import { DefaultLayout } from '../../../layout/Default';
import { PageSubmenu } from '../../../common/PageSubmenu';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { GET_PARTNER_ARTIST } from '../../../../data/gql/queries/partner/getArtist.graphql';

/**
 * PartnerArtistFeedAddPage
 *
 * @returns {JSX.Element}
 */
export const PartnerArtistFeedAddPage = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const { setScrollRef, scrollToRef } = useScrollToEl();
  const pageTitle = formatMessage({ id: 'components.mainMenu.artistFeedList' });
  const listLabel = formatMessage({ id: 'components.mainMenu.artistsList' });
  const feedListLabel = formatMessage({ id: 'components.artist.tab.feed' });
  const { id: artistId }: Record<string, any> = useParams();
  const partner = useSelector(selectPartner) || {};
  const { data: listArtist, loading: listLoading } = useQuery(GET_PARTNER_ARTIST, {
    fetchPolicy: 'network-only',
    variables: {
      partnerId: partner && partner.id,
      filters: { artistId: { value: artistId, operator: 'EQUAL' } },
    },
  });
  const partnerArtist =
    listArtist && listArtist.artist && listArtist.artist.items && listArtist.artist.items[0];
  const canAdmin = partnerArtist && partnerArtist.canUpdate;
  const listFeedUrl = UrlAssembler.partnerArtistFeedList(artistId);

  return (
    <DefaultLayout
      loading={listLoading}
      title={pageTitle}
      breadcrumbs={[
        <Link
          key="home"
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link
          key="allArtists"
          to={UrlAssembler.artistList()}
          title={listLabel}
          underline="hover"
          color="inherit"
        >
          {listLabel}
        </Link>,
        ...(partnerArtist && partnerArtist.artist
          ? [
              <Link
                key="profile"
                to={UrlAssembler.artistDetails(artistId)}
                title={partnerArtist.artist.name}
                underline="hover"
                color="inherit"
              >
                {partnerArtist.artist.name}
              </Link>,
              <Link
                key="feed"
                to={listFeedUrl}
                title={feedListLabel}
                underline="hover"
                color="inherit"
              >
                {feedListLabel}
              </Link>,
              <Typography key="add" variant="inherit">
                {formatMessage({ id: 'common.add' })}
              </Typography>,
            ]
          : []),
      ]}
      boxRefCallback={setScrollRef}
    >
      <PageSubmenu menu="artist" selected="partnerArtistFeedList" urlParams={{ id: artistId }} />
      <LayoutPaper>
        {canAdmin ? (
          <ArtistFeedAdd
            artistId={artistId}
            scrollToRef={scrollToRef}
            listUrl={listFeedUrl}
            entityUrlAssembler={(feedId: number) =>
              UrlAssembler.partnerArtistFeedDetail(artistId, feedId)
            }
          />
        ) : (
          <Alert severity="error">
            {formatMessage({ id: 'components.artist.canAdminMessage' })}
          </Alert>
        )}
      </LayoutPaper>
    </DefaultLayout>
  );
};
