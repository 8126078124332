import { gql } from '@apollo/client';
import { OFFER_FRAGMENT } from '../../fragments/offer/offer.graphql';

export const READ_SELLER_OFFER = gql`
  ${OFFER_FRAGMENT}
  query readSellerOffer($offerId: Int!, $sellerId: Int!) {
    readSellerOffer(offerId: $offerId, sellerId: $sellerId) {
      ...OfferFragment
    }
  }
`;
