import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Title, CircularLoader, Paragraph, Link } from '@riseart/dashboard';
import { selectSeller } from '../../../services/redux/selectors/seller';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { offer as OFFER_ENUM } from '../../../config/enumeration.js';
import { OFFERS_SUMMARY } from '../../../data/gql/queries/offer/summary.graphql';

/**
 * DashboardCardOffers
 *
 * @returns {JSX.Element}
 */
export function DashboardCardOffers(): JSX.Element {
  const { id: sellerId } = useSelector(selectSeller) || {};
  const { data: offers, loading: summaryLoading } = useQuery(OFFERS_SUMMARY, {
    variables: { sellerId, page: 1, items: 1 },
  });
  const DATA_KEY_MAPPER = [
    {
      key: 'all',
      translation: 'total',
      url: UrlAssembler.offersList(),
    },
    {
      key: 'active',
      translation: 'active',
      url: UrlAssembler.offersList({ search: { show: OFFER_ENUM.status.ACTIVE } }),
    },
    {
      key: 'pending',
      translation: 'pending',
      url: UrlAssembler.offersList({ search: { show: OFFER_ENUM.status.PENDING } }),
    },
  ];

  return (
    <CircularLoader active={summaryLoading}>
      <Title color="primary" sx={{ marginBottom: 3 }}>
        <FormattedMessage id="components.offer.summary.title" />
      </Title>
      <Grid container justifyContent="left" spacing={2} columns={{ xs: 2, sm: 5 }}>
        {DATA_KEY_MAPPER.map(({ key, translation, url }) =>
          offers && offers[key] ? (
            <Grid key={key} item xs={1} sm={1}>
              <FormattedMessage id={`components.offer.summary.${translation}`}>
                {(text: ReactNode[]): any | null => (
                  <div>
                    <Link to={url} title={text} underline="none">
                      <Typography component="p" variant="h4" color="text.primary">
                        {offers[key].pagination.totalItems}
                      </Typography>{' '}
                      <Paragraph>{text}</Paragraph>
                    </Link>
                  </div>
                )}
              </FormattedMessage>
            </Grid>
          ) : null,
        )}
      </Grid>
    </CircularLoader>
  );
}
