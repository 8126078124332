import { Suspense } from 'react';
import { ApolloProvider } from '@apollo/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CircularLoader } from '@riseart/dashboard';
import { initClientEnvironment } from './services/riseart/client';
import { RouterRoutes } from './components/router/Routes';
import { TokenProvider } from './data/token/Token';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import './styles/print.css';
import styles from './styles/breakpoints.module.css';
import cssVars from './styles/variables.module.css';

// Setup client application environment
const { reduxStore, apolloClient } = initClientEnvironment();

function App(): JSX.Element {
  const theme = createTheme({
    palette: {
      primary: {
        light: cssVars.colorPrimaryLight,
        main: cssVars.colorPrimary,
        dark: cssVars.colorPrimaryDark,
        contrastText: cssVars.colorPrimaryText,
      },
      secondary: {
        light: cssVars.colorSecondaryLight,
        main: cssVars.colorSecondary,
        dark: cssVars.colorSecondaryDark,
        contrastText: cssVars.colorSecondaryText,
      },
      text: {
        primary: cssVars.colorTextPrimary,
        secondary: cssVars.colorTextSecondary,
        disabled: cssVars.colorTextDisabled,
      },
      grey: {
        100: cssVars.colorBackground,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: parseInt(styles.raScreenSm, 10),
        md: parseInt(styles.raScreenMd, 10),
        lg: parseInt(styles.raScreenLg, 10),
        xl: parseInt(styles.raScreenXl, 10),
      },
    },
    shape: {
      borderRadius: 0,
    },
    transitions: {
      create: () => 'none',
    },
    // @ts-ignore
    shadows: Array(25).fill('none'),
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {},
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: cssVars.colorTextPrimary,
            textDecorationColor: cssVars.colorTextPrimary,
            '&:hover': {
              color: cssVars.colorTextPrimary,
              textDecorationColor: cssVars.colorTextPrimary,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            border: '2px',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: cssVars.colorButtonBackground,
            color: cssVars.colorButtonBackground,
            lineHeight: '1.6',
            backgroundColor: cssVars.colorWhite,
            boxShadow: 'no',
            '&:hover': {
              backgroundColor: cssVars.colorSecondary,
              color: cssVars.colorWhite,
            },
            '&.MuiButton-contained': {
              border: 'none',
              backgroundColor: cssVars.colorButtonBackground,
              color: cssVars.colorWhite,

              '&.MuiButton-sizeSmall': {
                paddingTop: '5px',
                paddingBottom: '5px',
              },
              '&.MuiButton-sizeMedium': {
                paddingTop: '7px',
                paddingBottom: '7px',
              },
              '&.MuiButton-sizeLarge': {
                paddingTop: '9px',
                paddingBottom: '9px',
              },
              '&:hover': {
                backgroundColor: cssVars.colorSecondary,
                borderColor: cssVars.colorSecondary,
                color: cssVars.colorWhite,
              },

              '&.Mui-disabled': {
                color: cssVars.colorWhite,
                borderColor: cssVars.colorPrimaryLight,
                backgroundColor: cssVars.colorPrimaryLight,
              },
            },

            '&.MuiButton-outlined': {
              backgroundColor: cssVars.colorWhite,
              borderWidth: '2px',
              borderColor: cssVars.colorButtonBackground,

              '&:hover': {
                backgroundColor: cssVars.colorSecondary,
                borderColor: cssVars.colorSecondary,
                color: cssVars.colorWhite,
              },
            },
            '&.MuiButton-text': {
              borderColor: 'transparent',
            },
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: cssVars.colorSecondary,
              color: cssVars.colorWhite,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: cssVars.colorBackground,
            },
          },
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          root: {
            '.MuiButtonGroup-grouped.MuiButton-contained.MuiButton-containedPrimary:last-of-type': {
              borderRight: 'none',
            },
            '.MuiButtonGroup-grouped.MuiButton-outlined:not(:last-of-type)': {
              '&:hover': {
                borderRightColor: 'transparent',
              },
            },
            '.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary': {
              borderRight: `2px solid ${cssVars.colorWhite}`,
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            '&.MuiPaper-elevation.MuiPaper-elevation1.MuiMenu-paper': {
              '&:not(.MuiDrawer-paper)': {
                '.MuiList-root.MuiMenu-list': {
                  '.MuiListItem-root': {
                    '&:hover': {
                      backgroundColor: cssVars.colorListItemBackgroundHover,
                    },
                  },
                },
              },
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            '&.MuiList-padding': {
              paddingTop: '0',
              paddingBottom: '0',
              border: '1px solid #dfdcda',
              background: cssVars.colorWhite,
            },
            '.MuiMenuItem-root.MuiButtonBase-root': {
              lineHeight: 2,
              '&:hover': {
                backgroundColor: cssVars.colorListItemBackgroundHover,
              },
            },
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            '.MuiTableCell-root.MuiTableCell-body': { textOverflow: 'unset' },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          body: { padding: '10px', textOverflow: 'unset' },
          head: { padding: '0 10px' },
        },
      },
    },
  });

  return (
    <ReduxProvider store={reduxStore}>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <Suspense fallback={<CircularLoader active fullHeight />}>
            <ThemeProvider theme={theme}>
              <TokenProvider>
                {({ payload = {} }) => (
                  <>
                    <CssBaseline />
                    <RouterRoutes tokenPayload={payload} />
                  </>
                )}
              </TokenProvider>
            </ThemeProvider>
          </Suspense>
        </BrowserRouter>
      </ApolloProvider>
    </ReduxProvider>
  );
}

export default App;
