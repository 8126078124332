import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Link,
  Title,
  ConfirmationDialog,
  FilesGrid,
  FileList,
  FileListItem,
  FormViewField,
  useUploadedFiles,
  FormBuilder,
  FormHeader,
  FormSideItem,
  LayoutPaper,
  EmbeddedMedia,
} from '@riseart/dashboard';
import { FileUploader } from '@riseart/files';
import { art as ART_CONFIG } from '../../config/config.js';
import {
  units as UNITS_ENUM,
  file as FILE_ENUM,
  art as ART_ENUM,
  errors as ERRORS_ENUM,
} from '../../config/enumeration.js';
import { delay } from '../../services/riseart/utils/Utils';
import { useNotification } from '../../data/hooks/useNotification';
import { EditArtFormModel } from '../forms/models/art';
import { MediaAddFormModel } from '../forms/models/media';
import { FileDropzone } from '../common/file/Dropzone';
import { fileListClassName } from '../common/file/ListItem';
import { FileRestrictionsHint } from '../common/file/RestrictionsHint';
import { createFileUpload, updateFileUpload } from '../../data/gql/handlers';
import { selectSeller } from '../../services/redux/selectors/seller';
import { READ_ART } from '../../data/gql/queries/art/read.graphql';
import { UPDATE_ART } from '../../data/gql/queries/art/update.graphql';
import { LIST_SELLER_SHIPPING_TABLES } from '../../data/gql/queries/seller/listShippingTables.graphql';
import { DELETE_ART_IMAGE } from '../../data/gql/queries/art/deleteImage.graphql';
import { ADD_ART_MEDIA } from '../../data/gql/queries/art/addMedia.graphql';
import { DELETE_ART_MEDIA } from '../../data/gql/queries/art/deleteMedia.graphql';
import { mapToFormData, mapFormToArtData, normalizeRawData } from '../../data/normalizers/art';
import { Picture } from '../common/artdirection/Picture';
import { AddMedia } from '../common/media/Add';
import { extractImageFileFromData } from '../../services/riseart/utils/Image';
import { ArtActions } from '../art/Actions';
import { mapApiValidationErrors, mapApiErrors } from '../../services/riseart/utils/Form';
import { UrlAssembler } from '../../services/riseart/utils/UrlAssembler';
import { selectPartner } from '../../services/redux/selectors/partner';

type ImageType = 'main' | 'detail';

type Props = {
  artId: number;
  sellerId: number;
  canRentArt: boolean;
  currencyCode: string;
  storeCode: string;
  shipsCountryCode: string;
  artListAssembler: (options: Record<string, any>) => string;
  scrollToRef: () => void;
  onLoading?: (loading: boolean) => void;
  onData: (data: Record<string, any>) => void;
};

/**
 * ArtEdit
 *
 * @returns {JSX.Element}
 */
export const ArtEdit = ({
  artId,
  sellerId,
  canRentArt,
  currencyCode,
  storeCode,
  shipsCountryCode,
  artListAssembler,
  onLoading,
  onData,
  scrollToRef,
}: Props): JSX.Element | null => {
  const EditArtFormFields = EditArtFormModel.fields.filter(
    ({ name }) => name !== 'rent' || (canRentArt && name === 'rent'),
  );
  const { dispatchNotification } = useNotification({
    detail: 'components.notifications.artworkUpdated',
  });
  const { formatMessage } = useIntl();
  const history = useHistory();
  const partner = useSelector(selectPartner);
  const { canUseShippingTables } = useSelector(selectSeller) || {};
  const { data: shippingTablesData, loading: shippingTablesLoading } = useQuery(
    LIST_SELLER_SHIPPING_TABLES,
    { variables: { sellerId, items: 100 } },
  );
  const [deleteArtImage, { loading: deleteImageLoading }] = useMutation(DELETE_ART_IMAGE);
  const [addArtMedia, { loading: addMediaLoading }] = useMutation(ADD_ART_MEDIA);
  const [deleteArtMedia, { loading: deleteMediaLoading }] = useMutation(DELETE_ART_MEDIA);
  const [updateArt, { loading: updateLoading }] = useMutation(UPDATE_ART);
  // Component state
  const [selectedArtId, setSelectedArtId] = useState<Record<string, any> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    uploadedFiles,
    handleFileDrop: handleFileDropCallback,
    handleFileValidation,
  } = useUploadedFiles({
    filesByType: { main: [], detail: [] },
    maxFileSize: ART_CONFIG.maxFilesize,
    sizeValidationMessage: formatMessage(
      { id: 'forms.common.largerFilesize' },
      { maxFilesize: ART_CONFIG.maxFilesize },
    ),
  });
  const {
    data: readData,
    loading: readLoading,
    refetch: refetchReadArt,
  } = useQuery(READ_ART, {
    fetchPolicy: 'network-only',
    variables: {
      artId,
      store: storeCode,
      shippingCountry: shipsCountryCode,
    },
  });
  const artData = (readData && readData.readArt) || null;

  if ((!selectedArtId && artData) || (selectedArtId && selectedArtId !== artData.id)) {
    setSelectedArtId(artData.id);
  }

  const FORM_EDIT_MODE = artData && artData.productIsUnlisted ? 'ADVANCED' : 'BASIC';
  const canSubmitForm = !!(artData && artData.images && artData.images.length);

  /**
   * handleAddMedia
   */
  const handleAddMedia = useCallback(
    (inputArtMedia: Record<string, any>) =>
      addArtMedia({ variables: { artId: selectedArtId, inputArtMedia } }),
    [selectedArtId],
  );

  /**
   * handleFileUpload
   *
   * @param {Record<string, any>[]} files
   * @param {ImageType} type
   * @param {(state: Record<string, any> | null) => () => void} callback
   * @returns {void}
   */
  const handleFileUpload = (
    files: Record<string, any>[],
    type: ImageType,
    callback: (state: Record<string, any> | null) => () => void,
  ) => {
    const FileUploadClient = new FileUploader(
      FileUploader.flattenFiles({ [type]: files }),
      artData.id,
      FILE_ENUM.object.type.ART,
      createFileUpload,
      updateFileUpload,
    );
    FileUploadClient.subscribe(FileUploader.eventsTypes.UPDATE, (fileUploadState) => {
      callback(fileUploadState.files);
    });
    FileUploadClient.subscribe(FileUploader.eventsTypes.COMPLETE, (fileUploadState) => {
      refetchReadArt()
        .then(() => callback(fileUploadState.files))
        .catch(() => callback(null));
    });
    FileUploadClient.process();
  };

  /**
   * handleFileDrop
   *
   * @param {ImageType} type
   * @returns {(files: Record<string, any>[]) => void}
   */
  const handleFileDrop = (type: ImageType) => (files: Record<string, any>[]) => {
    handleFileUpload(files, type, handleFileDropCallback(type));
  };

  /**
   * handleImageDelete
   *
   * @param {Record<string, any>} image
   * @returns {() => void}
   */
  const handleImageDelete = (image: Record<string, any>) => () => {
    deleteArtImage({
      variables: {
        id: image.parentId || image.id,
        artId: artData.id,
        store: storeCode,
      },
    })
      .then(() => refetchReadArt())
      .catch(() => null);
  };

  /**
   * handleMediaDelete
   *
   * @param {number} id
   * @returns {() => void}
   */
  const handleMediaDelete = (id: number) => () => {
    deleteArtMedia({
      variables: { id, artId: artData.id },
    })
      .then(() => refetchReadArt())
      .catch(() => null);
  };

  const mainMasterImage =
    artData &&
    artData.images &&
    artData.images.length &&
    extractImageFileFromData.artMaster(artData.images, 'MAIN_MASTER');
  const mainImageElement = mainMasterImage ? (
    <Picture
      type="art.detailMain"
      artDirectionKey="art.main"
      image={mainMasterImage}
      alt={artData.title}
    />
  ) : null;

  useEffect(() => {
    if (typeof onLoading === 'function') {
      onLoading(
        loading ||
          readLoading ||
          updateLoading ||
          shippingTablesLoading ||
          deleteImageLoading ||
          deleteMediaLoading ||
          addMediaLoading,
      );
    }
  }, [
    loading,
    readLoading,
    updateLoading,
    shippingTablesLoading,
    deleteImageLoading,
    deleteMediaLoading,
    addMediaLoading,
  ]);

  useEffect(() => {
    if (artData && typeof onData === 'function') {
      onData(artData);
    }
  }, [artData]);

  return artData ? (
    <LayoutPaper>
      <FormHeader title={<Title variant="h5">{artData.title}</Title>}>
        <ArtActions
          data={{ ...normalizeRawData(artData, storeCode), canCopy: true }}
          sort={(a: Record<string, any>) => (a.actionKey === 'canCopy' ? -1 : 0)}
          onClick={() => setLoading(true)}
          onSuccess={(response: Record<string, any>, actionKey: string) => {
            refetchReadArt();
            dispatchNotification();
            setLoading(false);

            if (actionKey === 'canCopy') {
              if (
                response &&
                response.data &&
                response.data.actionArt &&
                response.data.actionArt.artId
              ) {
                delay(() => history.push(UrlAssembler.artDetail(response.data.actionArt.artId)));
              }
            } else if (actionKey !== 'productCanUnlist') {
              const MAP_ACTION_TO_FILTER: Record<string, string> = {
                productCanSubmit: ART_ENUM.state.PENDING,
                productCanSellOut: ART_ENUM.state.SOLD,
                productCanArchive: ART_ENUM.state.DISABLED,
              };

              history.push(
                artListAssembler(
                  MAP_ACTION_TO_FILTER[actionKey]
                    ? { search: `?show=${MAP_ACTION_TO_FILTER[actionKey]}` }
                    : {},
                ),
              );
            }
          }}
          onError={() => setLoading(false)}
        />
      </FormHeader>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={5}>
          <FormSideItem>
            {FORM_EDIT_MODE === 'ADVANCED' ? (
              <ConfirmationDialog
                title={formatMessage({ id: 'components.dialog.replaceMainImage.title' })}
                description={formatMessage({
                  id: 'components.dialog.replaceMainImage.description',
                })}
                disagreeLabel={formatMessage({ id: 'common.no' })}
                agreeLabel={formatMessage({ id: 'common.yes' })}
                // @ts-ignore
                onAgree={(event: SyntheticEvent, ...rest) => {
                  // @ts-ignore
                  handleFileDrop(ART_ENUM.image.category.MAIN)(...rest);
                }}
              >
                {(handleOpen: (props: Record<string, any>) => any) => (
                  <FileDropzone
                    label={formatMessage({ id: 'forms.art.label.mainImage' })}
                    uploadedImage={mainImageElement}
                    text={formatMessage({ id: 'forms.art.hints.mainImage' })}
                    textMiddle={formatMessage({ id: 'common.or' })}
                    textButton={formatMessage({ id: 'common.browseFiles' })}
                    onDrop={handleOpen}
                    validator={handleFileValidation}
                    accept={ART_CONFIG.acceptedFiles}
                    multiple={false}
                    hint={
                      <FileRestrictionsHint translationKey="forms.art.hints.mainImageUploadRestrictions" />
                    }
                  />
                )}
              </ConfirmationDialog>
            ) : (
              mainImageElement || null
            )}
            {uploadedFiles && uploadedFiles.main ? (
              <FileList>
                {uploadedFiles.main.map(
                  ({ name, progress, size, errorMessage }: Record<string, any>, idx: number) => (
                    <FileListItem
                      key={`${name}${idx}`}
                      className={fileListClassName}
                      name={name}
                      size={size}
                      errors={errorMessage}
                      progress={progress || 0}
                      status="loading"
                    />
                  ),
                )}
              </FileList>
            ) : null}
          </FormSideItem>
          <FormSideItem>
            <FileDropzone
              label={formatMessage({ id: 'forms.art.label.detailImages' })}
              text={formatMessage({ id: 'forms.art.hints.detailImages' })}
              textMiddle={formatMessage({ id: 'common.or' })}
              textButton={formatMessage({ id: 'common.browseFiles' })}
              // @ts-ignore
              onDrop={handleFileDrop(ART_ENUM.image.category.DETAIL)}
              validator={handleFileValidation}
              accept={ART_CONFIG.acceptedFiles}
              multiple
              hint={
                <FileRestrictionsHint translationKey="forms.art.hints.imageUploadRestrictions" />
              }
            />
            {uploadedFiles && uploadedFiles.detail ? (
              <FileList>
                {uploadedFiles.detail.map(
                  ({ name, progress, size, errorMessage }: Record<string, any>, idx: number) => (
                    <FileListItem
                      key={`${name}${idx}`}
                      className={fileListClassName}
                      name={name}
                      size={size}
                      errors={errorMessage}
                      progress={progress || 0}
                      status="loading"
                    />
                  ),
                )}
              </FileList>
            ) : (
              <></>
            )}
            <FilesGrid
              images={
                artData.images &&
                extractImageFileFromData.artDetail(artData.images, 'DETAIL_SQUARE')
              }
              itemRenderer={function itemRenderer(image: Record<string, any>) {
                return (
                  <Picture
                    type="art.detailGrid"
                    artDirectionKey="art.main"
                    image={image}
                    alt="Detail Image"
                  />
                );
              }}
              confirmationProps={{
                title: formatMessage({ id: 'components.dialog.deleteFile.title' }),
                description: formatMessage({
                  id: 'components.dialog.deleteFile.descriptionNoname',
                }),
                disagreeLabel: formatMessage({ id: 'common.no' }),
                agreeLabel: formatMessage({ id: 'common.yes' }),
              }}
              onDelete={handleImageDelete}
            />
          </FormSideItem>
          <AddMedia
            formModel={MediaAddFormModel(ART_ENUM.media.platform, ART_ENUM.media.type)}
            typeList={ART_ENUM.media.type}
            initialType={ART_ENUM.media.type.VIDEO}
            onAdd={handleAddMedia}
          />
          {artData.media && artData.media.length ? (
            <FormSideItem>
              {artData.media.map(({ id, mediaPlatform, mediaId }: Record<string, any>) => (
                <Box position="relative" marginY={3}>
                  <EmbeddedMedia platform={mediaPlatform} id={mediaId} />
                  <Box position="absolute" zIndex={1} bottom="10px" right="10px">
                    <ConfirmationDialog
                      {...{
                        title: formatMessage({ id: 'components.dialog.deleteMedia.title' }),
                        description: formatMessage({
                          id: 'components.dialog.deleteMedia.description',
                        }),
                        disagreeLabel: formatMessage({ id: 'common.no' }),
                        agreeLabel: formatMessage({ id: 'common.yes' }),
                      }}
                      onAgree={handleMediaDelete(id)}
                    >
                      {(handleOpen: (params?: Record<string, any>) => any) => (
                        <Fab color="primary" size="small" onClick={handleOpen} sx={{ ml: '10px' }}>
                          <DeleteIcon />
                        </Fab>
                      )}
                    </ConfirmationDialog>
                  </Box>
                </Box>
              ))}
            </FormSideItem>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={7}>
          <FormViewField label={formatMessage({ id: 'forms.art.label.type' })} sx={{ mt: 0 }}>
            {formatMessage({ id: `components.art.product.type.${artData.productType}` })}
          </FormViewField>
          <FormViewField label={formatMessage({ id: 'forms.art.label.status' })}>
            {formatMessage({ id: `components.art.product.state.${artData.state}` })}
          </FormViewField>
          {partner && artData.artist ? (
            <FormViewField label={formatMessage({ id: 'forms.art.label.artist' })}>
              <Link to={UrlAssembler.artistDetails(artData.artist.id)} title={artData.artist.name}>
                {artData.artist.name}
              </Link>
            </FormViewField>
          ) : null}
          <FormBuilder
            settings={EditArtFormModel.settings}
            fields={EditArtFormFields}
            initialValues={mapToFormData(artData, storeCode)}
            showReset
            submitText={formatMessage({ id: 'common.saveChanges' })}
            customData={{
              productType: artData.productType,
              editMode: FORM_EDIT_MODE,
              currencyCode,
              storeCode,
              unitSystem: UNITS_ENUM.system.metric,
              canUseShippingTables,
              shippingTables:
                shippingTablesData &&
                shippingTablesData.listSellerShippingTables &&
                shippingTablesData.listSellerShippingTables.items,
            }}
            disableSubmit={!canSubmitForm}
            onSubmit={async (formState, addApiErrors) => {
              updateArt({
                variables: {
                  id: artData.id,
                  store: storeCode,
                  inputArt: {
                    productType: artData.productType,
                    units: UNITS_ENUM.centimeter,
                    ...mapFormToArtData(formState.data, EditArtFormFields, FORM_EDIT_MODE),
                  },
                },
              })
                .then(() => {
                  refetchReadArt().catch(() => null);

                  // Scroll to top of container
                  scrollToRef();

                  // Show artowrk update success notification
                  dispatchNotification();
                })
                .catch((error) => {
                  // Map validation errors from response
                  const validationError = mapApiValidationErrors(error);

                  if (validationError && validationError.length && addApiErrors) {
                    addApiErrors(validationError);
                  } else {
                    // Map any other type of errors from response (validation without provided fields, or non-validation errors)
                    mapApiErrors(error).forEach((err: any) => {
                      dispatchNotification({
                        detail: err,
                        level: ERRORS_ENUM.levels.ERROR,
                        placeholder: ERRORS_ENUM.placeholders.PAGE,
                      });
                    });
                  }

                  // Scroll to error
                  scrollToRef();
                });
            }}
          />
        </Grid>
      </Grid>
    </LayoutPaper>
  ) : null;
};
