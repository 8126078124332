import { gql } from '@apollo/client';
import { ARTIST_COLLECTION_FRAGMENT } from '../../fragments/artist/collection.graphql';

export const CREATE_ARTIST_COLLECTION = gql`
  ${ARTIST_COLLECTION_FRAGMENT}
  mutation createArtistCollection($artistId: Int!, $inputArtistCollection: InputArtistCollection!) {
    createArtistCollection(artistId: $artistId, inputArtistCollection: $inputArtistCollection) {
      ...ArtistCollectionFragment
    }
  }
`;
