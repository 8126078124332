/**
 * mapApiToFormData
 *
 * @param {Object} rawData
 * @param {string} formFields
 * @returns {Record<string, any>}
 */
export function mapApiToFormData(
  rawData: Record<string, any> = {},
  formFields: Record<string, any>[],
): Record<string, any> {
  return {
    ...formFields.reduce(
      (accumulator, { name }) => ({
        ...accumulator,
        [name]: typeof rawData[name] !== 'undefined' ? rawData[name] : null,
      }),
      {},
    ),
  };
}

/**
 * mapFormToApiData
 *
 * @param {Record<string, any>} formData
 * @param {Record<string, any>[]} formFields
 * @param {boolean} editMode
 * @returns {Record<string, any>}
 */
export function mapFormToApiData(
  formData: Record<string, any> = {},
  formFields: Record<string, any>[],
  editMode = false,
): Record<string, any> {
  return formFields.reduce((accumulator, { name }) => {
    const formFieldValue = formData[name];
    const isValueSet = [null, undefined, ''].indexOf(formFieldValue) === -1;
    const fieldData = isValueSet
      ? { [name]: formFieldValue }
      : (editMode && { [name]: null }) || null;
    return {
      ...accumulator,
      ...fieldData,
    };
  }, {});
}
