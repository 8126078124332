import { gql } from '@apollo/client';
import { EVENT_FRAGMENT } from '../../fragments/event/event.graphql';
import { PAGINATION_FRAGMENT } from '../../fragments/common/pagination.graphql';

export const LIST_EVENTS = gql`
  ${EVENT_FRAGMENT}
  ${PAGINATION_FRAGMENT}
  query listEvents(
    $filters: InputEventListFilters
    $page: Int = 1
    $items: Int = 10
    $sort: String = "id"
    $order: String = "ASC"
  ) {
    listEvents(filters: $filters, page: $page, items: $items, sort: $sort, order: $order) {
      items {
        ...EventFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
`;
