import { FormattedMessage } from 'react-intl';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { FORM_VALIDATIONS } from '@riseart/dashboard';
import { event as EVENT_ENUM } from '../../../config/enumeration.js';
import {
  countriesListOptions,
  currencyListOptions,
  timeDurationPickerInput,
  phonePropsMapper,
} from '../utils';
import { dateRangePickerInput } from '../date';
import { DEFAULT_CHECKBOX_PROPS } from '../input/Checkbox';

/**
 * eventTypeListOptions
 *
 * @param {{ formatMessage: (options: Record<string, any>) => any; initialOption: JSX.Element | null | undefined; }}
 * @returns {JSX.Element[]}
 */
export function eventTypeListOptions({
  formatMessage,
  initialOption,
}: {
  formatMessage: (options: Record<string, any>) => any;
  initialOption: JSX.Element | null | undefined;
}): JSX.Element[] {
  return [
    ...(initialOption ? [initialOption] : []),
    ...Object.keys(EVENT_ENUM.type).map((type: string) => {
      // @ts-ignore
      const value = EVENT_ENUM.type[type];

      return (
        <MenuItem key={type} value={value}>
          {/* @ts-ignore  */}
          {formatMessage({ id: `components.event.type.${value}` })}
        </MenuItem>
      );
    }),
  ];
}

/**
 * eventRsvpTypeListOptions
 *
 * @param {{ formatMessage: (options: Record<string, any>) => any; initialOption: JSX.Element | null | undefined; }}
 * @returns {JSX.Element[]}
 */
export function eventRsvpTypeListOptions({
  formState,
  fieldName,
  formatMessage,
  initialOption,
  updateValues,
}: {
  formState: Record<string, any>;
  fieldName: string;
  formatMessage: (options: Record<string, any>) => any;
  initialOption: JSX.Element | null | undefined;
  updateValues: any;
}): JSX.Element[] {
  const { data } = formState;
  // eslint-disable-next-line
  const { ATTENDEE_ALL, ...privateRsvpTypeOptions } = EVENT_ENUM.rsvp.type;
  const attendeeNoneSelected = data[fieldName].indexOf(EVENT_ENUM.rsvp.type.ATTENDEE_NONE) > -1;
  // Set the attendee none value for the field if any other options were selected
  if (attendeeNoneSelected && data[fieldName].length > 1) {
    updateValues({ [fieldName]: [EVENT_ENUM.rsvp.type.ATTENDEE_NONE] });
  }

  return [
    ...(initialOption ? [initialOption] : []),
    ...Object.keys(privateRsvpTypeOptions).map((type: string) => {
      // @ts-ignore
      const value = EVENT_ENUM.rsvp.type[type];

      return (
        <MenuItem
          key={type}
          value={value}
          disabled={attendeeNoneSelected && value !== EVENT_ENUM.rsvp.type.ATTENDEE_NONE}
        >
          <Checkbox
            {...DEFAULT_CHECKBOX_PROPS}
            checked={data && data[fieldName] && data[fieldName].indexOf(value) > -1}
          />

          <ListItemText primary={formatMessage({ id: `components.event.rsvp.type.${value}` })} />
        </MenuItem>
      );
    }),
  ];
}

/**
 * eventVisibilityListOptions
 *
 * @param {{ formatMessage: (options: Record<string, any>) => any; initialOption: JSX.Element | null | undefined; }}
 * @returns {JSX.Element[]}
 */
export function eventVisibilityListOptions({
  formatMessage,
  initialOption,
}: {
  formatMessage: (options: Record<string, any>) => any;
  initialOption: JSX.Element | null | undefined;
}): JSX.Element[] {
  return [
    ...(initialOption ? [initialOption] : []),
    ...Object.keys(EVENT_ENUM.visibility).map((type: string) => {
      // @ts-ignore
      const value = EVENT_ENUM.visibility[type];

      return (
        <MenuItem key={type} value={value}>
          {/* @ts-ignore  */}
          {formatMessage({ id: `components.event.visibility.${value}` })}
        </MenuItem>
      );
    }),
  ];
}

/**
 * shouldRenderRsvpType
 *
 * @param {Record<string, any>} customData
 * @param {Record<string, any>} formState
 * @returns
 */
function shouldRenderRsvpType(
  customData: Record<string, any>,
  formState: Record<string, any>,
): boolean {
  return formState && formState.data && formState.data.visibility === EVENT_ENUM.visibility.PRIVATE;
}

/**
 * renderRsvpTypeValue
 *
 * @param {number[]} selectedValues
 * @returns {JSX.Element | null}
 */
function renderRsvpTypeValue(selectedValues: number[]): JSX.Element | null {
  return selectedValues && selectedValues.length ? (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {selectedValues.map((value: number) => (
        <Chip key={value} label={<FormattedMessage id={`components.event.rsvp.type.${value}`} />} />
      ))}
    </Box>
  ) : null;
}

/**
 * EventFormModel
 */
export const EventFormModel = {
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      tag: 'select',
      type: 'text',
      name: 'type',
      selectProps: { native: false, displayEmpty: true },
      selectOptions: eventTypeListOptions,
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.common.required',
        },
      ],
      placeholder: 'forms.event.label.type',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'name',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.common.required',
        },
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
      placeholder: 'forms.event.label.name',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'externalUrl',
      placeholder: 'forms.event.label.externalUrl',
      hint: 'forms.event.hints.externalUrl',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'description',
      multiline: true,
      minRows: 10,
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'stringLength',
          options: { max: 1000 },
          message: 'forms.validation.maxLength1000',
        },
      ],
      placeholder: 'forms.event.label.description',
    },
    {
      tag: 'input',
      type: 'json',
      name: 'dateDuration',
      placeholder: 'forms.event.label.startDate',
      hint: 'forms.event.hints.startDate',
      customRender: dateRangePickerInput,
      inputFieldData: {
        inputFormat: 'dd/MM/yyyy',
        start: {
          tag: 'input',
          type: 'json',
          name: 'startDate',
          rules: [
            {
              required: true,
              message: 'forms.validation.required',
            },
          ],
          text: 'forms.event.label.startDate',
        },
        end: { tag: 'input', type: 'json', name: 'endDate', text: 'forms.event.label.endDate' },
      },
      validateTrigger: 'onChange',
      rules: [
        {
          required: true,
          message: 'forms.common.required',
        },
        {
          type: 'custom',
          custom: (value: string | number): boolean =>
            !!(!value || (value && Array.isArray(value) && !value[0])),
          message: 'forms.validation.requiredStartDate',
        },
      ],
      isReadonly: true,
    },
    {
      tag: 'input',
      type: 'json',
      name: 'timeDuration',
      placeholder: 'forms.event.label.timeDuration',
      hint: 'forms.event.hints.startDate',
      customRender: timeDurationPickerInput,
      inputFieldData: {
        ampm: false,
        views: ['hours', 'minutes'],
        inputFormat: 'HH:mm',
        mask: '__:__',
      },
      validateTrigger: 'onChange',
      rules: [
        {
          required: true,
          message: 'forms.common.required',
        },
        {
          type: 'custom',
          custom: (value: string | number): boolean => {
            const [startTime, endTime] =
              (value && Array.isArray(value) && value.filter((item) => item !== null)) || [];
            return (startTime && !endTime) || (endTime && !startTime);
          },
          message: 'forms.validation.requiredTimeDuration',
        },
      ],
      isReadonly: true,
    },
    {
      tag: 'input',
      type: 'text',
      name: 'venueName',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.common.required',
        },
        {
          type: 'stringLength',
          options: { max: 100 },
          message: 'forms.validation.maxLength100',
        },
      ],
      placeholder: 'forms.event.label.venueName',
      hint: 'forms.event.hints.venueName',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'venueAddress',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
      placeholder: 'forms.event.label.venueAddress',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'venueCity',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'stringLength',
          options: { max: 100 },
          message: 'forms.validation.maxLength100',
        },
      ],
      placeholder: 'forms.event.label.venueCity',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'venuePostCode',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'stringLength',
          options: { max: 50 },
          message: 'forms.validation.maxLength50',
        },
      ],
      placeholder: 'forms.event.label.venuePostCode',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'venueCountry',
      selectProps: { native: false, displayEmpty: true },
      selectOptions: countriesListOptions,
      placeholder: 'forms.event.label.venueCountry',
    },
    {
      tag: 'input',
      type: 'phone',
      name: 'venuePhone',
      placeholder: 'forms.event.label.venuePhone',
      customPropsMapped: phonePropsMapper,
      rules: FORM_VALIDATIONS.PHONE,
    },
    {
      componentProps: DEFAULT_CHECKBOX_PROPS,
      tag: 'checkbox',
      type: 'text',
      name: 'rsvpShow',
      placeholder: 'forms.event.label.rsvpShow',
      hint: 'forms.event.hints.rsvpShow',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'visibility',
      selectProps: { native: false, displayEmpty: true },
      selectOptions: eventVisibilityListOptions,
      placeholder: 'forms.event.label.visibility',
      hint: 'forms.event.hints.visibility',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'rsvpType',
      selectProps: {
        native: false,
        displayEmpty: true,
        multiple: true,
        renderValue: renderRsvpTypeValue,
      },
      selectOptions: eventRsvpTypeListOptions,
      shouldRender: shouldRenderRsvpType,
      placeholder: 'forms.event.label.rsvpType',
      hint: 'forms.event.hints.rsvpType',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'ticketCurrency',
      selectProps: { native: false, displayEmpty: true },
      selectOptions: currencyListOptions,
      placeholder: 'forms.event.label.ticketCurrency',
      hint: 'forms.event.hints.ticketCurrency',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.common.required',
        },
      ],
    },
    {
      tag: 'input',
      type: 'text',
      name: 'ticketAmount',
      placeholder: 'forms.event.label.ticketAmount',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'ticketMaxNumber',
      placeholder: 'forms.event.label.ticketMaxNumber',
      hint: 'forms.event.hints.ticketMaxNumber',
    },
  ],
};
