import Chip from '@mui/material/Chip';
import { GridColumns } from '@mui/x-data-grid';
import { DataPicker } from '@riseart/dashboard';
import { DataList } from '../../data/tables/DataList';

type Props = {
  initialValue?: Record<string, any>[] | Record<string, any> | null;
  selectLabel: string;
  multiple?: boolean;
  clearOnSave?: boolean;
  onChange?: (data: Record<string, any> | null) => any;
  onSave?: (data: Record<string, any>) => any;
  children?: (data: Record<string, any>) => any;
  chipColor?:
    | 'primary'
    | 'default'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined;
  chipValueField?: string;
  chipValueRender?: (
    selectedItems: Record<string, any>[] | null,
    selectLabel: string,
    chipValueField?: string,
  ) => string | JSX.Element | null;
  gridProps: {
    query: Record<string, any>;
    queryVars?: Record<string, any> | null;
    itemMapper?: (item: Record<string, any>) => Record<string, any>;
    filterConfig: Record<string, any>[];
    sortConfig: Record<string, any>[];
    schema: GridColumns<any>;
    customData?: Record<string, any> | null;
  };
};

/**
 * defaultChipLabelRenderer
 *
 * @param {Record<string, any>[] | null} selectedItems
 * @param {string} selectLabel
 * @returns {string | JSX.Element | null}
 */
function defaultChipLabelRenderer(
  selectedItems: Record<string, any>[] | null,
  selectLabel: string,
  chipValueField = 'title',
): string | JSX.Element | null {
  return selectedItems && selectedItems.length
    ? selectedItems.map((item: Record<string, any>) => item[chipValueField]).join(', ')
    : selectLabel;
}

/**
 * DataPickerGrid
 *
 * @param {Record<string, any>} Props
 * @returns {JSX.Element}
 */
export function DataPickerGrid({
  initialValue = null,
  selectLabel,
  onChange,
  multiple = false,
  onSave,
  children,
  chipColor,
  chipValueField,
  chipValueRender = defaultChipLabelRenderer,
  gridProps: { customData = null, ...gridProps },
  clearOnSave = false,
}: Props): JSX.Element {
  return (
    <DataPicker
      initialValue={initialValue}
      label={selectLabel}
      title={selectLabel}
      multiple={multiple}
      onSave={onSave}
      onChange={onChange}
      clearOnSave={clearOnSave}
      action={({
        handleOpen,
        handleDelete,
        selectedItems,
        setSelectedItems,
      }: Record<string, any>) =>
        typeof children === 'function' ? (
          children({ handleOpen, onChange, selectedItems, setSelectedItems })
        ) : (
          <Chip
            color={chipColor || 'primary'}
            label={chipValueRender(selectedItems, selectLabel, chipValueField)}
            onClick={handleOpen}
            onDelete={selectedItems && selectedItems.length ? handleDelete : null}
          />
        )
      }
    >
      {(pickerProps: Record<string, any>) => (
        <DataList
          {...gridProps}
          itemsPerPage={25}
          customData={{ ...customData, picker: pickerProps }}
          onSelect={multiple ? null : pickerProps.handleSelect}
        />
      )}
    </DataPicker>
  );
}
