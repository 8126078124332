import { gql } from '@apollo/client';

export const ART_IMAGE_FRAGMENT = gql`
  fragment ArtImageFragment on ArtImage {
    id
    status
    public
    mimeType
    key
    extension
    size
    width
    height
    type
    active
    parentId
  }
`;
