import { gql } from '@apollo/client';

export const ART_MEDIA_FRAGMENT = gql`
  fragment ArtMediaFragment on ArtMedia {
    id
    artId
    status
    mediaType
    mediaPlatform
    mediaId
  }
`;
