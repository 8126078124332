import { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { NotificationMessage, NotificationMessageList } from '@riseart/dashboard';
import { store as CONFIG_STORE } from '../../../../config/config.js';
import { errors as ENUM_ERRORS } from '../../../../config/enumeration.js';
import {
  removeNotification,
  removeNotificationsByType,
} from '../../../../services/redux/actions/notifications/notifications';
import { isTranslationKey } from '../../../../services/riseart/utils/Utils';

type Props = Record<string, any>;

/**
 * NotificationMessages
 */
export class NotificationMessages extends Component<Props> {
  dismissTimeoutId: ReturnType<typeof setTimeout>[] = [];

  /**
   * componentWillUnmount
   */
  componentWillUnmount(): void {
    this.dismissTimeoutId &&
      this.dismissTimeoutId.forEach((id) => {
        clearTimeout(id);
      });
  }

  /**
   * handleDismiss
   *
   * @param {string} type
   * @returns {() => void}
   */
  handleDismiss(type: string): () => void {
    return () => {
      this.props.actionRemoveNotificationsByType(type);

      this.props.messages.forEach((message: Record<string, any>) => {
        if (message.type === type && this.dismissTimeoutId[message.id]) {
          clearTimeout(this.dismissTimeoutId[message.id]);
        }
      });
    };
  }

  /**
   * handleDismissTimeout
   *
   * @param {Record<string, any>} message
   */
  handleDismissTimeout(message: Record<string, any>): void {
    clearTimeout(this.dismissTimeoutId[message.id]);

    this.dismissTimeoutId[message.id] = setTimeout(
      () => this.props.actionRemoveNotification(message),
      message.expire * 1000,
    );
  }

  /**
   * bindMessages
   */
  bindMessages(): void {
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleDismissTimeout = this.handleDismissTimeout.bind(this);
  }

  /**
   * render
   *
   * @returns {(JSX.Element | (JSX.Element | null)[] | null}
   */
  render(): JSX.Element | (JSX.Element | null)[] | null {
    const { messages, fixed, intl, className } = this.props;
    const uniqueMsgs = messages.reduce(
      (aggregator: Record<string, any>, item: Record<string, any>) => {
        if (!aggregator[item.type]) {
          aggregator[item.type] = [];
        }

        if (
          aggregator[item.type] &&
          !aggregator[item.type].some(
            (msg: Record<string, any>) =>
              msg.detail === item.detail && msg.type === item.type && msg.expire === item.expire,
          )
        ) {
          aggregator[item.type].push(item);
        }

        return aggregator;
      },
      {},
    );

    if (this.props.placeholder === ENUM_ERRORS.placeholders.SNACKBAR) {
      return uniqueMsgs && Object.keys(uniqueMsgs).length
        ? Object.keys(uniqueMsgs).map((type: any) => {
            const items = uniqueMsgs[type];

            return items && items.length ? (
              <Snackbar key={type} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} open>
                <Alert severity={type} onClose={this.handleDismiss(type)}>
                  {items.map((message: Record<string, any>) => {
                    if (message.expire) {
                      this.handleDismissTimeout(message);
                    }
                    return (
                      <div key={message.id}>
                        {isTranslationKey(message.detail)
                          ? intl.formatMessage({
                              id: message.detail,
                              defaultMessage: message.detail,
                            })
                          : message.detail}
                      </div>
                    );
                  })}
                </Alert>
              </Snackbar>
            ) : null;
          })
        : null;
    }

    return uniqueMsgs && Object.keys(uniqueMsgs).length ? (
      <NotificationMessageList fixed={fixed} className={className}>
        {Object.keys(uniqueMsgs).map((type: string) => {
          const items = uniqueMsgs[type];

          return items && items.length ? (
            <NotificationMessage
              key={type}
              type={type}
              items={items.map((message: Record<string, any>) => {
                if (message.expire) {
                  this.handleDismissTimeout(message);
                }
                return isTranslationKey(message.detail)
                  ? intl.formatMessage({ id: message.detail, defaultMessage: message.detail })
                  : message.detail;
              })}
              onClose={this.handleDismiss(type)}
            />
          ) : null;
        })}
      </NotificationMessageList>
    ) : null;
  }
}

export const HOCMessagesNotificationsManager = (
  DecoratedComponent: JSX.Element,
): Record<string, any> =>
  connect(
    (state: Record<string, any>, ownProps: Record<string, any>) => {
      const notificationsState = state[CONFIG_STORE.keys.notifications];
      return {
        messages:
          notificationsState &&
          notificationsState.filter(
            ({ placeholder }: { placeholder: string }) => placeholder === ownProps.placeholder,
          ),
      };
    },
    (dispatch) => ({
      actionRemoveNotification: (message: Record<string, any>) =>
        dispatch(removeNotification(message)),
      actionRemoveNotificationsByType: (type: string) => dispatch(removeNotificationsByType(type)),
    }),
    // @ts-ignore
  )(injectIntl(DecoratedComponent));
