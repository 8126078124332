import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/client';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { CircularLoader, FormBuilder, FormTable, FormTableCell } from '@riseart/dashboard';
import { ArtistAwardModel } from '../forms/models/artist/award';
import { mapFormToApiData } from '../../data/normalizers/common';
import { CREATE_ARTIST_AWARD } from '../../data/gql/queries/artist/createAward.graphql';
import { TABLE_IN_ROW_CELL_PROPS } from '../common/tables/settings';

type Props = {
  artistId: string | number;
  onCancel: () => any;
  onSuccess: (response: any) => any;
  onError?: (errors: any, addApiErrors: any) => any;
};

/**
 * AwardAddRow
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const AwardAddRow = ({ artistId, onCancel, onSuccess, onError }: Props): JSX.Element => {
  const [createMutation, { loading: createLoading }] = useMutation(CREATE_ARTIST_AWARD);

  return (
    <TableRow>
      <TableCell colSpan={6} {...TABLE_IN_ROW_CELL_PROPS}>
        <CircularLoader active={createLoading} style={{ minHeight: 'auto' }}>
          <FormBuilder
            inputLabelProps={{ shrink: true }}
            containerWrapper={FormTable}
            fieldWrapper={FormTableCell}
            fieldWrapperProps={[
              { width: '20%', sx: { pl: 4 } },
              { width: '20%' },
              { width: '15%' },
              { width: '15%' },
              { width: '10%' },
              { width: '20%' },
            ]}
            settings={ArtistAwardModel.settings}
            fields={ArtistAwardModel.fields}
            showReset
            footer={({ hasErrors, disableSubmit }) => (
              <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <IconButton type="submit" size="large" disabled={hasErrors || disableSubmit}>
                  <DoneIcon
                    color={hasErrors || disableSubmit ? 'disabled' : 'success'}
                    fontSize="large"
                  />
                </IconButton>
                <IconButton size="large" onClick={onCancel}>
                  <CloseIcon color="error" fontSize="large" />
                </IconButton>
              </Stack>
            )}
            submitText={<FormattedMessage id="common.saveChanges" />}
            onSubmit={async (formState, addApiErrors) => {
              createMutation({
                variables: {
                  artistId,
                  inputArtistAward: mapFormToApiData(formState.data, ArtistAwardModel.fields),
                },
              })
                .then(onSuccess)
                .catch(
                  (errors: any) => typeof onError === 'function' && onError(errors, addApiErrors),
                );
            }}
          />
        </CircularLoader>
      </TableCell>
    </TableRow>
  );
};
