import { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useQuery } from '@apollo/client';
import { CircularLoader } from '@riseart/dashboard';
import { cookies as CONFIG_COOKIES } from '../../config/config.js';
import { getInitialAuthActions, getCookieValue } from '../../services/riseart/utils/Auth';
import { selectAuth, AuthSelectorType } from '../../services/redux/selectors/auth';
import { selectUser } from '../../services/redux/selectors/user';
import { READ_ME } from '../gql/queries/me/read.graphql';
import { meFetched } from '../../services/redux/actions/me/me';
import { ErrorPage } from '../../components/pages/error/Error';
import { ErrorService } from '../../services/riseart/errors/ErrorService';

/**
 * TokenProvider
 *
 * @param {props} props
 * @returns
 */
export const TokenProvider = ({
  children,
}: {
  children: (data: AuthSelectorType) => any;
}): JSX.Element => {
  const dispatch = useDispatch();
  const auth = useSelector(
    selectAuth,
    (oldState, newState) =>
      oldState.token === newState.token && shallowEqual(oldState.payload, newState.payload),
  );
  const user = useSelector(selectUser, shallowEqual);
  const [loadedInitialActions, setLoadedInitialActions] = useState<
    Record<string, any> | { type: string; payload: any } | null | undefined
  >(undefined);
  const {
    loading,
    error,
    data: meData,
  } = useQuery(READ_ME, {
    fetchPolicy: 'network-only',
    skip:
      loadedInitialActions === undefined ||
      !auth.token ||
      !!(
        user &&
        auth &&
        auth.payload &&
        parseInt(auth.payload.user_id, 10) === parseInt(user.id, 10)
      ),
  });
  const tokenFromCookie = getCookieValue(CONFIG_COOKIES.token.name);

  useEffect(() => {
    async function determineActions() {
      const initialAuthActions = await getInitialAuthActions(
        tokenFromCookie,
        auth && auth.token,
        dispatch,
      );

      setLoadedInitialActions(initialAuthActions);
    }

    determineActions();
  }, [tokenFromCookie, auth.token]);

  useEffect(() => {
    if (
      loadedInitialActions !== undefined &&
      loadedInitialActions !== null &&
      !loading &&
      meData &&
      !user
    ) {
      dispatch(meFetched(meData && meData.readMe));
    }
  }, [meData, user, loadedInitialActions, loading]);

  if (loadedInitialActions !== undefined) {
    if (loadedInitialActions === null || user) {
      return children(auth);
    }

    if (loading) {
      return <CircularLoader active fullHeight />;
    } else if (meData && !user) {
      return children(auth);
    } else if (error) {
      return <ErrorPage error={ErrorService.mapGraphqlError(error)} translate={false} showLogo />;
    }
  }

  return <CircularLoader active fullHeight />;
};
