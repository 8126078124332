import { gql } from '@apollo/client';
import { ARTIST_BIBLIOGRAPHY_FRAGMENT } from '../../fragments/artist/bibliography.graphql';

export const UPDATE_ARTIST_BIBLIOGRAPHY = gql`
  ${ARTIST_BIBLIOGRAPHY_FRAGMENT}
  mutation updateArtistBibliography(
    $id: ID!
    $artistId: Int!
    $inputArtistBibliography: InputArtistBibliography!
  ) {
    updateArtistBibliography(
      id: $id
      artistId: $artistId
      inputArtistBibliography: $inputArtistBibliography
    ) {
      ...ArtistBibliographyFragment
    }
  }
`;
