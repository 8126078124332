import { gql } from '@apollo/client';

export const ART_SUMMARY = gql`
  query artSummary(
    $filtersAll: InputArtListFilters
    $filtersActive: InputArtListFilters
    $filtersUnlisted: InputArtListFilters
    $filtersPending: InputArtListFilters
    $filtersSold: InputArtListFilters
    $store: String = "uk"
    $shippingCountry: String = "GB"
    $page: Int = 1
    $items: Int = 1
    $sort: String = "artist"
  ) {
    allArt: listArt(
      filters: $filtersAll
      store: $store
      shippingCountry: $shippingCountry
      page: $page
      items: $items
      sort: $sort
    ) {
      pagination {
        totalItems
      }
    }
    activeArt: listArt(
      filters: $filtersActive
      store: $store
      shippingCountry: $shippingCountry
      page: $page
      items: $items
      sort: $sort
    ) {
      pagination {
        totalItems
      }
    }
    unlistedArt: listArt(
      filters: $filtersUnlisted
      store: $store
      shippingCountry: $shippingCountry
      page: $page
      items: $items
      sort: $sort
    ) {
      pagination {
        totalItems
      }
    }
    pendingArt: listArt(
      filters: $filtersPending
      store: $store
      shippingCountry: $shippingCountry
      page: $page
      items: $items
      sort: $sort
    ) {
      pagination {
        totalItems
      }
    }
    soldArt: listArt(
      filters: $filtersSold
      store: $store
      shippingCountry: $shippingCountry
      page: $page
      items: $items
      sort: $sort
    ) {
      pagination {
        totalItems
      }
    }
  }
`;
