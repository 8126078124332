import queryString from 'query-string';
import { useEffect, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { DefaultLayout } from '../layout/Default';
import { authSignout } from '../../services/redux/actions/auth/auth';
import { authRedirect } from '../..//services/riseart/utils/Auth';

type Props = {
  history: Record<string, any>;
  location: Record<string, any>;
  currentLocale: Record<string, any>;
};

/**
 * SignoutPage
 *
 * @param {Props} props
 * @returns {ReactElement<any>}
 */
export const SignoutPage = ({ history, location }: Props): ReactElement<any> => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authSignout());
    const queryParameters = queryString.parse(location.search && location.search.forward);
    authRedirect(queryParameters, history);
  }, []);

  return (
    <DefaultLayout
      loading
      loadingContent={<FormattedMessage id="components.auth.signOutMessage" />}
    />
  );
};
