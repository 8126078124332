import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import { Link, MEDIA_PAGE_LINKS } from '@riseart/dashboard';
import styles from './List.module.css';

import imgYoutube from '../../../assets/media/youtube.png';
import imgVimeo from '../../../assets/media/vimeo.png';
import imgInstagram from '../../../assets/media/instagram.png';

type MediaListProps = {
  children: ReactNode;
};

type MediaListItemProps = {
  mediaType: string;
  mediaPlatform: string;
  mediaId: number;
  onDelete: () => any;
};

const VIDEO_PLATFORMS: Record<string, any> = {
  youtube: imgYoutube,
  vimeo: imgVimeo,
  instagram: imgInstagram,
};

/**
 * MediaList
 *
 * @param {MediaListProps} props
 * @returns {JSX.Element}
 */
export function MediaList({ children }: MediaListProps): JSX.Element {
  return <div className={styles.mediaList}>{children}</div>;
}

/**
 * MediaListItem
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export function MediaListItem({
  mediaPlatform,
  mediaId,
  onDelete,
}: MediaListItemProps): JSX.Element {
  const { formatMessage } = useIntl();
  const icon = VIDEO_PLATFORMS[mediaPlatform];

  return (
    <div className={styles.mediaListItem}>
      <div className={styles.icon}>
        <FormattedMessage
          id="components.media.viewVideo"
          values={{ platform: formatMessage({ id: `components.media.platform.${mediaPlatform}` }) }}
        >
          {(viewLabel) => (
            <Link
              to={MEDIA_PAGE_LINKS[mediaPlatform](mediaId)}
              external
              target="_blank"
              title={viewLabel}
            >
              <img src={icon} alt={viewLabel.join()} />
            </Link>
          )}
        </FormattedMessage>
      </div>
      <div className={styles.content}>
        <div>
          {formatMessage({ id: 'components.media.listPlatformLabel' })}:{' '}
          {formatMessage({ id: `components.media.platform.${mediaPlatform}` })}
        </div>
        <div>
          {formatMessage({ id: 'components.media.listIdLabel' })}:{' '}
          <FormattedMessage
            id="components.media.viewVideo"
            values={{
              platform: formatMessage({ id: `components.media.platform.${mediaPlatform}` }),
            }}
          >
            {(viewLabel) => (
              <Link
                to={MEDIA_PAGE_LINKS[mediaPlatform](mediaId)}
                external
                target="_blank"
                title={viewLabel}
              >
                {mediaId}
              </Link>
            )}
          </FormattedMessage>
        </div>
      </div>
      <div className={styles.action}>
        <IconButton
          aria-label="delete"
          onClick={onDelete}
          sx={{
            width: '100%',
            height: '100%',
            '&:hover': { borderRadius: 'initial' },
          }}
        >
          <CancelIcon fontSize="inherit" color="primary" />
        </IconButton>
      </div>
    </div>
  );
}
