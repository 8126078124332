import { gql } from '@apollo/client';
import { EVENT_FRAGMENT } from '../../fragments/event/event.graphql';

export const CREATE_EVENT = gql`
  ${EVENT_FRAGMENT}
  mutation createEvent($inputEvent: InputEvent!) {
    createEvent(inputEvent: $inputEvent) {
      ...EventFragment
    }
  }
`;
