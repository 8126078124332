import MenuItem from '@mui/material/MenuItem';
import { Link } from '@riseart/dashboard';
import { artist as ENUM_ARTIST } from '../../../../config/enumeration.js';
import { LocalizedConfig } from '../../../../services/riseart/utils/LocalizedConfig';

function mediaPlatformListOptions({
  formatMessage,
  initialOption,
}: {
  formatMessage: (options: Record<string, any>) => any;
  initialOption: any;
}): JSX.Element[] {
  return [
    ...(initialOption ? [initialOption] : []),
    ...Object.keys(ENUM_ARTIST.feed.media.platform).map((key: string) => {
      // @ts-ignore
      const value = ENUM_ARTIST.feed.media.platform[key];
      return (
        <MenuItem value={value} key={value}>
          {formatMessage({ id: `components.feed.media.platform.${value}` })}
        </MenuItem>
      );
    }),
  ];
}

/**
 * ArtistFeedModel
 */
export const ArtistFeedModel = {
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      tag: 'select',
      type: 'text',
      name: 'mediaPlatform',
      selectProps: { native: false, displayEmpty: true },
      selectOptions: mediaPlatformListOptions,
      shouldRender: ({ mediaType }: { mediaType: string }): boolean =>
        mediaType === ENUM_ARTIST.feed.media.type.VIDEO,
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      placeholder: 'forms.feed.label.platform',
      hint: 'forms.feed.hints.platform',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'mediaId',
      shouldRender: ({ mediaType }: { mediaType: string }): boolean =>
        mediaType === ENUM_ARTIST.feed.media.type.VIDEO,
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'custom',
          custom: (value: string | number, data: Record<string, any>): boolean => {
            if (data.mediaPlatform === ENUM_ARTIST.feed.media.platform.YOUTUBE) {
              return typeof value === 'string' && value.length > 0
                ? !/^([a-zA-Z0-9-_]+)$/.test(value)
                : false;
            }

            return false;
          },
          message: 'forms.validation.mediaPlatform.youtube',
        },
        {
          type: 'custom',
          custom: (value: string | number, data: Record<string, any>): boolean => {
            if (data.mediaPlatform === ENUM_ARTIST.feed.media.platform.VIMEO) {
              return typeof value === 'string' && value.length > 0
                ? !/^([0-9]+)$/.test(value)
                : false;
            }

            return false;
          },
          message: 'forms.validation.mediaPlatform.vimeo',
        },
        {
          type: 'custom',
          custom: (value: string | number, data: Record<string, any>): boolean => {
            if (data.mediaPlatform === ENUM_ARTIST.feed.media.platform.INSTAGRAM) {
              return typeof value === 'string' && value.length > 0
                ? !/^([a-zA-Z0-9-_]+)$/.test(value)
                : false;
            }

            return false;
          },
          message: 'forms.validation.mediaPlatform.instagram',
        },
      ],
      placeholder: 'forms.feed.label.videoId',
      hint: (
        options: { storeCode: string },
        formatMessage: (values: Record<string, any>, options?: Record<string, any>) => any,
      ): JSX.Element =>
        formatMessage(
          { id: 'forms.feed.hints.videoId' },
          {
            a: (chunk: any) => (
              <Link
                href={LocalizedConfig.get('externalUrls.studioDiaryHelp')}
                target="_blank"
                color="inherit"
                external
                title={formatMessage({ id: 'forms.feed.hints.videoIdLinkTitle' })}
              >
                {chunk}
              </Link>
            ),
          },
        ),
    },
    {
      tag: 'input',
      type: 'text',
      name: 'title',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 75 },
          message: 'forms.validation.maxLength75',
        },
      ],
      placeholder: 'forms.feed.label.title',
      hint: 'forms.feed.hints.title',
    },
  ],
};
