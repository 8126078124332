import { gql } from '@apollo/client';
import { ARTIST_BIBLIOGRAPHY_FRAGMENT } from '../../fragments/artist/bibliography.graphql';

export const LIST_ARTIST_BIBLIOGRAPHIES = gql`
  ${ARTIST_BIBLIOGRAPHY_FRAGMENT}
  query listArtistBibliographies($artistId: Int!) {
    listArtistBibliographies(artistId: $artistId) {
      items {
        ...ArtistBibliographyFragment
      }
    }
  }
`;
