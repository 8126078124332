export const DIMENSIONS = [
  {
    name: 'medium',
    childDimension: 'subMedium',
    values: [
      {
        name: 'collage',
        children: ['fabric', 'mixed-media-1', 'paper-1', 'photo'],
      },
      {
        name: 'drawings',
        children: ['chalk', 'charcoal', 'crayon', 'ink', 'pastel', 'pencil'],
      },
      {
        name: 'installation',
      },
      {
        name: 'paintings',
        children: [
          'acrylic',
          'airbrush',
          'encaustic',
          'fabric-1',
          'gouache',
          'ink-1',
          'mixed-media-2',
          'oil',
          'tempera',
          'watercolour',
        ],
      },
      {
        name: 'photography',
        children: ['black-white', 'colour', 'digital-1', 'mixed-media', 'pinhole'],
      },
      {
        name: 'prints',
        children: [
          'digital',
          'etching',
          'lino',
          'lithograph',
          'mixed-media-3',
          'mono',
          'screenprint',
          'transfer',
          'woodcut',
        ],
      },
      {
        name: 'sculpture',
        children: [
          'bronze',
          'ceramic',
          'clay',
          'enamel',
          'glass',
          'kinetic',
          'marble',
          'metal',
          'paper',
          'plastic',
          'resin',
          'stone',
          'wood',
        ],
      },
      {
        name: 'digital',
        children: ['vector'],
      },
    ],
  },
  {
    name: 'style',
    values: [
      { name: 'abstract' },
      { name: 'abstract-expressionism' },
      { name: 'abstract-marble' },
      { name: 'art-deco' },
      { name: 'cartoon' },
      { name: 'conceptual' },
      { name: 'cubism' },
      { name: 'colour-field' },
      { name: 'dada' },
      { name: 'expressionistic' },
      { name: 'figurative' },
      { name: 'geometric' },
      { name: 'geometric-abstract' },
      { name: 'graphic' },
      { name: 'illustrative' },
      { name: 'impressionistic' },
      { name: 'minimalistic' },
      { name: 'mosaic' },
      { name: 'photorealism' },
      { name: 'pop-art' },
      { name: 'realistic' },
      { name: 'stencil' },
      { name: 'street-art' },
      { name: 'surrealist' },
      { name: 'other' },
    ],
  },
  {
    name: 'subject',
    childDimension: 'subSubject',
    values: [
      { name: 'abstract' },
      {
        name: 'animals',
        children: ['bird', 'cat', 'cow', 'dog', 'fish', 'horse'],
      },
      { name: 'architecture' },
      {
        name: 'botanical',
        children: ['flower', 'leaves', 'tree'],
      },
      { name: 'celebrities' },
      {
        name: 'cityscapes',
        children: ['hong-kong', 'london-1', 'new-york-1', 'paris', 'singapore'],
      },
      { name: 'documentary' },
      { name: 'fantasy' },
      { name: 'fashion' },
      { name: 'food' },
      {
        name: 'landscapes',
        children: ['beach', 'rural', 'sky'],
      },
      {
        name: 'maps',
        children: ['london', 'new-york'],
      },
      { name: 'music' },
      { name: 'nature' },
      { name: 'nudes' },
      { name: 'politics' },
      {
        name: 'portraits',
        children: ['female', 'male'],
      },
      { name: 'science-technology' },
      { name: 'seascapes' },
      {
        name: 'spirituality',
        children: ['religion'],
      },
      {
        name: 'sports',
        children: ['athletics', 'cycling', 'football'],
      },
      {
        name: 'still-life',
        children: ['flower-1', 'food'],
      },
      {
        name: 'transport-auto',
        children: ['airplane', 'bike', 'car', 'ship', 'train', 'yacht'],
      },
      { name: 'typography' },
      { name: 'underwater' },
      { name: 'urban' },
      { name: 'wildlife' },
      { name: 'other' },
    ],
  },
];
