/**
 * HTTP_CODES
 */
export enum HTTP_CODES {
  RESPONSE_CODE_SUCCESS = 200,
  RESPONSE_CODE_CREATED = 201,
  RESPONSE_CODE_DELETED = 204,
  RESPONSE_CODE_BAD_REQUEST = 400,
  RESPONSE_CODE_UNAUTHORIZED = 401,
  RESPONSE_CODE_FORBIDDEN = 403,
  RESPONSE_CODE_NOT_FOUND = 404,
  RESPONSE_CODE_RESOURCE_ALREADY_EXIST = 409,
  RESPONSE_CODE_INTERNAL_SERVER_ERROR = 500,
}

/**
 * Defaults
 */
export class Default {
  static readonly STATUS: number = 500;
  static readonly CODE: string = 'ADMC_1000';
  static readonly TYPE: string = 'UnknownError';
  static readonly TITLE: string = 'Unknown Error';
  static readonly DETAIL: string = 'Something went wrong. Please try again.';
}

/**
 * Codes
 */
export class Codes {
  static INVALID_DATA = 'ADMC_1001';
}

/**
 * Types
 */
export class Types {
  static INVALID_DATA = 'ApiInvalidData';
}

// TODO: @nikola Localize error titles and messages

/**
 * Titles
 */
export class Titles {
  static INVALID_DATA = 'API Invalid Data';
}

/**
 * Messages
 */
export class Messages {
  static INVALID_DATA = 'Unable to process received data from API';
}
