import {
  Codes as ApiErrorCodes,
  Types as ApiErrorTypes,
  Titles as ApiErrorTitles,
  Messages as ApiErrorMessages,
  HTTP_CODES,
} from './ApiErrorDefinitions';

/**
 * ApiInvalidData
 */
class ApiInvalidData extends Error {
  public previous: any;
  public code: string;
  public type: string;
  public status: number;
  public title: string;
  public detail: string;

  /**
   * Constructor
   *
   * @param {string} message
   * @param {Record<string, any> | string} previous
   */
  constructor(detail?: string, previous?: Record<string, any> | string) {
    // Error message
    const message = previous
      ? typeof previous === 'string'
        ? previous
        : previous.message || ApiErrorMessages.INVALID_DATA
      : ApiErrorMessages.INVALID_DATA;

    // Parent constructor
    super(detail || message);

    // Set the prototype explicitly
    Object.setPrototypeOf(this, ApiInvalidData.prototype);

    // Set error properties
    this.name = this.constructor.name;
    this.code = ApiErrorCodes.INVALID_DATA;
    this.type = ApiErrorTypes.INVALID_DATA;
    this.status = HTTP_CODES.RESPONSE_CODE_INTERNAL_SERVER_ERROR;
    this.title = ApiErrorTitles.INVALID_DATA;
    this.detail = detail || message;
    this.previous = previous;
  }
}

export { ApiInvalidData };
