import { gql } from '@apollo/client';
import { ART_FRAGMENT } from '../../fragments/art/art.graphql';
import { PAGINATION_FRAGMENT } from '../../fragments/common/pagination.graphql';

export const LIST_ART = gql`
  ${ART_FRAGMENT}
  ${PAGINATION_FRAGMENT}
  query listArt(
    $filters: InputArtListFilters
    $store: String = "uk"
    $shippingCountry: String = "GB"
    $page: Int = 1
    $items: Int = 10
    $sort: String = "artist"
  ) {
    listArt(
      filters: $filters
      store: $store
      shippingCountry: $shippingCountry
      page: $page
      items: $items
      sort: $sort
    ) {
      items {
        ...ArtFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
`;
