import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { Title, LayoutPaper, Link } from '@riseart/dashboard';
import { selectArtist } from '../../../services/redux/selectors/artist';
import { useScrollToEl } from '../../../data/hooks/useScrollToEl';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { DefaultLayout } from '../../layout/Default';
import { ArtistProfileForm } from './ProfileForm';

/**
 * ArtistProfilePage
 *
 * @returns {JSX.Element}
 */
export const ArtistProfilePage = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const artist = useSelector(selectArtist) || {};
  const [artistName, setArtistName] = useState(null);
  const { formatMessage } = useIntl();
  const { setScrollRef, scrollToRef } = useScrollToEl();

  /**
   * handleRead
   *
   * @param {Record<string, any> | null} seller
   */
  function handleRead(artist: Record<string, any> | null) {
    if (artist && artist.name && artistName !== artist.name) {
      setArtistName(artist.name);
    }
  }

  return (
    <DefaultLayout
      loading={loading}
      title={formatMessage({ id: 'components.mainMenu.artistProfile' })}
      breadcrumbs={[
        <Link
          key="home"
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link
          key="artistProfile"
          to={UrlAssembler.byRouteKey('artistProfile')}
          title={formatMessage({ id: 'components.mainMenu.artistProfile' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'components.mainMenu.artistProfile' })}
        </Link>,

        ...(artistName
          ? [
              <Typography key="artistProfile" variant="inherit">
                {artistName}
              </Typography>,
            ]
          : []),
      ]}
      boxRefCallback={setScrollRef}
    >
      <ArtistProfileForm
        artistId={artist.id}
        title={
          <Title variant="h5">{formatMessage({ id: 'components.mainMenu.artistProfile' })}</Title>
        }
        submitText={formatMessage({ id: 'common.saveChanges' })}
        onLoading={setLoading}
        onRead={handleRead}
        wrapperComponent={LayoutPaper}
        scrollToRef={scrollToRef}
      />
    </DefaultLayout>
  );
};
