import { useEffect, FC } from 'react';
import Box from '@mui/material/Box';
import { useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Paragraph, Link, Logo, Title, FormBuilder } from '@riseart/dashboard';
import { DefaultLayout } from '../layout/Default';
import { validateToken } from '../../services/riseart/utils/Auth';
import { CREATE_JWT } from '../../data/gql/queries/auth/createJwt.graphql';
import { graphql as GQL_CONFIG } from '../../config/config.js';
import { auth as AUTH_ENUM } from '../../config/enumeration.js';
import { LocalizedConfig } from '../../services/riseart/utils/LocalizedConfig';
import { SocialContainer } from '../auth/containers/social/Social';
import { authTokenUpdated } from '../../services/redux/actions/auth/auth';
import { LoginFormModel } from '../forms/models/login';
import { withForwardUrlACL } from '../../data/acl/withForwardUrlACL';

type Props = {
  redirectByForwardACL: boolean;
};

/**
 * LoginPage
 *
 * @param {Props} props
 */
const Login: FC<any> = ({ redirectByForwardACL }: Props): JSX.Element => {
  const [createJwt, { data, loading, error }] = useMutation(CREATE_JWT);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data && !error) {
      const { token } = data.createJwt;

      // Validate token and pass its paayload to redux action
      validateToken(token).then((tokenPayload) => {
        dispatch(authTokenUpdated({ token, payload: tokenPayload, trigger: 'login' }));
      });
    }
  }, [data, error, loading]);

  return (
    <DefaultLayout hideHeader loading={loading || redirectByForwardACL}>
      {!redirectByForwardACL ? (
        <Box component="div" sx={{ mt: '90px', textAlign: 'center' }}>
          <Logo />
          <Title component="h2" variant="h4" sx={{ mt: '30px' }}>
            <FormattedMessage id="components.auth.loginHeading" />
          </Title>
          <SocialContainer createJwt={createJwt} isLogin />
          <Paragraph align="center">
            <FormattedMessage id="components.auth.loginParagraph" />
          </Paragraph>

          <Box component="div" sx={{ maxWidth: '400px', m: '0 auto' }}>
            <FormBuilder
              {...LoginFormModel}
              subFooterLinks={
                <Box textAlign="right">
                  <FormattedMessage id="components.auth.forgotPassword">
                    {(label) => (
                      <Link
                        href={LocalizedConfig.get('externalUrls.forgotPassword')}
                        external
                        title={label}
                        underline="hover"
                      >
                        {label}
                      </Link>
                    )}
                  </FormattedMessage>
                </Box>
              }
              submitText={<FormattedMessage id="components.auth.login" />}
              onSubmit={async ({
                data,
                hasErrors,
              }: {
                data: Record<string, any>;
                hasErrors: boolean;
              }) => {
                if (!hasErrors && data.email && data.password) {
                  createJwt({
                    variables: {
                      inputJwt: {
                        apiKey: GQL_CONFIG.authKey,
                        authModule: AUTH_ENUM.modules.SELLER_USER,
                        username: data.email,
                        password: data.password,
                      },
                    },
                  }).catch(() => null);
                }
              }}
            />
          </Box>
        </Box>
      ) : null}
    </DefaultLayout>
  );
};

export const LoginPage = withForwardUrlACL(Login);
