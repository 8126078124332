import {
  ArtistEditorialLocale,
  ArtistEditorial as ArtistEditorialBaseModel,
  ArtistEditorialPaginationResult as ArtistEditorialPaginationResultBaseModel,
  ArtistAward as ArtistAwardBaseModel,
  ArtistAwardListResult as ArtistAwardListResultBaseModel,
  ArtistCollection as ArtistCollectionBaseModel,
  ArtistCollectionListResult as ArtistCollectionListResultBaseModel,
  ArtistDegree as ArtistDegreeBaseModel,
  ArtistDegreeListResult as ArtistDegreeListResultBaseModel,
  ArtistExhibition as ArtistExhibitionBase,
  ArtistExhibitionListResult as ArtistExhibitionListResultBaseModel,
  ArtistBibliography as ArtistBibliographyBase,
  ArtistBibliographyListResult as ArtistBibliographyListResultBaseModel,
  ArtistImage as ArtistImageBaseModel,
  ArtistLocale,
  Artist as ArtistBaseModel,
  ArtistPaginationResult as ArtistPaginationResultBaseModel,
  ArtistFeedLocale,
  ArtistFeed as ArtistFeedBaseModel,
  ArtistFeedPaginationResult as ArtistFeedPaginationResultBaseModel,
} from '@riseart/models';
import { ApiInvalidData } from '../errors/ApiInvalidData';
import {
  ImageHierarchicalMixin,
  ImageHierarchicalArray,
  ImageHierarchical,
  ImageArray,
} from './Image';
import { PaginationInfo, ListInfo } from './List';
import { Author } from './Author';
import { LocaleMixin, FormDataMixin } from './Core';
import { artist as ARTIST_ENUM } from '../../config/enumeration.js';

/**
 * ArtistEditorial
 */
class ArtistEditorial extends FormDataMixin(
  LocaleMixin(ArtistEditorialBaseModel, ArtistEditorialLocale),
) {
  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {ArtistEditorial}
   */
  public hydrateFromApiData(data?: Record<string, any>): ArtistEditorial {
    try {
      // Deconstruct data
      const { author: authorData, ...editorialData } = data || {};

      // Root
      super.hydrateFromApiData(editorialData);

      // Author
      const author: Author | null = authorData ? new Author().hydrateFromApiData(authorData) : null;

      return this.hydrate({ author });
    } catch (error) {
      throw new ApiInvalidData('Unable to load ArtistEditorial from provided data');
    }
  }

  /**
   * mapFormToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   * @returns {Record<string, any>}
   */
  public static mapFormToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
  ): Record<string, any> {
    const mappedData = super.mapFormToApiData(data, fieldsSchema, includeNullValues);
    // eslint-disable-next-line
    const { locales, ...restData } = mappedData;
    return restData;
  }
}

/**
 * ArtistEditorialPaginationResult
 */
class ArtistEditorialPaginationResult extends ArtistEditorialPaginationResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {ArtistEditorialPaginationResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): ArtistEditorialPaginationResult {
    try {
      // Deconstruct
      const { items: itemsData, ...paginationData } = data || {};

      // Items
      const items: Array<ArtistEditorial> = itemsData
        ? itemsData.map((item: Record<string, any>) =>
            new ArtistEditorial().hydrateFromApiData(item),
          )
        : [];

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      // Hydrate model
      return this.hydrate({ items, pagination });
    } catch (error) {
      throw new ApiInvalidData('Unable to load ArtistEditorialPaginationResult from provided data');
    }
  }
}

/**
 * ArtistImageArray
 */
class ArtistImageArray extends ImageHierarchicalArray {
  private static itemClassInstance: ImageHierarchical;

  /**
   * Set image item class instance
   *
   * @param {ImageHierarchical} classInstance
   */
  static setItemClassInstance(classInstance: ImageHierarchical) {
    ArtistImageArray.itemClassInstance = classInstance;
  }

  /**
   * getImageObject
   *
   * @returns {ImageHierarchical}
   */
  protected getImageObject(): ImageHierarchical {
    if (ArtistImageArray.itemClassInstance) {
      return Object.create(ArtistImageArray.itemClassInstance);
    }
    return super.getImageObject();
  }
}

/**
 * ArtistImage
 */
class ArtistImage extends ImageHierarchicalMixin(ArtistImageBaseModel, ArtistImageArray) {}

// Set artist image object as child
// image class name in array object
ArtistImageArray.setItemClassInstance(new ArtistImage());

/**
 * Artist
 */
class Artist extends FormDataMixin(LocaleMixin(ArtistBaseModel, ArtistLocale)) {
  /** Property definitions */
  images: ArtistImageArray;

  /**
   * constructor
   *
   * @param {Array<Record<string, any>>} data
   */
  public constructor(data?: Record<string, any>) {
    super();
    if (data) {
      this.hydrateFromApiData(data);
    }
  }

  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {Artist}
   */
  public hydrateFromApiData(data?: Record<string, any>): Artist {
    try {
      // Deconstruct data
      const { images: imagesData = [], ...artistData } = data || {};

      // Root
      super.hydrateFromApiData(artistData);

      // Images
      const images: ArtistImageArray = new ArtistImageArray().hydrateFromApiData(
        imagesData,
      ) as ArtistImageArray;

      return this.hydrate({ images });
    } catch (error) {
      throw new ApiInvalidData('Unable to load Artist from provided data');
    }
  }

  /**
   * toFormData
   *
   * @param {Record<string, any>[]} formFields
   * @returns {Record<string, any>}
   */
  public toFormData(formFields: Record<string, any>[]): Record<string, any> {
    return {
      ...formFields.reduce((accumulator, { name }) => {
        return {
          ...accumulator,
          [name]: typeof this[name] !== 'undefined' ? this[name] : null,
        };
      }, {}),
    };
  }

  /**
   * hydrateFromFormData
   *
   * @param {Record<string, any>} formData
   * @returns {Artist}
   */
  public hydrateFromFormData(formData: Record<string, any>): Artist {
    return this.hydrate({ id: this.id, ...formData });
  }

  /**
   * hydrateFromLocaleFormData
   *
   * @param {Record<string, any>} formData
   * @param {string} localeCode
   * @returns {ArtistLocale}
   */
  public hydrateFromLocaleFormData(
    formData: Record<string, any>,
    localeCode: string,
  ): ArtistLocale {
    const existingLocale = this.locales.find(
      (localeItem: Record<string, any>) => localeItem.localeCode === localeCode,
    );
    const SHOULD_GENERATE_ID = !formData.id;
    const NewLocale = new ArtistLocale().hydrate({ localeCode, ...formData }, SHOULD_GENERATE_ID);

    this.locales = existingLocale
      ? this.locales.map((localeItem: Record<string, any>) => {
          if (localeItem.localeCode === localeCode) {
            return NewLocale;
          }

          return localeItem;
        })
      : [...this.locales, NewLocale];

    return NewLocale;
  }
}

/**
 * ArtistPaginationResult
 */
class ArtistPaginationResult extends ArtistPaginationResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {ArtistPaginationResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): ArtistPaginationResult {
    try {
      // Deconstruct
      const { items: itemsData, ...paginationData } = data || {};

      // Items
      const items: Array<Artist> = itemsData
        ? itemsData.map((item: Record<string, any>) => new Artist().hydrateFromApiData(item))
        : [];

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      // Hydrate model
      return this.hydrate({
        items: items,
        pagination: pagination || {},
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load ArtistPaginationResult from provided data');
    }
  }
}

/**
 * ArtistAward
 */
class ArtistAward extends FormDataMixin(ArtistAwardBaseModel) {
  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {ArtistAward}
   */
  public hydrateFromApiData(data?: Record<string, any>): ArtistAward {
    try {
      // Destruct data
      const { country: countryData, ...awardData } = data || {};

      return this.hydrate({
        ...(countryData ? { countryCode: countryData.code || null } : {}),
        ...awardData,
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load ArtistAward from provided data');
    }
  }
}

/**
 * ArtistAwardListResult
 */
class ArtistAwardListResult extends ArtistAwardListResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {ArtistAwardListResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): ArtistAwardListResult {
    try {
      // Deconstruct
      const { items: itemsData, collection: collectionData } = data || {};

      // Items
      const items: Array<ArtistAward> = itemsData
        ? itemsData.map((item: Record<string, any>) => new ArtistAward().hydrateFromApiData(item))
        : [];

      // Collection
      const collection: ListInfo = new ListInfo().hydrateFromApiData(collectionData);

      // Hydrate model
      return this.hydrate({ items, collection });
    } catch (error) {
      throw new ApiInvalidData('Unable to load ArtistAwardListResult from provided data');
    }
  }
}

/**
 * ArtistCollection
 */
class ArtistCollection extends FormDataMixin(ArtistCollectionBaseModel) {
  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {ArtistCollection}
   */
  public hydrateFromApiData(data?: Record<string, any>): ArtistCollection {
    try {
      // Destruct parameters
      const { country: countryData, ...collectionData } = data || {};

      return this.hydrate({
        ...(countryData ? { countryCode: countryData.code || null } : {}),
        ...collectionData,
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load ArtistCollection from provided data');
    }
  }
}

/**
 * ArtistCollectionListResult
 */
class ArtistCollectionListResult extends ArtistCollectionListResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {ArtistCollectionListResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): ArtistCollectionListResult {
    try {
      // Deconstruct
      const { items: itemsData, collection: collectionData } = data || {};

      // Items
      const items: Array<ArtistCollection> = itemsData
        ? itemsData.map((item: Record<string, any>) =>
            new ArtistCollection().hydrateFromApiData(item),
          )
        : [];

      // Collection
      const collection: ListInfo = new ListInfo().hydrateFromApiData(collectionData);

      // Hydrate model
      return this.hydrate({ items, collection });
    } catch (error) {
      throw new ApiInvalidData('Unable to load ArtistCollectionListResult from provided data');
    }
  }
}

/**
 * ArtistDegree
 */
class ArtistDegree extends FormDataMixin(ArtistDegreeBaseModel) {
  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {ArtistDegree}
   */
  public hydrateFromApiData(data?: Record<string, any>): ArtistDegree {
    try {
      // Destruct parameters
      const { country: countryData, ...degreeData } = data || {};

      return this.hydrate({
        ...(countryData ? { countryCode: countryData.code || null } : {}),
        ...degreeData,
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load ArtistDegree from provided data');
    }
  }
}

/**
 * ArtistDegreeListResult
 */
class ArtistDegreeListResult extends ArtistDegreeListResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {ArtistDegreeListResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): ArtistDegreeListResult {
    try {
      // Deconstruct
      const { items: itemsData, collection: collectionData } = data || {};

      // Items
      const items: Array<ArtistDegree> = itemsData
        ? itemsData.map((item: Record<string, any>) => new ArtistDegree().hydrateFromApiData(item))
        : [];

      // Collection
      const collection: ListInfo = new ListInfo().hydrateFromApiData(collectionData);

      // Hydrate model
      return this.hydrate({ items, collection });
    } catch (error) {
      throw new ApiInvalidData('Unable to load ArtistDegreeListResult from provided data');
    }
  }
}

/**
 * ArtistExhibition
 */
class ArtistExhibition extends FormDataMixin(ArtistExhibitionBase) {}

/**
 * ArtistExhibitionListResult
 */
class ArtistExhibitionListResult extends ArtistExhibitionListResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {ArtistExhibitionListResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): ArtistExhibitionListResult {
    try {
      // Deconstruct
      const { items: itemsData, collection: collectionData } = data || {};

      // Items
      const items: Array<ArtistExhibition> = itemsData
        ? itemsData.map((item: Record<string, any>) => new ArtistExhibition().hydrate(item))
        : [];

      // Collection
      const collection: ListInfo = new ListInfo().hydrateFromApiData(collectionData);

      // Hydrate model
      return this.hydrate({ items, collection });
    } catch (error) {
      throw new ApiInvalidData('Unable to load ArtistExhibitionListResult from provided data');
    }
  }
}

/**
 * ArtistBibliography
 */
class ArtistBibliography extends FormDataMixin(ArtistBibliographyBase) {}

/**
 * ArtistBibliographyListResult
 */
class ArtistBibliographyListResult extends ArtistBibliographyListResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {ArtistBibliographyListResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): ArtistBibliographyListResult {
    try {
      // Deconstruct
      const { items: itemsData, collection: collectionData } = data || {};

      // Items
      const items: Array<ArtistBibliography> = itemsData
        ? itemsData.map((item: Record<string, any>) => new ArtistBibliography().hydrate(item))
        : [];

      // Collection
      const collection: ListInfo = new ListInfo().hydrateFromApiData(collectionData);

      // Hydrate model
      return this.hydrate({ items, collection });
    } catch (error) {
      throw new ApiInvalidData('Unable to load ArtistBibliographyListResult from provided data');
    }
  }
}

/**
 * ArtistFeed
 */
class ArtistFeed extends LocaleMixin(FormDataMixin(ArtistFeedBaseModel), ArtistFeedLocale) {
  /** Property definitions */
  images: ImageArray;

  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {ArtistFeed}
   */
  public hydrateFromApiData(data?: Record<string, any>): ArtistFeed {
    try {
      // Deconstruct parameters
      const { images: imagesData, ...feedData } = data || {};

      // Root
      super.hydrateFromApiData(feedData);

      // Images
      const images: ImageArray = new ImageArray().hydrateFromApiData(imagesData);

      return this.hydrate({ images });
    } catch (error) {
      throw new ApiInvalidData('Unable to load ArtistFeed from provided data');
    }
  }

  /**
   * mapFormToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   * @returns {Record<string, any>}
   */
  public static mapFormToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
  ): Record<string, any> {
    const mappedData = super.mapFormToApiData(data, fieldsSchema, includeNullValues);
    const { mediaId, mediaPlatform, ...commonFields } = mappedData;

    switch (data.mediaType) {
      case ARTIST_ENUM.feed.media.type.VIDEO: {
        return { mediaId, mediaPlatform, ...commonFields };
      }
      case ARTIST_ENUM.feed.media.type.IMAGE: {
        return commonFields;
      }
      default: {
        return mappedData;
      }
    }
  }
}

/**
 * ArtistFeedPaginationResult
 */
class ArtistFeedPaginationResult extends ArtistFeedPaginationResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {ArtistFeedPaginationResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): ArtistFeedPaginationResult {
    try {
      // Deconstruct
      const { items: itemsData, ...paginationData } = data || {};

      // Items
      const items: Array<ArtistFeed> = itemsData
        ? itemsData.map((item: Record<string, any>) => new ArtistFeed().hydrateFromApiData(item))
        : [];

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      // Hydrate model
      return this.hydrate({ items, pagination });
    } catch (error) {
      throw new ApiInvalidData('Unable to load ArtistFeedPaginationResult from provided data');
    }
  }
}

export {
  ArtistEditorial,
  ArtistEditorialPaginationResult,
  Artist,
  ArtistPaginationResult,
  ArtistAward,
  ArtistAwardListResult,
  ArtistCollection,
  ArtistCollectionListResult,
  ArtistDegree,
  ArtistDegreeListResult,
  ArtistExhibition,
  ArtistExhibitionListResult,
  ArtistBibliography,
  ArtistBibliographyListResult,
  ArtistImage,
  ArtistFeed,
  ArtistFeedPaginationResult,
};
