/**
 * ArtistBibliographyModel
 */
export const ArtistBibliographyModel = {
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      tag: 'input',
      type: 'text',
      name: 'title',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
      placeholder: 'forms.bibliography.label.title',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'author',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'stringLength',
          options: { max: 45 },
          message: 'forms.validation.maxLength45',
        },
      ],
      placeholder: 'forms.bibliography.label.author',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'publication',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'stringLength',
          options: { max: 45 },
          message: 'forms.validation.maxLength45',
        },
      ],
      placeholder: 'forms.bibliography.label.publication',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'publisher',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'stringLength',
          options: { max: 45 },
          message: 'forms.validation.maxLength45',
        },
      ],
      placeholder: 'forms.bibliography.label.publisher',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'pages',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'stringLength',
          options: { max: 45 },
          message: 'forms.validation.maxLength45',
        },
      ],
      placeholder: 'forms.bibliography.label.pages',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'year',
      margin: 'none',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'regex',
          pattern: '^\\d{4}$',
          message: 'forms.validation.yearNotInFuture',
        },
        {
          type: 'custom',
          custom: (value: string | number): boolean =>
            !!(value && value > new Date().getFullYear()),
          message: 'forms.validation.yearNotInFuture',
        },
      ],
      placeholder: 'forms.bibliography.label.year',
    },
  ],
};
