import { store as CONFIG_STORE } from '../../../config/config.js';

/**
 * selectArtist
 *
 * @param {Record<string, any>} state
 * @returns {Record<string, any>}
 */
export function selectArtist(state: Record<string, any>): Record<string, any> | null {
  const me = state[CONFIG_STORE.keys.me];
  return (me && me.data && me.data.artist) || null;
}
