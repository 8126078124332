import { gql } from '@apollo/client';
import {
  ME_VISITOR_FRAGMENT,
  ME_USER_FRAGMENT,
  ME_SELLER_FRAGMENT,
  ME_ARTIST_FRAGMENT,
  ME_PARTNER_FRAGMENT,
} from '../../fragments/me.graphql';

export const READ_ME = gql`
  ${ME_VISITOR_FRAGMENT}
  ${ME_USER_FRAGMENT}
  ${ME_SELLER_FRAGMENT}
  ${ME_ARTIST_FRAGMENT}
  ${ME_PARTNER_FRAGMENT}
  query readMe {
    readMe {
      id
      visitor {
        ...MeVisitorFragment
      }
      user {
        ...MeUserFragment
      }
      seller {
        ...MeSellerFragment
      }
      artist {
        ...MeArtistFragment
      }
      partner {
        ...MePartnerFragment
      }
    }
  }
`;
