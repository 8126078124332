import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { LAYOUT_PAPER_PADDING, DataTable } from '@riseart/dashboard';
import { errors as ERRORS_ENUM } from '../../../../config/enumeration.js';
import { useNotification } from '../../../../data/hooks/useNotification';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { DefaultLayout } from '../../../layout/Default';
import { selectSeller } from '../../../../services/redux/selectors/seller';
import { SELLER_SHIPPING_TABLE_SCHEMA } from '../../../data/tables/schemas/seller';
import { LIST_SELLER_SHIPPING_TABLES } from '../../../../data/gql/queries/seller/listShippingTables.graphql';

/**
 * SellerShippingListPage
 *
 * @returns {JSX.Element}
 */
export const SellerShippingListPage = (): JSX.Element | null => {
  const { dispatchNotification } = useNotification();
  const { formatMessage } = useIntl();
  const seller = useSelector(selectSeller);
  const addShippingLabel = formatMessage({ id: 'components.seller.addShipping' });
  const pageTitle = formatMessage({ id: 'components.mainMenu.sellerShippingList' });

  if (seller && !seller.canUseShippingTables) {
    dispatchNotification({
      detail: 'components.notifications.noAccess',
      level: ERRORS_ENUM.levels.ERROR,
      placeholder: ERRORS_ENUM.placeholders.APP,
      handler: ERRORS_ENUM.handlers.ERROR_PAGE,
    });

    return null;
  }

  const {
    data,
    loading,
    refetch: refetchListQuery,
  } = useQuery(LIST_SELLER_SHIPPING_TABLES, {
    variables: {
      sellerId: seller && seller.id,
      items: 100,
    },
    skip: !seller || !seller.id,
  });

  const items =
    (data && data.listSellerShippingTables && data.listSellerShippingTables.items) || null;

  return (
    <DefaultLayout
      title={formatMessage({ id: 'components.mainMenu.seller' })}
      includesFab
      breadcrumbs={[
        <Link
          key="home"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link
          key="sellerProfile"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('sellerProfile')}
          title={formatMessage({ id: 'components.mainMenu.seller' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'components.mainMenu.seller' })}
        </Link>,
        <Typography key="sellerShippingList" variant="inherit">
          {pageTitle}
        </Typography>,
      ]}
    >
      <Paper sx={{ position: 'relative' }}>
        {seller ? (
          <DataTable
            columns={[
              {
                title: formatMessage({ id: 'components.tables.columns.sellerShipping.name' }),
                sx: { pl: LAYOUT_PAPER_PADDING.pl },
              },
              { title: formatMessage({ id: 'components.tables.columns.sellerShipping.currency' }) },
              {
                title: formatMessage({
                  id: 'components.tables.columns.sellerShipping.inheritsFrom',
                }),
              },
              {
                title: '',
                align: 'right',
                sx: { display: { xs: 'none', sm: 'table-cell' } },
              },
            ]}
            schema={SELLER_SHIPPING_TABLE_SCHEMA}
            layoutPaperPadding={LAYOUT_PAPER_PADDING}
            data={items}
            customData={{ sellerId: seller.id }}
            refetchListQuery={refetchListQuery}
            noData={formatMessage({ id: 'common.noItems' })}
            loading={loading}
            mainAction={
              <Button
                variant="contained"
                size="large"
                component={RouterLink}
                to={UrlAssembler.addShipping()}
                title={addShippingLabel}
                sx={{
                  display: { xs: 'none', sm: 'block' },
                }}
              >
                {addShippingLabel}
              </Button>
            }
            title={pageTitle}
          />
        ) : null}
        <Fab
          color="primary"
          component={RouterLink}
          to={UrlAssembler.addShipping()}
          title={addShippingLabel}
          sx={{
            position: { xs: 'fixed', lg: 'absolute' },
            top: { xs: 'auto', lg: '135px' },
            left: { xs: 'auto', lg: '-28px' },
            bottom: { xs: '25px', lg: 'auto' },
            right: { xs: '25px', lg: 'auto' },
            fontSize: '60px',
          }}
          size="large"
        >
          <AddIcon />
        </Fab>
      </Paper>
    </DefaultLayout>
  );
};
