import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Link } from '@riseart/dashboard';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { DefaultLayout } from '../../layout/Default';
import { selectPartner } from '../../../services/redux/selectors/partner';
import { PartnerInvitations } from './Invitations';
import { PartnerAdmins } from './Admins';

/**
 * SettingsDashboardPage
 *
 * @returns {JSX.Element}
 */
export function SettingsDashboardPage(): JSX.Element {
  const { formatMessage } = useIntl();
  const { id: partnerId } = useSelector(selectPartner) || {};
  const homeLabel = formatMessage({ id: 'common.home' });
  const settingsLabel = formatMessage({ id: 'components.mainMenu.settings' });
  const titleLabel = formatMessage({ id: 'components.mainMenu.team' });

  return (
    <DefaultLayout
      title={titleLabel}
      includesFab
      breadcrumbs={[
        <Link
          key="home"
          to={UrlAssembler.byRouteKey('home')}
          title={homeLabel}
          underline="hover"
          color="inherit"
        >
          {homeLabel}
        </Link>,
        <Link
          key="settings"
          to={UrlAssembler.byRouteKey('settings')}
          title={settingsLabel}
          underline="hover"
          color="inherit"
        >
          {settingsLabel}
        </Link>,
        <Typography key="list" variant="inherit">
          {titleLabel}
        </Typography>,
      ]}
    >
      <Paper sx={{ position: 'relative' }}>
        <PartnerInvitations partnerId={partnerId} />
      </Paper>
      <Paper sx={{ mt: 2, position: 'relative' }}>
        <PartnerAdmins partnerId={partnerId} />
      </Paper>
    </DefaultLayout>
  );
}
