import { gql } from '@apollo/client';

export const GET_PARTNER_ARTIST = gql`
  query listPartnerArtists(
    $partnerId: Int!
    $page: Int = 1
    $items: Int = 1
    $filters: InputPartnerArtistListFilters
  ) {
    artist: listPartnerArtists(
      partnerId: $partnerId
      page: $page
      items: $items
      filters: $filters
    ) {
      items {
        artistId
        canUpdate
        canManageArtwork
        artist {
          name
        }
      }
    }
  }
`;
