export const FILTER_CONFIG = [
  { columnField: 'id', type: 'integer' },
  { columnField: 'name', apiField: 'name', type: 'string' },
  { columnField: 'alias', apiField: 'alias', type: 'string' },
  {
    columnField: 'status',
    apiField: 'status',
    type: 'discrete',
    filterType: 'basic',
  },
  { columnField: 'livesCountry', apiField: 'livesCountry', type: 'discrete' },
  { columnField: 'gender', apiField: 'gender', type: 'discrete' },
];

export const SORT_CONFIG = [
  { columnField: 'id' },
  { columnField: 'name', apiField: 'name' },
  { columnField: 'alias', apiField: 'alias' },
  { columnField: 'status', apiField: 'status' },
  { columnField: 'livesCountry', apiField: 'lives' },
  { columnField: 'gender', apiField: 'gender' },
];

export const PARTNER_ARTIST_FILTER_CONFIG = [
  { columnField: 'admin', type: 'discrete', filterType: 'basic' },
];

export const PARTNER_ARTIST_SORT_CONFIG = [
  { columnField: 'id', apiField: 'artistId' },
  { columnField: 'name', apiField: 'artistName' },
  { columnField: 'alias', apiField: 'artistAlias' },
  { columnField: 'status', apiField: 'status' },
  { columnField: 'rank', apiField: 'rank' },
  { columnField: 'admin', apiField: 'admin' },
];
