export const APP_STORE_ACTIONS = {
  UPDATE_PROPERTY: 'APP_STORE_UPDATE_PROPERTY',
  RESET_PROPERTY: 'APP_STORE_RESET_PROPERTY',
  UPDATE_REDIRECT_PROPERTY: 'APP_STORE_UPDATE_REDIRECT_PROPERTY',
  UPDATE_LOCALES: 'APP_STORE_UPDATE_LOCALES',
};

export type ActionType = {
  type: string;
  payload: Record<string, any>;
};

// Action Creators
export function applicationStoreUpdate(
  property: string,
  value: string | number | Record<string, any> | Record<string, any>[],
): ActionType {
  return {
    type: APP_STORE_ACTIONS.UPDATE_PROPERTY,
    payload: { property, value },
  };
}

export function applicationStoreUpdateLocales(locales: Record<string, any>): ActionType {
  return {
    type: APP_STORE_ACTIONS.UPDATE_LOCALES,
    payload: { locales },
  };
}

export function applicationStoreReset(property: string): ActionType {
  return {
    type: APP_STORE_ACTIONS.RESET_PROPERTY,
    payload: { property },
  };
}

export function applicationStoreUpdateRedirects(redirects: string): ActionType {
  return {
    type: APP_STORE_ACTIONS.UPDATE_REDIRECT_PROPERTY,
    payload: { redirects },
  };
}
