import { gql } from '@apollo/client';
import { PARTNER_ADMIN_FRAGMENT } from '../../fragments/partner/admin.graphql';

export const UPDATE_PARTNER_ADMIN = gql`
  ${PARTNER_ADMIN_FRAGMENT}
  mutation updatePartnerAdmin(
    $userId: Int!
    $partnerId: Int!
    $inputPartnerAdmin: InputPartnerAdmin!
  ) {
    updatePartnerAdmin(
      userId: $userId
      partnerId: $partnerId
      inputPartnerAdmin: $inputPartnerAdmin
    ) {
      ...PartnerAdminFragment
    }
  }
`;
