import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { DefaultLayout } from '../../layout/Default';
import { selectArtist } from '../../../services/redux/selectors/artist';
import { ArtList } from '../../art/List';

/**
 * ArtListPage
 *
 * @returns {JSX.Element}
 */
export const ArtListPage = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const artist = useSelector(selectArtist);
  const pageTitle = formatMessage({ id: 'components.mainMenu.artList' });

  return (
    <DefaultLayout
      title={pageTitle}
      includesFab
      breadcrumbs={[
        <Link
          key="home"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Typography key="artList" variant="inherit">
          {pageTitle}
        </Typography>,
      ]}
    >
      <Paper sx={{ position: 'relative' }}>
        <ArtList
          canManageArtwork
          artistId={artist && artist.id}
          artListAssembler={(options: Record<string, any>): string => UrlAssembler.artList(options)}
          addUrl={UrlAssembler.addArtwork()}
        />
      </Paper>
    </DefaultLayout>
  );
};
