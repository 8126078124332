import { Cookies } from '../../riseart/utils/Cookies/Cookies';
import { cookies as CONFIG_COOKIES } from '../../../config/config.js';
import { RiseartLogger } from '../../riseart/Logger';
import { AUTH_ACTIONS, ME_ACTIONS } from '../actions/container';

/**
 * MiddlewareAuth
 */
export class MiddlewareAuth {
  /**
   * middleware
   *
   * @param {Object} store
   * @returns {Function}
   */
  // eslint-disable-next-line no-unused-vars
  static middleware = () => (next: any) => (action: Record<string, any>) => {
    switch (action.type) {
      case AUTH_ACTIONS.AUTH_COOKIE_UPDATE:
        MiddlewareAuth.updateAuthCookies(action.payload.payload);
        break;
      case AUTH_ACTIONS.AUTH_TOKEN_COOKIE_UPDATE:
        MiddlewareAuth.updateTokenCookie(action.payload.payload.token);
        break;
      case AUTH_ACTIONS.AUTH_TOKEN_COOKIE_CLEAR:
        MiddlewareAuth.clearTokenCookie();
        break;
      case AUTH_ACTIONS.AUTH_VISITOR_COOKIE_UPDATE:
        MiddlewareAuth.updateVisitorCookie(action.payload.payload);
        break;
      case ME_ACTIONS.ME_UPDATED:
        // set Sentry user only if seller data is received from graphql which includes email
        if (action.payload.payload.seller) {
          RiseartLogger.setUserContext(action.payload.payload);
        }
        break;
      default:
        break;
    }

    next(action);
  };

  /**
   * updateAuthCookies
   *
   * @param {Record<string, any>} payload
   * @returns {void}
   */
  static updateAuthCookies(payload: Record<string, any>): void {
    const { token } = payload;

    MiddlewareAuth.updateTokenCookie(token);
    MiddlewareAuth.updateVisitorCookie({ payload });
  }

  /**
   * updateTokenCookie
   *
   * @param {string} token
   * @returns {void}
   */
  static updateTokenCookie(token: string): void {
    if (!token) {
      return;
    }

    Cookies.set(CONFIG_COOKIES.token.name, token, CONFIG_COOKIES.token);
  }

  /**
   * clearTokenCookie
   *
   * @returns {void}
   */
  static clearTokenCookie(): void {
    Cookies.remove(CONFIG_COOKIES.token.name);
  }

  /**
   * updateVisitorCookie
   *
   * @param {Record<string, any>} data
   * @returns {void}
   */
  static updateVisitorCookie(data: Record<string, any> = {}): void {
    let visitorId = null;
    if (data.payload) {
      // Update from JWT payload
      visitorId = data.payload.visitor_id;
    } else if (data.visitor) {
      // Update from ME payload
      visitorId = data.visitor.id;
    }

    if (!visitorId) {
      return;
    }

    // @ts-ignore
    Cookies.set(CONFIG_COOKIES.visitor.name, visitorId, CONFIG_COOKIES.visitor);
  }
}
