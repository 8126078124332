import { gql } from '@apollo/client';
import { SELLER_PAYMENT_METHOD_FRAGMENT } from '../../fragments/seller/paymentMethod.graphql';

export const LIST_SELLER_PAYMENT_METHODS = gql`
  ${SELLER_PAYMENT_METHOD_FRAGMENT}
  query listSellerPaymentMethods(
    $sellerId: Int!
    $default: Boolean = true
    $items: Int = 10
    $offset: Int = 0
  ) {
    listSellerPaymentMethods(
      sellerId: $sellerId
      default: $default
      items: $items
      offset: $offset
    ) {
      items {
        ...SellerPaymentMethodFragment
      }
    }
  }
`;
