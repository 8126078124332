import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { DataTable, LAYOUT_PAPER_PADDING } from '@riseart/dashboard';
import { offer as OFFER_ENUM } from '../../../config/enumeration.js';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { DefaultLayout } from '../../layout/Default';
import { DataListFeed } from '../../data/ListFeed';
import { OfferPaginationResult as OfferPaginationResultModel } from '../../../data/models/Offer';
import { selectSeller } from '../../../services/redux/selectors/seller';
import { OFFERLIST_TABLE_SCHEMA_ADVANCED } from '../../data/tables/schemas/offer';
import { LIST_SELLER_OFFERS } from '../../../data/gql/queries/offer/listSeller.graphql';
import { ACTION_OFFER } from '../../../data/gql/queries/offer/action.graphql';
import { StatusSelectButton } from '../../common/buttons/StatusSelect';

/**
 * OfferListPage
 *
 * @returns {JSX.Element}
 */
export function OfferListPage(): JSX.Element {
  const ITEMS_PER_PAGE = 10;
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { id: sellerId } = useSelector(selectSeller) || {};
  const [updateMutation, { loading: updateLoading }] = useMutation(ACTION_OFFER);

  return (
    <DefaultLayout
      title={formatMessage({ id: 'components.mainMenu.offersList' })}
      includesFab
      breadcrumbs={[
        <Link
          key="home"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Typography key="list" variant="inherit">
          {formatMessage({ id: 'components.mainMenu.offersList' })}
        </Typography>,
      ]}
    >
      <Paper sx={{ position: 'relative' }}>
        <DataListFeed
          query={LIST_SELLER_OFFERS}
          fetchPolicy="network-only"
          itemsPerPage={ITEMS_PER_PAGE}
          buildUrl={({
            page,
            show = OFFER_ENUM.status.ALL,
            itemsPerPage = ITEMS_PER_PAGE,
            qsParams,
          }: Record<string, any>) => {
            const searchParams = {
              ...qsParams,
              ...(page && page > 1 ? { page } : null),
              ...(itemsPerPage && itemsPerPage !== ITEMS_PER_PAGE ? { itemsPerPage } : null),
              ...(show && show !== OFFER_ENUM.status.ALL ? { show } : null),
            };

            return UrlAssembler.offersList({
              search: searchParams && Object.keys(searchParams).length ? searchParams : null,
            });
          }}
          variablesMapper={({
            page = 1,
            itemsPerPage,
            show,
          }: Record<string, any>): Record<string, any> => ({
            sellerId,
            page,
            items: itemsPerPage || ITEMS_PER_PAGE,
            ...(show && show !== OFFER_ENUM.status.ALL ? { filters: { status: show } } : null),
          })}
        >
          {({
            loading,
            refetchListQuery,
            items,
            pagination,
            initialValues,
            handleParamsChange,
          }: Record<string, any>) => (
            <DataTable
              columns={[
                {
                  title: formatMessage({ id: 'components.common.id' }),
                  sx: { pl: LAYOUT_PAPER_PADDING.pl, whiteSpace: 'nowrap' },
                  width: '10%',
                },
                {
                  title: formatMessage({ id: 'components.common.state' }),
                  sx: { display: { xs: 'none', sm: 'table-cell' }, whiteSpace: 'nowrap' },
                  width: '10%',
                },
                {
                  title: formatMessage({ id: 'components.common.status' }),
                  sx: { display: { xs: 'none', sm: 'table-cell' }, whiteSpace: 'nowrap' },
                  width: '10%',
                },
                {
                  title: formatMessage({ id: 'components.offer.acceptedDate' }),
                  sx: { whiteSpace: 'nowrap' },
                  width: '13%',
                },
                {
                  title: formatMessage({ id: 'components.offer.expiryDate' }),
                  sx: { whiteSpace: 'nowrap' },
                  width: '13%',
                },
                {
                  title: formatMessage({ id: 'components.common.currency' }),
                  sx: { whiteSpace: 'nowrap' },
                  width: '10%',
                },
                {
                  title: formatMessage({ id: 'components.offer.offerAmount' }),
                  sx: { whiteSpace: 'nowrap' },
                  width: '12%',
                  align: 'right',
                },
                {
                  title: formatMessage({ id: 'components.offer.offerAgreed' }),
                  sx: { whiteSpace: 'nowrap' },
                  width: '12%',
                  align: 'right',
                },
                {
                  title: '',
                  align: 'right',
                  width: '160px',
                  sx: { display: { xs: 'none', sm: 'table-cell' } },
                },
              ]}
              schema={OFFERLIST_TABLE_SCHEMA_ADVANCED}
              layoutPaperPadding={LAYOUT_PAPER_PADDING}
              customData={{ updateMutation, history, refetchListQuery }}
              data={
                new OfferPaginationResultModel().hydrateFromApiData({ items, pagination }).items
              }
              refetchListQuery={refetchListQuery}
              pagination={pagination}
              noData={formatMessage({ id: 'common.noItems' })}
              loading={loading || updateLoading}
              title={formatMessage({ id: 'components.mainMenu.offersList' })}
              secondaryAction={
                <StatusSelectButton
                  initialValue={initialValues.show || OFFER_ENUM.status.ALL}
                  optionsList={{
                    ALL: OFFER_ENUM.status.ALL,
                    COUNTER: OFFER_ENUM.status.PENDING,
                    ACCEPTED: OFFER_ENUM.status.ACTIVE,
                    DECLINED: OFFER_ENUM.status.INACTIVE,
                  }}
                  optionsTranslationPrefix="components.offer.status"
                  dialogTranslationPrefix="components.dialog.offer.action"
                  onChange={(show: string | number) => handleParamsChange({ show })}
                />
              }
            />
          )}
        </DataListFeed>
      </Paper>
    </DefaultLayout>
  );
}
