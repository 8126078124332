import InputAdornment from '@mui/material/InputAdornment';
import { currency as CURRENCY_ENUM } from '../../../config/enumeration.js';
import { getStoreByCurrencyCode } from '../../../services/riseart/utils/Utils';
import { Offer } from '../../../data/models/Offer';

const FIELDS = {
  AMOUNT: (offer: Offer) => ({
    tag: 'input',
    type: 'text',
    name: 'amount',
    validateTrigger: 'onBlur',
    rules: [
      {
        required: true,
        message: 'forms.validation.required',
      },
      {
        type: 'custom',
        custom: (value: number) => value <= 0 || value >= offer.sellerProductPrice,
        message: 'forms.offer.validation.counterAmount',
      },
    ],
    preffix: (): JSX.Element => (
      <InputAdornment position="start">
        {
          CURRENCY_ENUM.signs[
            getStoreByCurrencyCode(offer.sellerCurrencyCode) as keyof typeof CURRENCY_ENUM.signs
          ]
        }
      </InputAdornment>
    ),
    placeholder: 'components.offer.amount',
  }),
  COMMENT: {
    tag: 'input',
    type: 'text',
    name: 'comment',
    validateTrigger: 'onBlur',
    rules: [
      {
        type: 'stringLength',
        options: { max: 512 },
        message: 'forms.validation.maxLength512',
      },
    ],
    placeholder: 'components.offer.comment',
    customPropsMapped: () => ({
      // Prevent propagation of event while typing in the field
      // This prevents losing the focus of the input when typing
      // a character matching the shortcut key for MUI MenuItem
      onKeyDown: (e: any) => {
        e.stopPropagation();
      },
    }),
  },
};

export const OfferActionCounterFormModel = (offer: Offer): Record<string, any> => ({
  settings: { layout: 'inline', method: 'post', style: { minWidth: '300px' } },
  fields: [FIELDS.AMOUNT(offer), FIELDS.COMMENT],
});

export const OfferActionDeclineFormModel = {
  settings: { layout: 'inline', method: 'post', style: { minWidth: '300px' } },
  fields: [
    {
      ...FIELDS.COMMENT,
      rules: [
        ...FIELDS.COMMENT.rules,
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
    },
  ],
};
