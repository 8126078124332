import { gql } from '@apollo/client';
import { SELLER_PAYMENT_METHOD_FRAGMENT } from '../../fragments/seller/paymentMethod.graphql';

export const CREATE_SELLER_PAYMENT_METHOD = gql`
  ${SELLER_PAYMENT_METHOD_FRAGMENT}
  mutation createSellerPaymentMethod(
    $sellerId: Int!
    $inputPaymentMethod: InputSellerPaymentMethod!
  ) {
    createSellerPaymentMethod(sellerId: $sellerId, inputPaymentMethod: $inputPaymentMethod) {
      ...SellerPaymentMethodFragment
    }
  }
`;
