import { gql } from '@apollo/client';

export const ME_VISITOR_FRAGMENT = gql`
  fragment MeVisitorFragment on MeVisitor {
    id
    language
    locale
    role
    email
    isUser
    isCustomer
    storeCode
    countryCode
    shippingCountryCode
    cartQty
    cartCurrencyCode
    cartTotal
    cohort
    ipAddress
    userAgent
    created
    autoLogin
  }
`;

export const ME_USER_FRAGMENT = gql`
  fragment MeUserFragment on MeUser {
    id
    firstName
    lastName
    email
    role
    lastLogin
    registrationDate
    city
    phone
    birthDate
    ageRange
    gender
    timeZone
    quizDate
    quizPersonality
    countryCode
    customerCreditValue
    customerCreditCurrency
    privacySettings {
      id
      userId
      category
      name
      type
      value
      order
    }
    avatars {
      id
      status
      public
      mimeType
      key
      extension
      size
      width
      height
      type
      active
    }
  }
`;

export const ME_SELLER_FRAGMENT = gql`
  fragment MeSellerFragment on MeSeller {
    id
    type
    name
    entityRole
    entityId
    integrationId
    onboardingStage
    currencyCode
    storeCode
    shipsCountryCode
    canRentArt
    canUseShippingTables
  }
`;

export const ME_ARTIST_FRAGMENT = gql`
  fragment MeArtistFragment on MeArtist {
    id
    alias
    birthYear
    birthCity
    birthCountryCode
    livesCity
    livesCountryCode
  }
`;

export const ME_PARTNER_FRAGMENT = gql`
  fragment MePartnerFragment on MePartner {
    id
    name
    type
    email
    phone
    city
    postCode
    countryCode
  }
`;
