import { FormattedMessage, FormattedDate } from 'react-intl';
import { SplitButton } from '@riseart/dashboard';
import { partner as PARTNER_ENUM } from '../../../../config/enumeration.js';
import { delay } from '../../../../services/riseart/utils/Utils';
import { splitButtonConfirmationProps } from '../../../common/buttons/settings';
import { Status } from '../../../common/Status';

export const PARTNER_USER_TABLE_SCHEMA = [
  {
    key: 'userId',
  },
  {
    key: 'name',
    render: ({ data }: Record<string, any>): JSX.Element => data.fullName,
  },
  {
    key: 'status',
    render: ({ data }: Record<string, any>): JSX.Element => (
      <Status type="partnerUser" status={data.status} />
    ),
  },
  {
    key: 'aclRole',
    render: ({ data }: Record<string, any>): JSX.Element => (
      <FormattedMessage id={`components.partner.acl.roles.${data.aclRole}`} />
    ),
  },
  {
    key: 'actions',
    render: ({
      data: { userId, status },
      handleToggleMode,
      customData: { loggedUserId, updateMutation, updateLoading, refetchListQuery },
    }: Record<string, any>): JSX.Element | null => {
      // Partner admin is the logged in user
      if (parseInt(userId, 10) === parseInt(loggedUserId, 10)) {
        return null;
      }

      const [mainAction, ...secondaryActions] = [
        ...(status === PARTNER_ENUM.admin.status.ACTIVE
          ? [
              {
                onClick: handleToggleMode(true),
                children: <FormattedMessage id="common.edit" />,
              },
              {
                actionKey: 'inactivate',
                requireConfirmation: true,
                text: <FormattedMessage id="common.inactivate" />,
                onClick: () => {
                  updateMutation(userId, PARTNER_ENUM.admin.status.INACTIVE)
                    .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                    .catch(() => null);
                },
              },
            ]
          : [
              {
                actionKey: 'activate',
                requireConfirmation: true,
                children: <FormattedMessage id="common.activate" />,
                onClick: () => {
                  updateMutation(userId, PARTNER_ENUM.admin.status.ACTIVE)
                    .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                    .catch(() => null);
                },
              },
            ]),
      ];

      return (
        <SplitButton
          disabled={updateLoading}
          mainButtonProps={mainAction}
          translationPrefix="components.dialog.partner.user"
          confirmationDialogProps={splitButtonConfirmationProps}
          options={secondaryActions}
        />
      );
    },
  },
];

export const PARTNER_INVITATION_TABLE_SCHEMA = [
  { key: 'id' },
  { key: 'email' },
  {
    key: 'aclRole',
    render: ({ data }: Record<string, any>): JSX.Element => (
      <FormattedMessage id={`components.partner.acl.roles.${data.aclRole}`} />
    ),
  },
  {
    key: 'created',
    render: ({ data }: Record<string, any>): JSX.Element | null =>
      data.created ? (
        <FormattedDate
          value={data.created}
          year="numeric"
          month="short"
          day="numeric"
          weekday="short"
        />
      ) : null,
  },
  {
    key: 'expirationDate',
    render: ({ data }: Record<string, any>): JSX.Element | null =>
      data.expirationDate ? (
        <FormattedDate
          value={data.expirationDate}
          year="numeric"
          month="short"
          day="numeric"
          weekday="short"
        />
      ) : null,
  },
  {
    key: 'actions',
    render: ({
      data,
      customData: { partnerId, deleteMutation, refetchListQuery },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          actionKey: 'cancel',
          requireConfirmation: true,
          onClick: () => {
            deleteMutation({ variables: { invitationId: data.id, partnerId } })
              .then(() => typeof refetchListQuery === 'function' && delay(refetchListQuery))
              .catch(() => null);
          },
          children: <FormattedMessage id="common.cancel" />,
        }}
        translationPrefix="components.dialog.partner.invitation"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[]}
      />
    ),
  },
];
