import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import { ConfirmationDialog } from '@riseart/dashboard';
import { DataArtActions } from '../data/art/Actions';

type Props = {
  data: Record<string, any>;
  sort?: (a: Record<string, any>, b: Record<string, any>) => number;
  onClick: () => void;
  onSuccess: (response: any, actionKey: string) => void;
  onError: (error: any) => void;
};

/**
 * ArtActions
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const ArtActions = ({ data, sort, onClick, onSuccess, onError }: Props): JSX.Element => {
  const {
    id,
    productCanUnlist,
    productCanSubmit,
    productCanArchive,
    productCanSellOut,
    productStore,
    canCopy,
  } = data;

  return (
    <DataArtActions
      {...{
        id,
        store: productStore && productStore.storeCode,
        productCanUnlist,
        productCanSubmit,
        productCanArchive,
        productCanSellOut,
        canCopy,
      }}
    >
      {(availableActions) => (
        <Stack spacing={1} direction="row">
          {(typeof sort === 'function' ? availableActions.sort(sort) : availableActions).map(
            ({ actionKey, text, actionHandler, buttonProps }, idx) => (
              <ConfirmationDialog
                key={idx}
                title={<FormattedMessage id="components.dialog.art.action.title" />}
                description={<FormattedMessage id={`components.dialog.art.action.${actionKey}`} />}
                disagreeLabel={<FormattedMessage id="common.no" />}
                agreeLabel={<FormattedMessage id="common.yes" />}
                onAgree={() => {
                  onClick();
                  actionHandler()
                    .then((response) => onSuccess(response, actionKey))
                    .catch(onError);
                }}
              >
                {(handleOpen) => (
                  <Button variant="contained" {...(buttonProps || null)} onClick={handleOpen}>
                    {text}
                  </Button>
                )}
              </ConfirmationDialog>
            ),
          )}
        </Stack>
      )}
    </DataArtActions>
  );
};
