import { ReactElement } from 'react';
import { DefaultLayout } from '../../layout/Default';
import { ErrorPage } from './Error';

type Props = {
  error: Record<string, any>;
};
/**
 * ErrorBoundaryPage
 *
 * @param {Props} props
 * @returns {ReactElement<any>}
 */
export const ErrorBoundaryPage = (props: Props): ReactElement<any> => {
  return (
    <DefaultLayout>
      <ErrorPage {...props}></ErrorPage>
    </DefaultLayout>
  );
};
