import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Title, FormBuilder, FormWrapper, FormHeader, LayoutPaper } from '@riseart/dashboard';
import { errors as ERRORS_ENUM } from '../../../config/enumeration.js';
import { useNotification } from '../../../data/hooks/useNotification';
import { useScrollToEl } from '../../../data/hooks/useScrollToEl';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { DefaultLayout } from '../../layout/Default';
import { mapApiValidationErrors, mapApiErrors } from '../../../services/riseart/utils/Form';
import { mapToAwayModeFormData } from '../../../data/normalizers/seller';
import { selectSeller } from '../../../services/redux/selectors/seller';
import { SellerAwayModeFormModel } from '../../forms/models/sellerAwayMode';
import { READ_SELLER } from '../../../data/gql/queries/seller/read.graphql';
import { UPDATE_SELLER } from '../../../data/gql/queries/seller/update.graphql';

/**
 * SellerAwayModePage
 *
 * @returns {JSX.Element}
 */
export const SellerAwayModePage = (): JSX.Element => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
  const { formatMessage } = useIntl();
  const { setScrollRef, scrollToRef } = useScrollToEl();
  const { dispatchNotification } = useNotification();
  const meSeller = useSelector(selectSeller) || {};

  // Queries and Mutations
  const {
    data: readSellerData,
    loading: readLoading,
    refetch: refetchReadSeller,
  } = useQuery(READ_SELLER, {
    fetchPolicy: 'network-only',
    variables: { sellerId: meSeller.id },
  });
  const readSeller = (readSellerData && readSellerData.readSeller) || null;
  const [updateSeller, { loading: updateLoading }] = useMutation(UPDATE_SELLER);

  return (
    <DefaultLayout
      loading={readLoading || updateLoading}
      title={formatMessage({ id: 'components.mainMenu.seller' })}
      breadcrumbs={[
        <Link
          key="home"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link
          key="sellerProfile"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('sellerProfile')}
          title={formatMessage({ id: 'components.mainMenu.seller' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'components.mainMenu.seller' })}
        </Link>,
        <Typography key="seller" variant="inherit">
          {formatMessage({ id: 'components.mainMenu.sellerAwayMode' })}
        </Typography>,
      ]}
      boxRefCallback={setScrollRef}
    >
      {readSeller ? (
        <LayoutPaper>
          <FormHeader
            title={
              <Title variant="h5">
                {formatMessage({ id: 'components.mainMenu.sellerAwayMode' })}
              </Title>
            }
          />
          <FormWrapper size="medium">
            <FormBuilder
              settings={SellerAwayModeFormModel.settings}
              fields={SellerAwayModeFormModel.fields}
              initialValues={mapToAwayModeFormData(readSeller)}
              showReset
              customData={{ isLargeScreen }}
              submitText={formatMessage({ id: 'common.saveChanges' })}
              onSubmit={async (formState, addApiErrors) => {
                const {
                  away,
                  awayDates: [awayFrom, awayTo],
                } = formState.data;
                updateSeller({
                  variables: {
                    id: readSeller.id,
                    inputSeller: {
                      away,
                      awayFrom: away === false ? null : awayFrom,
                      awayTo: away === false ? null : awayTo,
                    },
                  },
                })
                  .then(() => {
                    refetchReadSeller().catch(() => null);

                    // Scroll to container
                    scrollToRef();

                    // Show artowrk update success notification
                    dispatchNotification({
                      detail: 'components.notifications.sellerProfileUpdated',
                      level: ERRORS_ENUM.levels.SUCCESS,
                      placeholder: ERRORS_ENUM.placeholders.SNACKBAR,
                      expire: 5,
                    });
                  })
                  .catch((error) => {
                    // Map validation errors from response
                    const validationError = mapApiValidationErrors(error);

                    if (validationError && validationError.length && addApiErrors) {
                      addApiErrors(validationError);
                    } else {
                      // Map any other type of errors from response (validation without provided fields, or non-validation errors)
                      mapApiErrors(error).forEach((err) => {
                        dispatchNotification({
                          detail: err,
                          level: ERRORS_ENUM.levels.ERROR,
                          placeholder: ERRORS_ENUM.placeholders.PAGE,
                        });
                      });
                    }

                    // Scroll to error
                    scrollToRef();
                  });
              }}
            />
          </FormWrapper>
        </LayoutPaper>
      ) : null}
    </DefaultLayout>
  );
};
