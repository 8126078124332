import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { FormViewField, FormTableCell } from '@riseart/dashboard';
import { countries as COUNTRIES_LIST } from '../../config/directory.js';
import { currency as CURRENCY_ENUM } from '../../config/enumeration.js';
import { compareTime } from '../../services/riseart/utils/Utils';

/**
 * countriesListOptions
 *
 * @param {{(options: Record<string, any>) => any}} options
 * @returns {JSX.Element[]}
 */
export function countriesListOptions({
  formatMessage,
  initialOption,
}: {
  formatMessage: (options: Record<string, any>) => any;
  initialOption: JSX.Element | null | undefined;
}): JSX.Element[] {
  return [
    ...(initialOption ? [initialOption] : []),
    ...COUNTRIES_LIST.all.map((code: string) => (
      <MenuItem key={code} value={code}>
        {formatMessage({ id: `countries.${code}` })}
      </MenuItem>
    )),
  ];
}

/**
 * currencyListOptions
 *
 * @param {{ formatMessage: (options: Record<string, any>) => any; initialOption: JSX.Element | null | undefined; }}
 * @returns {JSX.Element[]}
 */
export function currencyListOptions({
  initialOption,
}: {
  formatMessage: (options: Record<string, any>) => any;
  initialOption: JSX.Element | null | undefined;
}): JSX.Element[] {
  return [
    ...(initialOption ? [initialOption] : []),
    ...Object.keys(CURRENCY_ENUM.signs).map((sign: string) => {
      // @ts-ignore
      const code = CURRENCY_ENUM.codes[sign];

      return (
        <MenuItem key={sign} value={code}>
          {/* @ts-ignore  */}
          {CURRENCY_ENUM.signs[sign]} ({code})
        </MenuItem>
      );
    }),
  ];
}

/**
 * timePickerInput
 *
 * @param {Record<string, any>} field
 * @param {Record<string, any>} customData
 * @param {Record<string, any>} formState
 * @param {string | JSX.Element} label
 * @param {(options: Record<string, any>) => any} formatMessage
 * @param {(options: Record<string, any>) => any} register
 * @returns {JSX.Element | null}
 */
export function timePickerInput(
  field: Record<string, any>,
  customData: Record<string, any>,
  formState: Record<string, any>,
  label: string | JSX.Element,
  formatMessage: (options: Record<string, any>) => any,
  register: (options: Record<string, any>) => any,
): JSX.Element | null {
  const { value, onChange } = register(field);
  const { [field.name]: fieldError } = (formState && formState.errors) || {};
  const isRequired =
    field &&
    field.rules &&
    field.rules.some(({ required }: Record<string, any>) => required === true);

  return (
    <FormViewField key={field.name} label={label} error={fieldError} required={isRequired}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          ampm={false}
          views={['hours', 'minutes', 'seconds']}
          inputFormat="HH:mm:ss"
          mask="__:__:__"
          // Convert date to string to get the time in date format, because timepicker will not work otherise
          value={
            typeof value === 'string' ? new Date(`${new Date().getFullYear()} ${value}`) : value
          }
          onChange={(value: string | null) => onChange({ target: { value } })}
          renderInput={(params: Record<string, any>) => (
            <TextField variant="standard" {...params} error={!!fieldError} />
          )}
        />
      </LocalizationProvider>
    </FormViewField>
  );
}

/**
 * timeDurationPickerInput
 *
 * @param {Record<string, any>} field
 * @param {Record<string, any>} customData
 * @param {Record<string, any>} formState
 * @param {string | JSX.Element} label
 * @param {(options: Record<string, any>) => any} formatMessage
 * @param {(options: Record<string, any>) => any} register
 * @returns {JSX.Element | null}
 */
export function timeDurationPickerInput(
  field: Record<string, any>,
  customData: Record<string, any>,
  formState: Record<string, any>,
  label: string | JSX.Element,
  formatMessage: (options: Record<string, any>) => any,
  register: (options: Record<string, any>) => any,
): JSX.Element | null {
  const { value, onChange } = register(field);
  const [startTime, endTime] = value;
  const { [field.name]: formfieldError } = (formState && formState.errors) || {};
  const fieldError: Array<string> = [...(formfieldError || [])];
  const isRequired =
    field &&
    field.rules &&
    field.rules.some(({ required }: Record<string, any>) => required === true);
  const areFieldsRequired = !!(startTime || endTime);
  const areValidTimes = startTime && endTime ? compareTime(startTime, endTime) : null;

  // startTime is lower than endTime
  if (areValidTimes !== null && areValidTimes > -1) {
    fieldError.push('forms.validation.invalidTime');
  }

  const hasErrors = !!(fieldError && fieldError.length);

  return (
    <FormViewField
      key={field.name}
      // @ts-ignore
      error={hasErrors ? fieldError.map((error: string) => formatMessage({ id: error })) : null}
      required={isRequired}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ display: 'flex' }}>
          <TimePicker
            {...field.inputFieldData}
            // Convert date to string to get the time in date format, because timepicker will not work otherise
            value={
              typeof startTime === 'string'
                ? new Date(`${new Date().getFullYear()} ${startTime}`)
                : startTime || null
            }
            onChange={(value: string) => onChange({ target: { value: [value, endTime] } })}
            label={formatMessage({ id: 'forms.event.label.startTime' })}
            renderInput={(params: Record<string, any>) => (
              <TextField
                variant="standard"
                sx={{ maxWidth: '166px' }}
                required={areFieldsRequired}
                {...params}
                error={hasErrors}
              />
            )}
          />
          <Box sx={{ mx: 2, mt: '18px' }}> {formatMessage({ id: 'common.to' })} </Box>
          <TimePicker
            {...field.inputFieldData}
            // Convert date to string to get the time in date format, because timepicker will not work otherise
            value={
              typeof endTime === 'string'
                ? new Date(`${new Date().getFullYear()} ${endTime}`)
                : endTime || null
            }
            onChange={(value: string) => onChange({ target: { value: [startTime, value] } })}
            label={formatMessage({ id: 'forms.event.label.endTime' })}
            renderInput={(params: Record<string, any>) => (
              <TextField
                variant="standard"
                sx={{ maxWidth: '166px' }}
                required={areFieldsRequired}
                {...params}
                error={hasErrors}
              />
            )}
          />
        </Box>
      </LocalizationProvider>
    </FormViewField>
  );
}

/**
 * blankLabelRenderer
 *
 * @param {{ valueRenderer: (value: any, formState?: Record<string, any>) => any; }} options
 * @returns {Function}
 */
export function blankLabelRenderer(
  {
    valueRenderer,
  }: {
    valueRenderer: (value: any, formState?: Record<string, any>) => any;
  } = {
    valueRenderer: (value: any) => value,
  },
) {
  return (
    field: Record<string, any>,
    customData: Record<string, any> | null,
    formState: Record<string, any>,
  ): JSX.Element => {
    return (
      <FormTableCell
        width={
          customData &&
          customData.generatedFieldWrapperProps &&
          customData.generatedFieldWrapperProps.width
        }
        key={field.name}
      >
        {typeof valueRenderer === 'function'
          ? valueRenderer(formState.data[field.name], formState)
          : ''}
      </FormTableCell>
    );
  };
}

/**
 * textFieldRenderer
 *
 * @param {Record<string, any>} fieldProps
 * @param {Record<string, any>} customData
 * @returns {JSX.Element}
 */
export function textFieldRenderer(
  fieldProps: Record<string, any>,
  customData: Record<string, any>,
): JSX.Element {
  const { data } = customData;

  return (
    <FormTableCell width={fieldProps.width} key={fieldProps.name}>
      <FormViewField>
        {typeof fieldProps.viewDataRenderer === 'function'
          ? fieldProps.viewDataRenderer(data)
          : data[fieldProps.name]}
      </FormViewField>
    </FormTableCell>
  );
}

/**
 * phonePropsMapper
 *
 * @returns {Record<string, any>}
 */
export function phonePropsMapper(): Record<string, any> {
  return { countries: COUNTRIES_LIST.dialing };
}
