import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useQuery, useMutation } from '@apollo/client';
import { LAYOUT_PAPER_PADDING } from '@riseart/dashboard';
import { errors as ERRORS_ENUM } from '../../../../config/enumeration.js';
import { useNotification } from '../../../../data/hooks/useNotification';
import { AdvancedTable } from '../../../common/tables/Advanced';
import { selectSeller } from '../../../../services/redux/selectors/seller';
import { ShippingRateEditRow } from '../../../seller/ShippingRateEditRow';
import { ShippingRateAddRow } from '../../../seller/ShippingRateAddRow';
import { SELLER_SHIPPING_TABLE_RATES_SCHEMA } from '../../../data/tables/schemas/seller';
import { LIST_SELLER_SHIPPING_TABLE_RATES } from '../../../../data/gql/queries/seller/listShippingTableRates.graphql';
import { DELETE_SELLER_SHIPPING_TABLE_RATE } from '../../../../data/gql/queries/seller/deleteShippingTableRate.graphql';

type Props = {
  tableId: number;
  storeCode: string;
  scrollToTop: () => void;
};

/**
 * ShippingRates
 *
 * @returns {JSX.Element}
 */
export const ShippingRates = ({ tableId, storeCode, scrollToTop }: Props): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const { dispatchNotification } = useNotification();
  const { id: sellerId, canUseShippingTables } = useSelector(selectSeller) || {};

  if (!canUseShippingTables) {
    dispatchNotification({
      detail: 'components.notifications.noAccess',
      level: ERRORS_ENUM.levels.ERROR,
      placeholder: ERRORS_ENUM.placeholders.APP,
      handler: ERRORS_ENUM.handlers.ERROR_PAGE,
    });

    return null;
  }

  const [deleteRate, { loading: deleteLoading }] = useMutation(DELETE_SELLER_SHIPPING_TABLE_RATE);
  const {
    data,
    loading,
    refetch: refetchListQuery,
  } = useQuery(LIST_SELLER_SHIPPING_TABLE_RATES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { tableId, sellerId, items: 100 },
  });
  const ratesItems =
    data && data.listSellerShippingTableRates && data.listSellerShippingTableRates.items;
  const addShippingRateLabel = formatMessage({ id: 'components.sellerRates.addRate' });

  return (
    <AdvancedTable
      columns={[
        {
          title: formatMessage({ id: 'components.tables.columns.shippingRate.destination' }),
          width: '30%',
          sx: { pl: LAYOUT_PAPER_PADDING.pl },
        },
        {
          title: formatMessage({ id: 'components.tables.columns.shippingRate.baseCost' }),
          align: 'right',
          width: '25%',
        },
        {
          title: formatMessage({
            id: 'components.tables.columns.shippingRate.pctCost',
          }),
          align: 'right',
          width: '25%',
        },
        {
          title: '',
          align: 'right',
        },
      ]}
      schema={SELLER_SHIPPING_TABLE_RATES_SCHEMA}
      layoutPaperPadding={LAYOUT_PAPER_PADDING}
      data={ratesItems}
      firstRow={({
        customData,
        dataItems,
        showAdd,
        handleAdd,
        handleSuccess,
        handleError,
      }: Record<string, any>) =>
        dataItems && showAdd ? (
          <ShippingRateAddRow
            tableId={tableId}
            sellerId={customData.sellerId}
            storeCode={storeCode}
            ratesItems={ratesItems}
            onCancel={handleAdd(false)}
            onSuccess={handleSuccess(handleAdd(false))}
            onError={handleError}
          />
        ) : null
      }
      editRenderer={({ handleSuccess, handleError }: Record<string, any>) =>
        ({ data, switchToDisplayMode }: Record<string, any>) =>
          (
            <ShippingRateEditRow
              tableId={tableId}
              sellerId={sellerId}
              ratesItems={ratesItems}
              storeCode={storeCode}
              data={data}
              onSuccess={handleSuccess(switchToDisplayMode)}
              onError={handleError}
              onCancel={switchToDisplayMode}
            />
          )}
      customData={{
        tableId,
        sellerId,
        ratesItems,
        storeCode,
        refetchListQuery,
        deleteRate,
        deleteLoading,
        scrollToTop,
      }}
      refetchListHandler={refetchListQuery}
      loading={loading}
      mainActionLabel={addShippingRateLabel}
      title={formatMessage({ id: 'components.seller.rates' })}
      deleteLoading={deleteLoading}
      deleteHandler={deleteRate}
      scrollToTop={() => null}
    />
  );
};
