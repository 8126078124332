import { gql } from '@apollo/client';

export const ARTIST_BIBLIOGRAPHY_FRAGMENT = gql`
  fragment ArtistBibliographyFragment on ArtistBibliography {
    id
    artistId
    title
    author
    publication
    publisher
    pages
    year
  }
`;
