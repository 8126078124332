export const LoginFormModel = {
  settings: {},
  fields: [
    {
      tag: 'input',
      type: 'email',
      name: 'email',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'email',
          message: 'forms.auth.validation.invalidEmail',
        },
        {
          required: true,
          message: 'forms.auth.validation.requiredEmail',
        },
      ],
      placeholder: 'forms.auth.label.email',
    },
    {
      tag: 'input',
      type: 'password',
      name: 'password',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.auth.validation.requiredPassword',
        },
      ],
      placeholder: 'forms.auth.label.password',
    },
  ],
};
