import { gql } from '@apollo/client';
import { offer as OFFER_ENUM } from '../../../../config/enumeration.js';

export const OFFERS_SUMMARY = gql`
  query offersSummary($sellerId: Int!, $page: Int = 1, $items: Int = 1) {
    all: listSellerOffers(sellerId: $sellerId, page: $page, items: $items) {
      pagination {
        totalItems
      }
    }
    active: listSellerOffers(sellerId: $sellerId, page: $page, items: $items, filters: { status: "${OFFER_ENUM.status.ACTIVE}" }) {
      pagination {
        totalItems
      }
    }
    pending: listSellerOffers(sellerId: $sellerId, page: $page, items: $items, filters: { status: "${OFFER_ENUM.status.PENDING}" }) {
      pagination {
        totalItems
      }
    }
  }
`;
