import { useState, SyntheticEvent } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import {
  CircularLoader,
  Paragraph,
  Title,
  FileList,
  FileListItem,
  FileUploadDialog,
  FilePreviewList,
  useUploadedFiles,
  FormBuilder,
  FormHeader,
  FormWrapper,
} from '@riseart/dashboard';
import { FileUploader } from '@riseart/files';
import { useNotification } from '../../../data/hooks/useNotification';
import { fileListConfirmationProps } from '../../common/file/settings';
import { feed as FEED_CONFIG } from '../../../config/config.js';
import {
  file as FILE_ENUM,
  artist as ARTIST_ENUM,
  errors as ERRORS_ENUM,
} from '../../../config/enumeration.js';
import { mapApiValidationErrors, mapApiErrors } from '../../../services/riseart/utils/Form';
import { FileDropzone } from '../../common/file/Dropzone';
import { fileListClassName } from '../../common/file/ListItem';
import { FileRestrictionsHint } from '../../common/file/RestrictionsHint';
import { ArtistFeedModel } from '../../forms/models/artist/feed';
import { mapFormToApiData } from '../../../data/normalizers/common';
import { createFileUpload, updateFileUpload } from '../../../data/gql/handlers';
import { CREATE_ARTIST_FEED } from '../../../data/gql/queries/artist/createFeed.graphql';

type Props = {
  artistId: number;
  scrollToRef: () => any;
  listUrl: string;
  entityUrlAssembler: (id: number, options?: Record<string, any>) => string;
};

/**
 * ArtistFeedAdd
 *
 * @returns {JSX.Element}
 */
export const ArtistFeedAdd = ({
  artistId,
  scrollToRef,
  listUrl,
  entityUrlAssembler,
}: Props): JSX.Element => {
  const ACCEPTED_FILES = 'image/jpeg, image/png';
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { dispatchNotification } = useNotification();
  const [createArtistFeed, { data: feedData }] = useMutation(CREATE_ARTIST_FEED);
  const [mediaType, setMediaType] = useState<string>('');
  const {
    uploadedFiles,
    handleFileDrop,
    handleFileRemove,
    handleFileValidation,
    hasFilesWithErrors,
  } = useUploadedFiles({
    filesByType: { main: [] },
    maxFileSize: FEED_CONFIG.maxFilesize,
    sizeValidationMessage: formatMessage(
      { id: 'forms.common.largerFilesize' },
      { maxFilesize: FEED_CONFIG.maxFilesize },
    ),
  });
  const [progress, setProgress] = useState<{
    loading: boolean;
    opened: boolean;
    text: string | JSX.Element | null;
    step: 'post' | 'images' | null;
    additional: Record<string, any> | null;
  }>({
    loading: false,
    opened: false,
    text: null,
    step: null,
    additional: null,
  });

  return (
    <>
      <FileUploadDialog
        loadingComponent={CircularLoader}
        opened={progress.opened}
        loading={progress.loading}
        onClose={function handleDialogClose() {
          if (feedData && feedData.createArtistFeed) {
            history.push(entityUrlAssembler(feedData.createArtistFeed.id));
          }
        }}
        hasErrors={
          progress.opened && hasFilesWithErrors(progress.additional && progress.additional.files)
        }
      >
        <Paragraph align="center">{progress.text}</Paragraph>
        {progress.step === 'images' ? (
          <FileList>
            {progress.additional
              ? progress.additional.files.map(
                  ({ name, progress, size, errorMessage }: Record<string, any>, idx: number) => (
                    <FileListItem
                      key={`${name}${idx}`}
                      className={fileListClassName}
                      name={name}
                      size={size}
                      errors={errorMessage}
                      progress={progress || 0}
                      status="loading"
                    />
                  ),
                )
              : null}
          </FileList>
        ) : null}
      </FileUploadDialog>
      <FormHeader
        title={<Title variant="h5">{formatMessage({ id: 'components.feed.addPost' })}</Title>}
      />
      <FormWrapper size="medium">
        <TextField
          type="select"
          label={formatMessage({ id: 'forms.feed.label.type' })}
          variant="standard"
          margin="normal"
          select
          SelectProps={{ native: false }}
          value={mediaType}
          fullWidth
          helperText={formatMessage({ id: 'forms.feed.hints.type' })}
          required
          onChange={(e: SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            setMediaType(target.value);
          }}
        >
          {Object.keys(ARTIST_ENUM.feed.media.type).map((key: string) => {
            // @ts-ignore
            const mediaType = ARTIST_ENUM.feed.media.type[key];
            return (
              <MenuItem key={key} value={mediaType}>
                {formatMessage({ id: `components.feed.media.type.${mediaType}` })}
              </MenuItem>
            );
          })}
        </TextField>
        {mediaType === ARTIST_ENUM.feed.media.type.IMAGE ? (
          <>
            <FileDropzone
              label={formatMessage({ id: 'forms.feed.label.image' })}
              text={formatMessage({ id: 'forms.feed.hints.image' })}
              textMiddle={formatMessage({ id: 'common.or' })}
              textButton={formatMessage({ id: 'common.browseFiles' })}
              // @ts-ignore
              onDrop={handleFileDrop(ARTIST_ENUM.feed.image.category.MAIN)}
              validator={handleFileValidation}
              accept={ACCEPTED_FILES}
              multiple={false}
              hint={
                <FileRestrictionsHint translationKey="forms.feed.hints.imageUploadRestrictions" />
              }
            />
            {/* @ts-ignore */}
            <FilePreviewList
              canDelete
              listItemProps={{ className: fileListClassName }}
              inputFiles={uploadedFiles.main}
              confirmationDialogProps={fileListConfirmationProps}
              // @ts-ignore
              onDelete={handleFileRemove(ARTIST_ENUM.feed.image.category.MAIN)}
            />
          </>
        ) : null}
        {mediaType ? (
          <FormBuilder
            settings={ArtistFeedModel.settings}
            fields={ArtistFeedModel.fields}
            submitText={formatMessage({ id: 'components.feed.addPost' })}
            customData={{
              mediaType,
            }}
            disableSubmit={
              !(
                mediaType === ARTIST_ENUM.feed.media.type.VIDEO ||
                !!(
                  mediaType === ARTIST_ENUM.feed.media.type.IMAGE &&
                  uploadedFiles.main.length &&
                  uploadedFiles.main.some(
                    (file: Record<string, any>) => !file.errors || !file.errors.length,
                  )
                )
              )
            }
            cancel={
              <Button
                component={RouterLink}
                to={listUrl}
                type="button"
                variant="outlined"
                size="large"
                fullWidth
              >
                {formatMessage({ id: 'common.cancel' })}
              </Button>
            }
            onSubmit={async (formState, addApiErrors) => {
              try {
                setProgress({
                  loading: true,
                  opened: mediaType === ARTIST_ENUM.feed.media.type.IMAGE,
                  step: 'post',
                  text: formatMessage({ id: `components.dialog.feed.steps.addPost` }),
                  additional: null,
                });
                const response = await createArtistFeed({
                  variables: {
                    artistId,
                    inputArtistFeed: {
                      mediaType,
                      ...mapFormToApiData(formState.data, ArtistFeedModel.fields),
                    },
                  },
                });

                if (mediaType === ARTIST_ENUM.feed.media.type.VIDEO) {
                  setProgress({
                    loading: false,
                    text: null,
                    opened: false,
                    step: null,
                    additional: null,
                  });
                  history.push(entityUrlAssembler(response.data.createArtistFeed.id));
                  return;
                } else {
                  setProgress((prevState) => ({
                    ...prevState,
                    step: 'images',
                    text: formatMessage({ id: `components.dialog.feed.steps.uploadingImage` }),
                  }));
                }

                setTimeout(() => {
                  // Upload files after artwork is created
                  if (response.data.createArtistFeed.id) {
                    const { id } = response.data.createArtistFeed;
                    const FileUploadClient = new FileUploader(
                      FileUploader.flattenFiles(uploadedFiles),
                      id,
                      FILE_ENUM.object.type.ARTIST_FEED,
                      createFileUpload,
                      updateFileUpload,
                    );
                    FileUploadClient.subscribe(
                      FileUploader.eventsTypes.UPDATE,
                      (fileUploadState) => {
                        setProgress((prevState) => ({
                          ...prevState,
                          additional: fileUploadState,
                        }));
                      },
                    );
                    FileUploadClient.subscribe(
                      FileUploader.eventsTypes.COMPLETE,
                      (fileUploadState) => {
                        const hasErrors = hasFilesWithErrors(fileUploadState.files);
                        setProgress((prevState) => ({
                          ...prevState,
                          loading: false,
                          additional: fileUploadState,
                          text: formatMessage({
                            id: `components.dialog.feed.file.${hasErrors ? 'error' : 'success'}`,
                          }),
                        }));

                        if (!hasErrors) {
                          setTimeout(() => history.push(entityUrlAssembler(id)), 5000);
                        }
                      },
                    );

                    FileUploadClient.process();
                  }
                }, 2000);
              } catch (error: any) {
                // Map validation errors from response
                const validationError = mapApiValidationErrors(error);

                if (validationError && validationError.length && addApiErrors) {
                  addApiErrors(validationError);
                } else {
                  // Map any other type of errors from response (validation without provided fields, or non-validation errors)
                  mapApiErrors(error).forEach((err) => {
                    dispatchNotification({
                      detail: err,
                      level: ERRORS_ENUM.levels.ERROR,
                      placeholder: ERRORS_ENUM.placeholders.PAGE,
                    });
                  });
                }

                // Reset loader state
                setProgress({
                  loading: false,
                  text: null,
                  opened: false,
                  step: null,
                  additional: null,
                });

                // Scroll to error
                scrollToRef();
              }
            }}
          />
        ) : null}
      </FormWrapper>
    </>
  );
};
