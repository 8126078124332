import { ReactChildren, ReactChild } from 'react';
import { IntlProvider } from 'react-intl';
import { I18nComponentsLoader } from './ComponentsLoader';
import { UrlAssembler } from '../../services/riseart/utils/UrlAssembler';
import { LocalizedConfig } from '../../services/riseart/utils/LocalizedConfig';

type Props = {
  locale: Record<string, any>;
  routeLocale: Record<string, any>;
  children: ReactChildren | ReactChild;
};

/**
 * I18nProvider
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const I18nProvider = ({ locale, routeLocale, children }: Props): JSX.Element => {
  // Config UrlAssembler with selected locale
  UrlAssembler.config(routeLocale);

  // Set locale in localized config service
  LocalizedConfig.config(locale && locale.name);

  return (
    <I18nComponentsLoader dataKey="translations" locale={locale.name}>
      {(messages: Record<string, any>): ReactChild | ReactChildren => (
        <IntlProvider
          locale={locale.name}
          messages={messages}
          defaultRichTextElements={{
            sup: (chunks) => <sup>{chunks}</sup>,
            br: () => <br />,
            strong: (chunks) => <strong>{chunks}</strong>,
            i: (chunks) => <i>{chunks}</i>,
          }}
        >
          {children}
        </IntlProvider>
      )}
    </I18nComponentsLoader>
  );
};
