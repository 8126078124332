import { gql } from '@apollo/client';
import { ART_FRAGMENT } from '../../fragments/art/art.graphql';

export const READ_ART = gql`
  ${ART_FRAGMENT}
  query readArt($artId: String!, $store: String = "uk", $shippingCountry: String = "GB") {
    readArt(artId: $artId, store: $store, shippingCountry: $shippingCountry) {
      ...ArtFragment
    }
  }
`;
