import get from 'lodash/get';
import find from 'lodash/find';
import {
  ValueOptions,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  getGridDateOperators,
  GridFilterOperator,
} from '@mui/x-data-grid';
import { application as APP_CONFIG } from '../../../../config/config.js';
import { data as DATA_ENUM } from '../../../../config/enumeration.js';

/**
 * filterSelectOptions
 *
 * @param {Record<string, any>} items
 * @param {string} translationKey
 * @returns {ValueOptions[]}
 */
export function filterSelectOptions(
  items: Record<string, any>,
  translationKey: string,
  formatMessage: (options: Record<string, any>) => string,
): ValueOptions[] {
  return Object.keys(items).map((key): ValueOptions => {
    const value = items[key];

    return {
      value,
      // @ts-ignore
      label: formatMessage({ id: `${translationKey}.${value}` }),
    };
  });
}

/**
 * filterSelectBoolean
 *
 * @param { (options: Record<string, any>) => string} formatMessage
 * @returns {ValueOptions[]}
 */
export function filterSelectBoolean(
  formatMessage: (options: Record<string, any>) => string,
): ValueOptions[] {
  return [
    { value: true, label: formatMessage({ id: 'common.yes' }) },
    { value: false, label: formatMessage({ id: 'common.no' }) },
  ];
}

/**
 * filterSelectLocale
 *
 * @param { (options: Record<string, any>) => string} formatMessage
 * @returns {ValueOptions[]}
 */
export function filterSelectLocale(
  formatMessage: (options: Record<string, any>) => string,
): ValueOptions[] {
  return APP_CONFIG.i18n.locales.map(({ name: localeName }) => ({
    value: localeName,
    label: formatMessage({ id: `locales.${localeName.toLowerCase()}` }),
  }));
}

/**
 * getOperatorsByType
 *
 * @param { 'number' | 'string' | 'discrete' | 'discreteSingle'} type
 * @returns {Array<GridFilterOperator<any, string | number | null, any>>}
 */
export function getOperatorsByType(
  type: 'number' | 'string' | 'discrete' | 'discreteSingle' | 'date',
): Array<GridFilterOperator<any, string | number | null, any>> {
  const singleSelectOperators = getGridSingleSelectOperators();
  const availableOperators: Record<
    'number' | 'string' | 'discrete' | 'discreteSingle' | 'date',
    Array<GridFilterOperator<any, string | number | null, any>>
  > = {
    number: getGridNumericOperators(),
    string: getGridStringOperators(),
    discrete: singleSelectOperators,
    discreteSingle: singleSelectOperators,
    // @ts-ignore
    date: getGridDateOperators(),
  };

  const operators = {
    number: ['=', '!=', '>', '>=', '<', '<=', 'isEmpty', 'isNotEmpty', 'isAnyOf'],
    string: ['=', '!=', 'contains', 'startsWith', 'endsWith', 'isEmpty', 'isNotEmpty', 'isAnyOf'],
    discrete: ['=', '!=', 'isEmpty', 'isNotEmpty', 'is', 'isAnyOf'],
    discreteSingle: ['=', '!=', 'isEmpty', 'isNotEmpty', 'is'],
    date: availableOperators.date.map(({ value }) => value),
  };

  return availableOperators[type].filter(
    (operator) => operators[type].indexOf(operator.value) > -1,
  );
}

export const DISCRETE_TYPE_OPERATORS = getOperatorsByType('discrete');
export const DISCRETESINGLE_TYPE_OPERATORS = getOperatorsByType('discreteSingle');

/**
 * convertFiltersToApiPayload
 *
 * @param { { value: string; columnField: string; operatorValue: string } } filters
 * @param {Record<string, any>[]} config
 * @returns {Record<string, string> | null}
 */
export function convertFiltersToApiPayload(
  filters: {
    value: string;
    columnField: string;
    operatorValue: string;
  },
  config: Record<string, any>[],
): Record<string, string> | null {
  if (
    !filters ||
    (([undefined, null, ''].indexOf(filters.value) > -1 || filters.value.length === 0) &&
      ['isEmpty', 'isNotEmpty'].indexOf(filters.operatorValue) === -1)
  ) {
    return null;
  }

  const fieldConfig = find(config, ({ columnField }) => columnField === filters.columnField);
  const columnField =
    (fieldConfig && (fieldConfig.apiField || fieldConfig.columnField)) || filters.columnField;
  const mappedFilters =
    fieldConfig && typeof fieldConfig.mapper === 'function' ? fieldConfig.mapper(filters) : filters;

  // @ts-ignore
  if (fieldConfig && fieldConfig.filterType === 'basic') {
    return { [`filters[${columnField}]`]: mappedFilters.value };
  }

  return {
    [`filters[${columnField}][value]`]: mappedFilters.value,
    [`filters[${columnField}][operator]`]:
      // @ts-ignore
      mappedFilters.operatorValue && DATA_ENUM.operators.api[mappedFilters.operatorValue],
  };
}
/**
 * convertSortToApiPayload
 *
 * @param {{ field: string; sort: string; }} sort
 * @param {Record<string, any>[]} config
 * @returns {{ sort: string; order: string } | null}
 */
export function convertSortToApiPayload(
  sort: {
    field: string;
    sort: string;
  },
  config: Record<string, any>[],
): { sort: string; order: string } | null {
  if (!sort || !sort.field || !sort.sort) {
    return null;
  }

  const sortConfig = find(config, ({ columnField }) => columnField === sort.field);
  const field = (sortConfig && sortConfig.apiField) || sort.field;

  return { sort: field, order: sort.sort.toUpperCase() };
}
/**
 * convertSortToGridModel
 *
 * @param {{ field: string; sort: string; }} sort
 * @param {Record<string, any>[]} config
 * @returns {{ sort: string; order: string } | null}
 */
export function convertSortToGridModel(
  { order, sort }: Record<string, any>,
  config: Record<string, any>[],
): { sort: string; field: string } | null {
  if (!order || !sort) {
    return null;
  }

  const sortConfig = find(config, ({ apiField }) => apiField && apiField === sort);
  const field = (sortConfig && sortConfig.columnField) || sort;

  return { field, sort: order.toLowerCase() };
}
/**
 * convertFilterToGridModel
 *
 * @param {{ field: string; sort: string; }} sort
 * @param {Record<string, any>[]} config
 * @returns {{ sort: string; order: string } | null}
 */
export function convertFilterToGridModel(
  { filters }: Record<string, any>,
  config: Record<string, any>[],
): {
  columnField: string | null;
  operatorValue: string | null;
  value: string | null;
} | null {
  if (!filters) {
    return null;
  }

  const [rawColumnField] = Object.keys(filters);
  const fieldConfig = find(config, ({ apiField }) => apiField === rawColumnField);
  const columnField = (fieldConfig && fieldConfig.columnField) || rawColumnField;
  const isBasicFilter = fieldConfig && fieldConfig.filterType === 'basic';
  const filterOptions = filters[columnField] || filters[rawColumnField];
  const convertedOperator = Object.keys(DATA_ENUM.operators.api).reduce(
    (accumulator, key) =>
      // @ts-ignore
      filterOptions &&
      filterOptions.operator &&
      // @ts-ignore
      DATA_ENUM.operators.api[key] === filterOptions.operator
        ? key
        : accumulator,
    null,
  );
  const basicFilterOperator =
    isBasicFilter &&
    (get(
      find(fieldConfig && fieldConfig.operators, ({ isDefault }) => isDefault === true),
      'name',
    ) ||
      'equals');

  const value = isBasicFilter ? filterOptions : filterOptions.value;
  const mappedValue =
    fieldConfig && typeof fieldConfig.mapper === 'function'
      ? fieldConfig.mapper({ value }, true).value
      : value;

  return filterOptions && (isBasicFilter || filterOptions.operator || filterOptions.value)
    ? {
        columnField,
        operatorValue: isBasicFilter ? basicFilterOperator : convertedOperator,
        value: mappedValue,
      }
    : null;
}
/**
 * DATE_TYPE_MAPPER
 *
 * @param {Record<string, any>}
 * @param {boolean} fromApi
 * @returns {Record<string, any>}
 */
export function DATE_FILTER_TYPE_MAPPER(
  { value, ...filters }: Record<string, any>,
  fromApi = false,
): Record<string, any> {
  if (fromApi) {
    const date = value ? new Date(value) : null;

    return {
      ...filters,
      value: date
        ? `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
            '0' + date.getDate()
          ).slice(-2)}`
        : '',
    };
  }

  return {
    ...filters,
    value: value ? new Date(value).toISOString() : '',
  };
}
