export const RegisterFormModel = {
  settings: {},
  fields: [
    {
      tag: 'input',
      type: 'text',
      name: 'firstName',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.common.required',
        },
        {
          type: 'regex',
          pattern: "^[a-zA-ZÀÁÂÄÇÈÉÊÌÍÒÓÖÔÜàáäâçèéêìíòóöôüß'-\\s]*$",
          message: 'forms.auth.validation.invalidName',
        },
      ],
      placeholder: 'forms.auth.label.firstName',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'lastName',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.common.required',
        },
        {
          type: 'regex',
          pattern: "^[a-zA-ZÀÁÂÄÇÈÉÊÌÍÒÓÖÔÜàáäâçèéêìíòóöôüß'-\\s]*$",
          message: 'forms.auth.validation.invalidName',
        },
      ],
      placeholder: 'forms.auth.label.lastName',
    },
    {
      tag: 'input',
      type: 'email',
      name: 'email',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'email',
          message: 'forms.auth.validation.invalidEmail',
        },
        {
          required: true,
          message: 'forms.auth.validation.requiredEmail',
        },
      ],
      placeholder: 'forms.auth.label.email',
    },
    {
      tag: 'input',
      type: 'password',
      name: 'password',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.auth.validation.requiredPassword',
        },
        {
          type: 'regex',
          pattern: '^[a-zA-Z0-9!@#$%^&*)(+=._-]{6,32}$',
          message: 'forms.auth.validation.invalidPassword',
        },
      ],
      placeholder: 'forms.auth.label.password',
    },
  ],
};
