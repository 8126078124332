import { ME_ACTIONS, AUTH_ACTIONS, APP_STORE_ACTIONS } from '../actions/container';
import { GOOGLE_TAGMANAGER_ACTIONS } from '../actions/google/tagManager';

type ActionMapperRule = {
  match: {
    type?: string;
    method?: string;
    endpoint?: string;
  };
  actions: Array<any>;
};

/**
 * List of actions routes
 *
 * @type { Array<ActionMapperRule> }
 */
const ACTION_MAPPER = [
  {
    match: {
      type: AUTH_ACTIONS.TOKEN_UPDATED,
    },
    actions: [AUTH_ACTIONS.AUTH_TOKEN_COOKIE_UPDATE],
  },
  {
    match: {
      type: AUTH_ACTIONS.SIGNOUT,
    },
    actions: [AUTH_ACTIONS.TOKEN_CLEAR, AUTH_ACTIONS.AUTH_TOKEN_COOKIE_CLEAR, ME_ACTIONS.ME_CLEAR],
  },
  {
    match: {
      type: ME_ACTIONS.ME_PATCH,
    },
    actions: [ME_ACTIONS.ME_UPDATED, GOOGLE_TAGMANAGER_ACTIONS.ME_UPDATED],
  },
  {
    match: {
      type: ME_ACTIONS.ME_FETCHED,
    },
    actions: [ME_ACTIONS.ME_UPDATED, GOOGLE_TAGMANAGER_ACTIONS.ME_UPDATED],
  },
  {
    match: {
      type: APP_STORE_ACTIONS.UPDATE_PROPERTY,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.APPLICATION_PROPERTY_UPDATE],
  },
];

/**
 * Middleware actions dispatcher
 */
export default class MiddlewareActionsRouter {
  /**
   * Actions router
   *
   * @param {Record<string, any>} action
   * @returns {Record<string, any>[]}
   */
  static route = (action: Record<string, any>): Record<string, any>[] => {
    const actions: Record<string, any>[] = [];

    ACTION_MAPPER.forEach((rule: ActionMapperRule) => {
      if (
        action.type === rule.match.type &&
        action.method === rule.match.method &&
        action.endpoint === rule.match.endpoint &&
        rule.actions
      ) {
        rule.actions.forEach((item) => {
          if (typeof item === 'function') {
            actions.push(item());
          } else {
            actions.push({ type: item, payload: action });
          }
        });
      }
    });

    return actions;
  };

  /**
   * Redux Middleware
   *
   * @param { Record<string, any> } store
   */
  static middleware = (store: Record<string, any>) => (next: any) => (action: any) => {
    const actions = MiddlewareActionsRouter.route(action);

    actions.forEach((item) => {
      store.dispatch(item);
    });

    next(action);
  };
}
