/**
 * GTMService
 * Service wrapper arround the GTM dataLayer if existing.
 * It also provides interface to extract state and to push initial state into the data layer
 * which is particularly useful for collecting data from server side code into the layer
 * and then set it as initial data to the window.dataLayer
 */
export const GTMService: Record<string, any> = {
  dataLayer: [],
  /**
   * config
   * @param {Array<Object>} initialData
   * @returns {void}
   */
  config(initialData: Record<string, any>[] = []): void {
    let clientDataLayer = [];

    if (typeof window === 'object') {
      // first initialize window.dataLayer if not existing yet
      window.dataLayer = window.dataLayer || [];
      clientDataLayer = window.dataLayer;
    }

    GTMService.dataLayer = clientDataLayer;
    GTMService.push(initialData);
  },
  /**
   * push
   * @param {Record<string, any> | Record<string, any>[]} item
   * @returns {void}
   */
  push(item: Record<string, any> | Record<string, any>[] = []): void {
    GTMService.dataLayer.push(...(Array.isArray(item) ? item : [item]));
  },
  /**
   * extract
   * @returns Current dataLayer array
   */
  extract(): Record<string, any>[] {
    return GTMService.dataLayer;
  },
};
