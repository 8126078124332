import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import { DataTable, LAYOUT_PAPER_PADDING } from '@riseart/dashboard';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { DefaultLayout } from '../../layout/Default';
import { DataListFeed } from '../../data/ListFeed';
import { Event as EventModel } from '../../../data/models/Event';
import { selectUser } from '../../../services/redux/selectors/user';
import { selectPartner } from '../../../services/redux/selectors/partner';
import { EVENTLIST_TABLE_SCHEMA_ADVANCED } from '../../data/tables/schemas/event';
import { LIST_EVENTS } from '../../../data/gql/queries/event/list.graphql';
import { UPDATE_EVENT } from '../../../data/gql/queries/event/update.graphql';

/**
 * EventListPage
 *
 * @returns {JSX.Element}
 */
export const EventListPage = (): JSX.Element => {
  const ITEMS_PER_PAGE = 10;
  const { formatMessage } = useIntl();
  const history = useHistory();
  const user = useSelector(selectUser);
  const partner = useSelector(selectPartner);
  const addLabel = formatMessage({ id: 'components.event.add' });
  const [updateMutation, { loading: updateEventLoading }] = useMutation(UPDATE_EVENT);

  return (
    <DefaultLayout
      title={formatMessage({ id: 'components.mainMenu.eventList' })}
      includesFab
      breadcrumbs={[
        <Link
          key="home"
          component={RouterLink}
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Typography key="eventList" variant="inherit">
          {formatMessage({ id: 'components.mainMenu.eventList' })}
        </Typography>,
      ]}
    >
      <Paper sx={{ position: 'relative' }}>
        {user || partner ? (
          <DataListFeed
            query={LIST_EVENTS}
            fetchPolicy="cache-and-network"
            itemsPerPage={ITEMS_PER_PAGE}
            buildUrl={({ page, itemsPerPage = ITEMS_PER_PAGE, qsParams }: Record<string, any>) => {
              const search = queryString.stringify({
                ...qsParams,
                ...(page && page > 1 ? { page } : null),
                ...(itemsPerPage && itemsPerPage !== ITEMS_PER_PAGE ? { itemsPerPage } : null),
              });
              return UrlAssembler.eventList({ search: search ? `?${search}` : '' });
            }}
            variablesMapper={({
              page = 1,
              itemsPerPage,
            }: Record<string, any>): Record<string, any> => ({
              page,
              items: itemsPerPage || 10,
              sort: 'current',
              filters: {
                ...(partner && partner.id ? { partnerId: { value: partner.id } } : null),
                ...(!partner && user && user.id ? { userId: { value: user.id } } : null),
                future: false,
              },
            })}
          >
            {({ loading, refetchListQuery, items, pagination }: Record<string, any>) => (
              <DataTable
                columns={[
                  {
                    title: formatMessage({ id: 'components.tables.columns.event.name' }),
                    sx: { pl: LAYOUT_PAPER_PADDING.pl, whiteSpace: 'nowrap' },
                    width: '15%',
                  },
                  {
                    title: formatMessage({ id: 'components.tables.columns.event.type' }),
                    sx: { display: { xs: 'none', sm: 'table-cell' }, whiteSpace: 'nowrap' },
                    width: '10%',
                  },
                  {
                    title: formatMessage({ id: 'components.tables.columns.event.status' }),
                    sx: { display: { xs: 'none', sm: 'table-cell' }, whiteSpace: 'nowrap' },
                    width: '10%',
                  },
                  {
                    title: formatMessage({ id: 'components.tables.columns.event.location' }),
                    width: '15%',
                  },
                  {
                    title: formatMessage({ id: 'components.tables.columns.event.dates' }),
                    sx: { whiteSpace: 'nowrap' },
                    width: '15%',
                  },
                  {
                    title: formatMessage({ id: 'components.tables.columns.event.hours' }),
                    sx: { whiteSpace: 'nowrap' },
                    width: '15%',
                  },
                  {
                    title: formatMessage({ id: 'components.tables.columns.event.ticket' }),
                    sx: { whiteSpace: 'nowrap' },
                    width: '10%',
                    align: 'right',
                  },
                  {
                    title: '',
                    align: 'right',
                    width: '160px',
                    sx: { display: { xs: 'none', sm: 'table-cell' } },
                  },
                ]}
                schema={EVENTLIST_TABLE_SCHEMA_ADVANCED}
                layoutPaperPadding={LAYOUT_PAPER_PADDING}
                customData={{ updateMutation, history, refetchListQuery }}
                data={
                  items &&
                  items.map((item: Record<string, any>) =>
                    new EventModel().hydrateFromApiData(item),
                  )
                }
                refetchListQuery={refetchListQuery}
                pagination={pagination}
                noData={formatMessage({ id: 'common.noItems' })}
                loading={loading || updateEventLoading}
                mainAction={
                  <Box
                    sx={{
                      display: { xs: 'none', sm: 'block' },
                      flexGrow: 1,
                      pr: 1,
                      textAlign: 'right',
                    }}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      component={RouterLink}
                      to={UrlAssembler.addEvent()}
                      title={addLabel}
                    >
                      {addLabel}
                    </Button>
                  </Box>
                }
                title={formatMessage({ id: 'components.mainMenu.eventList' })}
              />
            )}
          </DataListFeed>
        ) : null}
        <Fab
          color="primary"
          component={RouterLink}
          to={UrlAssembler.addEvent()}
          title={addLabel}
          sx={{
            position: { xs: 'fixed', lg: 'absolute' },
            top: { xs: 'auto', lg: '135px' },
            left: { xs: 'auto', lg: '-28px' },
            bottom: { xs: '25px', lg: 'auto' },
            right: { xs: '25px', lg: 'auto' },
            fontSize: '60px',
          }}
          size="large"
        >
          <AddIcon />
        </Fab>
      </Paper>
    </DefaultLayout>
  );
};
