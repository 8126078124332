/**
 * DO NOT MANUALLY EDIT THIS FILE
 * This file is generated automatically by the build script on prebuild step.
 * Please refer to documentation for more information
 */
import React from 'react';

/**
 * LOCALE_COMPONENTS
 */
export const LOCALE_COMPONENTS: Record<string, any> = {
  translations: {
    'en-GB': React.lazy(() => import('./translations.en-GB')),
    'fr-FR': React.lazy(() => import('./translations.fr-FR')),
  },
};
