import { createStore, applyMiddleware, compose } from 'redux';
import { debug as DEBUG_CONFIG } from '../../config/config.js';
import { rootReducer } from './reducers/container';
import MiddlewareActionsRouter from './middleware/MiddlewareActionsRouter';
import MiddlewareGoogleTagManager from './middleware/MiddlewareGoogleTagManager';
import { MiddlewareApplication } from './middleware/MiddlewareApplication';
import { MiddlewareLogger } from './middleware/MiddlewareLogger';
import { MiddlewareError } from './middleware/MiddlewareError';
import { MiddlewareAuth } from './middleware/MiddlewareAuth';

// Disable redux debug tools for security on server
// rendering and if debug.redux === false
const composeEnhancers =
  (DEBUG_CONFIG.redux &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

/**
 * Application store settings
 *
 * @param { Record<string, any> } initialState The initial redux store state
 * @returns {{runSaga: Task, close: void}}
 */
const configureStore = (initialState = {}): Record<string, any> => {
  // Create redux store
  const reduxStore = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        MiddlewareLogger.middleware,
        MiddlewareActionsRouter.middleware,
        MiddlewareGoogleTagManager.middleware,
        MiddlewareApplication.middleware,
        MiddlewareAuth.middleware,
        MiddlewareError.middleware,
      ),
    ),
  );

  // Return redux store augmented
  return {
    ...reduxStore,
  };
};

export default configureStore;
