import { gql } from '@apollo/client';
import { ARTIST_DEGREE_FRAGMENT } from '../../fragments/artist/degree.graphql';

export const UPDATE_ARTIST_DEGREE = gql`
  ${ARTIST_DEGREE_FRAGMENT}
  mutation updateArtistDegree($id: ID!, $artistId: Int!, $inputArtistDegree: InputArtistDegree!) {
    updateArtistDegree(id: $id, artistId: $artistId, inputArtistDegree: $inputArtistDegree) {
      ...ArtistDegreeFragment
    }
  }
`;
