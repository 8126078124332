import { useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import { FormBuilder, FormWrapper, FormHeader } from '@riseart/dashboard';
import { errors as ERRORS_ENUM } from '../../../config/enumeration.js';
import { useNotification } from '../../../data/hooks/useNotification';
import { mapApiValidationErrors, mapApiErrors } from '../../../services/riseart/utils/Form';
import { mapToFormData, mapFormToApiData } from '../../../data/normalizers/artist';
import { mePatch } from '../../../services/redux/actions/me/me';
import { ArtistProfileFormModel } from '../../forms/models/artist';
import { READ_ARTIST } from '../../../data/gql/queries/artist/read.graphql';
import { UPDATE_ARTIST } from '../../../data/gql/queries/artist/update.graphql';
import { READ_ME } from '../../../data/gql/queries/me/read.graphql';

type Props = {
  artistId: number;
  align?: 'left' | 'center' | 'right';
  title?: string | JSX.Element | null;
  description?: string | JSX.Element | null;
  submitText: string | JSX.Element;
  scrollToRef?: () => any;
  onLoading: (loading: boolean) => any;
  onRead?: (data: Record<string, any> | null) => any;
  onUpdate?: (data: Record<string, any>) => any;
  wrapperComponent?: any;
};

/**
 * ArtistProfileForm
 *
 * @returns {JSX.Element}
 */
export const ArtistProfileForm = ({
  artistId,
  align,
  title,
  description,
  submitText,
  onLoading,
  scrollToRef,
  onRead,
  onUpdate,
  wrapperComponent,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const { dispatchNotification } = useNotification();
  const Wrapper = wrapperComponent || Fragment;

  // Queries and Mutations
  const {
    data: readArtistData,
    loading: readLoading,
    refetch: refetchRead,
  } = useQuery(READ_ARTIST, {
    fetchPolicy: 'network-only',
    variables: { id: artistId },
  });
  const readData = (readArtistData && readArtistData.readArtist) || null;
  const [updateArtist, { loading: updateLoading, client }] = useMutation(UPDATE_ARTIST);

  useEffect(() => {
    onLoading(readLoading || updateLoading);
  }, [readLoading, updateLoading]);

  useEffect(() => {
    if (typeof onRead === 'function') {
      onRead(readData);
    }
  }, [readData]);

  return readData ? (
    <Wrapper>
      {title ? (
        <FormHeader title={title} justifyContent={align === 'center' ? 'center' : undefined} />
      ) : null}
      {description}
      <FormWrapper size="medium" align={align}>
        <FormBuilder
          settings={ArtistProfileFormModel.settings}
          fields={ArtistProfileFormModel.fields}
          initialValues={mapToFormData(readData, ArtistProfileFormModel.fields)}
          showReset
          submitText={submitText}
          customData={{
            biographyLocked: readData.biographyLocked,
            aliasUpdated: readData.aliasUpdated,
          }}
          onSubmit={async (formState, addApiErrors) => {
            updateArtist({
              variables: {
                id: readData.id,
                inputArtist: mapFormToApiData(formState.data, readData),
              },
            })
              .then((updateResponse) => {
                refetchRead().catch(() => null);

                // Fetch and update me after successfully updated artist
                client
                  .query({
                    query: READ_ME,
                    fetchPolicy: 'network-only',
                  })
                  .then((response: any) => {
                    if (response && response.data && response.data.readMe) {
                      dispatch(mePatch(response.data.readMe));
                    }
                  });

                if (typeof onUpdate === 'function') {
                  onUpdate(updateResponse.data.updateArtist);
                }

                // Scroll to container
                if (typeof scrollToRef === 'function') {
                  scrollToRef();
                }

                // Show artowrk update success notification
                dispatchNotification({
                  detail: 'components.notifications.artistProfileUpdated',
                  level: ERRORS_ENUM.levels.SUCCESS,
                  placeholder: ERRORS_ENUM.placeholders.SNACKBAR,
                  expire: 5,
                });
              })
              .catch((error) => {
                // Map validation errors from response
                const validationError = mapApiValidationErrors(error);

                if (validationError && validationError.length && addApiErrors) {
                  addApiErrors(validationError);
                } else {
                  // Map any other type of errors from response (validation without provided fields, or non-validation errors)
                  mapApiErrors(error).forEach((err) => {
                    dispatchNotification({
                      detail: err,
                      level: ERRORS_ENUM.levels.ERROR,
                      placeholder: ERRORS_ENUM.placeholders.PAGE,
                    });
                  });
                }

                // Scroll to error
                if (typeof scrollToRef === 'function') {
                  scrollToRef();
                }
              });
          }}
        />
      </FormWrapper>
    </Wrapper>
  ) : null;
};
