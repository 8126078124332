import { gql } from '@apollo/client';
import { SELLER_FRAGMENT } from '../../fragments/seller/seller.graphql';

export const GRANT_USER_SELLER_ACCESS = gql`
  ${SELLER_FRAGMENT}
  mutation grantUserSellerAccess($userId: Int!, $code: String!) {
    grantUserSellerAccess(userId: $userId, code: $code) {
      ...SellerFragment
    }
  }
`;
