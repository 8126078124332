import { gql } from '@apollo/client';
import { PARTNER_ARTIST_FRAGMENT } from '../../fragments/partner/artist.graphql';

export const LINK_PARTNER_ARTIST = gql`
  ${PARTNER_ARTIST_FRAGMENT}
  mutation linkPartnerArtist($partnerId: Int!, $artistId: Int!, $rank: Int) {
    linkPartnerArtist(partnerId: $partnerId, artistId: $artistId, rank: $rank) {
      ...PartnerArtistFragment
    }
  }
`;
