import { Jwt, InvalidToken } from '@riseart/jwt';
import { isAbsoluteUrl, isRoleAllowedForACL as isRoleAllowedForACLUtil } from '@riseart/fe-utils';
import { application as CONFIG_APP } from '../../../config/config.js';
import { errors as ERRORS_ENUM } from '../../../config/enumeration.js';
import { isRoutedUrl } from './Utils';
import { LocationManager } from '../Location';
import { UrlAssembler } from './UrlAssembler';
import { Cookies } from '../utils/Cookies/Cookies';
import { ErrorService } from '../errors/ErrorService';
import { authCookieUpdate, authTokenUpdated } from '../../redux/actions/auth/auth';
import { errorAdd } from '../../redux/actions/errors/errors';

const DEFAULT_AUTH_REDIRECT_DELAY = 1000;
let redirectTimeoutIndex: ReturnType<typeof setTimeout>;

/**
 * getAuthForwardDestination
 *
 * @param {ObjeRecord<string, any>ct} params
 * @param {Record<string, any>} locale
 * @returns {string} redirect url based on forward parameter, or quizResponseId. default is the home page
 */
function getAuthForwardDestination({ forward }: Record<string, any>): string {
  if (forward) {
    return forward;
  }

  return UrlAssembler.byRouteKey('home');
}

/**
 * authRedirect
 *
 * @param {Record<string, any>} queryParameters
 * @param {Record<string, any>} history
 * @param {Record<string, any>} locale
 * @param {number} redirectDelay
 */
export function authRedirect(
  queryParameters: Record<string, any>,
  history: Record<string, any>,
  redirectDelay = DEFAULT_AUTH_REDIRECT_DELAY,
): void {
  const redirectRoute = getAuthForwardDestination(queryParameters);
  const match = isRoutedUrl(redirectRoute);
  clearTimeout(redirectTimeoutIndex);

  if (!history || !match || isAbsoluteUrl(redirectRoute)) {
    // redirectDelay is set so that the page redirect happens after a timeout
    // so that analytics data like GTM events can be send to services before redirecting
    if (LocationManager.isSSR || !redirectDelay) {
      LocationManager.redirect(redirectRoute);
      return;
    }

    redirectTimeoutIndex = setTimeout(() => {
      LocationManager.redirect(redirectRoute);
    }, redirectDelay);
  } else {
    redirectTimeoutIndex = setTimeout(() => {
      history.push(redirectRoute);
    }, redirectDelay);
  }
}

/**
 * isRoleAllowedForACL
 *
 * @param {Array<string> | Record<string, any>} resourcePermissions
 * @param {string} aclRole
 */
export function isRoleAllowedForACL(
  resourcePermissions: Array<string> | Record<string, any> = [],
  aclRole: string,
): boolean {
  return isRoleAllowedForACLUtil(resourcePermissions, aclRole, CONFIG_APP.acl.rolesHierarchy);
}

/**
 * getCookieValue
 *
 * @param {string} cookieName
 * @returns {string} token cookie value
 */
export function getCookieValue(cookieName: string): string | null {
  /*
   * All values in cookies are strings, so this is a list of falsey values in token cookie.
   * IMPORTANT: token cookie will and should not be set with falsey values in the first place,
   * but if somehow this happens then it will fallback to case when no value in cookie is set
   * and will enter a valid app state
   */
  const FALSEY_VALUES = ['undefined', 'null', 'NaN', 'Infinity', '-Infinity', ''];
  const value: string = Cookies.get(cookieName);

  return value && FALSEY_VALUES.indexOf(value) === -1 ? value : null;
}

/**
 * validateToken
 *
 * @param {any} token
 * @returns {Record<string, any>} decoded token payload
 */
export async function validateToken(
  token: string,
  aclRole: 'seller' | 'visitor' | 'user' = 'seller',
): Promise<Record<string, any>> {
  // @ts-ignore
  const decodedToken = await Jwt.decode(token, 186400);
  const payload = (decodedToken && decodedToken.payload) || null;

  if (
    !payload ||
    (aclRole === 'seller' && !payload.seller_id) ||
    (aclRole === 'visitor' && !payload.visitor_id) ||
    (aclRole === 'user' && !payload.user_id)
  ) {
    throw new InvalidToken();
  }

  return payload;
}

/**
 * getInitialAuthActions
 *
 * @param {?string} tokenFromCookie
 * @param {?string} tokenFromState
 * @param {(Record<{ type: String, payload: any }>) => void} dispatch
 * @returns {Promise<any>}
 */
export async function getInitialAuthActions(
  tokenFromCookie: string | null,
  tokenFromState: string,
  dispatch: (action: { type: string; payload: any }) => void,
): Promise<{ type: string; payload: any } | Record<string, any> | null> {
  try {
    // We have a valid cookie and a token settled in the store...means that it could be a valid user
    if (tokenFromCookie && tokenFromState) {
      // This check will trigger an exception if the token is expired...
      const payload = await validateToken(tokenFromState);

      if (payload) {
        const tokenData: Record<string, any> = { token: tokenFromState, payload };

        if (tokenFromState !== tokenFromCookie) {
          dispatch(authCookieUpdate(tokenData));
        } else {
          dispatch(authTokenUpdated(tokenData));
        }

        return tokenData;
      }
    }

    // Token is not in the store, could be: a page refresh/an address inserted manually/user is coming back
    if (tokenFromCookie && !tokenFromState) {
      const payload = await validateToken(tokenFromCookie);
      const tokenData = { token: tokenFromCookie, payload };
      dispatch(authTokenUpdated(tokenData));

      return tokenData;
    }
  } catch (error: any) {
    dispatch(
      errorAdd(
        ErrorService.mapJSError(error, {
          type: ERRORS_ENUM.types.auth,
        }),
      ),
    );

    return null;
  }

  return null;
}
