import { APP_STORE_ACTIONS } from '../../actions/container';
import { ActionType } from '../../actions/application/store';

const DEFAULT_STATE = {
  userLocale: null,
  routeLocale: null,
  redirects: null,
};

/**
 * applicatonStoreReducer
 *
 * @param {Record<string, any>} state
 * @param {ActionType} action
 * @returns {Record<string, any>}
 */
export function applicatonStoreReducer(
  state: Record<string, any> = DEFAULT_STATE,
  action: ActionType,
): Record<string, any> {
  switch (action.type) {
    case APP_STORE_ACTIONS.UPDATE_PROPERTY:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      };
    case APP_STORE_ACTIONS.UPDATE_LOCALES: {
      const { routeLocale, userLocale } = action.payload.locales;

      return {
        ...state,
        ...(routeLocale ? { routeLocale } : null),
        ...(userLocale ? { userLocale } : null),
      };
    }
    case APP_STORE_ACTIONS.RESET_PROPERTY: {
      return {
        ...state,
        [action.payload.property]: null,
      };
    }
    default:
      return { ...state };
  }
}
