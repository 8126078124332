import { gql } from '@apollo/client';

export const PARTNER_INVITATION_FRAGMENT = gql`
  fragment PartnerInvitationFragment on PartnerInvitation {
    id
    created
    expired
    expirationDate
    email
    aclRole
  }
`;
