import { FormattedMessage, FormattedDate } from 'react-intl';
import { GridColumns, GridValueFormatterParams } from '@mui/x-data-grid';
import { Country, FileImageWrapper, Link, SplitButton } from '@riseart/dashboard';
import { artist as ENUM_ARTIST } from '../../../../config/enumeration.js';
import { countries as COUNTRIES_LIST } from '../../../../config/directory.js';
import { splitButtonConfirmationProps } from '../../../common/buttons/settings';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { Picture } from '../../../common/artdirection/Picture';
import { extractImageFileFromData } from '../../../../services/riseart/utils/Image';
import { SchemaType } from './types';
import { delay } from '../../../../services/riseart/utils/Utils';
import { DEFAULT_COLUMN_PROPS, dataPickerActionColumn } from '../utils';
import { DISCRETE_TYPE_OPERATORS, filterSelectOptions } from '../filters/utils';
import { artist as ARTIST_ENUM, profile as PROFILE_ENUM } from '../../../../config/enumeration.js';
import { Status } from '../../../common/Status';

import imgYoutube from '../../../../assets/media/youtube.png';
import imgVimeo from '../../../../assets/media/vimeo.png';
import imgInstagram from '../../../../assets/media/instagram.png';

const VIDEO_PLATFORMS: Record<string, any> = {
  youtube: imgYoutube,
  vimeo: imgVimeo,
  instagram: imgInstagram,
};

// Awards
export const ARTIST_AWARD_SCHEMA = ({ canAdmin = false }: Record<string, any>): SchemaType => [
  { key: 'name' },
  { key: 'body' },
  { key: 'city' },
  {
    key: 'country',
    render: ({ data }) => <Country code={data.countryCode} hideFallback />,
  },
  { key: 'year' },
  ...(canAdmin
    ? [
        {
          key: 'actions',
          render: ({
            data: { id, artistId },
            handleToggleMode,
            customData: { deleteMutation, refetchListQuery },
            deleteLoading,
          }: Record<string, any>): JSX.Element => (
            <SplitButton
              disabled={deleteLoading}
              mainButtonProps={{
                onClick: handleToggleMode(true),
                children: <FormattedMessage id="common.edit" />,
              }}
              translationPrefix="components.dialog.award.action"
              confirmationDialogProps={splitButtonConfirmationProps}
              options={[
                {
                  actionKey: 'delete',
                  requireConfirmation: true,
                  text: <FormattedMessage id="common.delete" />,
                  onClick: () => {
                    deleteMutation({ variables: { id, artistId } })
                      .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                      .catch(() => null);
                  },
                },
              ]}
            />
          ),
        },
      ]
    : []),
];

// Bibliography
export const ARTIST_BIBLIOGRAPHY_SCHEMA = ({
  canAdmin = false,
}: Record<string, any>): SchemaType => [
  { key: 'title' },
  { key: 'author' },
  { key: 'publication' },
  { key: 'publisher' },
  { key: 'pages' },
  { key: 'year' },
  ...(canAdmin
    ? [
        {
          key: 'actions',
          render: ({
            data: { id, artistId },
            handleToggleMode,
            customData: { deleteMutation, refetchListQuery },
            deleteLoading,
          }: Record<string, any>): JSX.Element => (
            <SplitButton
              disabled={deleteLoading}
              mainButtonProps={{
                onClick: handleToggleMode(true),
                children: <FormattedMessage id="common.edit" />,
              }}
              translationPrefix="components.dialog.bibliography.action"
              confirmationDialogProps={splitButtonConfirmationProps}
              options={[
                {
                  actionKey: 'delete',
                  requireConfirmation: true,
                  text: <FormattedMessage id="common.delete" />,
                  onClick: () => {
                    deleteMutation({ variables: { id, artistId } })
                      .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                      .catch(() => null);
                  },
                },
              ]}
            />
          ),
        },
      ]
    : []),
];

// Degree
export const ARTIST_DEGREE_SCHEMA = ({ canAdmin = false }: Record<string, any>): SchemaType => [
  { key: 'description' },
  { key: 'university' },
  { key: 'city' },
  {
    key: 'countryCode',
    render: ({ data }) => <Country code={data.countryCode} hideFallback />,
  },
  { key: 'year' },
  ...(canAdmin
    ? [
        {
          key: 'actions',
          render: ({
            data: { id, artistId },
            handleToggleMode,
            customData: { deleteMutation, refetchListQuery },
            deleteLoading,
          }: Record<string, any>): JSX.Element => (
            <SplitButton
              disabled={deleteLoading}
              mainButtonProps={{
                onClick: handleToggleMode(true),
                children: <FormattedMessage id="common.edit" />,
              }}
              translationPrefix="components.dialog.degree.action"
              confirmationDialogProps={splitButtonConfirmationProps}
              options={[
                {
                  actionKey: 'delete',
                  requireConfirmation: true,
                  text: <FormattedMessage id="common.delete" />,
                  onClick: () => {
                    deleteMutation({ variables: { id, artistId } })
                      .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                      .catch(() => null);
                  },
                },
              ]}
            />
          ),
        },
      ]
    : []),
];

// Collection
export const ARTIST_COLLECTION_SCHEMA = ({ canAdmin = false }: Record<string, any>): SchemaType => [
  { key: 'collection' },
  { key: 'city' },
  {
    key: 'countryCode',
    render: ({ data }) => <Country code={data.countryCode} hideFallback />,
  },
  ...(canAdmin
    ? [
        {
          key: 'actions',
          render: ({
            data: { id, artistId },
            handleToggleMode,
            customData: { deleteMutation, refetchListQuery },
            deleteLoading,
          }: Record<string, any>): JSX.Element => (
            <SplitButton
              disabled={deleteLoading}
              mainButtonProps={{
                onClick: handleToggleMode(true),
                children: <FormattedMessage id="common.edit" />,
              }}
              translationPrefix="components.dialog.collection.action"
              confirmationDialogProps={splitButtonConfirmationProps}
              options={[
                {
                  actionKey: 'delete',
                  requireConfirmation: true,
                  text: <FormattedMessage id="common.delete" />,
                  onClick: () => {
                    deleteMutation({ variables: { id, artistId } })
                      .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                      .catch(() => null);
                  },
                },
              ]}
            />
          ),
        },
      ]
    : []),
];

// Exhibition
export const ARTIST_EXHIBITION_SCHEMA = ({ canAdmin = false }: Record<string, any>): SchemaType => [
  { key: 'title' },
  { key: 'type' },
  { key: 'venue' },
  { key: 'year' },
  ...(canAdmin
    ? [
        {
          key: 'actions',
          render: ({
            data: { id, artistId },
            handleToggleMode,
            customData: { deleteMutation, refetchListQuery },
            deleteLoading,
          }: Record<string, any>): JSX.Element => (
            <SplitButton
              disabled={deleteLoading}
              mainButtonProps={{
                onClick: handleToggleMode(true),
                children: <FormattedMessage id="common.edit" />,
              }}
              translationPrefix="components.dialog.exhibition.action"
              confirmationDialogProps={splitButtonConfirmationProps}
              options={[
                {
                  actionKey: 'delete',
                  requireConfirmation: true,
                  text: <FormattedMessage id="common.delete" />,
                  onClick: () => {
                    deleteMutation({ variables: { id, artistId } })
                      .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                      .catch(() => null);
                  },
                },
              ]}
            />
          ),
        },
      ]
    : []),
];

// Feed
export const ARTIST_FEED_SCHEMA = ({ canAdmin = false }: Record<string, any> = {}): SchemaType => [
  {
    key: 'image',
    render: ({ data, customData }: Record<string, any>): JSX.Element => {
      const isImage = data.mediaType === ENUM_ARTIST.feed.media.type.IMAGE;
      const image = isImage && extractImageFileFromData.artistFeed(data.images, 'SQUARE');
      const picture =
        isImage && image ? (
          <Picture type="table.list" artDirectionKey="artist.feed" image={image} alt={data.title} />
        ) : (
          <FileImageWrapper>
            <img src={`${VIDEO_PLATFORMS[data.mediaPlatform]}`} alt={data.title} />
          </FileImageWrapper>
        );

      return canAdmin ? (
        <Link to={customData.entityUrlAssembler(data.id)} title={data.title}>
          {picture}
        </Link>
      ) : (
        picture
      );
    },
  },
  { key: 'title' },
  {
    key: 'publishedDate',
    render: ({ data }) => (
      <FormattedDate
        value={data.publishedDate}
        year="numeric"
        month="long"
        day="numeric"
        weekday="long"
      />
    ),
  },
  ...(canAdmin
    ? [
        {
          key: 'actions',
          render: ({ data, customData, deleteLoading }: Record<string, any>): JSX.Element => {
            return (
              <SplitButton
                disabled={deleteLoading}
                mainButtonProps={{
                  onClick: () => customData.history.push(customData.entityUrlAssembler(data.id)),
                  children: <FormattedMessage id="common.edit" />,
                }}
                translationPrefix="components.dialog.feed.action"
                confirmationDialogProps={splitButtonConfirmationProps}
                options={[
                  {
                    actionKey: 'deletePost',
                    requireConfirmation: true,
                    text: <FormattedMessage id="common.delete" />,
                    onClick: () => {
                      customData
                        .deleteFeed({ variables: { id: data.id, artistId: data.artistId } })
                        .then(() => {
                          customData.refetchListQuery && customData.refetchListQuery();
                        })
                        .catch(() => null);
                    },
                  },
                ]}
              />
            );
          },
        },
      ]
    : []),
];

export const ARTIST_PARTNER_LINK_LIST_TABLE_SCHEMA: SchemaType = [
  {
    key: 'id',
    render: ({ data }: Record<string, any>) => data.id,
  },
  {
    key: 'name',
    render: ({ data, customData }: Record<string, any>) => (
      <Link to={customData.entityUrlAssembler(data.id)} title={data.name} target="_blank">
        {data.name}
      </Link>
    ),
  },
  {
    key: 'country',
    render: ({ data }: Record<string, any>) => <Country code={data.livesCountry} hideFallback />,
  },
  {
    key: 'gender',
    render: ({ data }: Record<string, any>) =>
      data.gender ? <FormattedMessage id={`common.gender.${data.gender}`} /> : null,
  },
  {
    key: 'birthYear',
    render: ({ data }: Record<string, any>) => data.birthYear || null,
  },
  {
    key: 'actions',
    render: ({
      data: { id },
      customData: { setLoading, partnerId, linkPartnerArtist, linkLoading, history, listUrl },
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={linkLoading}
        mainButtonProps={{
          onClick: () => {
            setLoading(true);
            linkPartnerArtist({ variables: { partnerId, artistId: id } })
              .then(() => {
                delay(() => {
                  setLoading(false);
                  history.push(listUrl);
                });
              })
              .catch(() => {
                setLoading(false);

                return null;
              });
          },
          children: <FormattedMessage id="components.artist.linkBtn" />,
        }}
        translationPrefix="components.dialog.degree.action"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[]}
      />
    ),
  },
];

export const PARTNER_ARTIST_LIST_TABLE_SCHEMA_ADVANCED: SchemaType = [
  {
    key: 'id',
    render: ({ data }: Record<string, any>) => data.artist.id,
  },
  {
    key: 'name',
    render: ({ data }: Record<string, any>) =>
      data.artist.status === ARTIST_ENUM.status.ACTIVE ? (
        <Link to={UrlAssembler.artistDetails(data.artist.id)} title={data.artist.name}>
          {data.artist.name}
        </Link>
      ) : (
        data.artist.name
      ),
  },
  {
    key: 'country',
    render: ({ data }: Record<string, any>) => (
      <Country code={data.artist.livesCountry} hideFallback />
    ),
  },
  {
    key: 'status',
    render: ({ data }: Record<string, any>) =>
      data.artist ? <Status type="artist" status={data.artist.status} /> : null,
  },
  {
    key: 'gender',
    render: ({ data }: Record<string, any>) =>
      data.artist.gender ? <FormattedMessage id={`common.gender.${data.artist.gender}`} /> : null,
  },
  {
    key: 'birthYear',
    render: ({ data }: Record<string, any>) => data.artist.birthYear || null,
  },
];

export const PARTNER_ARTIST_DATAGRID_TABLE_BASE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage, showNameAsLink = false }) => [
  {
    field: 'id',
    type: 'number',
    headerName: formatMessage({ id: 'components.tables.columns.common.id' }),
    sortingOrder: ['desc', 'asc', null],
    filterable: false,
    valueFormatter: (params: GridValueFormatterParams) => params.value,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'name',
    type: 'string',
    flex: 1,
    filterable: false,
    headerName: formatMessage({ id: 'components.tables.columns.common.name' }),
    ...(showNameAsLink
      ? {
          renderCell: ({ row: data }: Record<string, any>): JSX.Element => {
            return (
              <Link to={UrlAssembler.artistDetails(data.id)} title={data.name}>
                {data.name}
              </Link>
            );
          },
        }
      : null),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'livesCountry',
    type: 'singleSelect',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.common.country' }),
    renderCell: ({ row: data }: Record<string, any>): JSX.Element => {
      return <Country code={data.livesCountry} />;
    },
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(COUNTRIES_LIST.all, 'countries', formatMessage),
    sortable: false,
    filterable: false,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'gender',
    type: 'singleSelect',
    width: 150,
    headerName: formatMessage({ id: 'components.tables.columns.common.gender' }),
    renderCell: ({ row: data }: Record<string, any>) =>
      data.gender ? <FormattedMessage id={`common.gender.${data.gender.toUpperCase()}`} /> : null,
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(PROFILE_ENUM.gender, 'common.gender', formatMessage),
    sortable: false,
    filterable: false,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'birthYear',
    type: 'number',
    width: 150,
    headerName: formatMessage({ id: 'components.tables.columns.artist.birthYear' }),
    renderCell: ({ row: data }: Record<string, any>): JSX.Element => data.birthYear || null,
    sortable: false,
    filterable: false,
  },
];

export const PARTNER_ARTIST_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage }) => [
  ...PARTNER_ARTIST_DATAGRID_TABLE_BASE_SCHEMA({ formatMessage }),
  dataPickerActionColumn(formatMessage),
];
