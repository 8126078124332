import { memo } from 'react';
import get from 'lodash/get';
import { generateSrc, generatePictureProps } from '@riseart/fe-utils';
import { PictureWithPlaceholder } from '@riseart/dashboard';
import { ART_DIRECTION_SIZES } from '../../../services/riseart/artdirection/sizes';
import { areDifferent } from '../../../services/riseart/utils/Utils';
import { artDirection as CONFIG_ART_DIRECTION, cdn as CONFIG_CDN } from '../../../config/config.js';

export const CDN_THUMBS_BASE_URL = `${CONFIG_CDN.fileSystem.host}/${CONFIG_CDN.fileSystem.thumbs}`;
export const generatePicturePropsForCDN = generatePictureProps.bind(null, CDN_THUMBS_BASE_URL);
export const generateSrcForCDN = generateSrc.bind(null, CDN_THUMBS_BASE_URL);

type Props = {
  alt: string;
  image: Record<string, any>;
  imgProps?: Record<string, any>;
  type: string;
  artDirectionKey: string;
  className?: string;
};
/**
 * Picture
 */
export const Picture = memo(
  function Picture(props: Props) {
    const { image, type, artDirectionKey, ...restProps } = props;
    const artDirectionSizes = get(ART_DIRECTION_SIZES, type);

    if (!artDirectionSizes) {
      throw new Error(
        `[<Picture />] Unrecognized art direction type '${type}'. Please check if you added this type in ART_DIRECTION_SIZES list.`,
      );
    }

    return (
      <PictureWithPlaceholder
        image={image}
        artDirectionProps={generatePicturePropsForCDN({
          image,
          sizesList: artDirectionSizes,
          artDirectionConfig: get(CONFIG_ART_DIRECTION, artDirectionKey),
        })}
        {...restProps}
      />
    );
  },
  (prevProps: Props, newProps: Props) => {
    return (
      prevProps.image.key !== newProps.image.key ||
      areDifferent(prevProps.imgProps, newProps.imgProps) ||
      areDifferent(prevProps.className, newProps.className)
    );
  },
);
