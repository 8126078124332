import { Author as AuthorBaseModel, AuthorLocale } from '@riseart/models';
import { author as AUTHOR_ENUM } from '../../config/enumeration.js';
import { ImageArray } from './Image';
import { ApiInvalidData } from '../errors/ApiInvalidData';
import { FormDataMixin, LocaleMixin } from './Core';

/**
 * Author
 */
class Author extends LocaleMixin(FormDataMixin(AuthorBaseModel), AuthorLocale) {
  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {Author}
   */
  public hydrateFromApiData(data?: Record<string, any>): Author {
    try {
      const { avatars: avatarsData, ...authorData } = data || {};

      // Locales
      super.hydrateFromApiData(authorData);

      // Avatars
      const avatars: ImageArray = new ImageArray().hydrateFromApiData(avatarsData || []);

      return this.hydrate({ avatars });
    } catch (error) {
      throw new ApiInvalidData('Unable to load Author from provided data.');
    }
  }

  /**
   * hydrateFromLocaleFormData
   *
   * @param {Record<string, any>} formData
   * @param {string} localeCode
   * @returns {ArtistLocale}
   */
  public hydrateFromLocaleFormData(
    formData: Record<string, any>,
    localeCode: string,
  ): AuthorLocale {
    const existingLocale = this.locales.find(
      (localeItem: Record<string, any>) => localeItem.localeCode === localeCode,
    );

    const SHOULD_GENERATE_ID = !formData.id;
    const NewLocale = new AuthorLocale().hydrate({ localeCode, ...formData }, SHOULD_GENERATE_ID);

    this.locales = existingLocale
      ? this.locales.map((localeItem: AuthorLocale) => {
          if (localeItem.localeCode === localeCode) {
            return NewLocale;
          }

          return localeItem;
        })
      : [...this.locales, NewLocale];

    return NewLocale;
  }

  /**
   * mapFormToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   * @returns {Record<string, any>}
   */
  public static mapFormToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
  ): Record<string, any> {
    const { internalTypeId, internalType, ...mappedData } = super.mapFormToApiData(
      data,
      fieldsSchema,
      includeNullValues,
    );

    return {
      ...mappedData,
      internalType,
      internalTypeId:
        internalType === AUTHOR_ENUM.internalType.AUTHOR
          ? null
          : internalTypeId && typeof internalTypeId === 'object'
          ? internalTypeId.id
          : internalTypeId || null,
    };
  }

  /**
   * toFormData
   *
   * @param {Record<string, any>[]} formFields
   * @returns {Record<string, any>}
   */
  public toFormData(formFields: Record<string, any>[]): Record<string, any> {
    return {
      ...formFields.reduce((accumulator, { name }) => {
        return {
          ...accumulator,
          [name]: typeof this[name] !== 'undefined' ? this[name] : null,
        };
      }, {}),
    };
  }
}

export { Author };
