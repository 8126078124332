import { gql } from '@apollo/client';

export const READ_LEGAL = gql`
  query readLegal($type: String!) {
    readLegal(type: $type) {
      id
      type
      active
      version
      effectiveDate
      title
      legalText
    }
  }
`;
