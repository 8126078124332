import trimEnd from 'lodash/trimEnd';
import * as Sentry from '@sentry/browser';
import {
  version as VERSION_CONFIG,
  environment as ENV_CONFIG,
  graphql as GRAPHQL_CONFIG,
  application as APP_CONFIG,
  libs as LIBS_CONFIG,
} from '../../config/config.js';
import { ENVIRONMENTS } from '../../config/enumeration.js';
import { MESSAGE_TYPES, RiseartLogger } from './Logger';
import configureStore from '../redux/store';
import { Client as ApolloClient } from './apollo/client';
import { Cookies } from './utils/Cookies/Cookies';
import { GTMService } from './GTM';
import { NavigatorService } from './/Navigator';
import { LocationManager } from './Location';

// Error message type
const { ERROR } = MESSAGE_TYPES;

/**
 * setupEnvironment
 *
 * @param { Record<string, any> } config
 * @param { Record<string, any> } store
 */
const setupEnvironment = (config: Record<string, any>, store: Record<string, any>) => {
  const { environment } = config;

  switch (environment) {
    case ENVIRONMENTS.development:
      // Expose store for development purposes
      window.store = store;
      break;

    case ENVIRONMENTS.test:
    case ENVIRONMENTS.production:
      break;

    default:
      RiseartLogger.message({
        message: `Unsupported environment '${environment}' supplied during application setup`,
        level: ERROR,
      });
      break;
  }
};

/**
 * setGlobalInfo: sets general environment and build information
 *
 * @param config
 */
const setGlobalInfo = (config: Record<string, any>) => {
  window.RiseArt = window.RiseArt || {};
  window.RiseArt.applicationInfo = {
    version: config.version.number,
    environment: config.environment,
    graphql: config.graphql.endpoint,
    hash: config.version.hash,
  };
};

/**
 * setupConsole: display rise art branding and build information in console
 *
 * @param config
 */
const setupConsole = (config: Record<string, any>) => {
  const element = document.createElement('script');
  element.type = 'text/javascript';
  element.async = true;
  element.src = `${trimEnd(process.env.PUBLIC_URL, '/')}${
    config.application.environmentLoggerScript
  }`;

  const tag = document.getElementsByTagName('script')[0];
  tag.parentNode && tag.parentNode.insertBefore(element, tag);
};

/**
 * setupFacebook
 *
 * @param config
 */
const setupFacebook = (config: Record<string, any>) => {
  const facebookId = config.libs.facebook.tagId;
  const script = config.libs.facebook.scriptTag;
  const fjs = document.getElementsByTagName(script)[0];
  if (document.getElementById(facebookId)) return;
  const js: Record<string, any> = document.createElement(script);
  js.id = facebookId;
  js.async = false;
  js.defer = true;
  js.src = config.libs.facebook.source;
  fjs.parentNode && fjs.parentNode.insertBefore(js, fjs);

  window.fbAsyncInit = () => {
    window.FB.init({
      appId: config.libs.facebook.appId,
      cookie: true,
      xfbml: true,
      version: config.libs.facebook.sdkVersion,
    });
  };
};

/**
 * setupGoogle
 *
 * @param config
 */
const setupGoogle = (config: Record<string, any>) => {
  const { google: googleConfig } = config.libs;
  // Set global key for callback function
  // @ts-ignore
  window[googleConfig.callbackName] = null;

  // @ts-ignore
  window.onGoogleLibraryLoad = () => {
    // @ts-ignore
    window.google.accounts.id.initialize({
      client_id: googleConfig.clientId,
      callback: (data: Record<string, any>) => {
        if (window && window[googleConfig.callbackName]) {
          // @ts-ignore
          window[googleConfig.callbackName](data);
        }
      },
    });

    if (googleConfig.allowPrompt) {
      // @ts-ignore
      window.google.accounts.id.prompt();
    }

    // @ts-ignore
    window.googleAccountsIdInitialized = true;
  };

  const e: Record<string, any> = document.createElement(googleConfig.scriptTag);
  e.type = googleConfig.jsType;
  e.async = false;
  e.defer = true;
  e.id = googleConfig.tagId;
  e.src = googleConfig.source;
  const t = document.getElementsByTagName(googleConfig.scriptTag)[0];
  t.parentNode && t.parentNode.insertBefore(e, t);
};

/**
 * initClientEnvironment
 *
 * @returns {Object}
 */
export const initClientEnvironment = (): Record<string, any> => {
  // Configure cookie library
  Cookies.config();

  // Configure redux store and run the sagas
  const reduxStore = configureStore({});

  // Create and configure apollo GraphQL client
  const apolloClient = ApolloClient.init(reduxStore);

  // Configure NavigatorService
  NavigatorService.config({
    userAgent: window.navigator.userAgent,
    language:
      window.navigator.language ||
      // @ts-ignore
      window.navigator.userLanguage ||
      // @ts-ignore
      window.navigator.browserLanguage,
  });

  // Configure LocationService
  // window.location is passes by reference because it is an object,
  // so there is no need to update it each time when client routing
  LocationManager.config(window.location);

  // Configure logger
  RiseartLogger.config(Sentry).init(reduxStore);

  // Setup GTMService dataLayer
  GTMService.config();

  const Config = {
    version: VERSION_CONFIG,
    environment: ENV_CONFIG,
    graphql: GRAPHQL_CONFIG,
    application: APP_CONFIG,
    libs: LIBS_CONFIG,
  };

  // Initial client side environment setup
  setGlobalInfo(Config);
  setupConsole(Config);
  setupEnvironment(Config, reduxStore);

  // Setup external libraries and clients
  setupFacebook(Config);
  setupGoogle(Config);

  return { reduxStore, apolloClient };
};
