import get from 'lodash/get';
import queryString from 'query-string';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import {
  Link,
  DataTable,
  LAYOUT_PAPER_PADDING,
  SortableList,
  SortableTable,
  SortableData,
} from '@riseart/dashboard';
import { art as ART_ENUM } from '../../config/enumeration.js';
import { delay } from '../../services/riseart/utils/Utils';
import { Client as GqlClient } from '../../services/riseart/apollo/client';
import { getGraphqlOperationName } from '../../services/riseart/apollo/helpers';
import { normalizeRawData } from '../../data/normalizers/art';
import { DataListFeed } from '../data/ListFeed';
import { selectSeller } from '../../services/redux/selectors/seller';
import { StatusSelectButton } from '../common/buttons/StatusSelect';
import { ArtBulkActionsButton } from '../art/BulkActions';
import {
  ARTLIST_TABLE_SCHEMA_BASIC,
  ARTLIST_TABLE_SCHEMA_ADVANCED,
  ARTLIST_PARTNER_TABLE_SCHEMA,
  ARTLIST_TABLE_COLUMNS,
  ARTLIST_PARTNER_TABLE_COLUMNS,
} from '../data/tables/schemas/art';
import { LIST_ART } from '../../data/gql/queries/art/list.graphql';
import { SORT_ARTIST_ART } from '../../data/gql/queries/art/sortArtist.graphql';

type Props = {
  artistId: string;
  artListAssembler: (options: Record<string, any>) => string;
  addUrl: string;
  canManageArtwork?: boolean;
};

/**
 * ArtList
 *
 * @returns {JSX.Element}
 */
export const ArtList = ({
  artistId,
  canManageArtwork = false,
  artListAssembler,
  addUrl,
}: Props): JSX.Element => {
  const ITEMS_PER_PAGE = 10;
  const VIEW_MODE = { SORT: 'sort', LIST: 'list' };
  const listArtGqlOperationName = getGraphqlOperationName(LIST_ART);
  const [mainLoading, setMainLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dataViewMode, setDataViewMode] = useState(VIEW_MODE.LIST);
  const { formatMessage } = useIntl();
  const seller = useSelector(selectSeller);
  const areRowsSelected = selectedRows.length;
  const addArtLabel = formatMessage({ id: 'components.art.addArt' });
  const [updateSortMutation, { loading: updateSortLoading }] = useMutation(SORT_ARTIST_ART);
  const TABLE_COLUMNS = artistId
    ? ARTLIST_TABLE_COLUMNS(formatMessage)
    : ARTLIST_PARTNER_TABLE_COLUMNS(formatMessage);

  const TABLE_SCHEMA = artistId
    ? ARTLIST_TABLE_SCHEMA_ADVANCED(canManageArtwork)
    : ARTLIST_PARTNER_TABLE_SCHEMA(canManageArtwork);

  return (
    <>
      {seller ? (
        <DataListFeed
          query={LIST_ART}
          fetchPolicy="cache-and-network"
          itemsPerPage={ITEMS_PER_PAGE}
          buildUrl={({
            page,
            show = ART_ENUM.state.ALL,
            itemsPerPage = ITEMS_PER_PAGE,
            qsParams,
          }: Record<string, any>) => {
            const search = queryString.stringify({
              ...qsParams,
              ...(page && page > 1 ? { page } : null),
              ...(show && show !== ART_ENUM.state.ALL ? { show } : null),
              ...(itemsPerPage && itemsPerPage !== ITEMS_PER_PAGE ? { itemsPerPage } : null),
            });
            return artListAssembler({ search: search ? `?${search}` : '' });
          }}
          variablesMapper={({
            page = 1,
            itemsPerPage,
            show,
          }: Record<string, any>): Record<string, any> => ({
            page,
            store: seller.storeCode,
            sort: ART_ENUM.sort.ARTIST,
            items: itemsPerPage || 10,
            shippingCountry: seller.shipsCountryCode,
            filters: {
              seller: seller.id,
              ...(artistId ? { artist: artistId } : null),
              state: show || ART_ENUM.state.ALL,
            },
          })}
        >
          {({
            loading,
            refetchListQuery,
            items,
            pagination,
            initialValues,
            handleParamsChange,
          }: Record<string, any>) => {
            return dataViewMode === VIEW_MODE.SORT ? (
              <SortableData
                requestHandler={async (page: number) => {
                  return await GqlClient.query({
                    query: LIST_ART,
                    fetchPolicy: 'network-only',
                    variables: {
                      sort: ART_ENUM.sort.ARTIST,
                      store: seller.storeCode,
                      shippingCountry: seller.shipsCountryCode,
                      filters: {
                        seller: seller.id,
                        ...(artistId ? { artist: artistId } : null),
                        state: ART_ENUM.state.ACTIVE,
                      },
                      items: ITEMS_PER_PAGE,
                      page,
                    },
                  });
                }}
                responseHandler={(response) => get(response, `data.${listArtGqlOperationName}`, {})}
              >
                {({ data, loading: loadingSortableData }) => {
                  const SHOW_ACTION = true;
                  const sortLoadingState =
                    updateSortLoading || loadingSortableData || loading || mainLoading;

                  return (
                    <SortableList items={data}>
                      {({ moveHandler, onDrop, items: sortableListItems }) => (
                        <SortableTable
                          onMove={moveHandler}
                          onDrop={onDrop}
                          columns={[{ title: '' }, ...TABLE_COLUMNS]}
                          schema={ARTLIST_TABLE_SCHEMA_BASIC(SHOW_ACTION)}
                          layoutPaperPadding={LAYOUT_PAPER_PADDING}
                          data={
                            sortableListItems &&
                            sortableListItems.map((item: Record<string, any>) =>
                              normalizeRawData(item, seller.storeCode),
                            )
                          }
                          noData={formatMessage({ id: 'common.noItems' })}
                          loading={sortLoadingState}
                          loadingText={formatMessage({
                            id: 'components.art.loadingAllArtworks',
                          })}
                          title={formatMessage({ id: 'components.art.sortArt' })}
                          mainAction={
                            <Box
                              sx={{
                                display: { xs: 'none', sm: 'block' },
                                flexGrow: 1,
                                textAlign: 'right',
                              }}
                            >
                              <Button
                                variant="outlined"
                                size="large"
                                sx={{ mr: 1 }}
                                disabled={sortLoadingState}
                                onClick={() => setDataViewMode(VIEW_MODE.LIST)}
                              >
                                {formatMessage({ id: 'common.cancel' })}
                              </Button>
                              {artistId ? (
                                <Button
                                  variant="contained"
                                  size="large"
                                  disabled={sortLoadingState}
                                  onClick={() => {
                                    setMainLoading(true);
                                    const inputArtSort = sortableListItems.reduce(
                                      (
                                        accumulator: { id: number; sort: number }[],
                                        item: Record<string, any>,
                                        index: number,
                                      ) => {
                                        return [...accumulator, { id: item.id, sort: index + 1 }];
                                      },
                                      [],
                                    );

                                    // Send updated items to API
                                    updateSortMutation({
                                      variables: { artistId, inputArtSort },
                                    })
                                      .then(() => {
                                        delay(() =>
                                          refetchListQuery()
                                            .then(() => {
                                              setMainLoading(false);
                                              setDataViewMode(VIEW_MODE.LIST);
                                            })
                                            .catch(() => {
                                              setMainLoading(false);
                                            }),
                                        );
                                      })
                                      .catch(() => {
                                        setMainLoading(false);
                                      });
                                  }}
                                >
                                  {formatMessage({ id: 'common.saveChanges' })}
                                </Button>
                              ) : null}
                            </Box>
                          }
                        />
                      )}
                    </SortableList>
                  );
                }}
              </SortableData>
            ) : (
              <DataTable
                checkboxSelection
                rowsPerPageOptions={[10]}
                onCheckboxSelected={setSelectedRows}
                columns={[
                  { title: '' },
                  // @ts-ignore
                  ...TABLE_COLUMNS,
                  // @ts-ignore
                  ...(canManageArtwork
                    ? [
                        {
                          title: '',
                          align: 'right',
                          width: '160px',
                        },
                      ]
                    : []),
                ]}
                schema={TABLE_SCHEMA}
                layoutPaperPadding={LAYOUT_PAPER_PADDING}
                data={
                  items &&
                  items.map((item: Record<string, any>) => ({
                    ...normalizeRawData(item, seller.storeCode),
                    canCopy: true,
                  }))
                }
                refetchListQuery={refetchListQuery}
                pagination={pagination}
                noData={formatMessage({ id: 'common.noItems' })}
                loading={loading || mainLoading}
                mainAction={
                  canManageArtwork ? (
                    <>
                      <Box
                        sx={{
                          display: { xs: 'none', sm: 'block' },
                          flexGrow: 1,
                          pr: 1,
                          textAlign: 'right',
                        }}
                      >
                        <Button
                          variant="contained"
                          size="large"
                          component={Link}
                          to={addUrl}
                          title={addArtLabel}
                        >
                          {addArtLabel}
                        </Button>
                        {artistId &&
                        items &&
                        items.length &&
                        !areRowsSelected &&
                        canManageArtwork ? (
                          <Button
                            variant="contained"
                            size="large"
                            onClick={() => setDataViewMode(VIEW_MODE.SORT)}
                            sx={{ ml: 1 }}
                          >
                            {formatMessage({ id: 'components.art.sortArt' })}
                          </Button>
                        ) : null}
                      </Box>
                      {areRowsSelected ? (
                        <Box
                          sx={{
                            pr: 1,
                            textAlign: 'right',
                          }}
                        >
                          <ArtBulkActionsButton
                            loading={mainLoading}
                            setLoading={setMainLoading}
                            selectedItems={selectedRows}
                            refetchQuery={refetchListQuery}
                          />
                        </Box>
                      ) : null}
                    </>
                  ) : null
                }
                title={formatMessage({ id: 'components.mainMenu.artList' })}
                secondaryAction={
                  <StatusSelectButton
                    initialValue={initialValues.show || ART_ENUM.state.ALL}
                    optionsList={ART_ENUM.state}
                    optionsTranslationPrefix="components.art.state.values"
                    dialogTranslationPrefix="components.dialog.art.action"
                    onChange={(show: string | number) => handleParamsChange({ show })}
                  />
                }
              />
            );
          }}
        </DataListFeed>
      ) : null}
      {dataViewMode === VIEW_MODE.LIST ? (
        <Fab
          color="primary"
          component={Link}
          to={addUrl}
          title={addArtLabel}
          sx={{
            position: { xs: 'fixed', lg: 'absolute' },
            top: { xs: 'auto', lg: '135px' },
            left: { xs: 'auto', lg: '-28px' },
            bottom: { xs: '25px', lg: 'auto' },
            right: { xs: '25px', lg: 'auto' },
            fontSize: '60px',
          }}
          size="large"
        >
          <AddIcon />
        </Fab>
      ) : null}
    </>
  );
};
