import { gql } from '@apollo/client';
import { SELLER_SHIPPING_TABLE_FRAGMENT } from '../../fragments/seller/shippingTable.graphql';

export const CREATE_SELLER_SHIPPING_TABLE = gql`
  ${SELLER_SHIPPING_TABLE_FRAGMENT}
  mutation createSellerShippingTable($sellerId: Int!, $inputShippingTable: InputShippingTable!) {
    createSellerShippingTable(sellerId: $sellerId, inputShippingTable: $inputShippingTable) {
      ...SellerShippingTableFragment
    }
  }
`;
