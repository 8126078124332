import { FileDropzone as DashboardFileDropzone } from '@riseart/dashboard';
import type { FileDropzoneProps } from '@riseart/dashboard';
import classNames from './Dropzone.module.css';

/**
 * FileDropzone
 *
 * @param {FileDropzoneProps} props
 * @returns {JSX.Element}
 */
export function FileDropzone({ children, ...props }: FileDropzoneProps): JSX.Element {
  return (
    <DashboardFileDropzone className={classNames} {...props}>
      {children}
    </DashboardFileDropzone>
  );
}
