import { gql } from '@apollo/client';

export const ARTIST_AWARD_FRAGMENT = gql`
  fragment ArtistAwardFragment on ArtistAward {
    id
    artistId
    name
    body
    year
    city
    countryCode
  }
`;
