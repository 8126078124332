import { ErrorService } from '../../riseart/errors/ErrorService';
import { ERRORS_ACTIONS } from '../actions/container';

/**
 * MiddlewareErrors
 * listens to errors in store and takes actions
 */
export class MiddlewareError {
  /**
   * MiddlewareError
   *
   * @param store
   */
  static middleware =
    (store: Record<string, any>) => (next: any) => (action: Record<string, any>) => {
      if (action.type === ERRORS_ACTIONS.ERROR_ADD) {
        ErrorService.dispatchHandler(store.dispatch)(action.payload);
      }
      next(action);
    };
}
