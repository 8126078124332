import { FormattedMessage } from 'react-intl';

/**
 * splitButtonConfirmationProps
 *
 * @param {Record<string, any>}
 * @returns {Record<string, any>}
 */
export function splitButtonConfirmationProps({
  translationPrefix,
  actionKey,
}: Record<string, any>): Record<string, any> {
  return {
    title: <FormattedMessage id={`${translationPrefix}.title`} />,
    description: <FormattedMessage id={`${translationPrefix}.${actionKey}`} />,
    disagreeLabel: <FormattedMessage id="common.no" />,
    agreeLabel: <FormattedMessage id="common.yes" />,
  };
}
