import { gql } from '@apollo/client';
import { ARTIST_AWARD_FRAGMENT } from '../../fragments/artist/award.graphql';

export const LIST_ARTIST_AWARDS = gql`
  ${ARTIST_AWARD_FRAGMENT}
  query listArtistAwards($artistId: Int!) {
    listArtistAwards(artistId: $artistId) {
      items {
        ...ArtistAwardFragment
      }
    }
  }
`;
