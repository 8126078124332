import { gql } from '@apollo/client';
import { ART_FRAGMENT } from '../../fragments/art/art.graphql';

export const UPDATE_ART = gql`
  ${ART_FRAGMENT}
  mutation updateArt($id: Int!, $store: String!, $inputArt: InputArt!) {
    updateArt(id: $id, store: $store, inputArt: $inputArt) {
      ...ArtFragment
    }
  }
`;
