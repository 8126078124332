import { gql } from '@apollo/client';
import { ARTIST_FRAGMENT } from '../../fragments/artist/artist.graphql';
import { PAGINATION_FRAGMENT } from '../../fragments/common/pagination.graphql';

export const LIST_ARTISTS = gql`
  ${ARTIST_FRAGMENT}
  ${PAGINATION_FRAGMENT}
  query listArtists($page: Int, $items: Int, $filters: InputArtistListFilters) {
    listArtists(page: $page, items: $items, filters: $filters) {
      items {
        ...ArtistFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
`;
