import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Link } from '@riseart/dashboard';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { selectPartner } from '../../../../services/redux/selectors/partner';
import { DefaultLayout } from '../../../layout/Default';
import { PageSubmenu } from '../../../common/PageSubmenu';
import { Artist as ArtistModel } from '../../../../data/models/Artist';
import { READ_ARTIST } from '../../../../data/gql/queries/artist/read.graphql';
import { GET_PARTNER_ARTIST } from '../../../../data/gql/queries/partner/getArtist.graphql';
import { ArtList } from '../../../art/List';

/**
 * ArtistArtListPage
 *
 * @returns {JSX.Element}
 */
export const ArtistArtListPage = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const { id: artistId }: Record<string, any> = useParams();
  const partner = useSelector(selectPartner) || {};
  const pageTitle = formatMessage({ id: 'components.mainMenu.artList' });
  const listLabel = formatMessage({ id: 'components.mainMenu.artistsList' });

  // Queries and Mutations
  const { data: listArtist, loading: listLoading } = useQuery(GET_PARTNER_ARTIST, {
    fetchPolicy: 'network-only',
    variables: {
      partnerId: partner && partner.id,
      filters: { artistId: { value: artistId, operator: 'EQUAL' } },
    },
  });
  const partnerArtist =
    listArtist && listArtist.artist && listArtist.artist.items && listArtist.artist.items[0];
  const { data: readArtistData, loading: readLoading } = useQuery(READ_ARTIST, {
    fetchPolicy: 'network-only',
    variables: { id: artistId },
    skip: !(partnerArtist && partnerArtist.artistId),
  });
  const { canManageArtwork } = partnerArtist || {};
  const rawArtistData = (readArtistData && readArtistData.readArtist) || null;
  const Artist = new ArtistModel();

  if (rawArtistData) {
    Artist.hydrateFromApiData(rawArtistData);
  }

  return (
    <DefaultLayout
      includesFab
      title={pageTitle}
      loading={readLoading || listLoading}
      breadcrumbs={[
        <Link
          key="home"
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link
          key="allArtists"
          to={UrlAssembler.artistList()}
          title={listLabel}
          underline="hover"
          color="inherit"
        >
          {listLabel}
        </Link>,
        ...(Artist.name
          ? [
              <Link
                key="profile"
                to={UrlAssembler.artistDetails(Artist.id)}
                title={Artist.name}
                underline="hover"
                color="inherit"
              >
                {Artist.name}
              </Link>,
              <Typography key="artList" variant="inherit">
                {formatMessage({ id: 'components.artist.tab.artworks' })}
              </Typography>,
            ]
          : []),
      ]}
    >
      <PageSubmenu menu="artist" selected="artistArtList" urlParams={{ id: Artist.id }} />
      <Paper sx={{ position: 'relative' }}>
        {Artist.id ? (
          <ArtList
            canManageArtwork={canManageArtwork}
            artistId={Artist.id}
            artListAssembler={(options: Record<string, any>): string =>
              UrlAssembler.artistArtList(Artist.id, options)
            }
            addUrl={UrlAssembler.artistArtAdd(Artist.id)}
          />
        ) : null}
      </Paper>
    </DefaultLayout>
  );
};
