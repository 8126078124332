import { gql } from '@apollo/client';
import { SELLER_PAYMENT_METHOD_FRAGMENT } from '../../fragments/seller/paymentMethod.graphql';

export const UPDATE_SELLER_PAYMENT_METHOD = gql`
  ${SELLER_PAYMENT_METHOD_FRAGMENT}
  mutation updateSellerPaymentMethod(
    $id: Int!
    $sellerId: Int!
    $inputPaymentMethod: InputSellerPaymentMethod!
  ) {
    updateSellerPaymentMethod(
      id: $id
      sellerId: $sellerId
      inputPaymentMethod: $inputPaymentMethod
    ) {
      ...SellerPaymentMethodFragment
    }
  }
`;
