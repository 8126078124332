import { FormattedMessage } from 'react-intl';
import { AdvancedTable as AdvancedCommonTable } from '@riseart/dashboard';
import { errors as ERRORS_ENUM } from '../../../config/enumeration.js';
import { useNotification } from '../../../data/hooks/useNotification';
import { mapApiValidationErrors, mapApiErrors } from '../../../services/riseart/utils/Form';

type Props = {
  sx?: Record<string, any>;
  includesFab?: boolean;
  data: Record<string, any>;
  loading: boolean;
  deleteLoading?: boolean;
  refetchListHandler?: () => Promise<any>;
  deleteHandler?: () => Promise<any>;
  customData?: Record<string, any> | null;
  scrollToTop?: () => void;
  schema: Array<Record<string, any>>;
  columns: Array<{
    title: string | JSX.Element;
    align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined;
    width?: string | number;
    sx?: Record<string, any>;
  }>;
  title: string;
  pagination?: Record<string, any> | null;
  mainAction?: JSX.Element | null;
  mainActionLabel?: string;
  mainActionProps?: Record<string, any> | null;
  mainAdditionalActions?: JSX.Element | null;
  noItemsLabel?: JSX.Element | string;
  firstRow?: ((data: Record<string, any>) => any) | null;
  editRenderer?: (data: Record<string, any>) => any;
  successTranslationKey?: string;
  layoutPaperPadding?: Record<string, any> | null;
};

/**
 * AdvancedTable
 *
 * @returns {JSX.Element}
 */
export const AdvancedTable = ({ noItemsLabel, ...props }: Props): JSX.Element | null => {
  const { dispatchNotification } = useNotification();
  const { scrollToTop, successTranslationKey = 'components.notifications.itemCreated' } = props;

  function handleSuccess() {
    // Show success notification
    dispatchNotification({
      detail: successTranslationKey,
      level: ERRORS_ENUM.levels.SUCCESS,
      placeholder: ERRORS_ENUM.placeholders.SNACKBAR,
      expire: 5,
    });
  }

  /**
   * handleAddError
   *
   * @param {Record<string, any>} error
   * @param {(errors: any) => any} addApiErrors
   */
  function handleError(error: Record<string, any>, addApiErrors: (errors: any) => any) {
    // Map validation errors from response
    const validationError = mapApiValidationErrors(error);

    if (validationError && validationError.length && addApiErrors) {
      addApiErrors(validationError);
    } else {
      // Map any other type of errors from response (validation without provided fields, or non-validation errors)
      mapApiErrors(error).forEach((err) => {
        dispatchNotification({
          detail: err,
          level: ERRORS_ENUM.levels.ERROR,
          placeholder: ERRORS_ENUM.placeholders.PAGE,
        });
      });
    }

    // Scroll to top of page
    if (typeof scrollToTop === 'function') {
      scrollToTop();
    }
  }

  return (
    // @ts-ignore
    <AdvancedCommonTable
      {...props}
      noItemsLabel={!noItemsLabel ? <FormattedMessage id="common.noItems" /> : noItemsLabel}
      onError={handleError}
      onSuccess={handleSuccess}
    />
  );
};
