import { useState } from 'react';
import get from 'lodash/get';
import queryString from 'query-string';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { useQuery, useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Title, Link, Paragraph, LayoutPaper, Logo } from '@riseart/dashboard';
import { legal as ENUM_LEGAL } from '../../config/enumeration.js';
import { UrlAssembler } from '../../services/riseart/utils/UrlAssembler';
import { selectUser } from '../../services/redux/selectors/user';
import { selectSeller } from '../../services/redux/selectors/seller';
import { LIST_SELLER_LEGALS } from '../../data/gql/queries/seller/listLegals.graphql';
import { READ_LEGAL } from '../../data/gql/queries/legal/read.graphql';
import { ACCEPT_SELLER_LEGAL } from '../../data/gql/queries/seller/acceptLegal.graphql';
import { DefaultLayout } from '../layout/Default';
import { handlePrintClick, delay } from '../../services/riseart/utils/Utils';

import styles from './Terms.module.css';

let redirectTimeoutId: ReturnType<typeof setTimeout> | null = null;

/**
 * TermsPage
 *
 * @returns {JSX.Element}
 */
export const TermsPage = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { id: sellerId } = useSelector(selectSeller) || {};
  const { id: userId } = useSelector(selectUser) || {};
  const [acceptLegal, { loading: acceptLegalLoading }] = useMutation(ACCEPT_SELLER_LEGAL);
  const { loading: readLegalLoading, data: readLegalData } = useQuery(READ_LEGAL, {
    variables: { type: ENUM_LEGAL.type.SELLER_TERMS },
  });
  const legal = get(readLegalData, 'readLegal', null);
  const legalId = get(legal, 'id', null);
  const { loading: listLegalsLoading, refetch: refetchListSellerLegals } = useQuery(
    LIST_SELLER_LEGALS,
    {
      skip: !sellerId || !legalId,
      variables: { sellerId, filters: { legal: legalId } },
    },
  );

  /**
   * redirectToForward
   */
  function redirectToForward() {
    const queryParams = queryString.parse(location.search);
    history.push(queryParams.forward || UrlAssembler.byRouteKey('home'));
  }

  /**
   * handleAccept
   */
  function handleAccept() {
    setLoading(true);
    acceptLegal({ variables: { sellerId, userId, legalId: legal.id } })
      .then(() => {
        redirectTimeoutId && clearTimeout(redirectTimeoutId);
        // Delay execution of refetch to avoid any cache issues
        redirectTimeoutId = delay(() =>
          refetchListSellerLegals().then(() => {
            setLoading(false);
            redirectToForward();
          }),
        );
      })
      .catch((error) => {
        setLoading(false);
        const gqlErrors = get(error, 'graphQLErrors', []);

        // Legals are already accepted by seller
        if (
          gqlErrors.some(
            (error: Record<string, any>) =>
              get(error, 'errorInfo.type') === 'AlreadyExistsException',
          )
        ) {
          redirectToForward();
        }
      });
  }

  return (
    <DefaultLayout
      hideHeader
      showMenu={false}
      topContent={
        <Box sx={{ textAlign: 'center', mt: -5, paddingY: 2 }}>
          <Logo type={'default'} size="small" href={UrlAssembler.byRouteKey('home')} />
        </Box>
      }
      loading={loading || listLegalsLoading || readLegalLoading || acceptLegalLoading}
      containerStyles={{ pl: { xs: 3, lg: 4 }, pr: { xs: 3, lg: 4 } }}
    >
      {legal ? (
        <LayoutPaper sx={{ p: { xs: '45px 25px', md: '60px 75px' } }}>
          <Title variant="h4">{legal.title}</Title>
          <Paragraph sx={{ my: '25px' }}>
            <FormattedMessage
              id="components.terms.effectiveDate"
              values={{
                date: (
                  <FormattedDate
                    value={legal.effectiveDate}
                    year="numeric"
                    month="long"
                    day="numeric"
                  />
                ),
              }}
            />
          </Paragraph>
          <ReactMarkdown linkTarget="_blank">{legal.legalText}</ReactMarkdown>
          <Paragraph sx={{ my: '25px' }}>
            <FormattedMessage id="components.terms.version" values={{ version: legal.version }} />
          </Paragraph>
          <Grid container justifyContent="space-between" my={3} className={styles.termsFooter}>
            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
              <Button
                component={Link}
                to={UrlAssembler.byRouteKey('signout')}
                variant="outlined"
                sx={{ width: { xs: '100%', md: 'auto' }, my: 1 }}
              >
                <FormattedMessage id="components.terms.doNotAccept" />
              </Button>
            </Grid>
            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} textAlign="right">
              <Button
                variant="outlined"
                onClick={handlePrintClick}
                sx={{ mr: { xs: 0, sm: 3 }, width: { xs: '100%', md: 'auto' }, my: 1 }}
              >
                <FormattedMessage id="components.terms.print" />
              </Button>
              <Button
                variant="contained"
                onClick={handleAccept}
                sx={{ width: { xs: '100%', md: 'auto' }, my: 1 }}
              >
                <FormattedMessage id="components.terms.accept" />
              </Button>
            </Grid>
          </Grid>
        </LayoutPaper>
      ) : null}
    </DefaultLayout>
  );
};
