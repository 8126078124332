import { gql } from '@apollo/client';

export const SELLER_PAYMENT_METHOD_FRAGMENT = gql`
  fragment SellerPaymentMethodFragment on SellerPaymentMethod {
    id
    sellerId
    typeCode
    default
    attributes {
      name
      value
    }
  }
`;
