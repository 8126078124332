import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import { useMutation } from '@apollo/client';
import { CircularLoader, FormBuilder, FormTable, FormTableCell } from '@riseart/dashboard';
import { EditSellerRateFormModel } from '../forms/models/sellerRate';
import { CREATE_SELLER_SHIPPING_TABLE_RATE } from '../../data/gql/queries/seller/createShippingTableRate.graphql';
import { UPDATE_SELLER_SHIPPING_TABLE_RATE } from '../../data/gql/queries/seller/updateShippingTableRate.graphql';
import { TABLE_IN_ROW_CELL_PROPS } from '../common/tables/settings';

type Props = {
  tableId: string | number;
  sellerId: string | number;
  ratesItems: Record<string, any>[];
  storeCode: string;
  data: Record<string, any>;
  onCancel: () => any;
  onSuccess: (response: any) => any;
  onError?: (errors: any, addApiErrors: any) => any;
};

/**
 * ShippingRateEditRow
 *
 * @param {Props} props
 * @returns
 */
export const ShippingRateEditRow = ({
  tableId,
  sellerId,
  ratesItems,
  storeCode,
  data,
  onCancel,
  onSuccess,
  onError,
}: Props): JSX.Element => {
  const [updateRate, { loading: updateLoading }] = useMutation(UPDATE_SELLER_SHIPPING_TABLE_RATE);

  const [createRate, { loading: createLoading }] = useMutation(CREATE_SELLER_SHIPPING_TABLE_RATE);

  return (
    <TableCell colSpan={4} {...TABLE_IN_ROW_CELL_PROPS}>
      <CircularLoader active={updateLoading || createLoading} style={{ minHeight: 'auto' }}>
        <FormBuilder
          inputLabelProps={{ shrink: true }}
          containerWrapper={FormTable}
          fieldWrapper={FormTableCell}
          fieldWrapperProps={[{ width: '30%', sx: { pl: 4 } }, { width: '25%' }, { width: '25%' }]}
          settings={EditSellerRateFormModel.settings}
          fields={EditSellerRateFormModel.fields}
          customData={{ ratesItems, storeCode }}
          initialValues={{
            destination: data.countryCode
              ? JSON.stringify({ countryCode: data.countryCode })
              : JSON.stringify({ regionCode: data.regionCode }),
            baseCost: data.baseCost,
            pctCost: data.pctCost,
          }}
          footer={({ hasErrors, disableSubmit }) => (
            <Stack direction="row" justifyContent="right" spacing={2}>
              <IconButton type="submit" size="large" disabled={hasErrors || disableSubmit}>
                <DoneIcon
                  color={hasErrors || disableSubmit ? 'disabled' : 'success'}
                  fontSize="large"
                />
              </IconButton>
              <IconButton size="large" onClick={onCancel}>
                <CloseIcon color="error" fontSize="large" />
              </IconButton>
            </Stack>
          )}
          onSubmit={async (formState, addApiErrors) => {
            (data.inherited ? createRate : updateRate)({
              variables: {
                ...(data.inherited ? null : { id: data.id }),
                tableId,
                sellerId,
                inputShippingTableRate: {
                  baseCost: formState.data.baseCost,
                  pctCost: formState.data.pctCost,
                  ...(data.inherited ? { ...JSON.parse(formState.data.destination) } : null),
                },
              },
            })
              .then(onSuccess)
              .catch(
                (errors: any) => typeof onError === 'function' && onError(errors, addApiErrors),
              );
          }}
        />
      </CircularLoader>
    </TableCell>
  );
};
