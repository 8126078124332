import { gql } from '@apollo/client';

export const SELLER_SHIPPING_TABLE_FRAGMENT = gql`
  fragment SellerShippingTableFragment on SellerShippingTable {
    id
    name
    default
    currency
    storeCode
    parentId
    parentName
    parentDefault
    parentCurrency
    parentStoreCode
  }
`;
