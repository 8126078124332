import {
  raScreenXsMax,
  raScreenSmMax,
  raScreenMdMax,
} from '../../../styles/breakpoints.module.css';

export const ART_DIRECTION_SIZES = {
  avatar: {
    small: {
      defaultSrcDimension: 20,
      sizes: `20px`,
    },
    medium: {
      defaultSrcDimension: 40,
      sizes: `40px`,
    },
    large: {
      defaultSrcDimension: 80,
      sizes: `80px`,
    },
  },
  art: {
    list: {
      defaultSrcDimension: 130,
      sizes: `130px`,
    },
    detailMain: {
      defaultSrcDimension: 560,
      sizes: `(max-width: ${raScreenXsMax}) 280px, (max-width: ${raScreenSmMax}) 250px, (max-width: ${raScreenMdMax}) 350px, 470px`,
    },
    detailGrid: {
      defaultSrcDimension: 280,
      sizes: `(max-width: ${raScreenXsMax}) 280px, (max-width: ${raScreenSmMax}) 120px, (max-width: ${raScreenMdMax}) 140px, 220px`,
    },
  },
  form: {
    main: {
      defaultSrcDimension: 560,
      sizes: `(max-width: ${raScreenXsMax}) 280px, (max-width: ${raScreenSmMax}) 250px, (max-width: ${raScreenMdMax}) 350px, 470px`,
    },
  },
  table: {
    list: {
      defaultSrcDimension: 130,
      sizes: `130px`,
    },
  },
};
