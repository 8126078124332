import { gql } from '@apollo/client';
import { ARTIST_COLLECTION_FRAGMENT } from '../../fragments/artist/collection.graphql';

export const UPDATE_ARTIST_COLLECTION = gql`
  ${ARTIST_COLLECTION_FRAGMENT}
  mutation updateArtistCollection(
    $id: ID!
    $artistId: Int!
    $inputArtistCollection: InputArtistCollection!
  ) {
    updateArtistCollection(
      id: $id
      artistId: $artistId
      inputArtistCollection: $inputArtistCollection
    ) {
      ...ArtistCollectionFragment
    }
  }
`;
