import { gql } from '@apollo/client';

export const ARTIST_COLLECTION_FRAGMENT = gql`
  fragment ArtistCollectionFragment on ArtistCollection {
    id
    artistId
    collection
    city
    countryCode
  }
`;
