import { store as CONFIG_STORE } from '../../../config/config.js';

export type AuthSelectorType = {
  token: string;
  payload: Record<string, any>;
  register: Record<string, any> | null;
};

/**
 * selectAuth
 *
 * @param {Record<string, any>} state
 * @returns { token: string; payload: Record<string, any> }
 */
export function selectAuth(state: Record<string, any>): AuthSelectorType {
  return state[CONFIG_STORE.keys.auth] || null;
}
