import { gql } from '@apollo/client';
import { SELLER_SHIPPING_TABLE_RATE_FRAGMENT } from '../../fragments/seller/shippingTableRate.graphql';

export const UPDATE_SELLER_SHIPPING_TABLE_RATE = gql`
  ${SELLER_SHIPPING_TABLE_RATE_FRAGMENT}
  mutation updateSellerShippingTableRate(
    $id: Int!
    $tableId: Int!
    $sellerId: Int!
    $inputShippingTableRate: InputShippingTableRate!
  ) {
    updateSellerShippingTableRate(
      id: $id
      tableId: $tableId
      sellerId: $sellerId
      inputShippingTableRate: $inputShippingTableRate
    ) {
      ...SellerShippingTableRateFragment
    }
  }
`;
