import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { DefaultLayout } from '../layout/Default';
import { DashboardCardProfile } from '../dashboard/cards/Profile';
import { DashboardCardArtworks } from '../dashboard/cards/Artworks';
import { DashboardCardPartnerArtists } from '../dashboard/cards/PartnerArtists';
import { DashboardCardHelp } from '../dashboard/cards/Help';
import { DashboardCardOffers } from '../dashboard/cards/Offers';
import { selectPartner } from '../../services/redux/selectors/partner';

/**
 * HomePage
 *
 * @returns {JSX.Element}
 */
export const HomePage = (): JSX.Element => {
  const partner = useSelector(selectPartner) || {};
  return (
    <DefaultLayout title={<FormattedMessage id="components.mainMenu.home" />}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: { sm: 260 } }}>
            <DashboardCardProfile />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: { sm: 260 } }}>
            <DashboardCardArtworks />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: { sm: 260 } }}>
            <DashboardCardOffers />
          </Paper>
        </Grid>
        {partner && partner.id ? (
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2, height: { sm: 260 } }}>
              <DashboardCardPartnerArtists partnerId={partner.id} />
            </Paper>
          </Grid>
        ) : null}
        <Grid item xs={12} md={6}>
          <Paper>
            <DashboardCardHelp />
          </Paper>
        </Grid>
      </Grid>
    </DefaultLayout>
  );
};
