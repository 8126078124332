import {
  Offer as OfferBaseModel,
  OfferPaginationResult as OfferPaginationResultBaseModel,
  OfferLog as OfferLogBaseModel,
  OfferLogListResult as OfferLogListResultBaseModel,
} from '@riseart/models';
import { FormDataMixin } from './Core';
import { PaginationInfo, ListInfo } from './List';
import { Art, ProductSku } from './Art';
import { ApiInvalidData } from '../errors/ApiInvalidData';

/**
 * Offer
 */
class Offer extends FormDataMixin(OfferBaseModel) {
  /**
   * constructor
   *
   * @param {Array<Record<string, any>>} data
   */
  public constructor(data?: Record<string, any>) {
    super();
    if (data) {
      this.hydrateFromApiData(data);
    }
  }

  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {Offer}
   */
  public hydrateFromApiData(data?: Record<string, any>): Offer {
    try {
      const { store = {}, art = {}, sku = {}, ...offerData } = data || {};

      // Root
      super.hydrate(offerData);

      return this.hydrate({
        storeCode: store && store.code,
        art: art ? new Art().hydrateFromApiData(art) : null,
        sku: sku ? new ProductSku().hydrateFromApiData(sku) : null,
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load Offer from provided data');
    }
  }

  /**
   * hydrateFromFormData
   *
   * @param {Record<string, any>} formData
   * @returns {Article}
   */
  public hydrateFromFormData(formData: Record<string, any>): Offer {
    return this.hydrate({ id: this.id, ...formData });
  }

  /**
   * customerFullName
   */
  public get customerFullName(): string {
    return [this.customerFirstName, this.customerLastName]
      .filter((name: string) => !!name)
      .join(' ');
  }
}

/**
 * OfferPaginationResult
 */
class OfferPaginationResult extends OfferPaginationResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {OfferPaginationResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): OfferPaginationResult {
    try {
      // Deconstruct
      const { items: itemsData, ...paginationData } = data || {};

      // Items
      const items: Array<Offer> = itemsData
        ? itemsData.map((item: Record<string, any>) => new Offer().hydrateFromApiData(item))
        : [];

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      // Hydrate model
      return this.hydrate({
        items: items,
        pagination: pagination || {},
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load OfferPaginationResult from provided data');
    }
  }
}

/**
 * OfferLog
 */
class OfferLog extends OfferLogBaseModel {
  /**
   * constructor
   *
   * @param {Array<Record<string, any>>} data
   */
  public constructor(data?: Record<string, any>) {
    super();

    if (data) {
      this.hydrate(data);
    }
  }
}

/**
 * OfferLogListResult
 */
class OfferLogListResult extends OfferLogListResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {OfferLogListResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): OfferLogListResult {
    try {
      // Deconstruct
      const { items: itemsData, collection: collectionData } = data || {};

      // Items
      const items: Array<OfferLog> = itemsData
        ? itemsData.map((item: Record<string, any>) => new OfferLog().hydrate(item))
        : [];

      // Collection
      const collection: ListInfo = new ListInfo().hydrateFromApiData(collectionData);

      // Hydrate model
      return this.hydrate({ items, collection });
    } catch (error) {
      throw new ApiInvalidData('Unable to load OfferLogListResult from provided data');
    }
  }
}

export { Offer, OfferPaginationResult, OfferLog, OfferLogListResult };
