import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { application as APP_CONFIG } from '../../config/config.js';
import { seller as SELLER_ENUM } from '../../config/enumeration.js';
import { LocationManager } from '../../services/riseart/Location';
import { UrlAssembler } from '../../services/riseart/utils/UrlAssembler';
import { selectSeller } from '../../services/redux/selectors/seller';
import { isRoleAllowedForACL } from '../../services/riseart/utils/Auth';

type Props = {
  routeConfig: Record<string, any>;
  children: JSX.Element | null;
};

/**
 * OnboardingProvider
 *
 * @returns {JSX.Element}
 */
export const OnboardingProvider = ({ routeConfig, children }: Props): JSX.Element | null => {
  const WHITELIST_PAGES: Array<string> = ['terms'];
  const location = useLocation();
  const { onboardingStage } = useSelector(selectSeller) || {};
  const isOnboardingCompleted = onboardingStage === SELLER_ENUM.onboardingStage.COMPLETED;
  const isRequestedOnboardingPage = routeConfig.key === 'onboarding';
  const isPublicRoute = isRoleAllowedForACL(
    routeConfig && routeConfig.acl && routeConfig.acl.roles,
    APP_CONFIG.acl.rolesWildcard,
  );

  // Redirect to forward or home if onboarding page is requested but the seller has already finished the onboarding proccess
  if (isOnboardingCompleted && isRequestedOnboardingPage) {
    const queryParams = queryString.parse(location.search);
    return <Redirect to={queryParams.forward || UrlAssembler.byRouteKey('home')} />;
  }

  // Load requested page, or enter onboarding stages (if seller has not finished onboarding)
  return isOnboardingCompleted ||
    isRequestedOnboardingPage ||
    isPublicRoute ||
    WHITELIST_PAGES.indexOf(routeConfig.key) > -1 ? (
    children
  ) : (
    <Redirect
      to={{
        pathname: UrlAssembler.byRouteKey('onboarding'),
        search: `?forward=${encodeURIComponent(LocationManager.get('uri'))}`,
      }}
    />
  );
};
