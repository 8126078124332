import MenuItem from '@mui/material/MenuItem';
import { FormSelectNoneOption, selectOptions, FORM_VALIDATIONS } from '@riseart/dashboard';
import { stores as STORES_ENUM, seller as SELLER_ENUM } from '../../../config/enumeration.js';
import { countriesListOptions, phonePropsMapper } from '../utils';

/**
 * storeCodeOptions
 *
 * @param {{(options: Record<string, any>) => any}} options
 * @returns {JSX.Element[]}
 */
function storeCodeOptions({
  formatMessage,
}: {
  formatMessage: (options: Record<string, any>) => any;
}): JSX.Element[] {
  return STORES_ENUM.map((code) => (
    <MenuItem key={code} value={code}>
      {formatMessage({ id: `stores.${code}` })}
    </MenuItem>
  ));
}

/**
 * workingDaysOptions
 *
 * @param {{(options: Record<string, any>, variables?: Record<string, any>) => any}} options
 * @returns {JSX.Element[]}
 */
function workingDaysOptions({
  formatMessage,
}: {
  formatMessage: (options: Record<string, any>, variables?: Record<string, any>) => any;
}): JSX.Element[] {
  return Array.apply(0, Array(15)).map((day: any, idx: number) => {
    const dayNumber = idx + 1;
    return (
      <MenuItem key={dayNumber} value={dayNumber}>
        {formatMessage({ id: 'forms.seller.options.workingDays' }, { day: dayNumber })}
      </MenuItem>
    );
  });
}

/**
 * vatStatusOptions
 *
 * @param {formatMessage: (options: Record<string, any>, variables?: Record<string, any>) => any} options
 * @returns
 */
function vatStatusOptions({
  formatMessage,
}: {
  formatMessage: (options: Record<string, any>, variables?: Record<string, any>) => any;
}): JSX.Element[] {
  return Object.keys(SELLER_ENUM.tax.vat.status).map((key: string) => {
    // @ts-ignore
    const value = SELLER_ENUM.tax.vat.status[key];
    return (
      <MenuItem
        key={value}
        value={value}
        {...(value === 0 ? { sx: { fontStyle: 'italic' } } : null)}
      >
        {formatMessage({ id: `forms.seller.options.vatStatus.${value}` })}
      </MenuItem>
    );
  });
}

/**
 * shouldRenderTaxFields
 *
 * @param {Record<string, any>} customData
 * @param {Record<string, any>} formState
 * @returns
 */
function shouldRenderTaxFields(
  customData: Record<string, any>,
  formState: Record<string, any>,
): boolean {
  return (
    formState &&
    formState.data &&
    formState.data.taxStatus === SELLER_ENUM.tax.vat.status.REGISTERED
  );
}

/**
 * SellerProfileFormModel
 */
export const SellerProfileFormModel = {
  settings: {
    layout: 'inline',
    method: 'post',
  },
  fields: [
    {
      tag: 'input',
      type: 'text',
      name: 'name',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
      placeholder: 'forms.seller.label.name',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'storeCode',
      selectProps: { native: false, displayEmpty: true },
      selectOptions: storeCodeOptions,
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      placeholder: 'forms.seller.label.defaultStore',
      hint: 'forms.seller.hints.defaultStore',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'locale',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      selectProps: { native: false, displayEmpty: true },
      selectOptions: selectOptions(SELLER_ENUM.locale, 'components.seller.locale.'),
      placeholder: 'forms.common.locale',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'shipsCountryCode',
      selectProps: { native: false, displayEmpty: true },
      selectOptions: countriesListOptions,
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      placeholder: 'forms.seller.label.shippingFrom',
      hint: 'forms.seller.hints.shippingFrom',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'shipsIn',
      selectProps: { native: false, displayEmpty: true },
      selectOptions: workingDaysOptions,
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      placeholder: 'forms.seller.label.shipsIn',
      hint: 'forms.seller.hints.shipsIn',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'contactName',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
      placeholder: 'forms.seller.label.contactName',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'contactEmail',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'email',
          message: 'forms.validation.invalidEmail',
        },
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      placeholder: 'forms.seller.label.contactEmail',
    },
    {
      tag: 'input',
      type: 'phone',
      name: 'contactPhone',
      customPropsMapped: phonePropsMapper,
      validateTrigger: 'onBlur',
      rules: [
        ...FORM_VALIDATIONS.PHONE,
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      placeholder: 'forms.seller.label.contactPhone',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'billingName',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
      placeholder: 'forms.seller.label.billingName',
      hint: 'forms.seller.hints.billingName',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'billingEmail',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'email',
          message: 'forms.validation.invalidEmail',
        },
      ],
      placeholder: 'forms.seller.label.billingEmail',
      hint: 'forms.seller.hints.billingEmail',
    },
    {
      tag: 'input',
      type: 'phone',
      name: 'billingPhone',
      customPropsMapped: phonePropsMapper,
      validateTrigger: 'onBlur',
      rules: FORM_VALIDATIONS.PHONE,
      placeholder: 'forms.seller.label.billingPhone',
      hint: 'forms.seller.hints.billingPhone',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'street1',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
      placeholder: 'forms.seller.label.street1',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'street2',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
      placeholder: 'forms.seller.label.street2',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'city',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 100 },
          message: 'forms.validation.maxLength100',
        },
      ],
      placeholder: 'forms.seller.label.city',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'region',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'stringLength',
          options: { max: 100 },
          message: 'forms.validation.maxLength100',
        },
      ],
      placeholder: 'forms.seller.label.region',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'postCode',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
        {
          type: 'stringLength',
          options: { max: 50 },
          message: 'forms.validation.maxLength50',
        },
      ],
      placeholder: 'forms.seller.label.postCode',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'countryCode',
      selectProps: { native: false, displayEmpty: true },
      selectOptions: countriesListOptions,
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      placeholder: 'forms.seller.label.country',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'companyName',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'stringLength',
          options: { max: 255 },
          message: 'forms.validation.maxLength255',
        },
      ],
      placeholder: 'forms.seller.label.companyName',
      hint: 'forms.seller.hints.companyName',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'companyNumber',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'stringLength',
          options: { max: 50 },
          message: 'forms.validation.maxLength50',
        },
      ],
      placeholder: 'forms.seller.label.companyNumber',
      hint: 'forms.seller.hints.companyNumber',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'taxStatus',
      selectProps: { native: false, displayEmpty: true },
      selectOptions: vatStatusOptions,
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.validation.required',
        },
      ],
      placeholder: 'forms.seller.label.vatStatus',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'vatCode',
      validateTrigger: 'onBlur',
      shouldRender: shouldRenderTaxFields,
      hint: 'forms.seller.hints.vatCode',
      placeholder: 'forms.seller.label.vatCode',
      rules: [
        {
          required: true,
          message: 'forms.seller.validation.requiredVatCode',
          condition: {
            field: 'taxStatus',
            compare: (value: string): boolean =>
              parseInt(value, 10) === SELLER_ENUM.tax.vat.status.REGISTERED,
          },
        },
        {
          type: 'stringLength',
          options: { max: 50 },
          message: 'forms.validation.maxLength50',
        },
      ],
    },
    {
      tag: 'select',
      type: 'text',
      name: 'vatCountryCode',
      selectOptions: countriesListOptions,
      initialOption: FormSelectNoneOption(),
      shouldRender: shouldRenderTaxFields,
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.seller.validation.requiredVatCountryCode',
          condition: {
            field: 'taxStatus',
            compare: (value: string): boolean =>
              parseInt(value, 10) === SELLER_ENUM.tax.vat.status.REGISTERED,
          },
        },
      ],
      placeholder: 'forms.seller.label.vatCountryCode',
      hint: 'forms.seller.hints.vatCountryCode',
    },
  ],
};
