import { useState } from 'react';
import queryString from 'query-string';
import { DocumentNode } from 'graphql';
import { useLocation, useHistory } from 'react-router-dom';
import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { art as ART_ENUM } from '../../config/enumeration.js';
import { getGraphqlOperationName } from '../../services/riseart/apollo/helpers';

function defaultBuildUrl(DEFAULT_ITEMS_PER_PAGE = 10) {
  return function buildUrl({
    page,
    itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
    qsParams,
  }: Record<string, any>) {
    return {
      ...qsParams,
      ...(page && page > 1 ? { page } : null),
      ...(itemsPerPage ? { itemsPerPage } : null),
    };
  };
}

type Props = {
  query: DocumentNode;
  itemsPerPage?: number;
  fetchPolicy?: WatchQueryFetchPolicy | undefined;
  variablesMapper: (
    qsParams: Record<string, any>,
    options: Record<string, any>,
  ) => Record<string, any>;
  buildUrl?: (data: Record<string, any>) => any;
  children: (data: Record<string, any>) => any;
  onChangeParams?: (params: Record<string, any>) => any;
  onChangePage?: (
    params: Record<string, any>,
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => any;
  onChangeRowsPerPage?: (
    params: Record<string, any>,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => any;
  initialState?: Record<string, any>;
};

/**
 * DataListFeed
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const DataListFeed = ({
  query: LIST_QUERY,
  itemsPerPage: initialItemsPerPage = 10,
  fetchPolicy,
  initialState = {},
  buildUrl,
  variablesMapper,
  children,
}: Props): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const isHandledByUrl = typeof buildUrl === 'function';
  const QUERY_OPERATION_NAME = getGraphqlOperationName(LIST_QUERY);
  const DEFAULT_SHOW_PARAM = ART_ENUM.state.ALL;
  const DEFAULT_ITEMS_PER_PAGE = initialItemsPerPage;
  const buildUrlHandler = defaultBuildUrl(DEFAULT_ITEMS_PER_PAGE);
  const rawQsParams: Record<string, any> = queryString.parse(location.search);
  const { itemsPerPage, page = 1, ...restQsParams } = rawQsParams;
  const [qsParams, setQsParams] = useState({
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    page,
    ...restQsParams,
    ...initialState,
  });
  const {
    data,
    previousData,
    loading,
    error,
    refetch: refetchListQuery,
  } = useQuery(LIST_QUERY, {
    variables: variablesMapper(qsParams, { DEFAULT_SHOW_PARAM }),
    ...(fetchPolicy ? { fetchPolicy } : null),
  });

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const params = buildUrlHandler({
      qsParams: restQsParams,
      page: page + 1,
      itemsPerPage: itemsPerPage || DEFAULT_ITEMS_PER_PAGE,
    });

    setQsParams(params);

    if (isHandledByUrl) {
      history.push(buildUrl(params));
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const params = buildUrlHandler({
      qsParams: restQsParams,
      page: 0,
      itemsPerPage: parseInt(event.target.value, 10) || DEFAULT_ITEMS_PER_PAGE,
    });

    setQsParams(params);

    if (isHandledByUrl) {
      history.push(buildUrl(params));
    }
  };

  const handleParamsChange = (inputParams: Record<string, any>) => {
    const params = buildUrlHandler({
      qsParams: { ...qsParams, ...inputParams },
    });

    setQsParams(params);

    if (isHandledByUrl) {
      history.push(buildUrl(params));
    }
  };

  const outputData = data || previousData;
  const queryData = outputData && outputData[QUERY_OPERATION_NAME];

  return children({
    loading,
    error,
    refetchListQuery,
    items: queryData && queryData.items,
    rawData: outputData && outputData[QUERY_OPERATION_NAME],
    initialValues: qsParams,
    handleParamsChange,
    pagination: queryData &&
      queryData.pagination && {
        ...queryData.pagination,
        buildPaginationUrl: buildUrlHandler,
        handleChangeRowsPerPage,
        handleChangePage,
      },
  });
};
