import { ReactElement, ReactChild, ReactChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { ErrorDetails } from '@riseart/dashboard';
import { errors as ERRORS_CONFIG, debug as DEBUG_CONFIG } from '../../../config/config.js';

type Props = {
  showLogo?: boolean;
  children?: ReactChild | ReactChildren | null;
  translate?: boolean;
  error: Record<string, any>;
};

/**
 * ErrorPage
 */
export function ErrorPage({
  showLogo = false,
  children = null,
  error,
  translate = true,
}: Props): ReactElement<any> {
  return (
    <ErrorDetails
      error={error}
      translate={translate}
      showLogo={showLogo}
      defaultError={ERRORS_CONFIG.errorsTypes.__defaultError}
      showTraceback={DEBUG_CONFIG.traceback}
      translationComponent={FormattedMessage}
    >
      {children}
    </ErrorDetails>
  );
}
