import { ApiInvalidData } from '../errors/ApiInvalidData';

/**
 * Types
 */
type Constructor<T> = new (...args: any[]) => T;

type StoreItem = {
  storeCode: string;
  [key: string]: any;
};

type StoreClass = {
  stores: Array<StoreItem>;
  [key: string]: any;
};

type LocaleItem = {
  localeCode: string;
  [key: string]: any;
};

type LocaleClass = {
  locales: Array<LocaleItem>;
  [key: string]: any;
};

/**
 * StoreMixin
 *
 * @param {StoreClass} SuperClass
 */
function StoreMixin<T extends Constructor<StoreClass>, S extends Constructor<StoreItem>>(
  SuperClass: T,
  ItemClass: S,
): any {
  return class extends SuperClass {
    /**
     * hydrateFromApiData
     *
     * @param {Array<Record<string, any>>} data
     * @returns {StoreClass}
     */
    public hydrateFromApiData(data?: Record<string, any>): StoreClass {
      try {
        const { stores: storesData, ...modelData } = data || {};

        // Stores
        const stores: Array<StoreItem> = storesData
          ? storesData.map((item: Record<string, any>) => {
              const { store, ...storeData } = item || {};
              return new ItemClass().hydrate({
                storeCode: (store && store.code) || null,
                ...storeData,
              });
            })
          : [];

        return this.hydrate({
          stores,
          ...modelData,
        });
      } catch (error) {
        throw new ApiInvalidData('Unable to load StoreClass from provided data.');
      }
    }

    /**
     * getStore
     *
     * @param {string} storeCode
     * @returns {any}
     */
    public getStore(storeCode: string): StoreItem | undefined {
      if (this.stores) {
        return this.stores.find((store: StoreItem) => store.storeCode === storeCode);
      }
    }

    /**
     * getStoreValue
     *
     * @param {string} storeCode
     * @param {string} field
     * @returns {any}
     */
    public getStoreValue(storeCode: string, field: string): any {
      const store = this.getStore(storeCode);
      if (store) {
        return store[field];
      }
    }
  };
}

/**
 * LocaleMixin
 *
 * @param {LocaleClass} SuperClass
 */
function LocaleMixin<T extends Constructor<LocaleClass>, L extends Constructor<LocaleItem>>(
  SuperClass: T,
  ItemClass: L,
): any {
  return class extends SuperClass {
    /**
     * hydrateFromApiData
     *
     * @param {Array<Record<string, any>>} data
     * @returns {LocaleClass}
     */
    public hydrateFromApiData(data?: Record<string, any>): LocaleClass {
      try {
        const { locales: localesData, ...modelData } = data || {};

        // Locales
        const locales: Array<LocaleItem> = localesData
          ? localesData.map((item: Record<string, any>) => {
              const { locale, ...localeData } = item || {};
              return new ItemClass().hydrate({
                localeCode: (locale && locale.code) || null,
                ...localeData,
              });
            })
          : [];

        return this.hydrate({
          locales,
          ...modelData,
        });
      } catch (error) {
        throw new ApiInvalidData('Unable to load LocaleClass from provided data.');
      }
    }

    /**
     * getLocale
     *
     * @param {string} localeCode
     * @returns {any}
     */
    public getLocale(localeCode: string): LocaleItem | undefined {
      if (this.locales) {
        return this.locales.find((locale: LocaleItem) => locale.localeCode === localeCode);
      }
    }

    /**
     * getLocaleValue
     *
     * @param {string} localeCode
     * @param {string} field
     * @returns {any}
     */
    public getLocaleValue(localeCode: string, field: string): any {
      const locale = this.getLocale(localeCode);
      if (locale) {
        return locale[field];
      }
    }
  };
}

/**
 * FormDataMixin
 *
 * @param {LocaleClass} SuperClass
 */
function FormDataMixin<T extends Constructor<any>>(SuperClass: T): any {
  return class extends SuperClass {
    /**
     * mapFormToApiData
     *
     * @param {Record<string, any>} data
     * @param {Record<string, any>[]} fieldsSchema
     * @param {boolean} includeNullValues
     * @returns {Record<string, any>}
     */
    public static mapFormToApiData(
      data: Record<string, any>,
      fieldsSchema: Record<string, any>[],
      includeNullValues = true,
    ): Record<string, any> {
      const schema = fieldsSchema.reduce((accumulator, item) => {
        // readOnly fields are excluded from the sent input data
        if (typeof item.isReadonly === 'function' && item.isReadonly()) {
          return accumulator;
        } else {
          accumulator[item.name] = item;
        }
        return accumulator;
      }, {});

      return Object.keys(schema).reduce((accumulator: Record<string, any>, fieldName: string) => {
        if (schema[fieldName] && schema[fieldName].valueType === 'json') {
          // Parse JSON formatted data (e.g. art filter dimensions)
          const parsedValue = JSON.parse(data[fieldName]);
          // reset the value, as it may update different fields in API
          accumulator = {
            ...accumulator,
            ...(schema[fieldName].resetValue || null),
            ...parsedValue,
          };
        } else if (
          // Any empty string values will be send as null
          data[fieldName] === '' ||
          (data[fieldName] && data[fieldName].trim && data[fieldName].trim() === '')
        ) {
          if (includeNullValues) {
            accumulator[fieldName] = null;
          }
        } else if (
          schema[fieldName] !== undefined &&
          (schema[fieldName] !== null || (includeNullValues && schema[fieldName] === null))
        ) {
          // Other found in form field schema fields will be collected
          accumulator[fieldName] = data[fieldName];
        }

        return accumulator;
      }, {});
    }
  };
}

export { StoreMixin, LocaleMixin, FormDataMixin };
