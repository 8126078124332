import { gql } from '@apollo/client';
import { ARTIST_FEED_FRAGMENT } from '../../fragments/artist/feed.graphql';

export const UPDATE_ARTIST_FEED = gql`
  ${ARTIST_FEED_FRAGMENT}
  mutation updateArtistFeed($id: ID!, $artistId: Int!, $inputArtistFeed: InputArtistFeed!) {
    updateArtistFeed(id: $id, artistId: $artistId, inputArtistFeed: $inputArtistFeed) {
      ...ArtistFeedFragment
    }
  }
`;
