import isFunction from 'lodash/isFunction';
import { isRoleAllowedForACL } from '../../services/riseart/utils/Auth';
import { ACLContext } from './ACLContext';

/**
 * ACLProvider
 *
 * Usage:
 * // using a render prop
 *  <ACLProvider profileRole="visitor" render={
 *      (isAllowed, resourcePermissions) => {
 *          return isAllowed ? <div>Allowed</div> : <div>Restricted</div>
 *          }
 *      }
 *  />
 *
 * // using function as children
 * // note that this is directly passed as children to context consumer
 * // and it is up to the developer to add the render logic
 *  <ACLProvider>
 *      {(resourcePermissions) => (
 *          resourcePermissions.indexOf('admin') !== -1 ? <div>Allowed</div> : <div>Restricted</div>
 *      )}
 *  </ACLProvider>
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const ACLProvider = ({
  profileRole,
  render = () => null,
  children = null,
}: {
  profileRole: string;
  render: (
    isAllowed: boolean,
    resourcePermissions: string[] | Record<string, any>,
  ) => JSX.Element | null;
  children: ((data: any) => JSX.Element) | null;
}): JSX.Element => (
  <ACLContext.Consumer>
    {isFunction(children)
      ? children
      : (resourcePermissions: string[] | Record<string, any> = []) =>
          render(isRoleAllowedForACL(resourcePermissions, profileRole), resourcePermissions)}
  </ACLContext.Consumer>
);
