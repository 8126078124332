import { FormattedMessage } from 'react-intl';
import Chip from '@mui/material/Chip';
import { components as COMPONENTS_CONFIG } from '../../config/config.js';

type Props = {
  type: string;
  status: string | number;
  translationPrefix?: string;
};

/**
 * Status
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export function Status({ type, status, translationPrefix }: Props): JSX.Element {
  const TRANSLATION_PREFIX: Record<string, string> = {
    artist: 'components.artist.status',
    event: 'components.event.state',
    offer: 'components.offer.status',
    partnerUser: 'components.partner.user.status',
  };

  // @ts-ignore
  const statusConfig: Record<string, any> | undefined = COMPONENTS_CONFIG.status[type];

  return (
    <Chip
      color={(statusConfig && statusConfig[`${status}`]) || 'default'}
      label={<FormattedMessage id={`${translationPrefix || TRANSLATION_PREFIX[type]}.${status}`} />}
    />
  );
}
