import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link, SplitButton } from '@riseart/dashboard';
import { splitButtonConfirmationProps } from '../../../common/buttons/settings';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { SellerListActionButton } from '../../../seller/ListActionButton';
import { formatCurrency, parseFloatToLocaleString } from '../../../../services/riseart/utils/Utils';
import { SchemaType } from './types';

export const SELLER_SHIPPING_TABLE_SCHEMA: SchemaType = [
  {
    key: 'name',
    render: ({ data }: Record<string, any>) => (
      <>
        <Link to={UrlAssembler.shippingDetail(data.id)} title={data.name}>
          {data.name}
        </Link>
        {data.default ? (
          <Typography
            component="span"
            sx={{ fontStyle: 'italic', fontSize: '13px', color: '#9e9e9e' }}
          >
            {' '}
            &#8212; <FormattedMessage id="components.seller.defaultTable" />
          </Typography>
        ) : null}
      </>
    ),
  },
  {
    key: 'currency',
    render: ({ data }: Record<string, any>) => (
      <FormattedMessage id="components.seller.ratesIn" values={{ currency: data.currency }} />
    ),
  },
  {
    key: 'inheritsFrom',
    render: ({ data }: Record<string, any>) => (data.default ? '-' : data.parentName),
  },
  {
    key: 'actions',
    render: ({ data, customData, refetchListQuery }: Record<string, any>): JSX.Element => {
      return (
        <SellerListActionButton
          data={data}
          sellerId={customData.sellerId}
          refetchQuery={refetchListQuery}
        />
      );
    },
  },
];

export const SELLER_SHIPPING_TABLE_RATES_SCHEMA: SchemaType = [
  {
    key: 'destination',
    render: ({ data }) =>
      data.regionCode || data.countryCode ? (
        <>
          <FormattedMessage
            id={data.regionCode ? `regions.${data.regionCode}` : `countries.${data.countryCode}`}
          />
          <br />
          <Typography component="div" sx={{ fontSize: '13px', color: '#9e9e9e' }}>
            <FormattedMessage id={`common.${data.regionCode ? 'region' : 'country'}`} />
            {data.inherited ? (
              <Typography component="span" sx={{ fontStyle: 'italic', fontSize: 'inherit' }}>
                {' '}
                &#8212; <FormattedMessage id={`components.seller.inherited`} />
              </Typography>
            ) : null}
          </Typography>
        </>
      ) : null,
  },
  {
    key: 'baseCost',
    render: ({ data, customData }) => formatCurrency(data.baseCost, customData.storeCode),
  },
  {
    key: 'pctCost',
    render: ({ data }) => <>{parseFloatToLocaleString(data.pctCost)}%</>,
  },
  {
    key: 'actions',
    render: ({
      data,
      handleToggleMode,
      customData,
      deleteLoading,
    }: Record<string, any>): JSX.Element => {
      const handleClick = handleToggleMode(true);
      if (data.inherited) {
        return (
          <Button variant="contained" onClick={handleClick}>
            <FormattedMessage id="common.override" />
          </Button>
        );
      }
      return (
        <SplitButton
          disabled={deleteLoading}
          mainButtonProps={{
            onClick: handleClick,
            children: <FormattedMessage id="common.edit" />,
          }}
          translationPrefix="components.dialog.sellerRate.action"
          confirmationDialogProps={splitButtonConfirmationProps}
          options={[
            {
              actionKey: 'deleteRate',
              requireConfirmation: true,
              text: <FormattedMessage id="common.delete" />,
              onClick: () => {
                customData
                  .deleteRate({
                    variables: {
                      id: data.id,
                      tableId: data.tableId,
                      sellerId: customData.sellerId,
                    },
                  })
                  .then(() => {
                    customData.refetchListQuery && customData.refetchListQuery();
                  })
                  .catch(() => null);
              },
            },
          ]}
        />
      );
    },
  },
];
