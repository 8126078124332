import { gql } from '@apollo/client';

export const PARTNER_ADMIN_FRAGMENT = gql`
  fragment PartnerAdminFragment on PartnerAdmin {
    userId
    partnerId
    status
    aclRole
    userStatus
    userEmail
    userFirstName
    userLastName
    userRole
  }
`;
