import { seller as SELLER_ENUM } from '../../config/enumeration.js';

/**
 * mapToFormData
 *
 * @param {Record<string, any>} rawData
 * @param {string} formFields
 * @returns {Record<string, any>}
 */
export function mapToFormData(
  rawData: Record<string, any> = {},
  formFields: Record<string, any>[],
): Record<string, any> {
  return {
    ...formFields.reduce(
      (accumulator, { name }) => ({
        ...accumulator,
        [name]: typeof rawData[name] !== 'undefined' ? rawData[name] : null,
      }),
      {},
    ),
    postCode: rawData.postcode,
  };
}

/**
 * mapFormToSellerData
 *
 * @param {Record<string, any>} formData
 * @returns {Record<string, any>}
 */
export function mapFormToSellerData(formData: Record<string, any>): Record<string, any> {
  return {
    ...formData,
    ...(formData.taxStatus !== SELLER_ENUM.tax.vat.status.REGISTERED
      ? { vatCode: null, vatCountryCode: null }
      : null),
  };
}

/**
 * mapToAwayModeFormData
 *
 * @param {Object} rawData
 * @param {string} formFields
 * @returns {Record<string, any>}
 */
export function mapToAwayModeFormData(rawData: Record<string, any> = {}): Record<string, any> {
  return {
    away: rawData.away,
    awayDates: [rawData.awayFrom || null, rawData.awayTo || null],
  };
}
