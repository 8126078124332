import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { application as APP_CONFIG } from '../../config/config.js';
import { selectLocale } from '../../services/redux/selectors/gui';
import { guiUpdateSettings } from '../../services/redux/actions/application/gui';
import styles from './LocaleSelect.module.css';

type Props = {
  label?: JSX.Element | string | undefined;
  hint?: JSX.Element | string | undefined;
};

/**
 * LocaleSelect
 *
 * @returns {JSX.Element}
 */
export function LocaleSelect({ label, hint }: Props): JSX.Element {
  const dispatch = useDispatch();
  const locale = useSelector(selectLocale);

  /**
   * handleLocaleChange
   *
   * @param {Record<string, any>} formState
   */
  const handleLocaleChange = useCallback(
    (event: Record<string, any>) => {
      const newLocale = event.target.value;

      dispatch(guiUpdateSettings({ locale: newLocale }));

      if (locale !== newLocale) {
        // Reload the page when locale changes
        window.location.reload();
      }
    },
    [locale],
  );

  return (
    <TextField
      type="select"
      variant="standard"
      label={label}
      helperText={hint}
      select
      fullWidth
      value={locale}
      onChange={handleLocaleChange}
      className={styles.localeSelect}
    >
      {APP_CONFIG.i18n.locales.map(({ name }) => {
        return (
          <MenuItem key={name} value={name}>
            <FormattedMessage id={`locales.${name}`} />
          </MenuItem>
        );
      })}
    </TextField>
  );
}
