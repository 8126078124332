import { gql } from '@apollo/client';
import { ART_FRAGMENT } from '../art/art.graphql';

export const OFFER_FRAGMENT = gql`
  ${ART_FRAGMENT}
  fragment OfferFragment on Offer {
    id
    status
    state
    created
    updated
    acceptedDate
    expiryDate
    productSku
    productPrice
    userId
    offerCurrencyCode
    offerAmount
    offerCounter
    offerAgreed
    sellerCurrencyCode
    sellerProductPrice
    sellerAmount
    sellerCounter
    sellerAgreed
    artId
    canAccept
    canCounter
    canDecline
    canExtend
    canCancel
    storeCode
    art {
      ...ArtFragment
    }
  }
`;
