import { gql } from '@apollo/client';
import { ARTIST_FEED_FRAGMENT } from '../../fragments/artist/feed.graphql';

export const CREATE_ARTIST_FEED = gql`
  ${ARTIST_FEED_FRAGMENT}
  mutation createArtistFeed($artistId: Int!, $inputArtistFeed: InputArtistFeed!) {
    createArtistFeed(artistId: $artistId, inputArtistFeed: $inputArtistFeed) {
      ...ArtistFeedFragment
    }
  }
`;
