import { useEffect, SyntheticEvent } from 'react';
import { useIntl } from 'react-intl';
import { useQuery, useMutation } from '@apollo/client';
import Grid from '@mui/material/Grid';
import {
  ConfirmationDialog,
  FileList,
  FileListItem,
  FormViewField,
  useUploadedFiles,
  FormBuilder,
  FormWrapper,
  FormSideItem,
} from '@riseart/dashboard';
import { FileUploader } from '@riseart/files';
import { feed as FEED_CONFIG } from '../../../config/config.js';
import {
  errors as ERRORS_ENUM,
  artist as ARTIST_ENUM,
  file as FILE_ENUM,
} from '../../../config/enumeration.js';
import { useNotification } from '../../../data/hooks/useNotification';
import { Picture } from '../../common/artdirection/Picture';
import { FileDropzone } from '../../common/file/Dropzone';
import { fileListClassName } from '../../common/file/ListItem';
import { FileRestrictionsHint } from '../../common/file/RestrictionsHint';
import { ArtistFeedModel } from '../../forms/models/artist/feed';
import { mapApiToFormData, mapFormToApiData } from '../../../data/normalizers/common';
import { extractImageFileFromData } from '../../../services/riseart/utils/Image';
import { mapApiValidationErrors, mapApiErrors } from '../../../services/riseart/utils/Form';
import { createFileUpload, updateFileUpload } from '../../../data/gql/handlers';
import { READ_ARTIST_FEED } from '../../../data/gql/queries/artist/readFeed.graphql';
import { UPDATE_ARTIST_FEED } from '../../../data/gql/queries/artist/updateFeed.graphql';

type Props = {
  artistId: number;
  feedId: number;
  onData: (data: Record<string, any>) => void;
  onLoading?: (loading: boolean) => void;
  scrollToRef: () => void;
};

/**
 * ArtistFeedEdit
 *
 * @returns {JSX.Element}
 */
export const ArtistFeedEdit = ({
  artistId,
  feedId,
  onLoading,
  onData,
  scrollToRef,
}: Props): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const {
    uploadedFiles,
    handleFileDrop: handleFileDropCallback,
    handleFileValidation,
  } = useUploadedFiles({
    filesByType: { main: [] },
    maxFileSize: FEED_CONFIG.maxFilesize,
    sizeValidationMessage: formatMessage(
      { id: 'forms.common.largerFilesize' },
      { maxFilesize: FEED_CONFIG.maxFilesize },
    ),
  });
  const { dispatchNotification } = useNotification();
  const [updateFeed, { loading: updateLoading }] = useMutation(UPDATE_ARTIST_FEED);
  const {
    data: readData,
    loading: readLoading,
    refetch: refetchReadFeed,
  } = useQuery(READ_ARTIST_FEED, {
    fetchPolicy: 'network-only',
    variables: { id: feedId, artistId },
  });
  const feedData = (readData && readData.readArtistFeed) || null;
  const currentImage =
    feedData &&
    feedData.images &&
    feedData.images.length &&
    extractImageFileFromData.artistFeed(feedData.images, 'SQUARE');
  const currentImageElement = currentImage ? (
    <Picture
      type="form.main"
      artDirectionKey="artist.feed"
      image={currentImage}
      alt={feedData.title}
    />
  ) : null;

  /**
   * handleFileUpload
   *
   * @param {Record<string, any>[]} files
   * @param {ImageType} type
   * @param {(state: Record<string, any> | null) => () => void} callback
   * @returns {void}
   */
  const handleFileUpload = (
    files: Record<string, any>[],
    type: string,
    callback: (state: Record<string, any> | null) => () => void,
  ) => {
    const FileUploadClient = new FileUploader(
      FileUploader.flattenFiles({ [type]: files }),
      feedData.id,
      FILE_ENUM.object.type.ARTIST_FEED,
      createFileUpload,
      updateFileUpload,
    );
    FileUploadClient.subscribe(FileUploader.eventsTypes.UPDATE, (fileUploadState) => {
      callback(fileUploadState.files);
    });
    FileUploadClient.subscribe(FileUploader.eventsTypes.COMPLETE, (fileUploadState) => {
      refetchReadFeed()
        .then(() => callback(fileUploadState.files))
        .catch(() => callback(null));
    });
    FileUploadClient.process();
  };

  useEffect(() => {
    if (typeof onLoading === 'function') {
      onLoading(readLoading || updateLoading);
    }
  }, [readLoading, updateLoading]);

  useEffect(() => {
    if (feedData) {
      onData(feedData);
    }
  }, [feedData]);

  return feedData ? (
    <Grid container spacing={4}>
      {feedData.mediaType === ARTIST_ENUM.feed.media.type.IMAGE ? (
        <Grid item xs={12} sm={5}>
          <FormSideItem>
            <ConfirmationDialog
              title={formatMessage({ id: 'components.dialog.replaceFeedImage.title' })}
              description={formatMessage({
                id: 'components.dialog.replaceFeedImage.description',
              })}
              disagreeLabel={formatMessage({ id: 'common.no' })}
              agreeLabel={formatMessage({ id: 'common.yes' })}
              onAgree={(event: SyntheticEvent, ...rest) => {
                handleFileUpload(
                  ...rest,
                  ARTIST_ENUM.feed.image.category.MAIN,
                  handleFileDropCallback(ARTIST_ENUM.feed.image.category.MAIN),
                );
              }}
            >
              {(handleOpen) => (
                <FileDropzone
                  label={formatMessage({ id: 'forms.feed.label.image' })}
                  uploadedImage={currentImageElement}
                  text={formatMessage({ id: 'forms.feed.hints.image' })}
                  textMiddle={formatMessage({ id: 'common.or' })}
                  textButton={formatMessage({ id: 'common.browseFiles' })}
                  onDrop={handleOpen}
                  validator={handleFileValidation}
                  accept={FEED_CONFIG.acceptedFiles}
                  multiple={false}
                  hint={
                    <FileRestrictionsHint translationKey="forms.feed.hints.imageUploadRestrictions" />
                  }
                />
              )}
            </ConfirmationDialog>
            {uploadedFiles && uploadedFiles.main ? (
              <FileList>
                {uploadedFiles.main.map(
                  ({ name, progress, size, errorMessage }: Record<string, any>, idx: number) => (
                    <FileListItem
                      key={`${name}${idx}`}
                      className={fileListClassName}
                      name={name}
                      size={size}
                      errors={errorMessage}
                      progress={progress || 0}
                      status="loading"
                    />
                  ),
                )}
              </FileList>
            ) : null}
          </FormSideItem>
        </Grid>
      ) : null}
      <Grid item xs={12} sm={7}>
        <FormWrapper size="medium">
          <FormViewField label={formatMessage({ id: 'forms.feed.label.type' })} sx={{ mt: 0 }}>
            {formatMessage({ id: `components.feed.media.type.${feedData.mediaType}` })}
          </FormViewField>
          <FormBuilder
            settings={ArtistFeedModel.settings}
            fields={ArtistFeedModel.fields}
            initialValues={mapApiToFormData(feedData, ArtistFeedModel.fields)}
            showReset
            submitText={formatMessage({ id: 'common.saveChanges' })}
            customData={{
              mediaType: feedData.mediaType,
            }}
            onSubmit={async (formState, addApiErrors) => {
              updateFeed({
                variables: {
                  id: feedData.id,
                  artistId: feedData.artistId,
                  inputArtistFeed: {
                    mediaType: feedData.mediaType,
                    ...mapFormToApiData(formState.data, ArtistFeedModel.fields),
                  },
                },
              })
                .then(() => {
                  refetchReadFeed().catch(() => null);

                  // Scroll to top of container
                  scrollToRef();

                  // Show artowrk update success notification
                  dispatchNotification({
                    detail: 'components.notifications.itemUpdated',
                    level: ERRORS_ENUM.levels.SUCCESS,
                    placeholder: ERRORS_ENUM.placeholders.SNACKBAR,
                    expire: 5,
                  });
                })
                .catch((error) => {
                  // Map validation errors from response
                  const validationError = mapApiValidationErrors(error);

                  if (validationError && validationError.length && addApiErrors) {
                    addApiErrors(validationError);
                  } else {
                    // Map any other type of errors from response (validation without provided fields, or non-validation errors)
                    mapApiErrors(error).forEach((err: string) => {
                      dispatchNotification({
                        detail: err,
                        level: ERRORS_ENUM.levels.ERROR,
                        placeholder: ERRORS_ENUM.placeholders.PAGE,
                      });
                    });
                  }

                  // Scroll to error
                  scrollToRef();
                });
            }}
          />
        </FormWrapper>
      </Grid>
    </Grid>
  ) : null;
};
