import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import { LayoutPaper, Link } from '@riseart/dashboard';
import { useScrollToEl } from '../../../../data/hooks/useScrollToEl';
import { selectSeller } from '../../../../services/redux/selectors/seller';
import { selectPartner } from '../../../../services/redux/selectors/partner';
import { DefaultLayout } from '../../../layout/Default';
import { PageSubmenu } from '../../../common/PageSubmenu';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { GET_PARTNER_ARTIST } from '../../../../data/gql/queries/partner/getArtist.graphql';
import { ArtAdd } from '../../../art/Add';

/**
 * ArtistArtAddPage
 *
 * @returns {JSX.Element}
 */
export const ArtistArtAddPage = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const { setScrollRef, scrollToRef } = useScrollToEl();
  const [loading, setLoading] = useState(false);
  const artistListLabel = formatMessage({ id: 'components.mainMenu.artistsList' });
  const listLabel = formatMessage({ id: 'components.artist.tab.artworks' });
  const { id: artistId }: Record<string, any> = useParams();
  const { id: sellerId, currencyCode, storeCode, canRentArt } = useSelector(selectSeller) || {};
  const partner = useSelector(selectPartner) || {};
  const { data: listArtist, loading: listLoading } = useQuery(GET_PARTNER_ARTIST, {
    fetchPolicy: 'network-only',
    variables: {
      partnerId: partner && partner.id,
      filters: { artistId: { value: artistId, operator: 'EQUAL' } },
    },
  });
  const partnerArtist =
    listArtist && listArtist.artist && listArtist.artist.items && listArtist.artist.items[0];
  const artListUrl = UrlAssembler.artistArtList(artistId);

  return (
    <DefaultLayout
      loading={loading || listLoading}
      title={artistListLabel}
      breadcrumbs={[
        <Link
          key="home"
          to={UrlAssembler.byRouteKey('home')}
          title={formatMessage({ id: 'common.home' })}
          underline="hover"
          color="inherit"
        >
          {formatMessage({ id: 'common.home' })}
        </Link>,
        <Link
          key="allArtists"
          to={UrlAssembler.artistList()}
          title={artistListLabel}
          underline="hover"
          color="inherit"
        >
          {artistListLabel}
        </Link>,
        ...(partnerArtist && partnerArtist.artist
          ? [
              <Link
                key="profile"
                to={UrlAssembler.artistDetails(artistId)}
                title={partnerArtist.artist.name}
                underline="hover"
                color="inherit"
              >
                {partnerArtist.artist.name}
              </Link>,
              <Link
                key="artList"
                to={artListUrl}
                title={listLabel}
                underline="hover"
                color="inherit"
              >
                {listLabel}
              </Link>,
              <Typography key="add" variant="inherit">
                {formatMessage({ id: 'common.add' })}
              </Typography>,
            ]
          : []),
      ]}
      boxRefCallback={setScrollRef}
    >
      <PageSubmenu menu="artist" selected="artistArtList" urlParams={{ id: artistId }} />
      <LayoutPaper>
        <ArtAdd
          artist={{ id: artistId }}
          sellerId={sellerId}
          canRentArt={canRentArt}
          currencyCode={currencyCode}
          storeCode={storeCode}
          scrollToRef={scrollToRef}
          artListUrl={artListUrl}
          detailArtAssembler={(id: number) => UrlAssembler.artistArtDetail(artistId, id)}
          onLoading={setLoading}
        />
      </LayoutPaper>
    </DefaultLayout>
  );
};
