import { gql } from '@apollo/client';
import { ARTIST_EXHIBITION_FRAGMENT } from '../../fragments/artist/exhibition.graphql';

export const FILTER_ARTIST_EXHIBITIONS = gql`
  ${ARTIST_EXHIBITION_FRAGMENT}
  query filterArtistExhibitions($artistId: Int!, $type: String) {
    filterArtistExhibitions(artistId: $artistId, type: $type) {
      items {
        ...ArtistExhibitionFragment
      }
    }
  }
`;
